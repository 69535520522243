import { Component, ElementRef, computed, effect, input, output, signal, viewChild } from '@angular/core'
import { Params } from '@angular/router'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import {
  IndexedLogNote,
  IndexedLogSheet,
  IndexedOrder,
  IndexedRecording,
  IndexedRemark,
  SearchResult,
  SearchResultType,
  SearchResultTypeDiscriminator,
  SearchedCase,
  SearchedHearing,
} from '@ftr/contracts/api/search'
import { ElasticsearchIndex, VocabularyTerms } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { DestroySubscribers } from '@ftr/foundation'
import { AppPaths, CasePaths, CourtSystemPaths, TAB_QUERY_PARAM } from '@ftr/routing-paths'
import {
  SearchFocusState,
  getPlaybackStart,
  getPlaybackStartForLogNote,
  getPlaybackStartForLogSheet,
  getPlaybackStartForRemark,
  isIndexedLogNote,
  isIndexedLogSheet,
  isIndexedOrder,
  isIndexedRecording,
  isIndexedRemark,
  isSearchedCase,
  isSearchedHearing,
  viewOrderLink,
  viewRecordingLink,
} from '@ftr/ui-search'
import { ZoneId } from '@js-joda/core'
import { LOG_SHEET_TAB_VALUE, STT_TAB_VALUE } from '~app/features/recording-playback/recording-playback-tab.service'

export enum SearchNavigationDirection {
  Up = 'up',
  Down = 'down',
}

export interface SearchResultViewModel {
  content: SearchResult
  link: string[] | undefined
  queryParams: Params
  id: string
  /**
   * The index in the list it was shown (0 indexed)
   */
  index: number
}

export interface SearchResultLinkData {
  link: string[] | undefined
  queryParams: Params
}

@Component({
  selector: 'ftr-search-results-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.css'],
})
export class SearchResultsListComponent extends DestroySubscribers {
  isSearchWithinResource = input.required<boolean>()
  searchResults = input.required<SearchResult[]>()
  configuration = input.required<PublicConfiguration>()
  searchFocusState = input.required<SearchFocusState>()
  vocabularyTerms = input.required<VocabularyTerms>()

  onItemSelected = output<SearchResultViewModel>()
  onNewSearchFocusState = output<SearchFocusState>()

  resultsList = viewChild.required<ElementRef<HTMLElement>>('resultsList')

  searchItems = computed<SearchResultViewModel[]>(() => {
    const searchResults = this.searchResults()
    return searchResults.map((content, index) => this.buildViewSearchResult(content, index))
  })
  activeSearchIdLoading = signal<Uuid | null>(null)

  constructor() {
    super()

    effect(() => {
      const focusState = this.searchFocusState()
      if (focusState !== SearchFocusState.RESULTS_LIST) {
        return
      }
      const firstItem = this.resultsList().nativeElement.children?.[0]?.querySelector('a')
      if (firstItem) {
        firstItem.focus()
      } else {
        this.onNewSearchFocusState.emit(SearchFocusState.NONE)
      }
    })
  }

  onItemClick(data: SearchResultViewModel): void {
    this.activeSearchIdLoading.set(data.id)
    this.onItemSelected.emit(data)
  }

  onResultUp(event: Event): void {
    this.navigate(event, SearchNavigationDirection.Up)
  }

  onResultDown(event: Event): void {
    this.navigate(event, SearchNavigationDirection.Down)
  }

  onResultKeyDown($event: KeyboardEvent, item: SearchResultViewModel): void {
    if ($event.key === 'Enter') {
      this.onItemClick(item)
    } else if ($event.key === 'ArrowUp') {
      this.onResultUp($event)
    } else if ($event.key === 'ArrowDown') {
      this.onResultDown($event)
    }
  }
  onItemFocus(): void {
    this.onNewSearchFocusState.emit(SearchFocusState.RESULTS_LIST)
  }

  private buildViewSearchResult(data: SearchResult, index: number): SearchResultViewModel {
    const { link, queryParams } = this.createLinkForSearchResult(data)

    return {
      content: data,
      link,
      queryParams,
      id: index.toString(),
      index,
    }
  }

  private navigate(event: Event, direction: SearchNavigationDirection): void {
    const target = (event.target as HTMLElement).closest('ftr-complex-list-item') as HTMLElement
    if (!target) {
      return
    }
    const scrollIntoViewOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
    }
    event.preventDefault()
    if (direction === SearchNavigationDirection.Up) {
      const previousElementSibling = target.previousElementSibling?.querySelector('a') as HTMLElement
      if (previousElementSibling) {
        previousElementSibling.focus()
        previousElementSibling.scrollIntoView(scrollIntoViewOptions)
      } else {
        this.onNewSearchFocusState.emit(SearchFocusState.SEARCH_INPUT)
      }
    } else if (direction === SearchNavigationDirection.Down) {
      const nextElementSibling = target.nextElementSibling?.querySelector('a') as HTMLElement
      if (nextElementSibling) {
        nextElementSibling.focus()
        nextElementSibling.scrollIntoView(scrollIntoViewOptions)
      }
    }
  }

  private createLinkForSearchResult(result: SearchResult<SearchResultType>): SearchResultLinkData {
    const courtSystemId = this.configuration().courtSystemId

    switch (true) {
      case isIndexedOrder(result.body, result.type):
        return this.getLinkForOrderSearchResult(result.body, courtSystemId)
      case isIndexedRecording(result.body, result.type):
        return this.getLinkForAllRecordingsSearchResult(result.body, courtSystemId)
      case isSearchedCase(result.body, result.type):
        return this.getLinkForCaseSearchResult(result.body, courtSystemId)
      case isSearchedHearing(result.body, result.type):
        return this.getLinkForHearingSearchResult(result.body, courtSystemId)
      case this.isSearchWithinResource():
        return {
          link: undefined,
          queryParams: this.getQueryParamForRecordingSearchResult(
            result.body,
            result.type,
            this.configuration().timeZoneId,
          ),
        }
      default:
        return { link: undefined, queryParams: {} }
    }
  }

  private getLinkForOrderSearchResult(body: IndexedOrder, courtSystemId: Uuid): SearchResultLinkData {
    return { link: ['/', ...viewOrderLink(body.lineItemType, courtSystemId, body.id, body.jobId)], queryParams: {} }
  }

  private getLinkForAllRecordingsSearchResult(body: IndexedRecording, courtSystemId: Uuid): SearchResultLinkData {
    const link = (() => {
      if (body.logNotes?.length) {
        return this.getLinkForRecordingSearchResult(body.logNotes[0], courtSystemId, body.id, body.location)
      } else if (body.logSheets?.length) {
        return this.getLinkForRecordingSearchResult(body.logSheets[0], courtSystemId, body.id, body.location)
      } else if (body.transcript?.length) {
        return this.getLinkForRecordingSearchResult(body.transcript[0], courtSystemId, body.id, body.location)
      }

      return this.getLinkForRecordingSearchResult(body, courtSystemId, body.id, body.location)
    })()
    if (link) {
      link.unshift('/')
    }
    const params = this.getQueryParamForAllRecordingsSearchResult(body, this.configuration().timeZoneId)
    return { link, queryParams: params }
  }

  private getLinkForRecordingSearchResult(
    body: IndexedRecording | IndexedRemark | IndexedLogSheet | IndexedLogNote,
    courtSystemId: Uuid,
    recordingId?: Uuid,
    location?: IndexedRecording['location'],
  ): string[] | undefined {
    const type = ElasticsearchIndex.Recording
    if (isIndexedLogSheet(body, type) || isIndexedLogNote(body, type)) {
      return viewRecordingLink(
        courtSystemId,
        body.courthouseId || location?.courthouseId,
        body.courtroomId || location?.courtroomId,
        recordingId || body.recordingId!,
      )
    } else if (isIndexedRemark(body, type)) {
      return viewRecordingLink(
        courtSystemId,
        body.courthouseId || location?.courthouseId,
        body.courtroomId || location?.courtroomId,
        recordingId || body.recordingId!,
        body.recordingType,
      )
    } else if (isIndexedRecording(body, type)) {
      return viewRecordingLink(
        courtSystemId,
        body.location?.courthouseId,
        body.location?.courtroomId,
        body.id,
        body.recordingType,
      )
    }

    return undefined
  }

  private getLinkForCaseSearchResult(body: SearchedCase, courtSystemId: Uuid): SearchResultLinkData {
    return { link: ['/', AppPaths.CourtSystem, courtSystemId, CourtSystemPaths.Cases, body.id], queryParams: {} }
  }

  private getLinkForHearingSearchResult(body: SearchedHearing, courtSystemId: Uuid): SearchResultLinkData {
    return {
      link: [
        '/',
        AppPaths.CourtSystem,
        courtSystemId,
        CourtSystemPaths.Cases,
        body.caseId,
        CasePaths.HearingPlayback,
        body.id,
      ],
      queryParams: {},
    }
  }

  private getQueryParamForAllRecordingsSearchResult(body: IndexedRecording, courtSystemTimeZoneId: ZoneId): Params {
    const type = ElasticsearchIndex.Recording
    if (body.logNotes?.length) {
      return this.getQueryParamForRecordingSearchResult(body.logNotes[0], type, courtSystemTimeZoneId)
    } else if (body.logSheets?.length) {
      return this.getQueryParamForRecordingSearchResult(body.logSheets[0], type, courtSystemTimeZoneId)
    } else if (body.transcript?.length) {
      return this.getQueryParamForRecordingSearchResult(body.transcript[0], type, courtSystemTimeZoneId)
    }

    return this.getQueryParamForRecordingSearchResult(body, type, courtSystemTimeZoneId)
  }

  private getQueryParamForRecordingSearchResult(
    body: IndexedRecording | IndexedRemark | IndexedLogNote,
    type: SearchResultTypeDiscriminator,
    courtSystemTimeZoneId: ZoneId,
  ): Params {
    if (isIndexedLogNote(body, type)) {
      return { start: getPlaybackStartForLogNote(body, courtSystemTimeZoneId), [TAB_QUERY_PARAM]: LOG_SHEET_TAB_VALUE }
    } else if (isIndexedLogSheet(body, type)) {
      return { start: getPlaybackStartForLogSheet(body, courtSystemTimeZoneId), [TAB_QUERY_PARAM]: LOG_SHEET_TAB_VALUE }
    } else if (isIndexedRemark(body, type)) {
      return { start: getPlaybackStartForRemark(body, courtSystemTimeZoneId), [TAB_QUERY_PARAM]: STT_TAB_VALUE }
    } else if (isIndexedRecording(body, type)) {
      return { start: getPlaybackStart(body, courtSystemTimeZoneId) }
    }

    return {}
  }
}
