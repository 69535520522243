<div class="app-layout" [ngClass]="{ 'app-layout--white-background': routeData?.whiteBackground }">
  <ftr-header [isSearching]="isSearching" [logoData]="logoData"></ftr-header>

  <!-- Setting whiteBackground to true on a route will make the page background white
and add a shadow under the header to separate it from the page content -->
  @if (routeData?.whiteBackground || isSearching) {
    <div class="app-layout__header-shadow"></div>
  }

  @if (!!backToSearchResultsFullUrl && !isPlaybackPage) {
    <ftr-back-to-search-results-legacy
      [pageStyle]="routeData?.pageStyle || defaultPage"
      [isSealingModeOn]="false"
    ></ftr-back-to-search-results-legacy>
  }

  <main [class.searching]="isSearching">
    <ng-content></ng-content>
  </main>

  @if (isFooterVisible) {
    <ftr-footer [hideFooterMargin]="routeData?.hideFooterMargin"></ftr-footer>
  }
</div>
