<div class="search-result">
  <ftr-icon icon="PlayCircleOutline" gapRight="Regular" color="GreyLight"></ftr-icon>
  <div class="search-result__content">
    <div class="search-result__recording-details">
      <p class="card__item card__item--header">
        <a class="card__item-data" [class.search-result__recording-details--no-link]="!hasLink">
          <ftr-date
            [date]="
              searchResult.recordingStart
                | ftrCourtSystemTimeZone
                  : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.timeZoneId }
            "
            [format]="dateFormat.DateWithDay"
          >
          </ftr-date>
        </a>
      </p>
      @if (!isLiveStreaming) {
        <div class="search-result__recording-time">
          <ftr-date
            [date]="
              searchResult.recordingStart
                | ftrCourtSystemTimeZone
                  : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.timeZoneId }
            "
            [format]="dateFormat.TimeWithSeconds"
            [tooltipFormat]="dateFormat.DateAndTime"
          >
          </ftr-date>
          to
          <ftr-date
            [date]="
              searchResult.recordingEnd
                | ftrCourtSystemTimeZone
                  : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.timeZoneId }
            "
            [format]="dateFormat.TimeWithSeconds"
            [tooltipFormat]="dateFormat.DateAndTime"
          ></ftr-date>
        </div>
      } @else {
        <span ftr-live-indicator></span>
      }
    </div>
    <p class="search-result__location">{{ searchResult.location?.name }}</p>

    @if (firstUtterance) {
      <div class="search-result__utterance">
        <div class="search-result__utterance__details">
          <div>
            <ftr-date
              [date]="
                firstUtterance.startTime
                  | ftrCourtSystemTimeZone
                    : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.timeZoneId }
              "
              [format]="dateFormat.TimeWithSeconds"
            >
            </ftr-date>
          </div>
          <div [innerHTML]="firstUtterance.speakerName | ftrHighlightHtml"></div>
        </div>
        <div class="search-result__utterance__content" [innerHTML]="firstUtterance.content | ftrHighlightHtml"></div>
      </div>
    }

    @if (firstLogSheet) {
      <div class="search-result__log-sheet">
        <div class="search-result__log-sheet__details">
          <div>
            <ftr-date
              [date]="
                firstLogSheet.timestamp
                  | ftrCourtSystemTimeZone
                    : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.timeZoneId }
              "
              [format]="dateFormat.TimeWithSeconds"
            >
            </ftr-date>
          </div>
        </div>
        <div
          class="search-result__log-sheet__description"
          [innerHTML]="firstLogSheet.description | ftrHighlightHtml"
        ></div>
      </div>
    }

    @if (firstLogNote) {
      <div class="search-result__log-note">
        <div class="search-result__log-note__details">
          <div>
            <ftr-date
              [date]="
                firstLogNote.timestamp
                  | ftrCourtSystemTimeZone
                    : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.timeZoneId }
              "
              [format]="dateFormat.TimeWithSeconds"
            >
            </ftr-date>
          </div>
          <div [innerHTML]="firstLogNote.speakerName | ftrHighlightHtml"></div>
        </div>
        <div class="search-result__log-note__note" [innerHTML]="firstLogNote.note | ftrHighlightHtml"></div>
      </div>
    }
  </div>
</div>
