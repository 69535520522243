import { animate, style, transition, trigger } from '@angular/animations'
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  effect,
  input,
  untracked,
  viewChild,
} from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { ButtonDirection, ButtonDisplayType, DestroySubscribers, isOfNonZeroLength } from '@ftr/foundation'
import {
  SearchBarInputState,
  SearchFocusState,
  SearchState,
  SetBackToSearchResultsFullUrlAction,
  SetSearchBarInputStateAction,
  SetSearchFocusStateAction,
} from '@ftr/ui-search'
import { Store } from '@ngxs/store'
import { SearchBarDisplayService } from '~app/core/header/search-container-legacy/search-bar-legacy/search-bar-display.service'
import { FormGroupMembers, SearchBarService } from '~app/core/header/search-container/search-bar/search-bar.service'
import { SearchParams } from '~app/pages/search.params'
import { searchValidator } from './search-validator'

const SEARCH_BAR_TRANSITION_MS = 300

@Component({
  selector: 'ftr-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  host: { class: 'search' },
  animations: [
    trigger('expandSearch', [
      transition(':enter', [style({ width: 0 }), animate(SEARCH_BAR_TRANSITION_MS, style({ width: '100%' }))]),
    ]),
  ],
})
export class SearchBarComponent extends DestroySubscribers implements OnDestroy {
  @Input() isMobile: boolean
  @Input() isTablet: boolean
  @Input() isDesktop: boolean
  isSearching = input.required<boolean>()
  searchTerm = input.required<string>()
  searchFocusState = input.required<SearchFocusState>()

  searchIpt = viewChild<ElementRef<HTMLInputElement>>('searchIpt')

  protected readonly searchBarInputState = toSignal(this.store.select(SearchState.searchBarInputState), {
    initialValue: SearchBarInputState.Collapsed,
  })

  formGroup: FormGroup

  readonly buttonDisplayType = ButtonDisplayType
  readonly buttonDirection = ButtonDirection

  readonly inputStates = SearchBarInputState

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store,
    readonly searchBarDisplayService: SearchBarDisplayService,
    readonly searchBarService: SearchBarService,
  ) {
    super()
    this.formGroup = this.formBuilder.group({
      searchTerm: ['', [searchValidator]],
    })
    this.syncFormGroup()
    this.syncFocusState()
    this.syncInputFocus()
  }

  syncFormGroup(): void {
    effect(() => {
      const searchTerm = this.searchTerm()

      this.formGroup.patchValue({
        searchTerm: searchTerm || null,
      })
      if (!searchTerm || searchTerm.length === 0) {
        this.formGroup.controls.searchTerm.reset()
      }
    })
  }

  syncFocusState(): void {
    effect(() => {
      const searchInput = this.searchIpt()?.nativeElement
      if (!searchInput) {
        return
      }
      searchInput.addEventListener('focus', () => {
        this.onSearchInputFocus()
      })

      const searchTerm = this.searchTerm()
      if (isOfNonZeroLength(searchTerm)) {
        searchInput.focus()
      }
    })
  }

  private syncInputFocus(): void {
    effect(() => {
      const searchFocusState = this.searchFocusState()
      if (searchFocusState === SearchFocusState.SEARCH_INPUT) {
        untracked(this.searchIpt)?.nativeElement.focus()
      }
    })
  }

  ngOnDestroy(): void {
    this.formGroup.reset()
    super.ngOnDestroy()
  }

  @HostListener('document:keydown.escape') handleEscapeKeydown(): void {
    if (this.isSearching()) {
      this.onBack()
    }
  }

  onSearchButtonClick(): void {
    this.onSearchButtonFocus()

    const formValue = this.formGroup.value[FormGroupMembers.SearchTerm]
    const sanitizedFormValue = formValue?.trim()
    if (sanitizedFormValue !== formValue) {
      this.formGroup.patchValue({
        searchTerm: sanitizedFormValue,
      })
    }

    if (!this.formGroup.valid) {
      this.formGroup.markAsDirty()
      return
    }

    this.searchBarService.setUpSearch(this.formGroup.value[FormGroupMembers.SearchTerm])
    this.searchBarService.setLastUrlBeforeSearching(this.router.url)

    this.router.navigate([], {
      queryParams: {
        [SearchParams.SearchTerm]: this.formGroup.value[FormGroupMembers.SearchTerm],
        [SearchParams.SearchPageToken]: null,
        [SearchParams.EntitiesToSearch]: null,
      },
      queryParamsHandling: 'merge',
    })
  }

  onClear(): void {
    this.formGroup.controls.searchTerm.reset()
    this.searchIpt()?.nativeElement.focus()
  }

  onBack(): void {
    this.formGroup.controls.searchTerm.reset()
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Collapsed))
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
    this.store.dispatch(new SetBackToSearchResultsFullUrlAction())
    const priorURL = this.searchBarService.getSearchedFromUrl()
    if (priorURL) {
      this.router.navigateByUrl(priorURL)
    } else {
      const urlTree = this.router.parseUrl(this.router.url)
      // Preserve any non-search related query params
      delete urlTree.queryParams[SearchParams.SearchTerm]
      delete urlTree.queryParams[SearchParams.SearchPageNumber]
      delete urlTree.queryParams[SearchParams.EntitiesToSearch]
      delete urlTree.queryParams[SearchParams.SearchPageToken]
      this.router.navigateByUrl(urlTree)
    }
  }

  onDown(e: Event): void {
    // prevent arrow key scroll
    e.preventDefault()
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.RESULTS_LIST))
  }

  onClose(): void {
    if (this.isMobile) {
      this.onBack()
    }
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Collapsed))
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
  }

  onSearchInputClick(): void {
    this.onSearchInputFocus()
  }

  onSearchInputBlur(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
  }

  showSearchBar(): void {
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Expanded))
    this.onSearchInputFocus()
    setTimeout(() => this.searchIpt()?.nativeElement.focus())
  }

  private onSearchInputFocus(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.SEARCH_INPUT))
  }

  private onSearchButtonFocus(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.SEARCH_BUTTON))
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
