import { SEALED_CONTENT_PLACEHOLDER } from '@ftr/contracts/shared/SealedContentPlaceholder'
import { generateUuid, Uuid } from '@ftr/contracts/type/shared'
import { AbstractBuilder } from '@ftr/foundation'
import { convert, LocalDate, LocalDateTime } from '@js-joda/core'
import { RealTimeSttRemark } from '../types'

export class RealTimeSttRemarkBuilder extends AbstractBuilder<RealTimeSttRemark> {
  static defaultStartTime = LocalDateTime.parse('2020-09-02T11:00')
  static defaultStartDate = LocalDate.parse('2020-09-02')
  static defaultStartTimeEpoch = convert(RealTimeSttRemarkBuilder.defaultStartTime).toEpochMilli()
  static defaultStartEndDifference = 5
  static defaultEndTime = RealTimeSttRemarkBuilder.defaultStartTime.plusSeconds(
    RealTimeSttRemarkBuilder.defaultStartEndDifference,
  )
  static defaultEndTimeEpoch = convert(RealTimeSttRemarkBuilder.defaultEndTime).toEpochMilli()

  constructor() {
    super({
      id: generateUuid(),
      content: 'Some remark content',
      speakerName: 'Speaker Name 0',
      speakerId: generateUuid(),
      startTime: RealTimeSttRemarkBuilder.defaultStartTime,
      endTime: RealTimeSttRemarkBuilder.defaultEndTime,
    })
  }

  offsetTime(seconds: number): RealTimeSttRemarkBuilder {
    return this.with({
      startTime: this.result.startTime?.plusSeconds(seconds),
      endTime: this.result.endTime?.plusSeconds(seconds),
    })
  }

  offsetTimeTo(time: LocalDateTime): RealTimeSttRemarkBuilder {
    return this.with({
      startTime: time,
      endTime: time.plusSeconds(5),
    })
  }

  withSpeaker(speakerId: Uuid): RealTimeSttRemarkBuilder {
    return this.with({
      speakerId,
      speakerName: `Speaker Name ${speakerId.substr(0, 5)}`,
    })
  }

  buildAndClone(): RealTimeSttRemark {
    return { ...this.build() }
  }

  asSealedMarker(): RealTimeSttRemarkBuilder {
    return this.with({
      isSealedContentMarker: true,
      content: SEALED_CONTENT_PLACEHOLDER,
    })
  }
}
