<ftr-page
  [pageStyle]="pageStyle"
  [horizontalAutoMargin]="horizontalAutoMargin"
  [horizontalOuterMargin]="horizontalOuterMargin"
  [noMargin]="noMargin"
>
  <article class="page-layout" [ngClass]="{ 'page-layout--fill': displayMode === 'FILL' }">
    <ng-container *ngTemplateOutlet="staticHeaderTemplate"></ng-container>
    <ftr-notification-flash></ftr-notification-flash>
    <ftr-animated-remote
      [remote]="entity"
      [notAskedTemplate]="notAskedTemplate"
      [loadingTemplate]="loadingTemplate || loading"
      [failureTemplate]="failure"
      [successTemplate]="success"
      [emptyTemplate]="useFailureTemplateForEmpty ? failure : empty"
      [isEmpty]="isEmptyFunction"
      [displayMode]="displayMode"
    >
      <ng-template #loading>
        <div class="page-layout__loading">
          <ftr-loader [isLoading]="true" [loaderText]="'Loading ' + name" [fullPage]="true"></ftr-loader>
        </div>
      </ng-template>
      <ng-template #failure>
        <ftr-error-retry
          [text]="errorMessage || 'Failed to load ' + name"
          [icon]="false"
          (retry)="retry()"
        ></ftr-error-retry>
      </ng-template>
      <ng-template #empty>
        <div class="card-list__empty">
          @if (emptyBodyTemplate) {
            <ng-container *ngTemplateOutlet="emptyBodyTemplate"></ng-container>
          }
          @if (!emptyBodyTemplate) {
            <p>You have no {{ name }} yet.</p>
          }
        </div>
      </ng-template>
      <ng-template #success let-data>
        <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: data }"></ng-container>
        <section [ngClass]="{ 'page-layout__content--fill': displayMode === 'FILL' }">
          <ng-container *ngTemplateOutlet="bodyTemplate; context: { $implicit: data }"></ng-container>
        </section>
        <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: data }"></ng-container>
      </ng-template>
    </ftr-animated-remote>
  </article>
</ftr-page>
