import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { IndexedOrder, SearchRequestType, SearchResponse } from '@ftr/contracts/api/search'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { COURT_SYSTEM_ORDER_REFERENCE_MATCHER, PHRASE_QUERY_PATTERNS } from '@ftr/contracts/type/core'
import { Uuid, generateUuid } from '@ftr/contracts/type/shared'
import {
  ApiResult,
  ArrayUtils,
  DestroySubscribers,
  PageTitleService,
  RemoteData,
  SidePanelSize,
  isNotNullOrUndefined,
  isOfNonZeroLength,
  mapData,
  unwrapData,
} from '@ftr/foundation'
import { AudioOrderSttContext, RecordingSttContext, SttContext } from '@ftr/stt-search'
import { CoreConfigurationService } from '@ftr/ui-court-system'
import {
  GetSearchResultsAction,
  SearchBarInputState,
  SearchState,
  SetSearchBarInputStateAction,
  SetSearchScopeCourtSystemAction,
  SetSearchTermAction,
  viewOrderLink,
} from '@ftr/ui-search'
import { AnalyticsService, UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs'
import { WindowRefService } from '~app/services/window/window-ref.service'

// To prevent extra emits, caching a RemoteData.loading() object for search results
const searchResultsLoading = RemoteData.loading()

export interface SearchResultsViewModel {
  searchResults: SearchResponse
  configuration: PublicConfiguration
}

export const SEARCH_OVERLAY_SELECTOR = '.search-results-overlay'
@Component({
  selector: 'ftr-search-legacy-results',
  templateUrl: './search-legacy-results.component.html',
})
export class SearchLegacyResultsComponent extends DestroySubscribers implements OnInit, AfterViewInit, OnDestroy {
  @Input() query$: Observable<string | undefined>
  @Input() page$: Observable<number>
  @Input() searchRequestType$: Observable<SearchRequestType>
  @Input() sttContext$: Observable<SttContext | undefined>
  @Input() isSearching$: Observable<boolean>

  viewModel$: ApiResult<SearchResultsViewModel>

  // Toggle whether search results is fetched from the store or api
  refreshFromStore = new BehaviorSubject<boolean>(false)

  private searchResults$: ApiResult<SearchResponse>
  private scrollPosition$: Observable<number | undefined>
  // Handles 'retry()' in the failure template.
  private refreshSubject = new BehaviorSubject<void>(undefined)
  private refresh = this.refreshSubject.asObservable()

  readonly sidePanelSize = SidePanelSize

  // Toggles when to show search results (in the case of order redirection it prevents the
  // search results list appearing for a few moments before the order page is rendered)
  private readonly showSearchResults = new BehaviorSubject(true)

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly pageTitleService: PageTitleService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly windowRefService: WindowRefService,
    private readonly coreConfigurationService: CoreConfigurationService,
  ) {
    super()
  }

  isEmpty = (data: SearchResultsViewModel): boolean => data.searchResults.items.length === 0

  ngOnInit(): void {
    this.setupSearchTypeCourtSystem()
    this.setupRefreshFromStore()

    this.searchResults$ = this.store.select(SearchState.searchResults).pipe(
      takeUntil(this.finalize),
      map(remote => (this.showSearchResults.value ? remote : searchResultsLoading)),
    )

    this.viewModel$ = this.store.select(SearchState.searchScopeCourtSystem).pipe(
      filter(isNotNullOrUndefined),
      switchMap(courtSystem =>
        ApiResult.combine([this.searchResults$, this.coreConfigurationService.getByCourtSystem(courtSystem.id)]),
      ),
      mapData(([searchResults, configuration]) => ({
        searchResults,
        configuration,
      })),
    )

    this.forceSearchBarToBeOpen()

    this.watchQueryChanges()
    this.watchToSearch()
    /**
     * This must come after watch to search/query changes subscriptions, otherwise it receives a value change too
     * soon and tracks the previous search, then the next search.
     */
    this.trackSearches()
    /**
     * This has to be the last subscription, because most of the above ones have a takeUntil(this.finalize)
     * When redirectForExactOrderMatches navigates away, the search component is destroyed, taking with it the
     * subscriptions above :cry:
     */
    this.redirectForExactOrderMatches()
  }

  ngAfterViewInit(): void {
    this.scrollToPosition()
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Collapsed))
  }

  private setupSearchTypeCourtSystem(): void {
    combineLatest([
      this.searchRequestType$,
      this.sttContext$,
      this.store.select(SearchState.recordingCourtSystem),
      this.store.select(UserState.currentCourtSystem),
    ])
      .pipe(takeUntil(this.finalize))
      .subscribe(([searchType, sttContext, recordingCourtSystem, userCourtSystem]) => {
        if (sttContext?.type === 'recording' && searchType === SearchRequestType.ThisRecording) {
          this.store.dispatch(new SetSearchScopeCourtSystemAction(recordingCourtSystem))
        } else if (
          (sttContext?.type === 'audio-order' || sttContext?.type === 'shared-recording') &&
          searchType === SearchRequestType.ThisAudioSegment
        ) {
          this.store.dispatch(new SetSearchScopeCourtSystemAction(recordingCourtSystem))
        } else {
          this.store.dispatch(new SetSearchScopeCourtSystemAction(userCourtSystem))
        }
      })
  }

  setupRefreshFromStore(): void {
    this.scrollPosition$ = this.store.select(SearchState.searchResultsScrollPosition).pipe(
      take(1),
      filter(p => p !== undefined),
    )
    // On initial load if there is a scroll to position, load results from store
    this.scrollPosition$.subscribe(() => this.refreshFromStore.next(true))
  }

  retry(): void {
    this.refreshSubject.next(undefined)
  }

  private scrollToPosition(): void {
    // On view render if there is a scroll to position, scroll to that position
    // Timeout avoids scrolling to the wrong place randomly.
    this.scrollPosition$.pipe(filter(y => !!y)).subscribe(y => {
      setTimeout(() => this.windowRefService.scrollElementBy(SEARCH_OVERLAY_SELECTOR, y))
    })
  }

  private forceSearchBarToBeOpen(): void {
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Expanded))
  }

  private trackSearches(): void {
    this.searchResults$
      .pipe(
        takeUntil(this.finalize),
        filter(results => results.isSuccess()),
        withLatestFrom(this.store.select(SearchState.lastSearchTerm)),
      )
      .subscribe(([remote, searchTerm]) => {
        if (searchTerm !== undefined) {
          this.analyticsService.track({
            event: 'Search',
            searchTerm,
            resultCount: remote.data!.meta.totalItems,
          })
        }
        this.showSearchResults.next(true)
      })
  }

  private watchToSearch(): void {
    combineLatest([
      this.page$,
      this.store.select(SearchState.lastSearchTerm).pipe(filter(isOfNonZeroLength)),
      this.searchRequestType$,
      this.sttContext$.pipe(distinctUntilChanged()),
      this.isSearching$,
      this.store.select(SearchState.searchResultsScrollPosition).pipe(take(1)),
      this.store.select(SearchState.uniqueSearch),
      // Hack to get around the distinctUntilChanged. Only emits once on creation and every time retry is called
      this.refresh.pipe(map(() => generateUuid())),
      this.store.select(SearchState.searchScopeCourtSystem).pipe(filter(isNotNullOrUndefined)),
    ])
      .pipe(
        // We must clean up this when we leave search,
        // or it will re-subscribe and perform multiple calls!
        takeUntil(this.finalize),
        // We want a shallow equals of the page, lastQuery and uniqueSearch
        distinctUntilChanged(ArrayUtils.shallowEquals),
        // Given SearchBarComponent can change all 3 of these simultaneously,
        // we debounce to avoid triggering 3 separate calls.
        debounceTime(0),
        filter(([, , searchType, sttContext]) => this.hasContextForSearchRequest(searchType, sttContext)),
      )
      .subscribe(([page, lastSearchTerm, searchType, sttContext, isSearching, , , , currentCourtSystem]) => {
        if (isSearching) {
          if (this.refreshFromStore.value) {
            this.refreshFromStore.next(false)
          } else {
            this.store.dispatch(
              new GetSearchResultsAction(
                lastSearchTerm,
                searchType,
                currentCourtSystem.id,
                page,
                this.getResourceId(searchType, sttContext),
              ),
            )
          }
        }
      })
  }

  private hasContextForSearchRequest(searchType: SearchRequestType, sttContext: SttContext | undefined): boolean {
    switch (searchType) {
      case SearchRequestType.ThisRecording:
      case SearchRequestType.ThisAudioSegment:
      case SearchRequestType.Cases:
      case SearchRequestType.Hearings:
        return !!sttContext
      case SearchRequestType.Orders:
      case SearchRequestType.AllRecordings:
        return true
      default:
        assertUnreachable(searchType)
    }
  }

  private getResourceId(searchType: SearchRequestType, sttContext: SttContext | undefined): Uuid | undefined {
    switch (searchType) {
      case SearchRequestType.ThisRecording:
        return (sttContext as RecordingSttContext).recordingId
      case SearchRequestType.ThisAudioSegment:
        return (sttContext as AudioOrderSttContext).audioSegmentId
      case SearchRequestType.Orders:
      case SearchRequestType.AllRecordings:
      case SearchRequestType.Cases:
      case SearchRequestType.Hearings:
        return undefined

      default:
        assertUnreachable(searchType)
    }
  }

  private watchQueryChanges(): void {
    this.query$.pipe(takeUntil(this.finalize), distinctUntilChanged()).subscribe(searchTerm => {
      if (searchTerm) {
        this.store.dispatch(new SetSearchTermAction(searchTerm))
        this.pageTitleService.setTitle(`Search Results for '${searchTerm}'`)
      }
    })
  }

  /**
   * Note: this redirect assumes that the 'currentCourtSystem' stored in state (which is used for the header/footer
   * links), is the court system the matching order is in. If search changes to not use
   */
  private redirectForExactOrderMatches(): void {
    combineLatest([
      this.searchResults$.pipe(unwrapData()),
      this.store.select(SearchState.lastSearchTerm).pipe(filter(isNotNullOrUndefined)),
      this.store.select(UserState.currentCourtSystem).pipe(filter(isNotNullOrUndefined)),
    ])
      .pipe(
        takeUntil(this.finalize),
        filter(([searchResponse]) => isNotNullOrUndefined(searchResponse.meta)),
        filter(([, query, currentCourtSystem]) => this.isOrderReference(query, currentCourtSystem.code)),
      )
      .subscribe(([searchResponse, _, currentCourtSystem]) => {
        this.showSearchResults.next(false)
        const { items } = searchResponse
        const { totalItems } = searchResponse.meta
        if (totalItems === 1) {
          const data = items[0] && (items[0].body as IndexedOrder)
          const orderLink = viewOrderLink(data.lineItemType, currentCourtSystem.id, data.id, data.jobId)
          if (orderLink) {
            this.router.navigate(orderLink, { replaceUrl: true })
            return
          }
        }
        this.showSearchResults.next(true)
      })
  }

  private isOrderReference(query: string, courtSystemCode: string): boolean {
    return (
      COURT_SYSTEM_ORDER_REFERENCE_MATCHER(courtSystemCode).test(query) ||
      PHRASE_QUERY_PATTERNS.LegacyOrderReferenceMatcher.test(query)
    )
  }
}
