import { Component, computed, effect, input, output, signal, untracked } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { PaginationInfo, SearchEntityType, SearchManyResponse } from '@ftr/contracts/api/search'
import { SearchResult } from '@ftr/contracts/api/search/SearchResult'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { ElasticsearchIndex, VocabularyTerms } from '@ftr/contracts/type/core'
import {
  ApiResult,
  DestroySubscribers,
  LayoutService,
  PageStyle,
  RemoteData,
  SearchFilterToggledEvent,
  SidePanelSize,
  titleCase,
} from '@ftr/foundation'
import { SEARCH_MANY_PAGE_SIZE, SearchFocusState } from '@ftr/ui-search'
import { AnalyticsService } from '@ftr/ui-user'
import { SearchResultViewModel } from '~app/features/search/search-results/search-results-list/search-results-list.component'
import { SearchTypeFilterItemMetadata } from '~app/features/search/search-type-filter/search-type-filter.component'

type SearchTypeFilterItemBase = Omit<SearchTypeFilterItemMetadata, 'selected' | 'numberOfResults'>

export interface SearchManyResultsViewModel {
  searchResults: SearchManyResponse
  configuration: PublicConfiguration
  searchFocusState: SearchFocusState
}

export const SEARCH_OVERLAY_SELECTOR = '.search-results-overlay'

type PaginationToken = string | null
@Component({
  selector: 'ftr-global-search-results',
  styleUrl: './global-search-results.component.css',
  templateUrl: './global-search-results.component.html',
})
export class GlobalSearchResultsComponent extends DestroySubscribers {
  searchTerm = input.required<string>()
  selectedEntities = input.required<SearchEntityType[]>()
  searchResponse = input.required<RemoteData<SearchManyResponse>>()
  configuration = input.required<PublicConfiguration>()
  searchFocusState = input.required<SearchFocusState>()
  searchEntityTypeToNumberOfMatches = input.required<ReadonlyMap<SearchEntityType, number>>()
  availableEntities = input.required<SearchEntityType[]>()
  terms = input.required<VocabularyTerms>()

  onFilterItemSelected = output<SearchEntityType>()
  onNewSearchFocusState = output<SearchFocusState>()
  fetchPage = output<PaginationToken>()
  onRetry = output()

  protected isLegacyLayout = this.layoutService.isLegacyLayoutSignal()

  readonly pageStyle = PageStyle

  viewModel$: ApiResult<SearchManyResultsViewModel> = toObservable(
    computed(() => {
      const searchResultsVal = this.searchResponse()
      const configurationVal = this.configuration()
      const searchFocusState = this.searchFocusState()
      return RemoteData.map2(
        searchResultsVal,
        RemoteData.success(configurationVal),
        (searchResults, configuration) => ({
          searchResults,
          configuration,
          searchFocusState,
        }),
      )
    }),
  )

  private filterItemsForSelection = computed<SearchTypeFilterItemBase[]>(() => {
    const options = this.availableEntities()
    return (
      [
        {
          icon: 'Waveform',
          label: 'Recordings',
          type: SearchEntityType.Recordings,
        },
        {
          icon: 'Sell',
          label: 'Orders',
          type: SearchEntityType.Orders,
        },
        {
          icon: 'Work',
          label: titleCase(this.terms().case.plural),
          type: SearchEntityType.Cases,
        },
        {
          icon: 'FolderOpen',
          label: titleCase(this.terms().hearing.plural),
          type: SearchEntityType.Hearings,
        },
      ] satisfies SearchTypeFilterItemBase[]
    ).filter(x => options.some(y => y === x.type))
  })

  availableFilterItems = computed<SearchTypeFilterItemMetadata[]>(() => {
    const selectedEntities = this.selectedEntities()
    const searchEntityTypeToNumberOfMatches = this.searchEntityTypeToNumberOfMatches()
    return this.filterItemsForSelection().map(x => ({
      ...x,
      numberOfResults: searchEntityTypeToNumberOfMatches.get(x.type),
      selected: selectedEntities.includes(x.type),
    }))
  })

  lastTrackedSearchTerm = signal<string>('')

  readonly sidePanelSize = SidePanelSize
  pageSize = SEARCH_MANY_PAGE_SIZE

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly layoutService: LayoutService,
  ) {
    super()
    this.trackSearches()
  }

  isEmpty = (data: SearchManyResultsViewModel): boolean => data.searchResults.items.length === 0

  retry(): void {
    this.onRetry.emit()
  }
  protected trackPaginationEvent(paginationDirection: 'next' | 'previous', pagination: PaginationInfo): void {
    this.analyticsService.track({
      event: 'SearchPagination',
      eventLabel: paginationDirection,
      pageNumber: Math.ceil((pagination.offset + 1) / this.pageSize),
    })
  }

  protected trackSearchItemSelected(searchResult: SearchResultViewModel, pagination: PaginationInfo): void {
    const itemOrder = pagination.offset + searchResult.index + 1
    this.analyticsService.track({
      event: 'SearchResultSelected',
      searchCategory: searchResultToSearchEntityType(searchResult.content),
      itemOrder,
      pageNumber: Math.ceil((pagination.offset + 1) / this.pageSize),
    })

    if (searchResult.content.type === 'Hearings') {
      this.analyticsService.track({ event: 'HearingPlaybackNavigation', eventLabel: 'search' })
    }
  }

  protected handleFilterItemSelected(type: SearchEntityType): void {
    const filterCurrentlySelected = this.selectedEntities().some(entity => entity === type)
    this.trackFilterItemSelected(type, !filterCurrentlySelected)
    this.onFilterItemSelected.emit(type)
    this.onNewSearchFocusState.emit(SearchFocusState.RESULTS_LIST)
  }

  private trackSearches(): void {
    effect(
      () => {
        const searchResponse = this.searchResponse()
        if (searchResponse.isSuccess()) {
          // Only track search events when the search term changes (pagination and filters don't count)
          const currentSearchTerm = untracked(this.searchTerm)
          const lastTrackedSearchTerm = untracked(this.lastTrackedSearchTerm)
          if (lastTrackedSearchTerm !== currentSearchTerm) {
            this.analyticsService.track({
              event: 'Search',
              searchTerm: currentSearchTerm,
              resultCount: searchResponse.data!.pagination.totalItems,
            })
            this.lastTrackedSearchTerm.set(currentSearchTerm)
          }
        }
      },
      { allowSignalWrites: true },
    )

    // Handle clearing the search
    effect(
      () => {
        const searchTerm = this.searchTerm()
        if (searchTerm === '') {
          this.lastTrackedSearchTerm.set('')
        }
      },
      { allowSignalWrites: true },
    )
  }

  private trackFilterItemSelected(type: SearchEntityType, toggledOn: boolean): void {
    this.analyticsService.track({
      event: 'SearchFilterToggled',
      searchCategory: type,
      eventLabel: toggledOn ? 'toggled-on' : 'toggled-off',
    } satisfies SearchFilterToggledEvent)
  }
}

function searchResultToSearchEntityType(searchResult: SearchResult): SearchEntityType {
  const type = searchResult.type
  switch (type) {
    case ElasticsearchIndex.Order:
      return SearchEntityType.Orders
    case ElasticsearchIndex.Recording:
      return SearchEntityType.Recordings
    case 'Cases':
      return SearchEntityType.Cases
    case 'Hearings':
      return SearchEntityType.Hearings
    default:
      assertUnreachable(type)
  }
}
