import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { RealTimeSearchResult } from './real-time-search.model'

interface SearchResulInteractionState {
  readonly selected: RealTimeSearchResult | undefined
  readonly hovered: RealTimeSearchResult | undefined
}

export type RealTimeSearchResultInteractionUpdateType = 'Select' | 'Hover' | 'Clear'
export class RealTimeSearchResultInteractionUpdateEvent {
  static readonly type = '[RealTimeSearch] Search Result Interaction Update Event'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly updateType: RealTimeSearchResultInteractionUpdateType,
    readonly resultInteractionState: SearchResulInteractionState,
  ) {}
}
