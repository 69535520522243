import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormControl, FormsModule, ValidatorFn } from '@angular/forms'
import { AnchorComponent, ButtonColor, ButtonDisplayType, IconComponent, IndefinitePipe } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { isUniqueArray } from '../../validators'
import { FormInputComponent } from '../form-input'

/**
 * A wrapper for an input element, including rendering of elements essential to validation display.
 */
@Component({
  standalone: true,
  selector: 'ftr-form-input-list',
  templateUrl: './form-input-list.component.html',
  imports: [FormsModule, FormInputComponent, AnchorComponent, IconComponent, IndefinitePipe],
  styleUrls: ['./form-input-list.component.css'],
})
export class FormInputListComponent implements OnInit {
  @Input()
  maxCount = 5

  @Input()
  minCount = 0

  /**
   * Whether items in the array should be unique
   */
  @Input()
  unique = true

  /**
   * Whether the ordering in the list must be preserved to the minimum
   * count. In this case the remove button will never be available on
   * form fields up to the minimum count
   */
  @Input()
  minimumSorted = false

  /**
   * The validators to apply to every input control
   */
  @Input()
  inputValidators: ValidatorFn[] = []

  @Input()
  defaults: string[] = []

  @Input()
  formArray: FormArray<FormControl<string>>

  // All these inputs are forwarded to the underlying ftr-form-input and use the same defaults
  // See ftr-form-input for more information

  // Required
  @Input() label: string

  // Optional
  @Input() type = 'text'
  @Input() placeholder = ''
  @Input() name?: string
  @Input() prefix?: string
  @Input() pattern?: string
  @Input() spellcheck?: boolean
  @Input() tabIndex?: number
  @Input() autocomplete?: string | 'on' | 'off'
  @Input() submitAttempted: boolean
  @Input() highlightError: Observable<boolean>
  @Input() notrack = false
  @Input() hideLabel = false
  @Input() required = false
  @Input() addIndexToLabels = false
  @Input() addIndexToPlaceholders = false
  @Input() maxLength?: number
  @Input() leftAligned = false

  buttonColor = ButtonColor
  buttonDisplayType = ButtonDisplayType

  add(): void {
    if (this.formArray.length <= this.maxCount) {
      this.formArray.push(this.buildFormControl(''))
      this.triggerValidation()
    }
  }

  remove(idx: number): void {
    this.formArray.removeAt(idx)
    this.triggerValidation()
  }

  private buildFormControl(x: string): FormControl<string> {
    const formControl = new FormControl<string>(x, { nonNullable: true })
    if (this.unique) {
      const uniqueValidator = isUniqueArray(this.formArray, `${this.label} must be unique`)
      formControl.setValidators([...this.inputValidators, uniqueValidator])
    }
    return formControl
  }
  // We re-trigger validation when a child control is updated, due the unique validator
  triggerValidation(): void {
    this.formArray.controls.forEach(x => x.updateValueAndValidity())
  }

  ngOnInit(): void {
    this.defaults.forEach(x => this.formArray.push(this.buildFormControl(x)))
    const diffBetweenDefaultsAndMin = this.minCount - this.defaults.length
    if (diffBetweenDefaultsAndMin > 0) {
      Array.from(Array(diffBetweenDefaultsAndMin)).forEach(() => this.formArray.push(this.buildFormControl('')))
    }
  }
}
