import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core'
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms'
import { TIME_FORMAT, TimeRange } from '@ftr/foundation'
import moment, { Moment, MomentSetObject } from 'moment-timezone'
import { Observable } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'
import { LegacyTimeInputComponent } from '../legacy-time-input'

const END_AFTER_START_ERROR_MESSAGE = 'The end time must be after the start time.'
const DURATION_END_AFTER_START_ERROR_MESSAGE = 'The end duration must be after the start duration.'
const INCOMPLETE_ERROR_MESSAGE = 'Both start and end time must be completed.'
const EXCEEDED_MAXIMUM_END_TIME_ERROR_MESSAGE = 'The end time must not be in the future.'

export enum TimeRangePrecision {
  Seconds = 'seconds',
  Minutes = 'minutes',
}

export const FORMAT_ERROR_MESSAGES = {
  [TimeRangePrecision.Minutes]: 'Enter as HH:MM.',
  [TimeRangePrecision.Seconds]: 'Enter as HH:MM:SS.',
}

const MINUTE_FORMATS = [
  TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT,
  TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.NO_WHITESPACE,
  TIME_FORMAT.TWENTY_FOUR_HOUR.MINUTE_PRECISION.DEFAULT,
]

const ALLOWED_TIME_FORMATS = {
  [TimeRangePrecision.Minutes]: MINUTE_FORMATS,
  [TimeRangePrecision.Seconds]: [
    TIME_FORMAT.TWELVE_HOUR.SECOND_PRECISION.DEFAULT,
    TIME_FORMAT.TWELVE_HOUR.SECOND_PRECISION.NO_WHITESPACE,
    TIME_FORMAT.TWENTY_FOUR_HOUR.SECOND_PRECISION.DEFAULT,
    ...MINUTE_FORMATS,
  ],
}

export const DEFAULT_TIME_FORMATS = {
  [TimeRangePrecision.Minutes]: ALLOWED_TIME_FORMATS[TimeRangePrecision.Minutes][0],
  [TimeRangePrecision.Seconds]: ALLOWED_TIME_FORMATS[TimeRangePrecision.Seconds][0],
}

const PLACEHOLDERS = {
  [TimeRangePrecision.Minutes]: 'HH:MM AM/PM',
  [TimeRangePrecision.Seconds]: 'HH:MM:SS AM/PM',
}

/**
 * @deprecated Use ftr-time-range
 */
@Component({
  standalone: true,
  selector: 'ftr-legacy-time-range',
  templateUrl: './legacy-time-range.component.html',
  styleUrls: ['./legacy-time-range.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [LegacyTimeInputComponent, ReactiveFormsModule, ValidationErrorHintDirective],
})
export class LegacyTimeRangeComponent implements OnInit, OnChanges {
  @Input() required = true
  @Input() control: AbstractControl
  @Input() highlightError: Observable<boolean>
  @Input() submitAttempted: boolean
  @Input() allowedPrecision: TimeRangePrecision = TimeRangePrecision.Minutes
  @Input() timeFormat: string = TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT
  @Input() placeholder?: string
  @Input() maxEndTime: Moment | undefined
  @Input() startLabel: string | undefined
  @Input() endLabel: string | undefined
  @Input() verticalAlign = false
  @Input() trackData = false
  @Input() useAsDuration = false

  @Output() onChange = new EventEmitter<TimeRange>()

  @ViewChildren(LegacyTimeInputComponent) timeInputComponents: QueryList<LegacyTimeInputComponent>

  internalRangeForm: UntypedFormGroup
  error: string | null

  private range: TimeRange

  private timeFormats: string[]

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.timeFormats = ALLOWED_TIME_FORMATS[this.allowedPrecision]
    this.placeholder = this.placeholder || PLACEHOLDERS[this.allowedPrecision]

    if (this.allowedPrecision === TimeRangePrecision.Seconds) {
      this.timeFormat = TIME_FORMAT.TWELVE_HOUR.SECOND_PRECISION.DEFAULT
    }
    const timeRange = this.control.value
    this.range = {
      start: (timeRange && timeRange.start) || undefined,
      end: (timeRange && timeRange.end) || undefined,
    }
    this.setupForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.internalRangeForm && changes.maxEndTime) {
      /**
       * Workaround for when the maxHearingDate is updated every minute in the parent component,
       * causing ExpressionChangedAfterItHasBeenCheckedError if you enter e.g. 9:20 and its 9:15.
       * After 6 minutes at 9:20, the form becomes valid and pumps out that warning.
       */
      setTimeout(() => {
        this.internalRangeForm.get('end')!.updateValueAndValidity()
      }, 0)
    }
  }

  /**
   * When an individual time input changes, set the value to the internal form.
   */
  onTimeInputChange(value: moment.Moment | undefined, controlName: 'start' | 'end'): void {
    this.range[controlName] = value
    this.internalRangeForm.get(controlName)!.setValue(value)
    this.internalRangeForm.get(controlName)!.markAsTouched()
    this.internalRangeForm.get(controlName)!.updateValueAndValidity()
    this.onChange.emit(this.range)
  }

  shouldDisplayTimeRangeValidation(): boolean {
    return (
      this.internalRangeForm.controls.start.valid &&
      this.internalRangeForm.controls.end.valid &&
      this.internalRangeForm.controls.start.value &&
      this.internalRangeForm.controls.end.value
    )
  }

  private setupForm(): void {
    this.internalRangeForm = this.formBuilder.group(
      {
        start: [this.range.start, [this.isValidTime.bind(this)]],
        end: [this.range.end, [this.isValidTime.bind(this), this.isValidEndTime.bind(this)]],
      },
      { validators: this.validateRangeFormGroup.bind(this) },
    )
    this.touchControls()
  }

  /**
   * An additional validator for the whole internal form group
   */
  private validateRangeFormGroup(formGroup: UntypedFormGroup): ValidationErrors | null {
    const timeRangeError = this.endAfterStart('start', 'end')(formGroup)
    const startControl = formGroup.get('start')!
    const endControl = formGroup.get('end')!
    const startValidTimeError = startControl.getError('isValidTime')
    const endValidTimeError = endControl.getError('isValidTime')
    const exceededMaximumEndTime = endControl.getError('exceededMaximumEndTime')
    const incompleteError = this.required && (!this.range.start || !this.range.end) ? INCOMPLETE_ERROR_MESSAGE : null

    if (!timeRangeError && !startValidTimeError && !endValidTimeError && !incompleteError && !exceededMaximumEndTime) {
      return null
    }

    return {
      ...timeRangeError,
      startValidTimeError,
      endValidTimeError,
      incompleteError,
      exceededMaximumEndTime,
    }
  }

  private touchControls(): void {
    if (this.range.start) {
      this.internalRangeForm.controls.start.markAsTouched()
    }
    if (this.range.end) {
      this.internalRangeForm.controls.end.markAsTouched()
    }
  }

  private endAfterStart(startKey: string, endKey: string): (group: UntypedFormGroup) => ValidationErrors | null {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const start = this.parse(group.get(startKey)!.value)
      const end = this.parse(group.get(endKey)!.value)

      if (!start || !end) {
        return null
      }

      const defaultDate: MomentSetObject = {
        year: 1970,
        month: 0,
        date: 1,
        millisecond: 0,
      }

      const isEndAfter = this.useAsDuration
        ? end.isAfter(start)
        : end.clone().set(defaultDate).utc(true).isAfter(start.clone().set(defaultDate).utc(true))

      return isEndAfter
        ? null
        : { endAfterStart: this.useAsDuration ? DURATION_END_AFTER_START_ERROR_MESSAGE : END_AFTER_START_ERROR_MESSAGE }
    }
  }

  private isValidTime(timeControl: AbstractControl): ValidationErrors | null {
    // This isn't actually valid, we just don't want to show a validation message while its empty.
    if (!timeControl.value && (!timeControl.touched || !this.required)) {
      return null
    }
    const time = this.parse(timeControl.value)
    return time && time.isValid()
      ? null
      : {
          isValidTime: FORMAT_ERROR_MESSAGES[this.allowedPrecision],
        }
  }

  private isValidEndTime(timeControl: AbstractControl): ValidationErrors | null {
    const endTime = this.parse(timeControl.value)
    if (!this.maxEndTime || !endTime) {
      return null
    }
    const normalizedMaximumEndTime = this.parse(this.maxEndTime.format(DEFAULT_TIME_FORMATS[this.allowedPrecision]))
    return endTime.isAfter(normalizedMaximumEndTime)
      ? { exceededMaximumEndTime: EXCEEDED_MAXIMUM_END_TIME_ERROR_MESSAGE }
      : null
  }

  private parse(time: Moment | string): Moment | undefined {
    if (!time) {
      return undefined
    }
    if (moment.isMoment(time)) {
      return time
    }
    return moment(time, this.timeFormats, true)
  }
}
