import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AppPaths } from '@ftr/routing-paths'
import { SupportedBrowsersModule } from '@ftr/ui-help'
import { LoggedInGuard } from '@ftr/ui-user'
import { CourtSystemForbiddenComponent } from '~app/features/court-system-forbidden/court-system-forbidden.component'
import { DesktopLoginSplashComponent } from '~app/features/desktop-login-splash/desktop-login-splash.component'
import { ExternalLoginModule } from '~app/features/external-login/external-login.module'
import { CourtSystemForbiddenPage } from '~app/pages/court-system-forbidden.page'
import { DesktopLoginSplashPage } from '~app/pages/desktop-login-splash.page'
import { ExternalLoginPage } from '~app/pages/external-login.page'
import { ForbiddenModule } from '../features/forbidden/forbidden.module'
import { LoginModule } from '../features/login/login.module'
import { NotFoundModule } from '../features/not-found/not-found.module'
import { ForbiddenPage } from './forbidden.page'
import { LoginPage } from './login.page'
import { LogoutPage } from './logout.page'
import { NotFoundPage } from './not-found.page'
import { SupportedBrowsersPage } from './supported-browsers.page'

@NgModule({
  imports: [
    LoginModule,
    SupportedBrowsersModule,
    ForbiddenModule,
    NotFoundModule,
    ExternalLoginModule,
    CourtSystemForbiddenComponent,
    DesktopLoginSplashComponent,
    RouterModule.forChild([
      {
        path: AppPaths.Login,
        component: LoginPage,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Log In',
          shouldShowSidenav: false,
        },
      },
      {
        path: AppPaths.DesktopLoginSplash,
        component: DesktopLoginSplashPage,
        data: {
          title: 'Launching For The Record',
        },
      },
      {
        path: AppPaths.Logout,
        component: LogoutPage,
        data: {
          shouldShowSidenav: false,
        },
      },
      {
        path: AppPaths.SupportedBrowsers,
        component: SupportedBrowsersPage,
        data: {
          title: 'Supported Browsers',
          shouldShowSidenav: false,
        },
      },
      {
        path: AppPaths.Forbidden,
        component: ForbiddenPage,
      },
      {
        path: AppPaths.NotFound,
        component: NotFoundPage,
      },
      {
        path: AppPaths.ExternalLogin,
        component: ExternalLoginPage,
      },
      {
        path: AppPaths.CourtSystemForbidden,
        component: CourtSystemForbiddenPage,
      },
      {
        path: AppPaths.CourtroomDisplay,
        loadChildren: () =>
          import('./courtoom-display/courtroom-display.module').then(m => m.CourtroomDisplayPageModule),
      },
    ]),
  ],
  declarations: [
    LoginPage,
    DesktopLoginSplashPage,
    LogoutPage,
    SupportedBrowsersPage,
    ForbiddenPage,
    NotFoundPage,
    ExternalLoginPage,
    CourtSystemForbiddenPage,
  ],
  exports: [RouterModule],
})
export class RootModule {}
