<div class="dialog__container">
  @if (isShowing) {
    <div [@backdropEnterLeave] class="dialog__backdrop"></div>
  }

  @if (isShowing) {
    <div [@dialogEnterLeave] class="dialog">
      <div class="dialog__content">{{ message }}</div>
      <div class="dialog__footer">
        <div class="dialog__actions">
          <a (click)="confirm(false, name)">Cancel</a>
          <a (click)="confirm(true, name)"
            ><strong>{{ confirmButtonLabel }}</strong></a
          >
        </div>
      </div>
    </div>
  }
</div>
