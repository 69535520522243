import { Injectable, Signal, signal } from '@angular/core'
import { Observable, of } from 'rxjs'

export const DUMMY_LEGACY_LAYOUT_SERVICE = {
  isLegacyLayoutSignal: () => signal(true),
  isLegacyLayout$: () => of(true),
} as LayoutService

export const DUMMY_LAYOUT_SERVICE = {
  isLegacyLayoutSignal: () => signal(false),
  isLegacyLayout$: () => of(false),
} as LayoutService

/**
 * The legacy layout flag is based on the condition of a feature flag, so @ftr/foundation will not provide an implementation for this service.
 *  The sole use of this service is to handle the transition between the legacy layout and the new layout (circa May-June 2024).  Once that has been completed this service will be removed.
 */
@Injectable()
export abstract class LayoutService {
  public static readonly CONTENT_WINDOW_CLASS = 'app-layout__content'

  // TODO: Remove with CO-2889
  abstract isLegacyLayoutSignal(): Signal<boolean>

  // TODO: Remove with CO-2889
  abstract isLegacyLayout$(): Observable<boolean>
}
