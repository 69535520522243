import { Component, Input, OnInit } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { IndexedLogNote, IndexedLogSheet, IndexedRecording, IndexedRemark } from '@ftr/contracts/api/search'
import { DateFormat, toMoment } from '@ftr/foundation'
import { CourtSystemTimeZonePipe } from '@ftr/ui-court-system'
import { isIndexedLogNote, isIndexedLogSheet, isIndexedRemark } from '@ftr/ui-search'
import moment from 'moment-timezone'

@Component({
  selector: 'ftr-search-legacy-results-all-recordings-item',
  templateUrl: './search-legacy-results-all-recordings-item.component.html',
  styleUrls: ['./search-legacy-results-all-recordings-item.component.css'],
})
export class SearchResultsAllRecordingsItemComponent implements OnInit {
  @Input() hasLink: boolean
  @Input() searchResult: IndexedRecording
  @Input() courtSystemConfig: PrivateConfiguration

  firstUtterance: IndexedRemark | undefined
  firstLogSheet: IndexedLogSheet | undefined
  firstLogNote: IndexedLogNote | undefined
  isLiveStreaming: boolean = false

  constructor(private readonly courtSystemTimeZonePipe: CourtSystemTimeZonePipe) {}

  readonly dateFormat = DateFormat

  ngOnInit(): void {
    if (this.searchResult.logNotes?.length && isIndexedLogNote(this.searchResult.logNotes[0])) {
      this.firstLogNote = this.prepareFirstLogNote(this.searchResult.logNotes)
    } else if (this.searchResult.logSheets?.length && isIndexedLogSheet(this.searchResult.logSheets[0])) {
      this.firstLogSheet = this.prepareFirstLogSheet(this.searchResult.logSheets)
    } else if (this.searchResult.transcript?.length && isIndexedRemark(this.searchResult.transcript[0])) {
      this.firstUtterance = this.prepareFirstUtterance(this.searchResult.transcript)
    }

    this.isLiveStreaming = this.prepareIsLiveStreaming()
  }

  private prepareFirstUtterance(transcripts: IndexedRemark[]): IndexedRemark {
    const [utterance] = transcripts

    const speakerName =
      this.getFirstUtteranceHighlightValue(utterance, 'transcript.speakerName') || utterance.speakerName
    const content = this.getFirstUtteranceHighlightValue(utterance, 'transcript.content') || utterance.content

    return {
      ...utterance,
      content,
      speakerName,
    }
  }

  private getFirstUtteranceHighlightValue(utterance: IndexedRemark, highlightKey: string): string | undefined {
    if (utterance.highlight) {
      if (utterance.highlight[highlightKey]) {
        if (utterance.highlight[highlightKey][0]) {
          return utterance.highlight[highlightKey][0]
        }
      }
    }

    return undefined
  }

  private prepareFirstLogSheet(logSheets: IndexedLogSheet[]): IndexedLogSheet {
    const logSheet = logSheets[0]
    return {
      ...logSheet,
      description: logSheet.highlight!['logSheets.description'][0],
    }
  }

  private prepareFirstLogNote(logNotes: IndexedLogNote[]): IndexedLogNote {
    const logNote = logNotes[0]

    const highlightedContent = logNote.highlight!
    const speakerHighlightedContent = highlightedContent['logSheets.logNotes.speakerName']
    const noteHighlightedContent = highlightedContent['logSheets.logNotes.note']

    return {
      ...logNote,
      note: noteHighlightedContent?.length ? noteHighlightedContent[0] : logNote.note,
      speakerName: speakerHighlightedContent?.length ? speakerHighlightedContent[0] : logNote.speakerName,
    }
  }

  private prepareIsLiveStreaming(): boolean {
    const recordingStart = this.courtSystemTimeZonePipe.toCourtSystemTimeZone(
      this.searchResult.recordingStart,
      this.courtSystemConfig,
      this.searchResult.timeZoneId,
    )

    return !!this.searchResult.isLive && toMoment(recordingStart).moment.isSame(moment(), 'day')
  }
}
