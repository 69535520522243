import { Injectable } from '@angular/core'
import { ELECTRON_API_KEY, ElectronApi } from '@ftr/contracts/type/desktop'
import { SimpleWindowRef, SimpleWindowRefService, isInDesktopApp } from '@ftr/foundation'
import { RECAPTCHA_CALLBACK_NAME } from '~app/services/recaptcha/recaptcha.consts'
import { WindowConfig } from '~environments/web-environment'

export interface ExtraWindowProps {
  config: WindowConfig
  [RECAPTCHA_CALLBACK_NAME]: () => void
  [ELECTRON_API_KEY]: ElectronApi
}

export type WindowRef = SimpleWindowRef & ExtraWindowProps

/**
 * Window references in angular are generally undesirable so
 * this class has been set up to wrap it so at least
 * mocks can be injected in place of this during testing
 */
@Injectable({
  providedIn: 'root',
})
export class WindowRefService extends SimpleWindowRefService {
  getElectronApi(): ElectronApi | undefined {
    if (!isInDesktopApp()) {
      return
    }

    return this.nativeWindow()[ELECTRON_API_KEY]
  }

  override nativeWindow(): WindowRef {
    return super.nativeWindow() as unknown as WindowRef
  }
}
