import { Component, Input, OnInit } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { CourtSystemWithMetadata } from '@ftr/contracts/api/court-system'
import { UserLocation, UserWithGlobalAdministratorRole } from '@ftr/contracts/api/user'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  AnimatedRemoteComponent,
  ApiResult,
  ButtonColor,
  ErrorRetryComponent,
  Icon,
  IconComponent,
  LoaderComponent,
  RemoteData,
  TypedContextDirective,
  mapData,
} from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { GlobalNavItems } from '@ftr/ui-core'
import {
  CourtSystemSelectorBadgeTypes,
  CourtSystemSelectorItem,
  CourtSystemSelectorItemComponent,
  mapCourtSystemWithMetadataToCourtSystemSelectorItem,
} from '@ftr/ui-court-system'
import {
  AuthenticationService,
  GetCurrentUserLocationAction,
  MfaStatusComponent,
  UserAvatarComponent,
  UserState,
} from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { BehaviorSubject, filter, from, map, startWith, switchMap } from 'rxjs'

export interface PersonalMenu {
  user: UserWithGlobalAdministratorRole
  courtSystem: CourtSystemSelectorItem | undefined
  courtSystemCount: number
  isMfaEnabled: boolean | undefined
  identityProviderTitle: string
  identityProviderIcon?: Icon
  locationString?: string
}

@Component({
  selector: 'ftr-personal-menu',
  templateUrl: './personal-menu.component.html',
  styleUrls: ['./personal-menu.component.css'],
  standalone: true,
  imports: [
    AnimatedRemoteComponent,
    ErrorRetryComponent,
    UserAvatarComponent,
    IconComponent,
    RouterLink,
    CourtSystemSelectorItemComponent,
    LoaderComponent,
    TypedContextDirective,
    MfaStatusComponent,
  ],
})
export class PersonalMenuComponent implements OnInit {
  @Input() currentCourtSystemId: Uuid | undefined

  viewModel$: ApiResult<PersonalMenu>
  refresh = new BehaviorSubject<void>(undefined)

  protected readonly ftrLogoPath = '../../../assets/images/logo-themed-no-text.svg'
  protected readonly buttonColor = ButtonColor
  protected readonly skipBadges: CourtSystemSelectorBadgeTypes[] = ['isPublished']

  constructor(
    protected readonly globalNavItems: GlobalNavItems,
    private readonly store: Store,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.viewModel$ = this.loadViewModel()
  }

  retry(): void {
    this.refresh.next()
  }

  onCourtSystemSelect(event: CourtSystemSelectorItem): void {
    const url = `${AppPaths.CourtSystem}/${event.id}`
    this.router.navigateByUrl(url)
  }

  private loadViewModel(): ApiResult<PersonalMenu> {
    return this.refresh.pipe(
      switchMap(() =>
        ApiResult.combine([
          this.store.select(UserState.user).pipe(filter(remoteData => remoteData.isSuccess())),
          ApiResult.from(
            this.store
              .dispatch(new GetCurrentUserLocationAction())
              .pipe(switchMap(() => this.store.select(UserState.currentUserLocation))),
          ),
          ApiResult.from(this.store.select(UserState.currentCourtSystem)),
          this.store.select(UserState.courtSystems),
          ApiResult.from(from(this.authenticationService.multifactorAuthenticationEnabled)),
          ApiResult.from(
            this.store
              .select(UserState.hasOnlyPermissionInAllCourtSystems)
              .pipe(map(fn => fn(UserGroupPermissionId.TranscribeRecordings))),
          ),
        ]).pipe(
          startWith(RemoteData.loading()),
          mapData(
            ([user, userLocation, currentCourtSystem, courtSystems, isMfaEnabled, hasOnlyTranscriberPermission]) => {
              const identityProvider = this.authenticationService.identityProvider ?? IdentityProviderName.Cognito
              return {
                user,
                courtSystem: this.currentCourtSystemId
                  ? mapCourtSystemWithMetadataToCourtSystemSelectorItem(
                      courtSystems.find(c => c.id === this.currentCourtSystemId) as CourtSystemWithMetadata,
                    )
                  : currentCourtSystem,
                courtSystemCount: courtSystems?.length > 0 && !hasOnlyTranscriberPermission ? courtSystems.length : 0,
                isMfaEnabled,
                identityProviderTitle: identityProvider === IdentityProviderName.Cognito ? 'FTR' : identityProvider,
                identityProviderIcon:
                  identityProvider === IdentityProviderName.Cognito ? undefined : (identityProvider as Icon),
                locationString: getLocationString(userLocation),
              }
            },
          ),
        ),
      ),
    )
  }
}

function getLocationString(userLocation: UserLocation | undefined): string {
  if (!userLocation || userLocation.locationString === '') {
    return 'Unknown'
  }
  const parts = userLocation.locationString.split(',')
  // Return only city and country
  if (parts.length > 2) {
    return `${parts.at(0)}, ${parts.at(-1)}`
  }
  return userLocation.locationString
}
