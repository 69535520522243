import { CommonModule } from '@angular/common'
import { Component, OnInit, Signal, computed, effect, input } from '@angular/core'
import { Data, RouterModule } from '@angular/router'
import { DestroySubscribers, LayoutService, ScreenSize, ScreenSizeService } from '@ftr/foundation'
import { SideNavGroup, SideNavItem, SideNavItemsProvider } from '@ftr/ui-core'
import { Observable, takeUntil } from 'rxjs'
import { SideNavDisplayComponent } from '~app/core/app-layout/side-nav/side-nav-display.component'
import { TopBarDisplayComponent } from '~app/core/app-layout/top-bar/top-bar-display.component'

const DEBOUNCE_WINDOW_RESIZE_MS = 50

@Component({
  selector: 'ftr-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule, SideNavDisplayComponent, TopBarDisplayComponent],
})
export class AppLayoutComponent extends DestroySubscribers implements OnInit {
  routeData = input({} as Data | undefined)

  // Controls wether the side nav is opened or closed (only for small screenss)
  protected sideNavOpen: boolean = false
  // Controls wether the side nav is expanded or not (only for large screens)
  protected sideNavExpanded: boolean = false
  protected hasToggledSideNav: boolean = false

  protected currentCourtSystemId: Signal<string | undefined> = computed(() => {
    return this.routeData()?.courtSystemId
  })
  protected sideNavMode = computed(() => {
    const currentSize = this.screenSizeService.sizeSignal()
    if (currentSize < ScreenSize.Large) {
      return 'OVERLAY'
    }
    return 'DEFAULT'
  })
  protected sideNavModel: Observable<{ navGroups: SideNavGroup[]; footerItems: SideNavItem[] }>
  protected readonly appContentWindowSelector = LayoutService.CONTENT_WINDOW_CLASS
  protected readonly appContentWindowSelectorGreyBackground = `${this.appContentWindowSelector}--grey-background`

  constructor(
    private screenSizeService: ScreenSizeService,
    private sideNavItemProvider: SideNavItemsProvider,
  ) {
    super()
    effect(() => {
      const courtSystemId = this.currentCourtSystemId()
      this.sideNavModel = this.sideNavItemProvider.provide(courtSystemId)
    })
  }

  ngOnInit(): void {
    this.watchScreenSize()
  }

  handleSideNavExpandedChange(): void {
    this.sideNavExpanded = !this.sideNavExpanded
    this.hasToggledSideNav = true

    // Fake a window resize event because while the window hasn't changed, the content panel has
    setTimeout(() => window.dispatchEvent(new Event('resize')), DEBOUNCE_WINDOW_RESIZE_MS)
  }

  handleSideNavOpenChange(forceState?: boolean): void {
    this.sideNavOpen = forceState !== undefined ? forceState : !this.sideNavOpen
  }

  private watchScreenSize(): void {
    this.screenSizeService.size.pipe(takeUntil(this.finalize)).subscribe(size => {
      if (!this.hasToggledSideNav) {
        if (size >= ScreenSize.XLarge) {
          this.sideNavExpanded = true
        }

        if (size === ScreenSize.Large) {
          this.sideNavExpanded = false
        }
      }

      if (this.sideNavOpen && size > ScreenSize.Medium) {
        this.sideNavOpen = false
      }
    })
  }
}
