import { Injectable } from '@angular/core'
import { Url } from '@ftr/contracts/type/shared'
import { BrowserFeaturesService, BrowserSnifferService, isInDesktopApp } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'

interface MultichannelPlayabilityInput {
  hasMultiChannel: boolean
  audioChannelCount: number
}

@Injectable()
export class MultiChannelPlaylistService {
  constructor(
    private readonly browserFeaturesService: BrowserFeaturesService,
    private readonly browserSnifferService: BrowserSnifferService,
    private readonly loggingService: LoggingService,
  ) {}

  getMultiChannelUrlWhenValid(url: Url, multichannelPlayabilityInput: MultichannelPlayabilityInput): string {
    if (this.canPlayMultiChannel(multichannelPlayabilityInput)) {
      url = url.replace('/playlist', '/multi-channel-playlist')
      return url.replace('/mono/', '/multichannel/')
    }
    url = url.replace('/multi-channel-playlist', '/playlist')
    return url.replace('/multichannel/', '/mono/')
  }

  canPlayMultiChannel({ hasMultiChannel, audioChannelCount }: MultichannelPlayabilityInput): boolean {
    const isSupportedBrowser = this.isSupportedBrowser()
    const isMediaSourceExtensionsSupported = this.browserFeaturesService.isMediaSourceExtensionsSupported()
    const canPlayMultiChannel = hasMultiChannel && isSupportedBrowser && isMediaSourceExtensionsSupported

    if (isInDesktopApp()) {
      // users are reporting intermittent issues with multichannel playback of same-day recordings in the desktop app
      this.loggingService.info({
        message: 'Evaluated multichannel playability in desktop application',
        hasMultiChannel,
        audioChannelCount,
        isSupportedBrowser,
        isMediaSourceExtensionsSupported,
        canPlayMultiChannel,
      })
    }

    return canPlayMultiChannel
  }

  private isSupportedBrowser(): boolean {
    const isAndroidChromium = this.browserSnifferService.isAndroid() && this.browserSnifferService.isChromeLike()
    return !this.browserSnifferService.isSafari() && !isAndroidChromium
  }
}
