<form
  class="form"
  [ngClass]="{ 'form--submitting': formState === 'SUBMITTING' }"
  [formGroup]="formGroup"
  [attr.name]="name"
  (ngSubmit)="submitForm()"
>
  @if (formState === 'SUBMITTING') {
    <div class="form__submit-overlay" [ngClass]="{ 'form__submit-overlay--minimal': minimalOverlay }">
      <div class="form__overlay-soft-light" [@fade]></div>
      <div class="form__overlay-grey" [@halffade]></div>
    </div>
  }

  <ng-container *ngTemplateOutlet="formTemplate; context: { highlightErrors: highlightErrors }"></ng-container>

  @if (formState === 'FAILURE' && submissionError) {
    <ftr-notification [type]="notificationType.Error" class="form__error">{{ submissionError }}</ftr-notification>
  }

  <div class="form__footer">
    <ng-container *ngTemplateOutlet="preFormFooterTemplate"></ng-container>
    @if (showGuard) {
      <ng-container
        *ngTemplateOutlet="
          submitGuardTemplate;
          context: { cancel: closeGuard.bind(this), submit: submitFormFromGuard.bind(this) }
        "
      >
      </ng-container>
    }
    <ng-container *ngTemplateOutlet="formFooterTemplate"></ng-container>
    @if (!hideSubmitButton) {
      <ftr-submit-button
        class="form__footer__submit"
        [class.form__footer__submit--full-width]="submitFullWidth || submitFullWidthMobile"
        [size]="submitSize"
        [highlightErrors]="highlightErrors"
        [formState]="formState"
        [submittingLabel]="submittingLabel"
        [submitLabel]="submitLabel"
        [direction]="submitDirection"
        [fullWidth]="submitFullWidth"
        [fullWidthMobile]="submitFullWidthMobile"
        [dataTrack]="trackSubmit"
        [color]="buttonColor"
      ></ftr-submit-button>
    }
  </div>

  <div class="form__instructions">
    <ng-container *ngTemplateOutlet="formInstructionsTemplate"></ng-container>
  </div>
</form>
