export const DEFAULT_VOLUME = 0.7
export const MIN_VOLUME = 0
export const MAX_VOLUME = 1
export const MAX_CONFIGURABLE_CHANNELS = 8
export const DEFAULT_PLAYBACK_RATE = 1.0
export const LEGACY_SUPPORTED_PLAYBACK_RATES = [0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0]
export const REAL_TIME_SUPPORTED_PLAYBACK_RATES = [0.5, 1, 1.5, 2]
export const SKIP_SECONDS = 5
/**
 * Duration is undefined under a playlist when using native Http Live Streaming, so we set it to a default of 5 minutes.
 */
export const MIN_DURATION = 300
