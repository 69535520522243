import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { SearchRequestType } from '@ftr/contracts/api/search'
import { ArrayUtils, ButtonDirection, ButtonDisplayType, DestroySubscribers, isOfNonZeroLength } from '@ftr/foundation'
import { PlaybackState } from '@ftr/ui-playback'
import {
  SearchBarInputState,
  SearchFocusState,
  SearchState,
  SetBackToSearchResultsFullUrlAction,
  SetSearchBarInputStateAction,
  SetSearchFocusStateAction,
  SetSttContextAction,
} from '@ftr/ui-search'
import { Store } from '@ngxs/store'
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  fromEvent,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs'
import { SearchBarDisplayService } from '~app/core/header/search-container-legacy/search-bar-legacy/search-bar-display.service'
import { SearchBarLegacyService } from '~app/core/header/search-container-legacy/search-bar-legacy/search-bar-legacy.service'
import { FormGroupMembers } from '~app/core/header/search-container/search-bar/search-bar.service'
import { searchValidator } from '~app/core/header/search-container/search-bar/search-validator'
import { SearchParams } from '~app/pages/search.params'

const SEARCH_BAR_TRANSITION_MS = 300
const HINTS = {
  [SearchRequestType.Orders]:
    'Search for orders or enter a reference to go to that order. Dates may be entered in the following formats: 28/03/2013, 28-03-2013.',
}

type Hints = {
  [k in SearchRequestType]: string
}

@Component({
  selector: 'ftr-search-bar-legacy',
  templateUrl: './search-bar-legacy.component.html',
  styleUrls: ['./search-bar-legacy.component.css'],
  host: { class: 'search' },
  animations: [
    trigger('expandSearch', [
      transition(':enter', [
        style({ width: 0 }),
        animate(SEARCH_BAR_TRANSITION_MS, style({ width: '100%' })),
        group([query('@expandSearchHint', [animateChild()], { optional: true })]),
      ]),
    ]),
    trigger('expandSearchHint', [
      transition(':enter', [style({ opacity: 0 }), animate(SEARCH_BAR_TRANSITION_MS, style({ opacity: 1 }))]),
    ]),
  ],
})
export class SearchBarLegacyComponent extends DestroySubscribers implements OnInit, AfterViewInit, OnDestroy {
  @Input() isMobile: boolean
  @Input() isTablet: boolean
  @Input() isDesktop: boolean
  @Input() isSearching$: Observable<boolean>
  @Input() searchTerm$: Observable<string>

  @ViewChild('searchIpt') searchIpt: ElementRef

  formGroup: UntypedFormGroup
  showSearchHint = new BehaviorSubject(false)

  readonly buttonDisplayType = ButtonDisplayType
  readonly buttonDirection = ButtonDirection

  readonly inputStates = SearchBarInputState

  readonly hints: Partial<Hints> = HINTS

  // Webstorm thinks this is readonly but it isn't - its used in the template.
  readonly searchBarInputState$: Observable<SearchBarInputState>

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store,
    readonly searchBarDisplayService: SearchBarDisplayService,
    readonly searchBarService: SearchBarLegacyService,
  ) {
    super()
    this.searchBarInputState$ = this.store.select(SearchState.searchBarInputState)
    this.formGroup = this.formBuilder.group({
      searchTerm: ['', [searchValidator]],
      searchType: [''],
    })
  }

  ngOnInit(): void {
    combineLatest([this.searchTerm$, this.store.select(PlaybackState.isPlaybackPage)])
      .pipe(takeUntil(this.finalize), distinctUntilChanged(ArrayUtils.shallowEquals))
      .subscribe(([searchTerm, isPlaybackPage]) => {
        if (!isPlaybackPage) {
          this.store.dispatch(new SetSttContextAction(undefined))
        }
        this.formGroup.patchValue({
          searchTerm: searchTerm || null,
        })
        if (!searchTerm || searchTerm.length === 0) {
          this.formGroup.controls.searchTerm.reset()
        }
      })

    this.searchBarDisplayService.defaultSearchType$
      .pipe(takeUntil(this.finalize))
      .subscribe(searchType => this.formGroup.patchValue({ searchType }))

    this.listenForFocusChanges()
  }

  ngAfterViewInit(): void {
    if (this.searchIpt) {
      fromEvent(this.searchIpt.nativeElement, 'focus')
        .pipe(takeUntil(this.finalize))
        .subscribe(() => this.onSearchInputFocus())

      this.searchTerm$
        .pipe(
          takeUntil(this.finalize),
          takeWhile(() => !!this.searchIpt),
          filter(isOfNonZeroLength),
        )
        .subscribe(() => {
          this.searchIpt.nativeElement.focus()
        })
    }
  }

  ngOnDestroy(): void {
    this.formGroup.reset()
    super.ngOnDestroy()
  }

  @HostListener('document:keydown.escape') handleEscapeKeydown(): void {
    this.isSearching$
      .pipe(
        take(1),
        filter(isSearching => isSearching),
      )
      .subscribe(() => this.onBack())
  }

  onSearchButtonClick(): void {
    this.onSearchButtonFocus()
    this.showSearchHint.next(false)

    const formValue = this.formGroup.value[FormGroupMembers.SearchTerm]
    const sanitizedFormValue = formValue?.trim()
    if (sanitizedFormValue !== formValue) {
      this.formGroup.patchValue({
        searchTerm: sanitizedFormValue,
      })
    }

    if (!this.formGroup.valid) {
      this.formGroup.markAsDirty()
      return
    }

    this.searchBarService.setUpSearch(this.formGroup.value[FormGroupMembers.SearchTerm])
    this.searchBarService.setLastUrlBeforeSearching(this.router.url)

    this.router.navigate([], {
      queryParams: {
        [SearchParams.SearchTerm]: this.formGroup.value[FormGroupMembers.SearchTerm],
        [SearchParams.SearchType]: this.formGroup.value[FormGroupMembers.SearchType],
        [SearchParams.SearchPageNumber]: 1,
      },
      queryParamsHandling: 'merge',
    })
  }

  onClear(): void {
    this.formGroup.controls.searchTerm.reset()
    this.showSearchHint.next(true)
    this.searchIpt.nativeElement.focus()
  }

  onBack(): void {
    this.formGroup.controls.searchTerm.reset()
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Collapsed))
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
    this.store.dispatch(new SetBackToSearchResultsFullUrlAction())
    const priorURL = this.searchBarService.getSearchedFromUrl()
    if (priorURL) {
      this.router.navigateByUrl(priorURL)
    } else {
      const urlTree = this.router.parseUrl(this.router.url)
      // Preserve any non-search related query params
      delete urlTree.queryParams[SearchParams.SearchTerm]
      delete urlTree.queryParams[SearchParams.SearchType]
      delete urlTree.queryParams[SearchParams.SearchPageNumber]
      this.router.navigateByUrl(urlTree)
    }
  }

  onDown(e: Event): void {
    // prevent arrow key scroll
    e.preventDefault()
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.RESULTS_LIST))
  }

  onClose(): void {
    if (this.isMobile) {
      this.onBack()
    }
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Collapsed))
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
  }

  onSearchInputClick(): void {
    this.onSearchInputFocus()
    this.showSearchHint.next(true)
  }

  onSearchTypeClick(): void {
    this.onSearchTypeFocus()
  }

  onSearchInputBlur(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
    this.showSearchHint.next(false)
  }

  showSearchBar(): void {
    this.store.dispatch(new SetSearchBarInputStateAction(SearchBarInputState.Expanded))
    this.onSearchInputFocus()
    setTimeout(() => this.searchIpt.nativeElement.focus())
    this.showSearchHint.next(true)
  }

  getPlaceholderForSearchInput(): string {
    const type: SearchRequestType = this.formGroup.controls.searchType.value
    switch (type) {
      case SearchRequestType.ThisAudioSegment:
        return 'Search this recording'
      default:
        return `Search ${type || ''}`
    }
  }

  private listenForFocusChanges(): void {
    this.store
      .select(SearchState.searchFocusState)
      .pipe(
        takeUntil(this.finalize),
        filter((s: SearchFocusState) => s === SearchFocusState.SEARCH_INPUT),
      )
      .subscribe(() => {
        if (this.searchIpt) {
          this.searchIpt.nativeElement.focus()
        }
      })
  }

  private onSearchInputFocus(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.SEARCH_INPUT))
  }

  private onSearchTypeFocus(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.SEARCH_TYPE))
  }

  private onSearchButtonFocus(): void {
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.SEARCH_BUTTON))
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }

  asHintKey(value: unknown): SearchRequestType {
    return value as SearchRequestType
  }
}
