import { Injectable } from '@angular/core'
import {
  SetBackToSearchResultsFullUrlAction,
  SetSearchResultsScrollPositionAction,
  SetSearchTermAction,
  SetUniqueSearchAction,
} from '@ftr/ui-search'
import { Store } from '@ngxs/store'
import { SearchParams } from '~app/pages/search.params'

export enum FormGroupMembers {
  SearchTerm = 'searchTerm',
  SearchType = 'searchType',
}

@Injectable({
  providedIn: 'root',
})
export class SearchBarService {
  private searchedFromUrl: string | undefined

  constructor(private readonly store: Store) {}

  setUpSearch(searchTerm: string): void {
    this.store.dispatch(new SetSearchTermAction(searchTerm))
    this.store.dispatch(new SetSearchResultsScrollPositionAction())
    this.store.dispatch(new SetUniqueSearchAction(new Date().getTime()))
    this.store.dispatch(new SetBackToSearchResultsFullUrlAction())
  }

  setLastUrlBeforeSearching(routerUrl: string): void {
    const rxSearchUrl = new RegExp(SearchParams.SearchPageNumber, 'gi')
    if (!rxSearchUrl.test(routerUrl)) {
      this.searchedFromUrl = routerUrl
    }
  }

  getSearchedFromUrl(): string | undefined {
    return this.searchedFromUrl
  }
}
