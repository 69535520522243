<ftr-search-bar
  [isMobile]="isMobile"
  [isTablet]="isTablet"
  [isDesktop]="isDesktop"
  [isMobile]="isMobile"
  [isTablet]="isTablet"
  [isDesktop]="isDesktop"
  [isSearching]="isSearching()"
  [searchTerm]="searchTerm() ?? ''"
  [searchFocusState]="searchFocusState()"
></ftr-search-bar>
@if (isSearching()) {
  @if (configuration(); as configuration) {
    <ftr-search-results-overlay
      [isSearching]="isSearching()"
      [selectedEntities]="selectedEntities()"
      [searchTerm]="searchTerm() ?? ''"
      [searchResponse]="searchResponse()"
      [searchFocusState]="searchFocusState()"
      [configuration]="configuration"
      [availableEntities]="availableEntities()"
      [searchEntityTypeToNumberOfMatches]="searchEntityTypeToNumberOfMatches()"
      [vocabularyTerms]="vocabularyTerms()"
      (onFilterItemSelected)="onFilterItemSelected($event)"
      (onNewSearchFocusState)="onNewSearchFocusState($event)"
      (onRetry)="onRetry()"
    ></ftr-search-results-overlay>
  }
}
