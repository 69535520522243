@if (totalItems() > pageSize()) {
  <div class="pagination" [ngClass]="'pagination--' + paginationType()">
    <span id="pagination__desc" class="pagination__desc" [attr.data-testid]="testIds.pageInfoText"
      >{{ pageStart() | number }} - {{ pageEnd() | number }} of {{ totalItems() | number }}</span
    >
    @if (!hasPrevPage()) {
      <ftr-icon
        [attr.data-testid]="testIds.previousPageButton"
        class="pagination__prev pagination__prev--disabled"
        icon="KeyboardArrowLeft"
        [align]="iconAlignment()"
      ></ftr-icon>
    }
    @if (hasPrevPage()) {
      <a
        [attr.data-testid]="testIds.previousPageButton"
        class="pagination__prev"
        [ngClass]="{ 'pagination__prev--disabled': disabled() }"
        [routerLink]="[]"
        queryParamsHandling="merge"
        [queryParams]="prevPageParams()"
        (click)="this.pageSelected.emit('previous')"
        data-track
        data-track-group="Pagination Prev"
      >
        <ftr-icon icon="KeyboardArrowLeft" [align]="iconAlignment()"></ftr-icon>
        Previous Page. Currently showing {{ pageStart() | number }} - {{ pageEnd() | number }} of
        {{ totalItems() | number }}</a
      >
    }
    @if (!hasNextPage()) {
      <ftr-icon
        [attr.data-testid]="testIds.nextPageButton"
        class="pagination__next pagination__next--disabled"
        icon="KeyboardArrowRight"
        [align]="iconAlignment()"
      ></ftr-icon>
    }
    @if (hasNextPage()) {
      <a
        [attr.data-testid]="testIds.nextPageButton"
        class="pagination__next"
        [ngClass]="{ 'pagination__next--disabled': disabled() }"
        [routerLink]="[]"
        queryParamsHandling="merge"
        [queryParams]="nextPageParams()"
        (click)="this.pageSelected.emit('next')"
        data-track
        data-track-group="Pagination Next"
      >
        <ftr-icon icon="KeyboardArrowRight" [align]="iconAlignment()"></ftr-icon>
        Next Page. Currently showing {{ pageStart() | number }} - {{ pageEnd() | number }} of
        {{ totalItems() | number }}</a
      >
    }
  </div>
}
