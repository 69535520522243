import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { fade } from '../../animations'
import { NotificationModule } from '../../services'
import { AnimatedRemoteComponent } from '../animated-remote'
import { ErrorRetryComponent } from '../error-retry'
import { LoaderComponent } from '../loader'
import { PageComponent, PageStyle } from '../page'
import { EmptyFunction, isEmpty } from '../remote'
import { ApiResult } from '../remote-data'

/**
 * A basic page layout that defines paddings/borders etc. Optionally:
 * - BYO remote data for load states
 * - can display success/error messages
 * - can override loading/failure/empty states
 */
@Component({
  selector: 'ftr-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.css',
  animations: [fade(300, 0)],
  standalone: true,
  imports: [
    AnimatedRemoteComponent,
    CommonModule,
    ErrorRetryComponent,
    LoaderComponent,
    NotificationModule,
    PageComponent,
  ],
})
export class PageLayoutComponent<T> implements OnInit {
  /**
   * The name of the page
   */
  @Input() name: string
  /*
   * The data used on the page
   */
  @Input() entity: ApiResult<T>
  /*
   * An optional success notification to display
   */
  @Input() successMessage?: string
  /*
   * An optional error notification to display in the remote data error template
   */
  @Input() errorMessage?: string | undefined
  /**
   * A template displayed before an API request
   */
  @Input() notAskedTemplate: TemplateRef<unknown> | null = null
  /**
   * A template while waiting from an API response
   */
  @Input() loadingTemplate: TemplateRef<unknown> | null = null
  /**
   * A template for the body
   */
  @Input() bodyTemplate: TemplateRef<unknown> | null = null

  /**
   * A template for the header. Only displays when with a successful ApiResult.
   *
   * provides access to the data for use in the template
   */
  @Input() headerTemplate: TemplateRef<unknown> | null = null

  /**
   * A static header, that is visible always.
   *
   * Important: This should not depend on any async data
   */
  @Input() staticHeaderTemplate: TemplateRef<unknown> | null = null

  /**
   * A template for the footer
   */
  @Input() footerTemplate: TemplateRef<unknown> | null = null
  /**
   * Optional template for an empty data result. Ensure useFailureTemplateForEmpty is false when the
   * empty body template is provided
   */
  @Input() emptyBodyTemplate: TemplateRef<unknown> | null = null
  /**
   * Used to emit that the user has requested that the data should be loaded again
   */
  @Output() onRetry = new EventEmitter<undefined>()
  /**
   * Use the failure template when the API returns an empty body or the isEmptyFunction returns true.
   * If an emptyBodyTemplate is provided, this will override it
   */
  @Input() useFailureTemplateForEmpty = false
  /**
   * An optional override selector to determine whether to display the empty state
   */
  @Input() isEmptyFunction: EmptyFunction<T> = isEmpty
  /**
   * The page style to use
   */
  @Input() pageStyle = PageStyle.Default

  @Input() horizontalAutoMargin = true

  @Input() horizontalOuterMargin = true

  @Input() noMargin = false

  @Input() displayMode: 'DEFAULT' | 'FILL' = 'DEFAULT'

  /**
   * The class name to apply to the root element
   */
  class: string

  ngOnInit(): void {
    this.setClass()
  }

  retry(): void {
    this.onRetry.emit()
  }

  private setClass(): void {
    this.class = this.name.toLowerCase().replace(' ', '-')
  }
}
