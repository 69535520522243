import { Component, input, OnInit, signal, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { toObservable, toSignal } from '@angular/core/rxjs-interop'
import { Favorite } from '@ftr/contracts/api/favorites'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  ButtonComponent,
  ButtonDisplayType,
  DestroySubscribers,
  Icon,
  IconComponent,
  LoaderComponent,
  ModalComponent,
  ModalLoaderStyle,
  ModalService,
} from '@ftr/foundation'
import { InlineInputComponent } from '@ftr/inline-forms'
import { IconUrlMapperService } from '@ftr/ui-core'
import {
  DeleteFavoriteFromCourtSystemAction,
  FavoritesState,
  SetFavoritesEditModalVisible,
  UpdateFavoritesInCourtSystemAction,
} from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { filter, switchMap, takeUntil } from 'rxjs'

type FavoriteEditState = Favorite & { icon: Icon; loading: boolean }

@Component({
  standalone: true,
  imports: [ModalComponent, InlineInputComponent, ButtonComponent, IconComponent, LoaderComponent],
  selector: 'ftr-favorites-edit-modal',
  templateUrl: './favorites-edit-modal.component.html',
  styleUrls: ['./favorites-edit-modal.component.css'],
})
export class FavoritesEditModalComponent extends DestroySubscribers implements OnInit {
  @ViewChild('favoritesModalTemplate') favoritesModalTemplate: TemplateRef<any>

  courtSystemId = input.required<Uuid>()
  protected readonly favorites = toSignal(
    toObservable(this.courtSystemId).pipe(
      switchMap(courtSystemId => {
        return this.store.select(FavoritesState.favorites(courtSystemId)).pipe(
          filter(favorite => favorite !== undefined),
          takeUntil(this.finalize),
        )
      }),
    ),
  )
  protected favoriteEditStates = signal<FavoriteEditState[]>([])
  protected loadingStates = signal<Record<Uuid, boolean>>({})

  constructor(
    private readonly store: Store,
    private readonly modalService: ModalService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly iconMapperService: IconUrlMapperService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.store
      .select(FavoritesState.showEditModal)
      .pipe(takeUntil(this.finalize))
      .subscribe(showModal => {
        if (showModal) {
          this.rebuildEditingState()
          this.modalService.open(this.favoritesModalTemplate, this.viewContainerRef)
        }
      })
  }

  deleteFavorite(favoriteId: Uuid): void {
    this.setFavoriteLoadingState(favoriteId, true)
    this.store.dispatch(new DeleteFavoriteFromCourtSystemAction(this.courtSystemId(), favoriteId)).subscribe(() => {
      this.favoriteEditStates.set(this.favoriteEditStates().filter(favorite => favoriteId !== favorite.id))
      this.setFavoriteLoadingState(favoriteId, false)
    })
  }

  updateFavorites(): void {
    const updatedFavorites = this.favoriteEditStates().map(({ id, label, url }) => {
      return {
        id,
        label,
        url,
      } as Favorite
    })
    this.store
      .dispatch(new UpdateFavoritesInCourtSystemAction(this.courtSystemId(), updatedFavorites))
      .subscribe(() => {
        this.closeModal()
      })
  }

  closeModal(): void {
    this.store.dispatch(new SetFavoritesEditModalVisible(false)).subscribe(() => {
      this.modalService.close()
    })
  }

  updateFavoriteLabel(favoriteId: Uuid, newLabel: string): void {
    this.favoriteEditStates.set(
      this.favoriteEditStates().map(favorite => ({
        ...favorite,
        label: favorite.id === favoriteId ? newLabel : favorite.label,
      })),
    )
  }

  protected readonly buttonDisplayType = ButtonDisplayType
  protected readonly modalLoaderStyle = ModalLoaderStyle

  hasErrors(): boolean {
    return this.favoriteEditStates().filter(favorite => favorite.label === '').length > 0
  }

  private setFavoriteLoadingState(favoriteId: Uuid, isLoading: boolean): void {
    this.loadingStates.set({
      ...this.loadingStates(),
      [favoriteId]: isLoading,
    })
  }

  private rebuildEditingState(): void {
    this.favoriteEditStates.set(
      this.favorites()?.map(
        favorite =>
          ({
            id: favorite.id,
            label: favorite.label,
            url: favorite.url,
            icon: this.iconMapperService.getIconForUrl(favorite.url) as Icon,
            loading: false,
          }) as FavoriteEditState,
      ) || [],
    )
  }
}
