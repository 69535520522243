import { Injectable } from '@angular/core'
import { DepartmentService } from '@ftr/api-core'
import { NotFoundApiError, serializeHttpParams } from '@ftr/api-shared'
import { Department } from '@ftr/contracts/api/department'
import { mapLocationToCourtroom } from '@ftr/contracts/api/location'
import { Timeframe, TimeframeWithLocalTimes } from '@ftr/contracts/api/shared'
import { SealingRequest } from '@ftr/contracts/message/recording'
import { Courtroom, Recording, RecordingSegment, RecordingWithSegments } from '@ftr/contracts/read'
import { isLive } from '@ftr/contracts/read/recording/utils'
import { ProducerCondition, StreamRecordingSummary } from '@ftr/contracts/regional-api'
import { RecordingConversionStatus, RecordingType, ReplaceSealedSegmentsQuery } from '@ftr/contracts/type/recording'
import { ONE_SECOND_MS, Uuid } from '@ftr/contracts/type/shared'
import { CourtroomService } from '@ftr/data-location'
import {
  ApiResult,
  RemoteData,
  distinctUntilDataChanged,
  mapData,
  recover,
  recoverType,
  recoverUndefinedData,
  switchMapData,
} from '@ftr/foundation'
import { plainToClass } from '@ftr/serialization'
import { LocalTime } from '@js-joda/core'
import { filter, startWith, switchMap, timer } from 'rxjs'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '../../api-client'
import { hasEqualSegments, hasEqualTimeframes, isRecordingLiveAndOnTheRecord } from '../../util'

type RealTimeRecordingWithSegments = RecordingWithSegments & {
  courtroom: Courtroom
}
// This currently must be provided in root so it can be used by PlaybackState, in CoreModule.
@Injectable({
  providedIn: 'root',
})
export class RealTimeLiveStreamService extends RegionalApiClientSetupService {
  /**
   * The read-only Playback API is for getting recording data and HLS assets.
   */
  playbackApiPath = 'playback'
  /**
   * This API is for modifying recordings, such as sealing and going on/off record.
   */
  override regionalApiPath = 'live-stream'

  constructor(
    private readonly courtroomService: CourtroomService,
    private readonly departmentService: DepartmentService,
    regionalApiClientFactory: RegionalApiClientFactory,
  ) {
    super(regionalApiClientFactory)
  }

  getRecordingFromPlaybackAPI(
    courtSystemId: Uuid,
    recordingId: Uuid,
    replaceSealedSegments?: boolean,
  ): ApiResult<Recording> {
    return this.regionalApiClient(this.playbackApiPath, courtSystemId).pipe(
      switchMap(client => {
        return client.get<Recording | undefined>({
          path: `recording/${recordingId}`,
          params: replaceSealedSegments
            ? serializeHttpParams(new ReplaceSealedSegmentsQuery(replaceSealedSegments))
            : undefined,
          headers: { courtSystemId },
        })
      }),
      mapData(response => plainToClass(Recording, response)),
    )
  }

  getConditionsForRecording(courtSystemId: Uuid, recordingId: Uuid): ApiResult<ProducerCondition[]> {
    return this.regionalApiClient(this.playbackApiPath, courtSystemId).pipe(
      switchMap(client => {
        return client.get<unknown[] | undefined>({
          path: `recording/${recordingId}/conditions`,
          headers: { courtSystemId },
        })
      }),
      recover([]),
      recoverUndefinedData([]),
      mapData(response => response?.map(x => plainToClass(ProducerCondition, x)) ?? []),
    )
  }

  getRecordingWithSegments(
    courtSystemId: Uuid,
    recordingId: Uuid,
    useReplacementMedia?: boolean,
  ): ApiResult<RealTimeRecordingWithSegments> {
    return this.getRecordingWithCourtroomAndDepartment(courtSystemId, recordingId, useReplacementMedia)
  }

  getRecordingWithCourtroomAndDepartment(
    courtSystemId: Uuid,
    recordingId: Uuid,
    useReplacementMedia?: boolean,
  ): ApiResult<RealTimeRecordingWithSegments> {
    return this.getRecordingFromPlaybackAPI(courtSystemId, recordingId, useReplacementMedia).pipe(
      switchMapData(recording =>
        ApiResult.combine([
          ApiResult.success(recording),
          this.courtroomService.findById(courtSystemId, recording.locationId),
          recording.departmentId ? this.departmentService.get(recording.departmentId) : ApiResult.success(undefined),
        ]),
      ),
      mapData(([recording, location, department]) =>
        mapRecordingToRecordingWithSegments(recording, mapLocationToCourtroom(location), department),
      ),
      mapData(toRealTimeRecordingWithSegments),
    )
  }

  getLocationActiveRecording(
    locationId: Uuid | undefined,
    courtSystemId: Uuid,
  ): ApiResult<StreamRecordingSummary | undefined | null> {
    if (!locationId) return ApiResult.success(null)
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client => {
        return client.get<StreamRecordingSummary>({
          path: `location/${locationId}/active-recording`,
          headers: { courtSystemId },
          responseBodyType: StreamRecordingSummary,
        })
      }),
      recoverType(NotFoundApiError, undefined),
    )
  }

  /**
   * Emits the given recording, then polls the recording and emits if it changes.
   * Designed to run in the background, so non-success states are not emitted.
   */
  pollLiveRecording(
    recording: RealTimeRecordingWithSegments,
    useReplacementMedia?: boolean,
  ): ApiResult<RealTimeRecordingWithSegments> {
    const delay = useReplacementMedia ? 0 : 5 * ONE_SECOND_MS
    const interval = 5 * ONE_SECOND_MS
    return timer(delay, interval).pipe(
      switchMap(() => this.getRecordingWithSegments(recording.courtSystemId, recording.id, useReplacementMedia)),
      startWith(RemoteData.success(recording)),
      filter(x => x.isSuccess()),
      distinctUntilDataChanged((a, b) => {
        return (
          a?.id === b?.id &&
          isLive(a) === isLive(b) &&
          hasEqualTimeframes(a.onTheRecordTimeframes, b.onTheRecordTimeframes) &&
          hasEqualTimeframes(a.sealedSegments, b.sealedSegments) &&
          hasEqualSegments(a.segments, b.segments) &&
          isRecordingLiveAndOnTheRecord(a) === isRecordingLiveAndOnTheRecord(b)
        )
      }),
    )
  }

  seal(courtSystemId: Uuid, recordingId: Uuid, start: number, end: number): ApiResult {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client => {
        const body = new SealingRequest(recordingId, true, start, end)
        return client.post<null>({
          path: '/recording/seal',
          body,
        })
      }),
    )
  }

  unseal(courtSystemId: Uuid, recordingId: Uuid, start: number, end: number): ApiResult {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client => {
        const body = new SealingRequest(recordingId, false, start, end)
        return client.post<null>({
          path: '/recording/seal',
          body,
        })
      }),
    )
  }

  goOnTheRecord(courtSystemId: Uuid, recordingId: Uuid): ApiResult {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client =>
        client.put<null>({
          path: `/recording/${encodeURIComponent(recordingId)}/on-the-record`,
        }),
      ),
    )
  }

  goOffTheRecord(courtSystemId: Uuid, recordingId: Uuid): ApiResult {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client =>
        client.put<null>({
          path: `/recording/${encodeURIComponent(recordingId)}/off-the-record`,
        }),
      ),
    )
  }

  toggleLiveSealing(courtSystemId: Uuid, recordingId: Uuid): ApiResult {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client =>
        client.post<null>({ path: `/recording/${encodeURIComponent(recordingId)}/toggle-live-sealing` }),
      ),
    )
  }
}

export function toRealTimeRecordingWithSegments(recording: RecordingWithSegments): RealTimeRecordingWithSegments {
  if (!recording.courtroom) {
    throw new Error('Courtroom is not set for stream recording')
  }
  return recording as RealTimeRecordingWithSegments
}

export function findLatestSegment(segments: RecordingSegment[]): RecordingSegment | undefined {
  return segments.length
    ? segments.reduce((prev, current) => (prev.startDateTime.isAfter(current.startDateTime) ? prev : current))
    : undefined
}

export function mapRecordingToRecordingWithSegments(
  recording: Recording,
  courtroom: Courtroom | undefined,
  department: Department | undefined,
): RecordingWithSegments {
  return new RecordingWithSegments(
    recording.id,
    recording.courtSystemId,
    `Live Stream ${recording.start.toString()}`,
    recording.start.toLocalDate(),
    RecordingConversionStatus.Converted,
    courtroom,
    recording.segments,
    recording.sealedTimes.map(x => createTimeframe(x.start, x.end)),
    undefined,
    recording.tags.has('video'),
    recording.tags.has('track-isolated'),
    department,
    recording.segments.length > 0 ? recording.segments[0].recordingType : RecordingType.Stream,
    recording.tags.has('stt'),
    recording.tags.has('multichannel'),
    recording.audioChannelCount,
    recording.onTheRecordTimes.map(x => createTimeframe(x.start, x.end)),
    recording.availableTimes.map(x => new TimeframeWithLocalTimes(x.start, x.end)),
    undefined,
    [],
    recording.tags.has('stt'), // a stream/capture recording stt is always attributed
    recording.tags,
  )
}

function createTimeframe(start: LocalTime, end: LocalTime): Timeframe {
  return { start: start.toSecondOfDay(), end: end.toSecondOfDay() }
}
