import { Pipe, PipeTransform } from '@angular/core'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { MomentConvertible, toMoment } from '@ftr/foundation'
import moment from 'moment-timezone'

export interface ToCourtSystemTimeZonePipeOptions {
  courtSystemConfiguration: PublicConfiguration
  timeZoneId?: string
}

@Pipe({ name: 'ftrCourtSystemTimeZone' })
export class CourtSystemTimeZonePipe implements PipeTransform {
  transform(value: string | undefined, args: ToCourtSystemTimeZonePipeOptions): MomentConvertible {
    return args && args.courtSystemConfiguration
      ? this.toCourtSystemTimeZone(value, args.courtSystemConfiguration, args.timeZoneId)
      : ''
  }

  toCourtSystemTimeZone(
    date: string | undefined,
    config: PublicConfiguration,
    timeZoneId: string | undefined,
  ): MomentConvertible {
    // undefined is a valid moment date input
    const validDate = date && moment(date).isValid()
    return validDate && config && config.timeZoneId
      ? toMoment(date).moment.tz(timeZoneId ?? config.timeZoneId.toString())
      : ''
  }
}
