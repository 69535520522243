import { Component, Input, OnInit } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { IndexedLogNote, IndexedLogSheet, IndexedRemark, SearchResult } from '@ftr/contracts/api/search'
import { DateFormat } from '@ftr/foundation'
import { isIndexedLogNote, isIndexedLogSheet } from '@ftr/ui-search'

@Component({
  selector: 'ftr-search-legacy-results-this-recording-item',
  templateUrl: './search-legacy-results-this-recording-item.component.html',
  styleUrls: ['./search-legacy-results-this-recording-item.component.css'],
})
export class SearchResultsThisRecordingItemComponent implements OnInit {
  @Input() searchResult: SearchResult<IndexedRemark | IndexedLogSheet | IndexedLogNote>
  @Input() courtSystemConfig: PrivateConfiguration

  utterance: SearchResult<IndexedRemark> | undefined
  logSheet: SearchResult<IndexedLogSheet> | undefined
  logNote: SearchResult<IndexedLogNote> | undefined

  readonly dateFormat = DateFormat

  ngOnInit(): void {
    if (isIndexedLogNote(this.searchResult.body)) {
      this.logNote = this.searchResult as SearchResult<IndexedLogNote>
    } else if (isIndexedLogSheet(this.searchResult.body)) {
      this.logSheet = this.searchResult as SearchResult<IndexedLogSheet>
    } else {
      // Utterance is the fallback as ThisRecording search type only supports searching for log sheet
      // and/or stt content, not recording data such as court house/room names and dates of recordings
      this.utterance = this.searchResult as SearchResult<IndexedRemark>
    }
  }
}
