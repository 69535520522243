import { IndexedOrder, SearchResultType } from '@ftr/contracts/api/search'
import { get as getByStringPath } from 'lodash-es'

type SearchResultTypePropertyLabel = string
type HighlightKey = string
/**
 * Forms a recursive required contract from a SearchResultType.
 * - It assumes that all properties on SearchResultType are primitives
 * - It unwraps arrays storing type as the value Ex. string[] becomes string
 *
 * Caveats:
 * this will not work with mixed-type arrays. If we encounter mixed type arrays
 * we `might` be able to form a dynamic union type, but, it still may not work
 * if there are key collisions accross the types.
 */
type SearchResultTypeLabelMap<T> = {
  // enforce keys a required // if an array we hoist the type out of the array R[] => R
  [K in keyof T]-?: Required<T>[K] extends (infer R)[]
    ? SearchResultTypeLabelMap<R>
    : Required<T>[K] extends object
      ? SearchResultTypeLabelMap<T[K]>
      : SearchResultTypePropertyLabel
}

// We don't need labels for certain fields that 'always display' in the right-hand-side.
const LABEL_NOT_IMPLEMENTED = '__LABEL_NOT_IMPLEMENTED__'

export function getLabelByKey(
  indexLabelMap: SearchResultTypeLabelMap<SearchResultType>,
  key: string | HighlightKey,
): SearchResultTypePropertyLabel | undefined {
  const label = getByStringPath(indexLabelMap, key)
  return label && label !== LABEL_NOT_IMPLEMENTED ? label : undefined
}

export const indexedOrderLabelMap: SearchResultTypeLabelMap<IndexedOrder> = {
  id: LABEL_NOT_IMPLEMENTED,
  caseNumber: LABEL_NOT_IMPLEMENTED,
  caseTitle: LABEL_NOT_IMPLEMENTED,
  caseType: LABEL_NOT_IMPLEMENTED,
  claimedAdmin: {
    email: 'Claimed By',
    name: 'Claimed By',
  },
  customer: {
    email: 'Customer Email',
    name: LABEL_NOT_IMPLEMENTED,
  },
  dueDate: LABEL_NOT_IMPLEMENTED,
  hearingDate: LABEL_NOT_IMPLEMENTED,
  jobId: LABEL_NOT_IMPLEMENTED,
  languagesSpellingsInstructions: 'Notes',
  lineItemType: LABEL_NOT_IMPLEMENTED,
  location: {
    courthouse: LABEL_NOT_IMPLEMENTED,
    courtroom: 'Courtroom',
    name: LABEL_NOT_IMPLEMENTED,
  },
  orderReference: 'Order Reference',
  orderStatus: 'Order Status',
  placementDate: LABEL_NOT_IMPLEMENTED,
  portionsToTranscribe: {
    endTime: LABEL_NOT_IMPLEMENTED,
    startTime: LABEL_NOT_IMPLEMENTED,
  },
  hearingPortions: 'Portion Types',
  sharedWithUsers: {
    email: 'Shared With Email',
    name: 'Shared With',
  },
  transcriber: {
    email: 'Transcriber Email',
    name: 'Transcriber',
  },
  transcriptSubmittedDate: 'Transcript Submitted At',
  turnaround: 'Turnaround',
  uploadedDocuments: {
    fileName: 'File',
  },
  department: {
    name: LABEL_NOT_IMPLEMENTED,
  },
  judges: LABEL_NOT_IMPLEMENTED,
  languages: 'Languages',
  appearances: {
    name: 'Appearances - Name',
    role: 'Appearances - Role',
  },
}
