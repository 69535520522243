<div class="search-results-overlay">
  @if (isSearching()) {
    <ftr-global-search-results
      [searchTerm]="searchTerm()"
      [configuration]="configuration()"
      [selectedEntities]="selectedEntities()"
      [searchResponse]="searchResponse()"
      [searchFocusState]="searchFocusState()"
      [availableEntities]="availableEntities()"
      [terms]="vocabularyTerms()"
      [searchEntityTypeToNumberOfMatches]="searchEntityTypeToNumberOfMatches()"
      (onFilterItemSelected)="onFilterItemSelected.emit($event)"
      (onNewSearchFocusState)="onNewSearchFocusState.emit($event)"
      (onRetry)="onRetry.emit($event)"
      [terms]="terms()"
    >
    </ftr-global-search-results>
  }
</div>
