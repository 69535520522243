/**
 * Allows editing of appearances for a transcript order
 */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'
import { MAX_APPEARANCES } from '@ftr/contracts/type/order'
import { Appearance } from '@ftr/contracts/type/transcript'
import { FormInputComponent, validateRequiredSingleLineText } from '@ftr/forms'
import {
  ButtonColor,
  ButtonComponent,
  ButtonDisplayType,
  ButtonSize,
  DestroySubscribers,
  IconComponent,
} from '@ftr/foundation'
import { Observable, distinctUntilChanged, takeUntil } from 'rxjs'

@Component({
  selector: 'ftr-appearances-edit',
  templateUrl: './appearances-edit.component.html',
  styleUrls: ['./appearances-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [FormInputComponent, ButtonComponent, IconComponent],
  standalone: true,
})
export class AppearancesEditComponent extends DestroySubscribers implements OnInit {
  @Input() control: AbstractControl
  @Input() submitAttempted: Observable<boolean>
  @Input() onEditPage = false

  readonly maxAppearances = MAX_APPEARANCES
  readonly maxInputLength = 100
  readonly groupName = 'appearances'
  internalFormArray: UntypedFormArray

  readonly buttonSize = ButtonSize
  readonly buttonColor = ButtonColor
  readonly buttonDisplayType = ButtonDisplayType

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    super()
    this.internalFormArray = this.formBuilder.array([])
  }

  ngOnInit(): void {
    ;(this.control.value || []).forEach((appearance: Appearance) => {
      this.addAppearance(appearance)
    })

    this.internalFormArray.valueChanges
      .pipe(takeUntil(this.finalize), distinctUntilChanged())
      .subscribe(() => this.registerFormChanges())

    this.submitAttempted.pipe(takeUntil(this.finalize)).subscribe(res => this.registerFormChanges(res))
  }

  registerFormChanges(attempted: boolean = false): void {
    if (this.internalFormArray.valid) {
      this.control.setValue(this.internalFormArray.value)
    } else {
      this.control.setErrors({ appearancesInvalid: true })
      if (attempted) {
        this.internalFormArray.markAllAsTouched()
      }
    }
  }

  addAppearance(appearance: Appearance = { role: '', name: '' }): void {
    this.internalFormArray.push(
      this.formBuilder.group({
        role: this.formBuilder.control(appearance.role, [Validators.required, validateRequiredSingleLineText]),
        name: this.formBuilder.control(appearance.name, [Validators.required, validateRequiredSingleLineText]),
      }),
    )
  }

  removeAppearance(index: number): void {
    this.internalFormArray.removeAt(index)
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
