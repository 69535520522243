<ftr-page-layout
  [name]="name"
  [headerTemplate]="headerTemplate"
  [loadingTemplate]="loadingTemplate"
  [emptyBodyTemplate]="emptyTemplate"
  [bodyTemplate]="main"
  [entity]="entity"
  [horizontalAutoMargin]="size === smallSidePanel"
  [horizontalOuterMargin]="size === smallSidePanel"
  [errorMessage]="errorMessage"
  [isEmptyFunction]="isEmptyFunction"
  (onRetry)="onRetry.emit()"
>
  <ng-template #main let-data>
    <div [class]="size === largeSidePanel ? mainContainerClass : ''">
      <div
        [ngClass]="{
          'side-panel-page-layout__main': true,
          page__container: size === largeSidePanel,
        }"
      >
        <div class="row">
          <div [class]="sideBarClassList">
            <ng-container *ngTemplateOutlet="sideBarTemplate; context: { $implicit: data }"></ng-container>
          </div>
          <div [class]="bodyClassList">
            <ng-container *ngTemplateOutlet="bodyTemplate; context: { $implicit: data }"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ftr-page-layout>
