import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import {
  DateComponent,
  HighlightHtmlPipe,
  IconComponent,
  IllustrationComponent,
  LineLoaderComponent,
  PageLayoutComponent,
  PillComponent,
  SidePanelPageLayoutComponent,
  TypedContextDirective,
} from '@ftr/foundation'
import {
  ComplexListItemComponent,
  ComplexListLoadingComponent,
  PaginationComponent,
  TokenPaginationComponent,
} from '@ftr/lists'
import { CourtSystemTimezoneModule } from '@ftr/ui-court-system'
import { LineItemTypeIconPipe } from '@ftr/ui-ordering'
import { LiveIndicatorComponent, RealTimeNavUnderlayComponent } from '@ftr/ui-realtime-playback'
import { SearchTextWithHighlightingComponent } from '@ftr/ui-rtstt'
import { CardTypeLabelComponent } from '~app/features/search/search-results/card-type-label/card-type-label.component'
import { SearchResultsCasesItemComponent } from '~app/features/search/search-results/search-results-cases-item/search-results-cases-item.component'
import { SearchResultsHearingsItemComponent } from '~app/features/search/search-results/search-results-hearings-item/search-results-hearings-item.component'
import { SearchResultsOrdersItemComponent } from '~app/features/search/search-results/search-results-orders-item/search-results-orders-item.component'
import { SearchTypeFilterComponent } from '~app/features/search/search-type-filter/search-type-filter.component'
import { GlobalSearchResultsComponent } from './search-results/global-search-results.component'
import { SearchResultsNotAskedNotFoundComponent } from './search-results/not-found/search-results-not-asked-not-found.component'
import { SearchResultsAllRecordingsItemComponent } from './search-results/search-results-all-recordings-item/search-results-all-recordings-item.component'
import { SearchResultsItemComponent } from './search-results/search-results-item/search-results-item.component'
import { SearchResultsListComponent } from './search-results/search-results-list/search-results-list.component'
import { SearchResultsThisRecordingItemComponent } from './search-results/search-results-this-recording-item/search-results-this-recording-item.component'

@NgModule({
  imports: [
    CommonModule,
    PillComponent,
    RouterModule,
    CourtSystemTimezoneModule,
    IllustrationComponent,
    HighlightHtmlPipe,
    LineItemTypeIconPipe,
    SidePanelPageLayoutComponent,
    LiveIndicatorComponent,
    PaginationComponent,
    TokenPaginationComponent,
    LineLoaderComponent,
    DateComponent,
    IconComponent,
    SearchTypeFilterComponent,
    ComplexListItemComponent,
    TypedContextDirective,
    PageLayoutComponent,
    CardTypeLabelComponent,
    SearchTextWithHighlightingComponent,
    SearchResultsOrdersItemComponent,
    SearchResultsAllRecordingsItemComponent,
    ComplexListLoadingComponent,
    SearchResultsThisRecordingItemComponent,
    RealTimeNavUnderlayComponent,
    SearchResultsCasesItemComponent,
    SearchResultsHearingsItemComponent,
  ],
  declarations: [
    SearchResultsNotAskedNotFoundComponent,
    GlobalSearchResultsComponent,
    SearchResultsItemComponent,
    SearchResultsListComponent,
  ],
  exports: [
    SearchResultsNotAskedNotFoundComponent,
    GlobalSearchResultsComponent,
    SearchResultsItemComponent,
    SearchResultsListComponent,
    SearchTypeFilterComponent,
  ],
})
export class SearchModule {}
