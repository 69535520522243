import { Inject } from '@angular/core'
import { API_CONFIGURATION, FullApiClientConfiguration } from '@ftr/api-shared'
import { RecordingType } from '@ftr/contracts/type/recording'
import { Uuid } from '@ftr/contracts/type/shared'
import { JsJodaUtils } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'
import { LocalDateTime } from '@js-joda/core'
import { StreamControllerConfig } from 'hls.js'
import { IntervalTree, Node } from 'node-interval-tree'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { MediaPlayerType, MediaProgress, PlayerConfiguration, TimecodeInterval } from '../../types'
import { MediaStatus } from '../../types/media-status'
import { MultiChannelPlaylistService } from '../multi-channel-playlist'
import { MediaFactoryService } from './media-factory.service'

export abstract class MediaPlayerAbstract {
  /**
   * ID of court system that owns the media.
   */
  courtSystemId: Uuid

  /**
   * This is either a recordingId, audioSegmentId or public upload recording id
   */
  recordingId: Uuid

  readonly mediaEvents: Observable<MediaProgress>
  /**
   * The duration of the audio in the media element. Note that this is not the same as wallclock relative duration
   * if there are breaks. Wallclock time is correlated to duration through timecode interval tree.
   */
  readonly duration = new BehaviorSubject(0)
  /**
   * Part of the player configuration
   */
  protected autoplay: boolean

  /**
   * The medium the recording originated from, e.g. TRM, Stream, which also implies its DataStore (Core, Regional).
   */
  protected recordingType: RecordingType

  protected defaultAudioCodec: StreamControllerConfig['defaultAudioCodec']

  /**
   * Whether the media element has started playing before (at least once).
   */
  protected played = false

  /**
   * The number of seconds at which the media element should commence playback from.
   * This is to support starting playback from a particular point e.g. by clicking an annotation
   */
  protected initialPlaybackSeconds = 0

  /**
   * Centralised subject for handling subscriptions in concrete classes.
   */
  protected readonly finalize = new Subject<void>()
  /**
   * The Media Element (Audio)
   */
  protected readonly mediaElement: HTMLMediaElement
  private readonly mediaEventsSource: Subject<MediaProgress>

  /**
   * The media element audio source - it will be used to create the
   * channel splitter and the audio node gains for multi channel.
   */
  private mediaElementAudioSourceNode: MediaElementAudioSourceNode
  private audioContext: AudioContext

  protected constructor(
    mediaFactoryService: MediaFactoryService,
    readonly loggingService: LoggingService,
    @Inject(API_CONFIGURATION) readonly configurationService: FullApiClientConfiguration,
    readonly mediaPlayerType: MediaPlayerType,
    readonly multiChannelPlaylistService: MultiChannelPlaylistService,
  ) {
    this.mediaElement =
      this.mediaPlayerType === MediaPlayerType.Video
        ? mediaFactoryService.createVideo()
        : mediaFactoryService.createAudio()
    this.mediaElement.addEventListener('loadedmetadata', this.onLoadedMetadata.bind(this))
    this.mediaElement.addEventListener('playing', this.onPlaying.bind(this))
    this.mediaElement.addEventListener('pause', this.onPause.bind(this))
    this.mediaElement.addEventListener('ended', this.onEnded.bind(this))
    this.mediaElement.addEventListener('error', this.onError.bind(this))
    this.mediaElement.addEventListener('waiting', this.onWaiting.bind(this))
    this.mediaElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this))

    this.mediaEventsSource = new Subject<MediaProgress>()
    this.mediaEvents = this.mediaEventsSource.asObservable()
  }

  /**
   * Setup values from the player configuration required in further steps.
   */
  setup(playerConfiguration: PlayerConfiguration): void {
    this.courtSystemId = playerConfiguration.courtSystemId
    this.recordingId = playerConfiguration.audioId
    this.autoplay = playerConfiguration.autoplay
    this.recordingType = playerConfiguration.recordingType
    this.defaultAudioCodec = playerConfiguration.hasMultiChannel ? 'opus' : undefined
    if (this.multiChannelPlaylistService.canPlayMultiChannel(playerConfiguration)) {
      this.audioContext = new AudioContext()
      this.mediaElementAudioSourceNode = this.audioContext.createMediaElementSource(this.getMediaElement())
    }
  }

  isSeekable(): boolean {
    return this.mediaElement.seekable && this.mediaElement.seekable.length > 0
  }

  setSource(url: string): void {
    this.mediaElement.src = url
  }

  async startPlayback(): Promise<void> {
    if (!this.played && this.autoplay) {
      await this.play()
    }
  }

  getMediaElement(): HTMLMediaElement {
    return this.mediaElement
  }

  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/load
   */
  forceLoad(): void {
    this.mediaElement.load()
  }

  setupStartFromSeconds(
    startFromDateTime: LocalDateTime | undefined,
    timecodeTree: IntervalTree<TimecodeInterval>,
  ): void {
    this.initialPlaybackSeconds = this.getPlaybackTime(startFromDateTime, timecodeTree)
  }

  initialPlaybackTimeInSeconds(): number {
    return this.initialPlaybackSeconds
  }

  getPlaybackTime(startFromDateTime: LocalDateTime | undefined, timecodeTree: IntervalTree<TimecodeInterval>): number {
    return getPlaybackTime(startFromDateTime, timecodeTree)
  }

  get durationInSeconds(): number {
    return this.duration.value
  }

  getMediaElementSourceNode(): MediaElementAudioSourceNode {
    return this.mediaElementAudioSourceNode
  }

  getAudioContext(): AudioContext {
    return this.audioContext
  }

  destroy(): void {
    this.mediaElement.removeEventListener('error', this.onError)
    this.mediaElement.removeAttribute('src')
    this.finalize.next()
    this.finalize.complete()
  }

  /**
   * Begin playing the audio. This may not begin playing if not triggered by a user event and autoplay is not allowed
   * by the browser
   * @param loopAroundAtEnd When hitting play when the audio reaches the end, browsers will loop the audio around, which
   * may be undesirable in some cases. Use this flag to prevent that behavior.
   */
  async play(loopAroundAtEnd = true): Promise<void> {
    if (!this.played && this.mediaElement.readyState) {
      this.mediaElement.currentTime = this.initialPlaybackSeconds
      this.played = true
    }

    if (loopAroundAtEnd || this.mediaElement.currentTime !== this.duration.value) {
      await swallowAbortError(this.mediaElement.play())
    }
  }

  pause(): void {
    this.mediaElement.pause()
  }

  abstract reloadSource(): void

  onSeekStarted(seekSeconds: number): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.SeekStarted, seekSeconds))
  }

  onSeekFinished(seekSeconds: number): void {
    this.updateTime(seekSeconds)
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.SeekFinished, seekSeconds))
  }

  onSeekUpdated(seekSeconds: number): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.SeekUpdated, seekSeconds))
  }

  /**
   * Change the volume of the media element that is currently playing.
   * Expand on this when we have multi-track media
   * @param volume
   */
  setVolume(volume: number): void {
    this.mediaElement.volume = volume
  }

  setPlaybackRate(rate: number): void {
    this.mediaElement.playbackRate = rate
  }

  onJumpedToTime(seconds: number): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.JumpedToTime, seconds))
  }

  onSeekToEnd(seconds: number): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.SeekToEnd, seconds))
  }

  updateTime(newTime: number): void {
    this.mediaElement.currentTime = newTime
    if (!this.played) {
      this.initialPlaybackSeconds = newTime
      this.onTimeUpdate()
    }
  }

  protected onWaiting(): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.Waiting, this.mediaElement.currentTime))
  }

  protected dispatchError(): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.Errored, this.mediaElement.currentTime))
  }

  protected onPlaying(): void {
    this.played = true
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.Playing, this.mediaElement.currentTime))
  }

  private onLoadedMetadata(): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.LoadedMetadata, this.mediaElement.currentTime))
  }

  private onPause(): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.Paused, this.mediaElement.currentTime))
  }

  private onEnded(): void {
    this.mediaEventsSource.next(new MediaProgress(MediaStatus.Ended, this.mediaElement.currentTime))
  }

  private onTimeUpdate(): void {
    const time = this.played ? this.mediaElement.currentTime : this.initialPlaybackSeconds
    const status = this.mediaElement.paused ? MediaStatus.Paused : MediaStatus.Playing
    this.mediaEventsSource.next(new MediaProgress(status, time))
  }

  private onError($event: Event): void {
    const srcElement = ($event.srcElement || $event.target) as any
    if (srcElement && srcElement.error && isMediaError(srcElement.error)) {
      const error = srcElement.error
      const errorLog = {
        message: 'Media error playing audio',
        audioId: this.recordingId,
        mediaError: {
          code: getMediaErrorCodeDescription(error.code),
          msExtendedCode: error.msExtendedCode,
        },
      }
      const warnCodes = [MediaError.MEDIA_ERR_NETWORK]
      if (warnCodes.includes(error.code)) {
        this.loggingService.warn(errorLog)
      } else {
        this.loggingService.error(errorLog)
      }
      // We want to console log here as multiple developers have spent hours investigating these errors
      console.log(errorLog.message)
      console.log(errorLog.mediaError)
    } else {
      this.loggingService.error({
        message: 'Unknown error playing media',
        eventType: $event.type,
        audioId: this.recordingId,
      })
    }

    this.dispatchError()
  }
}

async function swallowAbortError(playPromise: any): Promise<void> {
  // See: https://developers.google.com/web/updates/2016/03/play-returns-promise
  if (playPromise !== undefined) {
    return playPromise.catch((error: any) => {
      if (error.name === 'NotAllowedError') {
        // Swallow. Expected on mobile devices that suppress auto-play
      } else if (error.code === DOMException.ABORT_ERR || error.name === 'AbortError') {
        // Swallow. Expected if user navigates away before ready to play
      } else {
        throw error
      }
    })
  }
}

function getMediaErrorCodeDescription(code: number): string | number {
  switch (code) {
    case MediaError.MEDIA_ERR_ABORTED:
      return 'MEDIA_ERR_ABORTED'
    case MediaError.MEDIA_ERR_DECODE:
      return 'MEDIA_ERR_DECODE'
    case MediaError.MEDIA_ERR_NETWORK:
      return 'MEDIA_ERR_NETWORK'
    case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
      return 'MEDIA_ERR_SRC_NOT_SUPPORTED'
    default:
      return code
  }
}

function isMediaError(error: any): error is MediaError {
  return error.code && error.MEDIA_ERR_SRC_NOT_SUPPORTED
}

/**
 * Performs a pre-order traversal to find the node where the startDateTime is between the start/end time of the
 * timecode.
 */
export function getNodeContainingStartTime(
  timecodeTree: IntervalTree<TimecodeInterval>,
  startFromDateTime: LocalDateTime,
): Node<TimecodeInterval> | undefined {
  let node = timecodeTree.root
  while (node?.records?.length) {
    const current = node.records[0].timecode
    // Is between inclusive
    if (JsJodaUtils.isDuring(current.start, current.end, startFromDateTime)) {
      break
    }

    if (startFromDateTime.isBefore(current.end)) {
      node = node.left
    } else {
      node = node.right
    }
  }
  return node
}

function getPlaybackTime(
  startFromDateTime: LocalDateTime | undefined,
  timecodeTree: IntervalTree<TimecodeInterval>,
): number {
  let initialPlaybackTime = 0
  if (startFromDateTime) {
    const node = getNodeContainingStartTime(timecodeTree, startFromDateTime)
    if (node?.records?.length) {
      const result: TimecodeInterval = node.records[0]
      /**
       * Example: StartDateTime is 11:12:34AM
       * Given TRMs (and duration ranges):
       * (1) 10:57-11:02AM (0-300)
       * (2) 11:02-11:07AM (300-600)
       * ..no trm..
       * (3) 11:12-11:17AM (600-900)
       * We want the difference between the startTime of TRM 3 + the low end of the range (600) to determine where
       * it should start playing from (as the player is just from 0...n)
       */
      const TIMECODE_START = result.timecode.start
      initialPlaybackTime = result.low + JsJodaUtils.fractionalSecondsBetween(TIMECODE_START, startFromDateTime)
      initialPlaybackTime = Math.max(0, initialPlaybackTime)
    }
  }
  return initialPlaybackTime
}

/**
 * Performance could be improved, but this is currently only used in an edge case when
 * we need to update playback to a time that doesn't correspond to audio in a recording
 */
export function getPreviousTimecode(
  startFromDateTime: LocalDateTime,
  timecodeTree: IntervalTree<TimecodeInterval>,
): TimecodeInterval {
  const iterator = timecodeTree.inOrder()
  let result = iterator.next()
  while (!result.done) {
    const newResult = iterator.next()
    if (newResult.value.timecode.start.isAfter(startFromDateTime)) {
      break
    }
    result = newResult
  }
  return result.value
}
