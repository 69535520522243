import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CourtSystemErrorCode } from '@ftr/contracts/api/court-system'
import { DepartmentErrorCode } from '@ftr/contracts/api/department'
import { FileErrorCode } from '@ftr/contracts/api/file/error/FileErrorCode'
import { LocationErrorCode } from '@ftr/contracts/api/location'
import { MultifactorAuthenticationErrorCode } from '@ftr/contracts/api/mfa'
import { OrderErrorCode, PaymentFailedCardError, PaymentFailedError } from '@ftr/contracts/api/order'
import { RealTimeSttErrorCode } from '@ftr/contracts/api/real-time-stt/error'
import { RecorderErrorCode } from '@ftr/contracts/api/recorder'
import { SharedRecordingErrorCode } from '@ftr/contracts/api/shared-recording/error'
import { SttErrorCode } from '@ftr/contracts/api/stt/error'
import { TranscriptTurnaroundErrorCode } from '@ftr/contracts/api/transcript-turnaround/error'
import { UserErrorCode } from '@ftr/contracts/api/user'
import { UserGroupErrorCode } from '@ftr/contracts/api/user-group'
import {
  HybridProducerNotReadyError,
  LiveStreamErrorCode,
  NoHybridCapableProducerFoundForLocationError,
} from '@ftr/contracts/regional-api/live-streaming/error'
import { RealtimeRecorderErrorCode } from '@ftr/contracts/regional-api/live-streaming/recorder/error'
import { Money } from '@ftr/contracts/type/order'
import { plainToClass } from '@ftr/serialization'
import { StatusCodes } from 'http-status-codes'
import {
  CannotUpdateClaimError,
  ClientError,
  ConflictApiError,
  CostWaiverDisabled,
  CouldNotRemoveLastAdminUserFromCourtSystem,
  CouldNotRemoveUserGroup,
  CourtSystemConfiguredWithTestPaymentKey,
  DuplicateCourtSystemNameError,
  DuplicateDepartmentName,
  DuplicateLocationName,
  FailedRecaptchaError,
  FailedToDeleteSttError,
  FailedToRequestSttError,
  FilesToRemoveAreNotFromTheSameRecording,
  ForbiddenApiError,
  InsufficientRealTimeCourtroomCapacityError,
  InvalidRecoveryCodeError,
  MaximumNumberOfTurnaroundsReachedError,
  MinimumNumberOfTurnaroundsReachedError,
  NoRemovalItemsFound,
  NonmatchingObjectChecksum,
  NotFoundApiError,
  OrderPlacementValidationError,
  OrderResubmissionValidationError,
  OrderTurnaroundNotFoundError,
  RateLimitApiError,
  RealTimeOrderRecordingIsNotLive,
  RecorderAlreadyEnabledForLocationError,
  RecorderAlreadyExistsError,
  RecorderDependenciesExistsError,
  RecordingAlreadyHasSttError,
  RecordingDoesNotHaveRequestedTimecodesError,
  RecordingDoesNotHaveSttError,
  RegistrationRateLimitedError,
  RegistrationValidationError,
  RequiredCostWaiverDocumentsNotAttached,
  RequiredSupportingDocumentsNotAttached,
  RthErrorNameMap,
  SttStillInProgressError,
  UnauthorizedApiError,
  UnknownApiError,
  UnsupportedTimeZoneError,
  UserCannotBeRemovedFromCourtSystemError,
  UserCannotBeRemovedFromCourtSystemsError,
  UserDistributionPermissionRevoked,
  UserGroupNameAlreadyExists,
  UserHasNoPaymentMethods,
  UserNotAllowedToDistributeTranscripts,
  UserNotFoundError,
} from './errors'

type ApiErrorStatusMap = Record<string, (jsonResponse: any) => Error>
type ApiStatusCodeMap = Record<number, (jsonResponse: any) => Error>

const apiErrorCodeMap: ApiErrorStatusMap = {
  [OrderErrorCode.PaymentFailedCardError]: (jsonResponse: any) => {
    if (typeof jsonResponse.error?.message === 'string') {
      return new PaymentFailedCardError(
        jsonResponse.error.message,
        jsonResponse.error.amount ? plainToClass(Money, jsonResponse.error.amount as {}) : undefined,
      )
    }

    return new UnknownApiError()
  },
  [OrderErrorCode.PaymentFailedError]: (jsonResponse: any) => {
    if (typeof jsonResponse.error?.message === 'string') {
      return new PaymentFailedError(
        jsonResponse.error.message,
        jsonResponse.error.amount ? plainToClass(Money, jsonResponse.error.amount as {}) : undefined,
      )
    }

    return new UnknownApiError()
  },
  [UserErrorCode.ValidationFailed]: () => new RegistrationValidationError(),
  [UserErrorCode.FailedRecaptcha]: () => new FailedRecaptchaError(),
  [UserErrorCode.UpstreamLimitExceeded]: () => new RegistrationRateLimitedError(),
  [UserErrorCode.UserCannotBeRemovedFromCourtSystems]: (response: any) =>
    new UserCannotBeRemovedFromCourtSystemsError(response.error),
  [OrderErrorCode.InvalidOrderPlacement]: () => new OrderPlacementValidationError(),
  [OrderErrorCode.InvalidOrderResubmission]: () => new OrderResubmissionValidationError(),
  [OrderErrorCode.OrderTurnaroundNotFound]: () => new OrderTurnaroundNotFoundError(),
  [OrderErrorCode.CannotUpdateClaimOnStaleOrder]: () => new CannotUpdateClaimError(),
  [OrderErrorCode.UserNotAllowedToDistributeTranscripts]: () => new UserNotAllowedToDistributeTranscripts(),
  [OrderErrorCode.UserDistributionPermissionRevoked]: (response: any) =>
    new UserDistributionPermissionRevoked(response.error.message),
  [OrderErrorCode.RequiredSupportingDocumentsNotAttached]: (response: any) =>
    new RequiredSupportingDocumentsNotAttached(response.error.message),
  [OrderErrorCode.CostWaiverDisabled]: (response: any) => new CostWaiverDisabled(response.error.message),
  [OrderErrorCode.RequiredCostWaiverDocumentsNotAttached]: (response: any) =>
    new RequiredCostWaiverDocumentsNotAttached(response.error.message),
  [OrderErrorCode.MissingActiveRecordingForRealTimeOrder]: () => new RealTimeOrderRecordingIsNotLive(),
  [OrderErrorCode.UserHasNoPaymentMethods]: () => new UserHasNoPaymentMethods(),
  [TranscriptTurnaroundErrorCode.MinimumNumberOfTurnaroundsReached]: () => new MinimumNumberOfTurnaroundsReachedError(),
  [TranscriptTurnaroundErrorCode.MaximumNumberOfTurnaroundsReached]: () => new MaximumNumberOfTurnaroundsReachedError(),
  [FileErrorCode.NonmatchingObjectChecksum]: () => new NonmatchingObjectChecksum(),
  [FileErrorCode.FilesToRemoveAreNotFromSameRecordingError]: () => new FilesToRemoveAreNotFromTheSameRecording(),
  [FileErrorCode.NoRemovalItemsFound]: () => new NoRemovalItemsFound(),
  [CourtSystemErrorCode.UserNotFound]: () => new UserNotFoundError(),
  [CourtSystemErrorCode.NameAlreadyExists]: () => new DuplicateCourtSystemNameError(),
  [CourtSystemErrorCode.UserCannotBeRemovedFromCourtSystem]: (response: any) =>
    new UserCannotBeRemovedFromCourtSystemError(response.error.message),
  [RecorderErrorCode.RecorderAlreadyExists]: (response: any) => new RecorderAlreadyExistsError(response.error),
  [CourtSystemErrorCode.CourtSystemConfiguredWithTestPaymentKey]: () => new CourtSystemConfiguredWithTestPaymentKey(),
  [LocationErrorCode.DuplicateLocationName]: () => new DuplicateLocationName(),
  // The error from billing groups and user groups is a UserGroup error
  [UserGroupErrorCode.UserGroupNameAlreadyExist]: () => new UserGroupNameAlreadyExists(),
  [UserGroupErrorCode.CouldNotRemoveUserGroup]: () => new CouldNotRemoveUserGroup(),
  [UserGroupErrorCode.CouldNotRemoveLastAdminUserFromCourtSystem]: (response: any) =>
    new CouldNotRemoveLastAdminUserFromCourtSystem(response.error.message),
  [DepartmentErrorCode.DuplicateDepartmentName]: () => new DuplicateDepartmentName(),
  [RecorderErrorCode.DependenciesExist]: (response: any) => new RecorderDependenciesExistsError(response.error),
  [CourtSystemErrorCode.UnsupportedTimeZone]: (response: any) => new UnsupportedTimeZoneError(response.error.message),
  [SttErrorCode.FailedToCreateInitialSttState]: () => new FailedToRequestSttError(),
  [SttErrorCode.FailedToDeleteStt]: () => new FailedToDeleteSttError(),
  [SttErrorCode.RecordingDoesNotHaveStt]: () => new RecordingDoesNotHaveSttError(),
  [SttErrorCode.RecordingAlreadyHasStt]: () => new RecordingAlreadyHasSttError(),
  [SttErrorCode.SttStillInProgressForRecording]: () => new SttStillInProgressError(),
  [RealTimeSttErrorCode.InsufficientCourtroomCapacity]: () => new InsufficientRealTimeCourtroomCapacityError(),
  [LiveStreamErrorCode.HybridProducerNotReady]: () => new HybridProducerNotReadyError(),
  [LiveStreamErrorCode.NoHybridCapableProducerFoundForLocation]: () =>
    new NoHybridCapableProducerFoundForLocationError(),
  [SharedRecordingErrorCode.RecordingDoesNotHaveRequestedTimecodes]: () =>
    new RecordingDoesNotHaveRequestedTimecodesError(),
  [MultifactorAuthenticationErrorCode.InvalidRecoveryCode]: () => new InvalidRecoveryCodeError(),
  [RealtimeRecorderErrorCode.RecorderAlreadyEnabledForLocation]: (response: any) =>
    new RecorderAlreadyEnabledForLocationError(response.error),
}

const apiErrorNameMap: ApiErrorStatusMap = {
  ...RthErrorNameMap,
}

const apiErrorStatusCodeMap: ApiStatusCodeMap = {
  [StatusCodes.NOT_FOUND]: () => new NotFoundApiError(),
  [StatusCodes.FORBIDDEN]: () => new ForbiddenApiError(),
  [StatusCodes.UNAUTHORIZED]: () => new UnauthorizedApiError(),
  [StatusCodes.CONFLICT]: (error: Error) => new ConflictApiError(error),
  [StatusCodes.TOO_MANY_REQUESTS]: () => new RateLimitApiError(),
}

/**
 * Maps API errors to errors suitable for display to an end user.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiErrorMapper {
  /**
   * Extracts an error from the given HTTP response and maps it to an error suitable
   * for display to an end user.
   *
   * If the error cannot be extracted, or if no mapping exists for it, returns
   * the given default error.
   *
   * @param {HttpErrorResponse} response
   * @param {Error} fallbackError
   * @returns {Error}
   */
  mapErrorResponse(response: HttpErrorResponse, fallbackError?: Error): Error {
    // Details around the handling of error responses: https://angular.io/guide/http#getting-error-details
    if (response.error instanceof ErrorEvent) {
      return new ClientError()
    } else {
      const body = response.error
      if (body && body.error && apiErrorCodeMap[body.error.code]) {
        return apiErrorCodeMap[body.error.code](body)
      }
      if (body && body.error && apiErrorNameMap[body.error]) {
        return apiErrorNameMap[body.error](body)
      }
      if (response.status && apiErrorStatusCodeMap[response.status]) {
        return apiErrorStatusCodeMap[response.status](body)
      }
      return fallbackError || new UnknownApiError()
    }
  }
}
