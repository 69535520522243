import { Injectable } from '@angular/core'
import { BrowserSnifferService } from '@ftr/foundation'
import {
  CHROME_LIKE_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT,
  DEFAULT_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT,
  FIREFOX_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT,
} from '../../types'
import { ChannelMapperAbstract } from './channel-mapper.abstract'
import { ChannelMapperStrategyService } from './strategy/channel-mapper-strategy.service'
import { NoopChannelMapperStrategyService } from './strategy/noop-channel-mapper-strategy.service'

@Injectable({
  providedIn: 'root',
})
export class TrmChannelMapperService extends ChannelMapperAbstract {
  constructor(protected override readonly browserSnifferService: BrowserSnifferService) {
    super()
  }

  public determineMapperStrategy(disableChromeLikeRemapping: boolean, disableChannelRemapping: boolean): void {
    // with no channel mapper set it will default to pass through the supplied value in the remap method
    this.mapper = new NoopChannelMapperStrategyService()
    if (this.isChromeLike()) {
      this.mapper = !disableChromeLikeRemapping
        ? new ChannelMapperStrategyService(CHROME_LIKE_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT)
        : this.mapper
    } else if (this.isFirefox()) {
      this.mapper = !disableChannelRemapping
        ? new ChannelMapperStrategyService(FIREFOX_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT)
        : this.mapper
    } else if (!disableChannelRemapping) {
      this.mapper = new ChannelMapperStrategyService(DEFAULT_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT)
    }
  }
}
