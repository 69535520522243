/* eslint-disable max-lines */
import { Injectable } from '@angular/core'
import { CourtroomRealTimeSttConfigurationService, RegionalPermissionService } from '@ftr/api-regional'
import { Timeframe } from '@ftr/contracts/api/shared'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { LiveSttErrorOccurred } from '@ftr/contracts/regional-api/live-stt'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  RealTimePlaybackAudioSegmentKey,
  RealTimePlaybackHearingKey,
  RealTimePlaybackKey,
  RealTimePlaybackRecordingKey,
} from '@ftr/data-realtime-playback'
import {
  ApiResult,
  ArrayUtils,
  RemoteData,
  assertUnreachable,
  ignoreErrors,
  mapData,
  unwrapData,
} from '@ftr/foundation'
import { AuthenticationService } from '@ftr/ui-user'
import { LocalDateTime } from '@js-joda/core'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { ɵPatchSpec } from '@ngxs/store/operators/patch'
import { memoize } from 'lodash-es'
import { Observable, firstValueFrom, lastValueFrom, takeUntil } from 'rxjs'
import {
  RealTimeAudioSegmentSttService,
  RealTimeCourtRecordingSttService,
  RegionalHearingsService,
} from '../../services'
import { RealTimeSttRemark, RealTimeSttSegmentMap } from '../../types'
import {
  RealTimeLiveSttService,
  buildJoinLiveStreamRoomRequest,
} from '../real-time-live-stt/real-time-live-stt.service'
import {
  FetchRealTimeSttCanSealContentAction,
  FetchRealTimeSttEnabledAction,
  FetchRealTimeSttSegmentMapAction,
  HighlightRealTimeSttTranscriptElementsAtTimestamp,
  MarkRealTimeSttRemarksToBeSealedDuringTimeRange,
  RealTimeSttAction,
  SetRealTimeSttFailedToSetupConnectionAction,
  SetRealTimeSttFatalErrorLiveStreamingAction,
  SetRealTimeSttIsLivePinnedAction,
  SetRealTimeSttSourceStateAction,
  StopRealTimeSttSegmentMapLiveUpdatesAction,
  StreamRealTimeSttSegmentMapLiveUpdatesAction,
  UpdateRealTimeSttLiveSealedSegmentsAction,
  UpdateRealTimeSttSealedSegmentsAction,
  UpdateRealTimeSttSourceStateSegmentMapAction,
  UpsertRealTimeSttLiveRemarksAction,
} from './real-time-stt.actions'
import {
  FetchRemarksCommand,
  RealTimeSttSetSearchHighlightsCommand,
  SetRealTimeRecordingEndTimeCommand,
  SetRealTimeSttCurrentPlaybackTimeCommand,
  SetRealTimeSttHearingMarkersCommand,
  SetRealTimeSttSealingMarkersCommand,
  SetRealTimeSttSessionMarkersCommand,
  SetRealTimeSttTranscriptDividersCommand,
  UpdateRealTimeLiveLocalTimeCommand,
} from './real-time-stt.commands'
import { RealTimeSttSourceStateModel, RealTimeSttStateModel } from './real-time-stt.model'

export function defaultRealTimeSttState(): RealTimeSttStateModel {
  return {
    sttSourceState: {},
  }
}

export function defaultSourceState(): RealTimeSttSourceStateModel {
  return {
    isLivePinned: false,
    segmentMap: RemoteData.notAsked(),
    highlightedTranscriptElementIds: [],
    playbackMarkerTranscriptElementIds: [],
    toBeSealedRemarkIds: [],
    isRealTimeSttEnabled: false,
    canSealContent: false,
    canReadStt: false,
    sealedSegments: [],
    isStreamingLive: false,
    stopLiveStreamingHandle: undefined,
    fatalErrorLiveStreaming: false,
    failedRealTimeSttConnection: false,
    currentPlaybackTime: undefined,
    recordingEndTime: undefined,
    liveLocalTime: undefined,
  }
}

@State<RealTimeSttStateModel>({
  name: 'realTimeSttState',
  defaults: defaultRealTimeSttState(),
})
@Injectable()
export class RealTimeSttState {
  @Selector()
  static allSttSourceStates(state: RealTimeSttStateModel): RealTimeSttStateModel['sttSourceState'] {
    return state.sttSourceState
  }

  static readonly sttSourceState = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((
      sourceStates: ReturnType<typeof RealTimeSttState.allSttSourceStates>,
    ) => RealTimeSttSourceStateModel | undefined) => {
      return createSelector(
        [RealTimeSttState.allSttSourceStates],
        (sourceStates: ReturnType<typeof RealTimeSttState.allSttSourceStates>) => sourceStates[getSttId(playbackKey)],
      )
    },
    playbackKey => getSttId(playbackKey),
  )

  static readonly sttSpeakers = memoize(
    (playbackKey: RealTimePlaybackKey): ((sourceStates: RealTimeSttSourceStateModel | undefined) => string[]) => {
      const selectedSttSourceState = RealTimeSttState.sttSourceState(playbackKey)
      return createSelector([selectedSttSourceState], (state: ReturnType<typeof selectedSttSourceState>) => {
        if (!state?.segmentMap.isSuccess()) {
          return []
        }

        const speakers = new Set<string>()
        for (const segment of state.segmentMap._data.segments) {
          for (const transcriptElement of segment.getTranscriptElements()) {
            if (transcriptElement.type === 'Remark' && transcriptElement.speakerName) {
              speakers.add(transcriptElement.speakerName)
            }
          }
        }

        return Array.from(speakers)
      })
    },
    playbackKey => getSttId(playbackKey),
  )

  static readonly sttSegmentMap = createMemoizedSttSourceStateSelector('segmentMap')

  static readonly sttIsLivePinned = createMemoizedSttSourceStateSelector('isLivePinned')

  static readonly highlightedRemarkIds = createMemoizedSttSourceStateSelector('highlightedTranscriptElementIds')

  static readonly playbackMarkerTranscriptElementIds = createMemoizedSttSourceStateSelector(
    'playbackMarkerTranscriptElementIds',
  )

  static readonly toBeSealedRemarkIds = createMemoizedSttSourceStateSelector('toBeSealedRemarkIds')

  static readonly isRealTimeSttEnabled = createMemoizedSttSourceStateSelector('isRealTimeSttEnabled')

  static readonly canSealContent = createMemoizedSttSourceStateSelector('canSealContent')

  static readonly fatalErrorLiveStreaming = createMemoizedSttSourceStateSelector('fatalErrorLiveStreaming')

  static readonly failedRealTimeSttConnection = createMemoizedSttSourceStateSelector('failedRealTimeSttConnection')

  static readonly recordingEndTime = createMemoizedSttSourceStateSelector('recordingEndTime')

  static readonly liveLocalTime = createMemoizedSttSourceStateSelector('liveLocalTime')

  constructor(
    private readonly courtroomRealTimeSttConfigurationService: CourtroomRealTimeSttConfigurationService,
    private readonly regionalPermissionService: RegionalPermissionService,
    private readonly realTimeAudioSegmentSttService: RealTimeAudioSegmentSttService,
    private readonly realTimeCourtRecordingSttService: RealTimeCourtRecordingSttService,
    private readonly liveSttService: RealTimeLiveSttService,
    private readonly authService: AuthenticationService,
    private readonly regionalHearingsService: RegionalHearingsService,
  ) {}

  @Action(SetRealTimeSttSourceStateAction)
  setSttSourceState(
    { setState }: StateContext<RealTimeSttStateModel>,
    { playbackKey, state }: SetRealTimeSttSourceStateAction,
  ): void {
    const sttId = getSttId(playbackKey)
    setState(
      patch<RealTimeSttStateModel>({
        sttSourceState: patch({
          [sttId]: state,
        }),
      }),
    )
  }

  @Action(UpdateRealTimeSttSourceStateSegmentMapAction)
  async updateRealTimeSttSourceStateSegmentMap(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, segmentMap }: UpdateRealTimeSttSourceStateSegmentMapAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const segmentMapRemoteData: RemoteData<RealTimeSttSegmentMap> = RemoteData.success(segmentMap)

    patchSttSourceState(setState, sttId, { segmentMap: segmentMapRemoteData })
  }

  @Action(FetchRealTimeSttSegmentMapAction)
  async fetchRealTimeSttSegmentMap(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    {
      playbackKey,
      courtSystemId,
      recordingStartTime,
      pauseConfiguration,
      canReadStt,
    }: FetchRealTimeSttSegmentMapAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    if (getState().sttSourceState[sttId]?.segmentMap.isLoading()) {
      return Promise.resolve()
    }

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.loading() })

    const remarks$ = this.getRemarks(playbackKey, courtSystemId, canReadStt)

    const state = getState().sttSourceState[sttId]
    const sealedSegments = state?.sealedSegments

    return lastValueFrom(
      remarks$.pipe(
        mapData(remarks =>
          RealTimeSttSegmentMap.createSegmentMapWithFullDayOfSegments(
            recordingStartTime,
            pauseConfiguration,
            remarks,
            sealedSegments,
            {
              selected: undefined,
              hovered: undefined,
            },
          ),
        ),
      ),
    ).then(segmentMap => patchSttSourceState(setState, sttId, { segmentMap, canReadStt }))
  }

  @Action(FetchRemarksCommand)
  async fetchRemarks(
    { setState, getState }: StateContext<RealTimeSttStateModel>,
    { playbackKey, courtSystemId }: FetchRemarksCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    const currentState = getState().sttSourceState[sttId]
    if (currentState?.segmentMap.isSuccess()) {
      const canReadStt = currentState.canReadStt
      const currentSegmentMap = currentState.segmentMap
      const remarks$ = this.getRemarks(playbackKey, courtSystemId, canReadStt)

      return lastValueFrom(
        remarks$.pipe(
          mapData(remarks =>
            RealTimeSttSegmentMap.createSegmentMapWithFullDayOfSegments(
              currentSegmentMap._data.recordingStartTime,
              currentSegmentMap._data.pauseConfiguration,
              remarks,
              currentState.sealedSegments,
              {
                selected: undefined,
                hovered: undefined,
              },
            ),
          ),
        ),
      ).then(segmentMap => patchSttSourceState(setState, sttId, { segmentMap, canReadStt }))
    }
  }

  @Action(SetRealTimeSttTranscriptDividersCommand)
  async setRealTimeSttTranscriptDividers(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, dividers }: SetRealTimeSttTranscriptDividersCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const segmentMap = getState().sttSourceState[sttId]?.segmentMap
    if (!segmentMap?.isSuccess()) {
      // Can't update dividers if we don't have a segment map
      return
    }

    const updatedSegmentMap = segmentMap._data.rebuildWithTranscriptDividers(dividers)

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.success(updatedSegmentMap) })
  }

  @Action(SetRealTimeSttSessionMarkersCommand)
  async setRealTimeSttSessionMarkers(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, sessionMarkers }: SetRealTimeSttSessionMarkersCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const segmentMap = getState().sttSourceState[sttId]?.segmentMap
    if (!segmentMap?.isSuccess()) {
      // Can't update markers if we don't have a segment map
      return
    }

    const updatedSegmentMap = segmentMap._data.rebuildWithSessionMarkers(sessionMarkers)

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.success(updatedSegmentMap) })
  }

  @Action(SetRealTimeSttSealingMarkersCommand)
  async setRealTimeSttSealingMarkers(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, sealingMarkers }: SetRealTimeSttSealingMarkersCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const segmentMap = getState().sttSourceState[sttId]?.segmentMap
    if (!segmentMap?.isSuccess()) {
      // Can't update markers if we don't have a segment map
      return
    }

    const updatedSegmentMap = segmentMap._data.rebuildWithSealingMarkers(sealingMarkers)

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.success(updatedSegmentMap) })
  }

  @Action(StreamRealTimeSttSegmentMapLiveUpdatesAction)
  async streamRealTimeSttSegmentMapLiveUpdates(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, courtSystemId, region, canAccessSealedContent }: StreamRealTimeSttSegmentMapLiveUpdatesAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    const originalState = await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)
    if (originalState && originalState.isStreamingLive) {
      // Don't want to double stream updates
      return
    }

    patchSttSourceState(setState, sttId, {
      isStreamingLive: true,
      fatalErrorLiveStreaming: false,
      failedRealTimeSttConnection: false,
    })

    const shouldStopLiveStreaming = new Promise<void>(resolve => {
      patchSttSourceState(setState, sttId, {
        stopLiveStreamingHandle: {
          stopStreaming: () => {
            resolve()
          },
        },
      })
    })

    const joinRoomRequest = buildJoinLiveStreamRoomRequest(
      playbackKey,
      courtSystemId,
      region,
      canAccessSealedContent,
      await this.authService.currentJwtToken,
    )

    if (!joinRoomRequest) {
      return
    }

    const onJoinRoomFailed: () => void = () => dispatch(new SetRealTimeSttFailedToSetupConnectionAction(playbackKey))

    this.liveSttService
      .observeLiveSttResults(joinRoomRequest, onJoinRoomFailed)
      .pipe(
        ignoreErrors(), // So if anything above crashes, we continue receiving socket events
        takeUntil(shouldStopLiveStreaming),
      )
      .subscribe(update => {
        dispatch(new UpsertRealTimeSttLiveRemarksAction(playbackKey, update))
      })

    this.liveSttService
      .listenForMessage(joinRoomRequest, LiveSttErrorOccurred)
      .pipe(ignoreErrors(), takeUntil(shouldStopLiveStreaming))
      .subscribe(() => {
        dispatch(new SetRealTimeSttFatalErrorLiveStreamingAction(playbackKey, true))
      })
  }

  @Action(SetRealTimeSttFatalErrorLiveStreamingAction)
  async setRealTimeSttFatalErrorLiveStreaming(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, fatalError }: SetRealTimeSttFatalErrorLiveStreamingAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    patchSttSourceState(setState, sttId, {
      fatalErrorLiveStreaming: fatalError,
    })
  }

  @Action(UpsertRealTimeSttLiveRemarksAction)
  async upsertRealTimeSttLiveRemarks(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, update }: UpsertRealTimeSttLiveRemarksAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const segmentMapRemote = getState().sttSourceState[sttId]?.segmentMap

    if (!segmentMapRemote || !segmentMapRemote.isSuccess()) {
      // Can't update remarks if we don't have a segment map
      return
    }
    const segmentMap = segmentMapRemote._data.processLiveUpdate(update)

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.success(segmentMap) })
  }

  @Action(StopRealTimeSttSegmentMapLiveUpdatesAction)
  async stopRealTimeSttSegmentMapLiveUpdates(
    { getState, setState }: StateContext<RealTimeSttStateModel>,
    { playbackKey }: StopRealTimeSttSegmentMapLiveUpdatesAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    const originalState = getState().sttSourceState[sttId]
    if (!originalState?.isStreamingLive) {
      // We weren't streaming
      return
    }
    if (originalState.stopLiveStreamingHandle) {
      originalState.stopLiveStreamingHandle.stopStreaming()
    }
    patchSttSourceState(setState, sttId, { isStreamingLive: false, stopLiveStreamingHandle: undefined })
  }

  @Action(SetRealTimeSttIsLivePinnedAction)
  async setSttIsLivePinned(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, isLivePinned }: SetRealTimeSttIsLivePinnedAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    patchSttSourceState(setState, sttId, { isLivePinned })
  }

  @Action(SetRealTimeSttCurrentPlaybackTimeCommand)
  async setSttCurrentPlaybackTime(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, currentPlaybackTime }: SetRealTimeSttCurrentPlaybackTimeCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    patchSttSourceState(setState, sttId, { currentPlaybackTime })
  }

  @Action(HighlightRealTimeSttTranscriptElementsAtTimestamp)
  setHighlightedRemarks(
    { setState, getState }: StateContext<RealTimeSttStateModel>,
    { playbackKey, timestamp }: HighlightRealTimeSttTranscriptElementsAtTimestamp,
  ): void {
    const sttId = getSttId(playbackKey)
    const originalState = getState().sttSourceState[sttId]
    if (!originalState || !originalState.segmentMap.isSuccess()) {
      // We can't highlight remarks which don't exist 🧠
      return
    }
    const segmentMap = originalState.segmentMap._data

    const remarksToHighlight = segmentMap.getRemarksAt(timestamp) ?? []

    const pauseAtTime = segmentMap.getPauseAt(timestamp)

    // Pauses are not highlighted in the designs - they only include the playback marker
    const highlightedTranscriptElementIds = remarksToHighlight.map(({ id }) => id)
    const playbackMarkerTranscriptElementIds = remarksToHighlight.map(
      ({ firstRemarkIdOfSpeaker }) => firstRemarkIdOfSpeaker,
    )
    if (pauseAtTime) {
      playbackMarkerTranscriptElementIds.push(pauseAtTime.id)
    }

    patchSttSourceState(setState, sttId, {
      highlightedTranscriptElementIds,
      playbackMarkerTranscriptElementIds,
    })
  }

  @Action(MarkRealTimeSttRemarksToBeSealedDuringTimeRange)
  setToBeSealedRemarks(
    { setState, getState }: StateContext<RealTimeSttStateModel>,
    { playbackKey, startTime, endTime }: MarkRealTimeSttRemarksToBeSealedDuringTimeRange,
  ): void {
    const sttId = getSttId(playbackKey)
    const originalState = getState().sttSourceState[sttId]
    if (!originalState || !originalState.segmentMap.isSuccess()) {
      // We can't highlight remarks which don't exist 🧐
      return
    }

    const remarksToBeSealed =
      (startTime && endTime && originalState.segmentMap._data.getRemarksDuring(startTime, endTime)) ?? []

    patchSttSourceState(setState, sttId, { toBeSealedRemarkIds: remarksToBeSealed.map(({ id }) => id) })
  }

  @Action(FetchRealTimeSttEnabledAction)
  async fetchRealTimeSttEnabled(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, courtSystemId, locationId }: FetchRealTimeSttEnabledAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const courtroomRealTimeSttConfigurationService = await firstValueFrom(
      this.courtroomRealTimeSttConfigurationService.get({ courtSystemId, locationId }).pipe(unwrapData()),
    )

    patchSttSourceState(setState, sttId, {
      isRealTimeSttEnabled: courtroomRealTimeSttConfigurationService.isSttEnabled,
    })
  }

  @Action(FetchRealTimeSttCanSealContentAction)
  async fetchCanSealContent(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, courtSystemId }: FetchRealTimeSttCanSealContentAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    // We only allow sealing on the recording playback page
    const canSealContent =
      playbackKey.type !== 'recording'
        ? false
        : await firstValueFrom(
            this.regionalPermissionService
              .hasPermission(courtSystemId, UserGroupPermissionId.SealContent, 'recording', [playbackKey.recordingId])
              .pipe(unwrapData()),
          )

    patchSttSourceState(setState, sttId, { canSealContent })
  }

  /**
   * TODO OR-2212 check if this should be removed when removing `release-sealed-segments` flag
   */
  @Action(UpdateRealTimeSttSealedSegmentsAction)
  async updateRealTimeSttSealedSegments(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, courtSystemId, sealedSegments, canReadStt }: UpdateRealTimeSttSealedSegmentsAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    const originalState = await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const newState = getState().sttSourceState[sttId]

    const newSealedSegments = filterSealedSegments(sealedSegments, newState?.recordingEndTime)

    // Only update when sealed segments are actually different.
    // Prevents an unnecessary loading state when modifying sessions.
    if (sameSealedSegments(originalState?.sealedSegments, newSealedSegments)) {
      return
    }

    patchSttSourceState(setState, sttId, {
      sealedSegments: newSealedSegments,
    })
    if (newState?.segmentMap.isSuccess()) {
      dispatch(
        new FetchRealTimeSttSegmentMapAction(
          playbackKey,
          courtSystemId,
          newState.segmentMap._data.recordingStartTime,
          newState.segmentMap._data.pauseConfiguration,
          canReadStt,
        ),
      )
    }
  }

  /**
   * TODO OR-2212 check if this should be removed when removing `release-sealed-segments` flag
   */
  @Action(UpdateRealTimeSttLiveSealedSegmentsAction)
  async updateLiveSealedSegments(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, sealedSegments }: UpdateRealTimeSttLiveSealedSegmentsAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    const newState = getState().sttSourceState[sttId]
    const filteredSealedSegments = filterSealedSegments(sealedSegments, newState?.recordingEndTime)

    if (newState?.segmentMap.isSuccess()) {
      const newSegmentMap = newState.segmentMap._data.rebuildWithUpdatedSealedSegments(filteredSealedSegments)
      patchSttSourceState(setState, sttId, {
        sealedSegments: filteredSealedSegments,
        segmentMap: RemoteData.success(newSegmentMap),
      })
    }
  }

  @Action(RealTimeSttSetSearchHighlightsCommand)
  setRemarkSearchHighlightContext(
    { setState, getState }: StateContext<RealTimeSttStateModel>,
    { playbackKey, searchHighlights }: RealTimeSttSetSearchHighlightsCommand,
  ): void {
    const sttId = getSttId(playbackKey)
    const originalState = getState().sttSourceState[sttId]
    if (!originalState || !originalState.segmentMap.isSuccess()) {
      // We can't update search highlighting for remarks which don't exist
      return
    }

    const originalSegmentMap = originalState.segmentMap._data
    const segmentMap = originalSegmentMap.rebuildWithUpdatedSearchHighlights(searchHighlights)

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.success(segmentMap) })
  }

  @Action(SetRealTimeSttFailedToSetupConnectionAction)
  async setFailedToSetupConnection(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey }: SetRealTimeSttFailedToSetupConnectionAction,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    patchSttSourceState(setState, sttId, { failedRealTimeSttConnection: true })
  }

  @Action(SetRealTimeSttHearingMarkersCommand)
  async setHearingMarkers(
    { setState, getState }: StateContext<RealTimeSttStateModel>,
    { playbackKey, hearingMarkers }: SetRealTimeSttHearingMarkersCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    const originalState = getState().sttSourceState[sttId]

    if (!originalState || !originalState.segmentMap.isSuccess()) {
      // We can't update hearing markers if we don't have a segment map
      return
    }
    const segmentMapWithHearingMarkers = originalState.segmentMap._data.rebuildWithHearingMarkers(hearingMarkers)

    patchSttSourceState(setState, sttId, { segmentMap: RemoteData.success(segmentMapWithHearingMarkers) })
  }

  @Action(SetRealTimeRecordingEndTimeCommand)
  async setRecordingEndTime(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, recordingEndTime }: SetRealTimeRecordingEndTimeCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    patchSttSourceState(setState, sttId, { recordingEndTime })
  }

  @Action(UpdateRealTimeLiveLocalTimeCommand)
  async updateLiveLocalTime(
    { setState, getState, dispatch }: StateContext<RealTimeSttStateModel>,
    { playbackKey, liveLocalTime }: UpdateRealTimeLiveLocalTimeCommand,
  ): Promise<void> {
    const sttId = getSttId(playbackKey)
    await this.getOrCreateSourceState(sttId, playbackKey, getState, dispatch)

    patchSttSourceState(setState, sttId, { liveLocalTime })
  }

  private async getOrCreateSourceState(
    sttId: Uuid,
    playbackKey: RealTimePlaybackAudioSegmentKey | RealTimePlaybackRecordingKey | RealTimePlaybackHearingKey,
    getState: () => RealTimeSttStateModel,
    dispatch: (actions: RealTimeSttAction) => Observable<void>,
  ): Promise<RealTimeSttSourceStateModel | undefined> {
    const originalState = getState().sttSourceState[sttId]
    if (!originalState) {
      await firstValueFrom(dispatch(new SetRealTimeSttSourceStateAction(playbackKey, defaultSourceState())))
    }
    return originalState
  }

  private getRemarks(
    playbackKey: RealTimePlaybackKey,
    courtSystemId: Uuid,
    canReadStt: boolean,
  ): ApiResult<RealTimeSttRemark[], Error> {
    let remarks$: ApiResult<RealTimeSttRemark[]>
    switch (playbackKey.type) {
      case 'recording':
        remarks$ = this.realTimeCourtRecordingSttService
          .getSttWithRemarks(courtSystemId, playbackKey.recordingId, canReadStt)
          .pipe(mapData(x => x.remarks))
        break
      case 'audio-segment':
        remarks$ = this.realTimeAudioSegmentSttService
          .getAudioSegmentWithSttRemarks(courtSystemId, playbackKey.audioSegmentId, canReadStt)
          .pipe(mapData(x => x.remarks))
        break
      case 'hearing':
        remarks$ = this.regionalHearingsService
          .getHearingWithSttRemarks(courtSystemId, playbackKey.hearingId)
          .pipe(mapData(x => x.remarks))
        break
      default:
        assertUnreachable(playbackKey)
    }

    return remarks$
  }
}

function patchSttSourceState(
  setState: StateContext<RealTimeSttStateModel>['setState'],
  sttId: Uuid,
  update: ɵPatchSpec<RealTimeSttSourceStateModel>,
): void {
  setState(
    patch<RealTimeSttStateModel>({
      sttSourceState: patch({
        [sttId]: patch(update),
      }),
    }),
  )
}

function getSttId(key: RealTimePlaybackKey): Uuid {
  switch (key.type) {
    case 'recording':
      return key.recordingId
    case 'audio-segment':
      return key.audioSegmentId
    case 'hearing':
      return key.hearingId
    default:
      assertUnreachable(key)
  }
}

function createMemoizedSttSourceStateSelector<T extends keyof RealTimeSttSourceStateModel>(
  property: T,
): (
  playbackKey: RealTimePlaybackKey,
) => (sourceStates: RealTimeSttSourceStateModel | undefined) => RealTimeSttSourceStateModel[T] {
  return memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((sourceStates: RealTimeSttSourceStateModel | undefined) => RealTimeSttSourceStateModel[T]) => {
      const selectedSttSourceState = RealTimeSttState.sttSourceState(playbackKey)
      return createSelector([selectedSttSourceState], (state: ReturnType<typeof selectedSttSourceState>) => {
        return state?.[property] ?? defaultSourceState()[property]
      })
    },
    playbackKey => getSttId(playbackKey),
  )
}

/**
 * A sealed segment's start === recordingEndTime in the following edge case scenario:
 * We're live sealing and the recorder turns off, then we unseal the latest sealed segment retroactively
 * while the recorder is off.
 * This leaves us with a sealed segment that starts at recordingEndTime and ends at 23:59:59, we don't want to
 * display it, this function filters it out.
 * Once the recorder comes back on, recordingEndTime changes, it will not be filtered out anymore.
 */
function filterSealedSegments(sealedSegments: Timeframe[], recordingEndTime: LocalDateTime | undefined): Timeframe[] {
  return sealedSegments.filter(s =>
    recordingEndTime ? s.start !== recordingEndTime.toLocalTime().toSecondOfDay() : true,
  )
}

function sameSealedSegments(one: Timeframe[] = [], two: Timeframe[] = []): boolean {
  if (!one.length && !two.length) {
    return true
  }

  return ArrayUtils.equals(one, two, (x, y) => x.start === y.start && x.end === y.end)
}
