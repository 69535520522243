import { Pipe, PipeTransform } from '@angular/core'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { LineItemType } from '@ftr/contracts/type/order'
import { Icon } from '@ftr/foundation'

/**
 * Converts line item types to their icon image locations
 */
@Pipe({ name: 'ftrLineItemTypeIcon', standalone: true })
export class LineItemTypeIconPipe implements PipeTransform {
  transform(type: LineItemType, speechToTextAdded?: boolean | undefined): Icon {
    switch (type) {
      case LineItemType.Audio:
        if (speechToTextAdded) {
          return 'PlaylistPlayBack'
        }
        return 'PlayCircleOutline'
      case LineItemType.CertifiedTranscript:
        return 'TranscriptOutline'
      case LineItemType.RealTime:
        return 'PlaylistPlayBack'
      default:
        return assertUnreachable(type)
    }
  }
}
