<div class="textarea__container" [class.textarea__container--close-to-maxlength]="isCloseToMaxLength()">
  <label class="textarea">
    <span class="textarea__inner">
      <textarea
        #textarea
        maxLength="{{ maxLength }}"
        class="textarea__control"
        [class.textarea__control--has-value]="hasValue"
        [class.textarea__control--has-label]="!!label"
        [required]="required"
        [attr.name]="name"
        [attr.placeholder]="placeholder"
        [formControl]="ngFormControl"
        [attr.aria-invalid]="ngFormControl.touched && !ngFormControl.valid"
        [attr.data-notrack]="notrack"
      >
      </textarea>
      @if (label) {
        <span class="textarea__label">{{ label }}</span>
      }
      <span
        class="textarea__validation"
        ftrValidationErrorHint
        [label]="validationLabel || label || 'this field'"
        [ngFormControl]="ngFormControl"
        [controlTouched]="ngFormControl.touched"
        [submitAttempted]="submitAttempted"
      ></span>
    </span>
  </label>
  <div class="textarea__hint">
    @if (showMarkdownHint) {
      <div>
        <a class="textarea__markdown" href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
          <ftr-icon icon="HelpCircle" color="Primary" [interactive]="true" align="Top" size="Large"> </ftr-icon>
          Markdown is supported (except external images)
        </a>
      </div>
    }
    <div class="textarea__maxlength" [class.textarea__maxlength--is-maxlength]="isMaxLength()">
      {{ maxLength }} characters max.
    </div>
  </div>
</div>
