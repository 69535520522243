export enum ButtonColor {
  Primary = 'primary',
  Danger = 'danger',
  Grey = 'grey',

  // TODO(VS-40): This is a hollow button and should be
  // replaced with an actual secondary button like in the design system.
  // Hollow needs to become a ButtonDisplayType
  Secondary = 'secondary',
}

export enum ButtonDisplayType {
  Text = 'text',
  InlineText = 'inline-text',
  Icon = 'icon',
}

export enum ButtonSize {
  Tiny = 'tiny',
  Small = 'small',
  Large = 'large',
}

export enum ButtonDirection {
  Forward = 'forward',
  Backward = 'backward',
}

export const ButtonColors = Object.values(ButtonColor)
export const ButtonSizes = Object.values(ButtonSize)
export const ButtonDirections = Object.values(ButtonDirection)
