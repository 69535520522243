import { Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { RecordingType } from '@ftr/contracts/type/recording'
import { ArrayUtils, FloatingTab, Icon, IconColor, SizesService, isNotNullOrUndefined } from '@ftr/foundation'
import { TAB_QUERY_PARAM } from '@ftr/routing-paths'
import { PlaybackState, PlayerService } from '@ftr/ui-playback'
import { LocalDateTime } from '@js-joda/core'
import { Store } from '@ngxs/store'
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { WindowRefService } from '~app/services/window/window-ref.service'

export const STT_TAB_VALUE = 'stt'
export const LOG_SHEET_TAB_VALUE = 'logSheet'

export type RecordingPageTabValue = typeof STT_TAB_VALUE | typeof LOG_SHEET_TAB_VALUE

// Includes the date component in all the remark and the stt segment marker components
const STT_SEGMENT_DATE_SELECTOR = 'ftr-stt-segment ftr-date'
// Includes all STT content which generally takes up most of the viewport
const FINALIZED_REMARK_CONTENT_SELECTOR = '.remark-finalized__content'
// Includes all the grey placeholder lines that appear while STT content is loading
const SEGMENT_MARKER_CONTENT_SELECTOR = '.marker__loading'

export const STT_TAB_SELECTORS = [
  STT_SEGMENT_DATE_SELECTOR,
  FINALIZED_REMARK_CONTENT_SELECTOR,
  SEGMENT_MARKER_CONTENT_SELECTOR,
].join(',')

// Includes the date component in the log note and the log note segment marker components
const LOG_NOTE_DATE_SELECTOR = 'ftr-log-note-segment ftr-date'
// Includes all Log Note content which generally takes up most of the viewport
const LOG_NOTE_CONTENT_SELECTOR = '.log-note__content'

export const LOG_SHEET_TAB_SELECTORS = [
  LOG_NOTE_DATE_SELECTOR,
  LOG_NOTE_CONTENT_SELECTOR,
  SEGMENT_MARKER_CONTENT_SELECTOR,
].join(',')

@Injectable()
export class RecordingPlaybackTabService {
  /**
   * Config for the buttons that appear in the PlayerComponent at the top of the page
   */
  tabs$: Observable<FloatingTab<RecordingPageTabValue>[]>
  /**
   * Path of the selected tab e.g. ./speech-to-text. Default is only needed for PlayerMoreMenuService.isExportAvailable() on audio order playback pages
   */
  selectedTabValue$: Observable<RecordingPageTabValue> = of()
  /**
   * Stores the current route that is emitted from selectedTabValue$
   */
  selectedTabValue: RecordingPageTabValue | undefined
  /**
   * Indicates whether the speech-to-text has been or will immediately be rendered. Cannot be set to false once it is true.
   */
  speechToTextRendered = false
  /**
   * Indicates whether log sheets have been or will immediately be rendered. Cannot be set to false once it is true.
   */
  logSheetRendered = false

  fetchingSttFailed = new BehaviorSubject(false)

  fetchingLogSheetsFailed = new BehaviorSubject(false)
  /**
   * Indicator for when either a recording has log sheets or fetching regional log sheet ids failed. If
   * the request fails, we want the user to be able to retry.
   * If this is true and the user has the ReadLogSheets permission, then the Log Sheets tab will display
   */
  recordingMayHaveLogSheets = new BehaviorSubject(false)
  /**
   * Indicates which remark (based on its start time) should be scrolled to when switching to the Stt tab
   */
  scrollToSttTime = new ReplaySubject<LocalDateTime | undefined>(1)
  /**
   * Indicates which Log Note (based on its timestamp) should be scrolled to when switching to the Log Note tab
   */
  scrollToLogNoteTime = new ReplaySubject<LocalDateTime | undefined>(1)
  /**
   * Path of the current route, e.g. ./speech-to-text, ./log-sheet, ./:recordingId
   */
  private currentTabQueryParam$: Observable<RecordingPageTabValue | undefined>
  /**
   * Indicates whether the search results page is or is about to be visible
   */
  private searching = false
  /**
   * Stores a time to be used when the user navigates away from the search results overlay
   */
  private scrollToTimeAfterSearching: LocalDateTime | undefined

  private readonly errorIcon: {
    icon: Icon
    colour: IconColor
  } = { icon: 'Error', colour: 'BrandDarker' }

  private readonly tabs: FloatingTab<RecordingPageTabValue>[] = [
    {
      label: 'Speech-to-Text',
      mobileLabel: 'STT',
      value: STT_TAB_VALUE,
    },
    {
      label: 'Log Sheet',
      value: LOG_SHEET_TAB_VALUE,
    },
  ]
  private finalize = new Subject<void>()

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly windowRefService: WindowRefService,
    private readonly headerSizeService: SizesService,
    private readonly playerService: PlayerService,
    private readonly store: Store,
  ) {}

  /**
   * Entrypoint of this service.
   */
  setupObservables(): void {
    this.setupCurrentRouteObservable()
    this.setupTabObservable()
    this.setupSelectedTabObservable()
  }

  recordingSupportsLogSheets(recordingType: RecordingType): boolean {
    return recordingType === RecordingType.TRM
  }

  /**
   * Resets the service when the recording changes or a user navigates away from the recording playback page
   */
  reset(): void {
    this.finalize.next()
    this.finalize.complete()
    this.finalize = new Subject()

    this.tabs$ = of([])
    this.selectedTabValue$ = of()
    this.selectedTabValue = undefined
    this.speechToTextRendered = false
    this.logSheetRendered = false
    this.fetchingSttFailed = new BehaviorSubject(false)
    this.fetchingLogSheetsFailed = new BehaviorSubject(false)
    this.recordingMayHaveLogSheets = new BehaviorSubject(false)
    this.scrollToSttTime = new ReplaySubject<LocalDateTime | undefined>(1)
    this.scrollToLogNoteTime = new ReplaySubject<LocalDateTime | undefined>(1)
    this.currentTabQueryParam$ = of()
    this.searching = false
    this.scrollToTimeAfterSearching = undefined
  }

  private setupCurrentRouteObservable(): void {
    this.currentTabQueryParam$ = this.router.events.pipe(
      takeUntil(this.finalize),
      filter(e => e instanceof NavigationEnd),
      map(() => this.route.snapshot.queryParams[TAB_QUERY_PARAM]),
      startWith(this.route.snapshot.queryParams[TAB_QUERY_PARAM]),
      shareReplay(1),
    )
  }

  private setupTabObservable(): void {
    this.tabs$ = this.playerService.mediaPlayerReady.pipe(
      filter(playerReady => playerReady),
      switchMap(() =>
        combineLatest([
          this.store.select(PlaybackState.canReadStt).pipe(filter(isNotNullOrUndefined)),
          this.store.select(PlaybackState.canReadLogSheets).pipe(filter(isNotNullOrUndefined)),
          this.fetchingSttFailed,
          this.fetchingLogSheetsFailed,
          this.recordingMayHaveLogSheets,
          this.currentTabQueryParam$,
        ]),
      ),
      takeUntil(this.finalize),
      distinctUntilChanged(ArrayUtils.shallowEquals),
      tap(([canReadStt, canReadLogSheets, , , , currentTabQueryParam]) =>
        this.redirectUserBasedOnFeaturesAndPermissions(currentTabQueryParam, canReadStt, canReadLogSheets),
      ),
      map(([canReadStt, canReadLogSheets, fetchingSttFailed, fetchingLogSheetsFailed, recordingMayHaveLogSheets]) => {
        const tabs = canReadStt && canReadLogSheets && recordingMayHaveLogSheets ? this.tabs : []
        return this.addErrorIconToTabs(tabs, fetchingSttFailed, fetchingLogSheetsFailed)
      }),
      map(tabs =>
        tabs.map(tab => ({
          value: tab.value,
          label: tab.label!,
          mobileLabel: tab.mobileLabel!,
          afterLabelIcon: tab.afterLabelIcon,
        })),
      ),
      shareReplay(1),
    )
  }

  private redirectUserBasedOnFeaturesAndPermissions(
    currentTab: RecordingPageTabValue | undefined,
    canReadStt: boolean,
    canReadLogSheets: boolean,
  ): void {
    if (!currentTab) {
      return this.redirectToTab(canReadStt, canReadLogSheets)
    }

    if (
      (currentTab === STT_TAB_VALUE && !canReadStt && canReadLogSheets) ||
      (currentTab === LOG_SHEET_TAB_VALUE && !canReadLogSheets && canReadStt)
    ) {
      this.redirectToTab(canReadStt, canReadLogSheets)
    }
  }

  /**
   * Derive the selected tab from the currentRoute$ and scroll to content when appropriate
   *
   * Most but not all scenarios that this rxjs stream needs to handle. If the numbers are updated,
   * please also ensure other mentions of these scenarios are also updated. All searches are for
   * This Recording and query param refers to the 'start' query param that is linked to the current
   * playback time and causes programmatic scrolling.
   *
   *  1. Change tabs from log sheet to speech-to-text
   *  2. Change tabs from log sheet (scrolled down) to speech-to-text
   *  3. Change tabs from speech-to-text (scrolled down) to log sheet
   *  4. Change tabs from speech-to-text (scrolled down) to log sheet back to speech-to-text
   *  5. Load search result page without query param and select speech-to-text result
   *  6. Load search result page without query param and select log sheet result
   *  7. Load search result page with query param and select speech-to-text result with same query param
   *  8. Load search result page with query param and select log sheet result with same query param
   *  9. Load search result page with query param and select speech-to-text result with different query param
   * 10. Load search result page with query param and select log sheet result with different query param
   * 11. Load search result page with query param and press search back
   * 12. Load search result page without query param and press search back
   * 13. From log sheet page with query param, search and select speech-to-text result with same query param
   * 14. From log sheet page with query param search and select speech-to-text result with different query param
   * 15. From log sheet page without query param, search and select speech-to-text result
   * 16. From speech-to-text page with query param, search and select a speech-to-text result with the same query param
   * 17. From speech-to-text page with query param, search and select a speech-to-text result with different query param
   * 18. From speech-to-text page without query param, search and select a speech-to-text result
   * 19. From speech-to-text page with query param, search and press search back
   * 20. From speech-to-text page without query param, search and press search back
   */
  private setupSelectedTabObservable(): void {
    this.selectedTabValue$ = this.currentTabQueryParam$.pipe(
      filter((r): r is RecordingPageTabValue =>
        new Array<string | undefined>(STT_TAB_VALUE, LOG_SHEET_TAB_VALUE).includes(r),
      ),
      tap(route => {
        this.selectedTabValue = route
        this.setScrollToTimeAfterSearching(route)
        this.scrollTo(route)
      }),
      takeUntil(this.finalize),
      shareReplay(1),
    )
  }
  /**
   * When the search overlay is about to be visible, we need to derive a time from the elements that
   * are on screen, so that if the search back button is pressed, we know where to scroll to. To avoid
   * scrolling / scroll bar issues, the recording page is reduced to max-height: 0 via the <main> element
   * in AppComponent.
   * Scenarios that apply to this block: 5-20
   */
  private setScrollToTimeAfterSearching(tab: RecordingPageTabValue): void {
    if (this.isSearching()) {
      if (tab === STT_TAB_VALUE) {
        this.scrollToTimeAfterSearching = this.getScrollToTime(STT_TAB_SELECTORS)
      } else if (tab === LOG_SHEET_TAB_VALUE) {
        this.scrollToTimeAfterSearching = this.getScrollToTime(LOG_SHEET_TAB_SELECTORS)
      }
    }
  }

  private scrollTo(route: RecordingPageTabValue): void {
    const searching = this.isSearching()
    /*
     * When a search result was selected or the search back button was clicked
     * we need to scroll to the time specified in the `start` query param.
     * Scenarios that apply to this block: 5-20
     */
    const startQueryParam = this.router.parseUrl(this.router.url).queryParamMap.get('start')
    if (!searching && this.searching && startQueryParam) {
      this.scrollToTimeAfterSearching = LocalDateTime.parse(startQueryParam)
    }
    /**
     * Scenarios that apply to this block: 1-20
     */
    if (!searching) {
      if (route === STT_TAB_VALUE) {
        this.speechToTextRendered = true
        const selector = this.searching ? STT_TAB_SELECTORS : LOG_SHEET_TAB_SELECTORS
        this.scrollToSttTime.next(this.scrollToTimeAfterSearching || this.getScrollToTime(selector))
      } else if (route === LOG_SHEET_TAB_VALUE) {
        this.logSheetRendered = true
        const selector = this.searching ? LOG_SHEET_TAB_SELECTORS : STT_TAB_SELECTORS
        this.scrollToLogNoteTime.next(this.scrollToTimeAfterSearching || this.getScrollToTime(selector))
      }
      this.scrollToTimeAfterSearching = undefined
    }
    /**
     * searching is set here so we can evaluate its 'previous' value above
     */
    this.searching = searching
  }
  /**
   * Simple URL check to determine whether the user is searching
   * i.e. the search results overlay will shortly be / has just been / is visible
   */
  private isSearching(): boolean {
    return this.router.parseUrl(this.router.url).queryParamMap.has('searchType')
  }
  /**
   * When switching tabs of navigating from search results, set the
   * time that the new selected tab will use to scroll to its content
   */
  private getScrollToTime(selector: string): LocalDateTime | undefined {
    const subHeaderEl = this.windowRefService.querySelector('.playback-hearing-details__sub-header') as HTMLElement
    const isPageScrolledNearOrAtTop = subHeaderEl && this.windowRefService.isElementInViewport(subHeaderEl)
    if (isPageScrolledNearOrAtTop) {
      return undefined
    }

    const el = Array.from(document.querySelectorAll<HTMLElement>(selector)).find(r =>
      this.headerSizeService.isElementAtLeastPartiallyInViewportUnderHeader(r),
    )
    return el?.dataset.datetime ? LocalDateTime.parse(el.dataset.datetime) : undefined
  }

  /**
   * Only redirects to child routes when a user has relevant read permissions, otherwise they remain on the parent route
   */
  private redirectToTab(canReadStt: boolean, canReadLogSheets: boolean): void {
    if (canReadStt) {
      this.router.navigate([], {
        queryParams: { [TAB_QUERY_PARAM]: STT_TAB_VALUE },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
    } else if (canReadLogSheets) {
      this.router.navigate([], {
        queryParams: { [TAB_QUERY_PARAM]: LOG_SHEET_TAB_VALUE },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
    }
  }
  /**
   * Add an error icon beside a tab's name in the segmented button and the player component if an API request fails
   */
  private addErrorIconToTabs(
    tabs: FloatingTab<RecordingPageTabValue>[],
    fetchingSttFailed: boolean,
    fetchingLogSheetsFailed: boolean,
  ): FloatingTab<RecordingPageTabValue>[] {
    if (fetchingSttFailed || fetchingLogSheetsFailed) {
      tabs = tabs.map(tab => this.addErrorIconToTab(tab, fetchingSttFailed, fetchingLogSheetsFailed))
    }
    return tabs
  }

  private addErrorIconToTab(
    tab: FloatingTab<RecordingPageTabValue>,
    fetchingSttFailed: boolean,
    fetchingLogSheetsFailed: boolean,
  ): FloatingTab<RecordingPageTabValue> {
    if (
      (tab.value === STT_TAB_VALUE && fetchingSttFailed) ||
      (tab.value === LOG_SHEET_TAB_VALUE && fetchingLogSheetsFailed)
    ) {
      return { ...tab, afterLabelIcon: { icon: this.errorIcon.icon, iconColor: this.errorIcon.colour } }
    }
    return tab
  }
}
