import { Injectable, Signal, WritableSignal, signal } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import 'zone.js/plugins/webapis-media-query'
import { TrackingEventType } from '../tracking/tracking'
import { TrackingService } from '../tracking/tracking.service'
import { SimpleWindowRefService } from '../window/simple-window-ref.service'

export type MediaQueryListListener = (event: MediaQueryListEvent) => void

interface BreakpointQuery {
  query: string
  breakpoint: ScreenSize
}

export enum ScreenSize {
  XSmall,
  Small,
  Medium,
  Large,
  XLarge,
}

const MOBILE_SCREEN_SIZES = [ScreenSize.XSmall, ScreenSize.Small]

const XSMALL = 390
const SMALL = 640
const MEDIUM = 1024
const LARGE = 1230

const queries: BreakpointQuery[] = [
  { query: `(max-width: ${XSMALL}px)`, breakpoint: ScreenSize.XSmall },
  {
    query: `(min-width: ${XSMALL + 1}px) and (max-width: ${SMALL}px)`,
    breakpoint: ScreenSize.Small,
  },
  {
    query: `(min-width: ${SMALL + 1}px) and (max-width: ${MEDIUM}px)`,
    breakpoint: ScreenSize.Medium,
  },
  {
    query: `(min-width: ${MEDIUM + 1}px) and (max-width: ${LARGE}px)`,
    breakpoint: ScreenSize.Large,
  },
  { query: `(min-width: ${LARGE + 1}px)`, breakpoint: ScreenSize.XLarge },
]

export const DUMMY_SCREEN_SIZE_SERVICE = {
  size: of(ScreenSize.Medium),
}

/**
 * A small service for keeping up to date with what size the user's current browser window is
 */
@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  size: Observable<ScreenSize>
  sizeSignal: Signal<ScreenSize>

  private writableSizeSignal: WritableSignal<ScreenSize>
  private subject: BehaviorSubject<ScreenSize>

  constructor(
    private readonly windowRefService: SimpleWindowRefService,
    private readonly analyticsService: TrackingService,
  ) {
    this.setupSizeSignal()
    this.setupSizeObservable()
    this.setupMediaQueries()
  }

  private setupSizeSignal(): void {
    this.writableSizeSignal = signal<ScreenSize>(this.currentSize())
    this.sizeSignal = this.writableSizeSignal.asReadonly()
  }

  private setupSizeObservable(): void {
    this.subject = new BehaviorSubject<ScreenSize>(this.currentSize())
    this.size = this.subject.asObservable()
  }

  private setupMediaQueries(): void {
    queries.forEach(breakpointQuery => {
      // There doesn't appear to be an initial change event, so we need to set the current screen size
      this.setInitialMediaSize(breakpointQuery.breakpoint)(this.windowRefService.matchMedia(breakpointQuery.query))
      // @see False deprecation of addListener https://github.com/microsoft/TypeScript/issues/32210
      this.windowRefService.matchMedia(breakpointQuery.query).addListener(this.mediaChange(breakpointQuery.breakpoint))
    })
  }

  private setInitialMediaSize(size: ScreenSize): (mql: MediaQueryList) => void {
    return (mql: MediaQueryList) => {
      if (mql.matches) {
        this.writableSizeSignal.set(size)
        this.subject.next(size)
      }
    }
  }

  private mediaChange(size: ScreenSize): MediaQueryListListener {
    return (event: MediaQueryListEvent) => {
      if (event.matches) {
        const currentSize = this.subject.value
        this.writableSizeSignal.set(size)
        this.subject.next(size)
        this.analyticsService.track({
          event: TrackingEventType.Resize,
          eventLabel: `From ${ScreenSize[currentSize]} to ${ScreenSize[size]}`,
        })
      }
    }
  }

  private currentSize(): ScreenSize {
    const current = queries.find(breakpointQuery => {
      return this.windowRefService.matchMedia(breakpointQuery.query).matches
    })
    return current?.breakpoint || ScreenSize.Large
  }
}

export function isMobileScreenSize(screenSize: ScreenSize | undefined | null): boolean {
  if (screenSize === null || screenSize === undefined) {
    return false
  }
  return MOBILE_SCREEN_SIZES.includes(screenSize)
}
