import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import {
  ButtonColor,
  ButtonComponent,
  DestroySubscribers,
  Illustration,
  IllustrationComponent,
  ModalComponent,
  ModalService,
  ModalSize,
  unwrapData,
} from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import {
  GetCurrentUserSettingsAction,
  MfaSetupModalAction,
  MfaSetupModalType,
  SetMfaSetupModal,
  UserSettingsService,
  UserState,
} from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, filter, map, switchMap, takeUntil } from 'rxjs'

@Component({
  selector: 'ftr-mfa-setup-modal',
  templateUrl: './mfa-setup-modal.component.html',
  styleUrls: ['./mfa-setup-modal.component.css'],
  standalone: true,
  imports: [ModalComponent, IllustrationComponent, ButtonComponent, RouterModule, CommonModule],
})
export class MfaSetupModalComponent extends DestroySubscribers implements AfterViewInit {
  @ViewChild('mfaSetupModalTemplate') mfaSetupModalTemplate: TemplateRef<any>

  mfaSetupModalType: MfaSetupModalType
  mfaSetupModalAction: MfaSetupModalAction

  readonly modalSize = ModalSize
  readonly illustration = Illustration
  readonly buttonColor = ButtonColor
  readonly mfaSetupModalTypes = MfaSetupModalType
  readonly mfaSetupModalActions = MfaSetupModalAction
  readonly mfaSetupPath = `${AppPaths.Account}/${AppPaths.MultiFactorAuthentication}`

  constructor(
    private readonly modalService: ModalService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly modalContainer: ViewContainerRef,
    private readonly userSettingsService: UserSettingsService,
  ) {
    super()
  }

  ngAfterViewInit(): void {
    this.store
      .select(UserState.isLoggedIn)
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        switchMap(_ => this.store.dispatch(new GetCurrentUserSettingsAction())),
        switchMap(_ => {
          return combineLatest([this.getUserMfaModalType(), this.store.select(UserState.mfaSetupModalAction)])
        }),
        takeUntil(this.finalize),
      )
      .subscribe(([modalType, modalAction]) => {
        this.mfaSetupModalType = modalType
        this.mfaSetupModalAction = modalAction

        if (modalType !== MfaSetupModalType.Closed) {
          this.modalService.open(this.mfaSetupModalTemplate, this.modalContainer)
        }
      })
  }

  closeModal(): void {
    this.store.dispatch(new SetMfaSetupModal(MfaSetupModalType.Closed))
    this.modalService.close()
  }

  notNow(): void {
    if (this.mfaSetupModalType !== MfaSetupModalType.Generic) {
      this.closeModal()
      return
    }
    this.userSettingsService
      .updateUserSettings({ showMfaSetup: false })
      .pipe(takeUntil(this.finalize))
      .subscribe(_ => this.closeModal())
  }

  navigateToSetupMfa(): void {
    this.closeModal()
    this.router.navigate([this.mfaSetupPath])
  }

  signOut(): void {
    this.closeModal()
    this.router.navigate([AppPaths.Logout])
  }

  private getUserMfaModalType(): Observable<MfaSetupModalType> {
    return combineLatest([
      this.store.select(UserState.currentUserEmail).pipe(unwrapData()),
      this.store.select(UserState.currentUserSettings),
      this.store.select(UserState.mfaSetupModalType),
    ]).pipe(
      map(([userEmail, userSettings, modalType]) => {
        if (isE2EUser(userEmail)) {
          return MfaSetupModalType.Closed
        }

        if (!userSettings.showMfaSetup && modalType === MfaSetupModalType.Generic) {
          return MfaSetupModalType.Closed
        }

        return modalType
      }),
    )
  }
}

function isE2EUser(currentUserEmail: string): boolean {
  return currentUserEmail.endsWith('mailosaur.io')
}
