/* eslint-disable max-lines */
import { Injectable } from '@angular/core'
import {
  isLiveSealing,
  isOnTheRecordByDeviceClock,
  isOnTheRecordBySegments,
  isTheRecorderRunning,
  RealTimeLiveStreamService,
} from '@ftr/api-regional'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { Timeframe, TimeframeWithLocalTimes } from '@ftr/contracts/api/shared'
import { RecordingSegment, RecordingWithSegments } from '@ftr/contracts/read'
import { collect, collectFirst } from '@ftr/contracts/shared/ArrayUtils'
import { SortedTimeframeCollection } from '@ftr/contracts/shared/timeframe'
import { Shortcut, ShortcutMap } from '@ftr/contracts/type/desktop'
import { DataStore, LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { Timecode } from '@ftr/contracts/type/shared/Timecode'
import { ApiResult, RemoteData, sleep, Success, unwrapData } from '@ftr/foundation'
import { OnRecordState } from '@ftr/stt-search'
import { CoreConfigurationService, TranscriptOrderingFeatureService } from '@ftr/ui-court-system'
import { SearchState } from '@ftr/ui-search'
import { CourtRecordingService } from '@ftr/ui-user'
import { LocalDate, ZoneId } from '@js-joda/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { isEqual } from 'lodash-es'
import moment from 'moment-timezone'
import { catchError, EMPTY, filter, Observable, of, tap } from 'rxjs'
import { PlaybackContext } from '../../types'
import {
  FetchCourtRecording,
  MarkSttStoppedForSession,
  ReevaluateOnRecordState,
  ResetCourtRecording,
  ResetPlaybackState,
  ScheduleStoppingSttForSessionTimeout,
  SetContentJumpButtonsVisibilityAction,
  SetExternalTranscriptOrderingUrl,
  SetHasRealTimeVideo,
  SetIsPlaybackPage,
  SetPlaybackInfo,
  SetRealTimeSecondaryContent,
  SetRecordingTimezone,
  SetSealingMode,
  SetSealingTimeRange,
  SetShortcuts,
  SetShowingAudioOrderShareModal,
  SetShowingChannels,
  SetShowingDebugInfo,
  SetShowingDownloadBundleModal,
  SetShowingFootPedalSettingsModal,
  SetShowingPlayerShortcutConfigurationModal,
  SetShowingRecordingDiagnosticsModal,
  SetShowingSharedWithModal,
  SetShowingShareRecordingModal,
  SetShowingSideMenu,
  SetSttHasAtLeastOneUtterance,
  ToggleContentJumpButtonsVisibilityAction,
  ToggleExpandedContextBar,
  ToggleShowingChannels,
  ToggleShowingSideMenu,
  UpdateAvailableTimeframes,
  UpdateLiveAudioSegmentPlaybackData,
  UpdateLiveRecordingInfo,
  UpdateSealedTimeframes,
} from './playback.actions'
import { PlaybackStateModel, PlaybackType, RealTimeSecondaryContent } from './playback.model'

export function defaultPlaybackState(): PlaybackStateModel {
  return {
    recordingId: undefined,
    courtSystemId: undefined,
    recordingName: undefined,
    sttHasAtLeastOneUtterance: false,
    exportData: undefined,
    isPlaybackPage: false,
    courtRecording: undefined,
    onRecordState: OnRecordState.NotRecording,
    stoppingSttForPreviousSession: false,
    playbackType: undefined,
    canOrderTranscript: false,
    canShareAudioOrder: false,
    showingAudioOrderShareModal: false,
    showingFootPedalSettingsModal: false,
    showingPlayerShortcutConfigurationModal: false,
    showingRecordingDiagnosticsModal: false,
    showingShareRecordingModal: false,
    showingSharedWithModal: false,
    showingDownloadBundleModal: false,
    sealingMode: false,
    sealingTimeRange: undefined,
    liveSealing: false,
    externalTranscriptOrderUrl: undefined,
    audioChannelCount: undefined,
    hasMultiChannel: false,
    showingChannels: false,
    showingSideMenu: false,
    hasRealTimeVideo: false,
    realTimeSecondaryContent: undefined,
    playbackContext: undefined,
    showingExpandedContextBar: false,
    showingDebugInfo: false,
    canAccessSealedContent: undefined,
    canAccessOffTheRecordContent: undefined,
    canReadStt: undefined,
    canReadLogSheets: undefined,
    canDownloadBundle: undefined,
    timezone: undefined,
    availableTimeframes: undefined,
    recordingSegments: undefined,
    onRecordTimeframes: undefined,
    sealedTimeframes: undefined,
    contentJumpButtonsVisible: false,
    failedShortcuts: [],
    shortcuts: new Map(),
    isAudioSegmentLive: false,
    canManagePastSessions: undefined,
  }
}

export const STOPPING_SESSION_TIMEOUT = 5000

export interface ExportState {
  recordingId: PlaybackStateModel['recordingId']
  courtSystemId: PlaybackStateModel['courtSystemId']
  recordingName: PlaybackStateModel['recordingName']
  sttHasAtLeastOneUtterance: PlaybackStateModel['sttHasAtLeastOneUtterance']
  exportData: PlaybackStateModel['exportData']
  courtRecording: RecordingWithSegments | undefined
}

const recordingNotAsked: RemoteData<RecordingWithSegments> = RemoteData.notAsked()

@State<PlaybackStateModel>({
  name: 'playbackState',
  defaults: defaultPlaybackState(),
})
@Injectable()
export class PlaybackState {
  constructor(
    private readonly courtRecordingService: CourtRecordingService,
    private readonly realTimeLiveStreamService: RealTimeLiveStreamService,
    private readonly transcriptOrderingFeatureService: TranscriptOrderingFeatureService,
    private readonly coreConfigurationService: CoreConfigurationService,
  ) {}

  @Selector()
  static exportQuery(state: PlaybackStateModel): ExportState {
    return {
      recordingId: state.recordingId,
      courtSystemId: state.courtSystemId,
      recordingName: state.recordingName,
      sttHasAtLeastOneUtterance: state.sttHasAtLeastOneUtterance,
      exportData: state.exportData,
      courtRecording: state.courtRecording?.isSuccess() ? state.courtRecording._data : undefined,
    }
  }

  @Selector()
  static courtRecording(state: PlaybackStateModel): RemoteData<RecordingWithSegments> {
    return state.courtRecording || recordingNotAsked
  }

  @Selector()
  static isPlaybackPage(state: PlaybackStateModel): boolean {
    return state.isPlaybackPage
  }

  @Selector()
  static timezone(state: PlaybackStateModel): ZoneId | undefined {
    return state.timezone
  }

  /**
   * Used for debugging, you should be using availableTimeframes
   *
   * This is the full unfiltered recording segment times,
   * the user does not necessarily have access to all of this
   */
  @Selector()
  static fullTimecodes(state: PlaybackStateModel): Timecode[] | undefined {
    return state.recordingSegments ? collect(state.recordingSegments, segment => segment.timecode) : []
  }

  @Selector()
  static availableTimeframes(state: PlaybackStateModel): TimeframeWithLocalTimes[] | undefined {
    return state.availableTimeframes
  }

  @Selector()
  static onRecordSegments(state: PlaybackStateModel): Timeframe[] | undefined {
    return state.onRecordTimeframes
  }

  @Selector()
  static sealedTimeframes(state: PlaybackStateModel): Timeframe[] | undefined {
    return state.sealedTimeframes
  }

  @Selector()
  static sealingMode(state: PlaybackStateModel): boolean {
    return state.sealingMode
  }

  @Selector()
  static liveSealing(state: PlaybackStateModel): boolean {
    return state.liveSealing
  }

  @Selector()
  static date(state: PlaybackStateModel): LocalDate | undefined {
    return state.courtRecording?.get()?.date
  }

  @Selector()
  static onRecordState(state: PlaybackStateModel): OnRecordState {
    return state.onRecordState
  }

  @Selector([PlaybackState])
  static stoppingSttForPreviousSession(state: PlaybackStateModel): boolean {
    return state.stoppingSttForPreviousSession
  }

  @Selector()
  static sealingTimeRange(state: PlaybackStateModel): LocalTimeRange | undefined {
    return state.sealingTimeRange
  }

  @Selector()
  static playbackType(state: PlaybackStateModel): PlaybackType | undefined {
    return state.playbackType
  }

  @Selector()
  static courtSystemId(state: PlaybackStateModel): Uuid | undefined {
    return state.courtSystemId
  }

  @Selector()
  static sttHasAtLeastOneUtterance(state: PlaybackStateModel): boolean {
    return state.sttHasAtLeastOneUtterance
  }

  @Selector()
  static showingAudioOrderShareModal(state: PlaybackStateModel): boolean {
    return state.showingAudioOrderShareModal
  }

  @Selector()
  static showingFootPedalSettingsModal(state: PlaybackStateModel): boolean {
    return state.showingFootPedalSettingsModal
  }

  @Selector()
  static showingRecordingDiagnosticsModal(state: PlaybackStateModel): boolean {
    return state.showingRecordingDiagnosticsModal
  }

  @Selector()
  static shortcuts(state: PlaybackStateModel): { shortcuts: ShortcutMap; failedShortcuts: Shortcut[] } {
    return { shortcuts: state.shortcuts, failedShortcuts: state.failedShortcuts }
  }

  @Selector()
  static showingPlayerShortcutConfigurationModal(state: PlaybackStateModel): boolean {
    return state.showingPlayerShortcutConfigurationModal
  }

  @Selector()
  static showingShareRecordingModal(state: PlaybackStateModel): boolean {
    return state.showingShareRecordingModal
  }

  @Selector()
  static showingSharedWithModal(state: PlaybackStateModel): boolean {
    return state.showingSharedWithModal
  }

  @Selector()
  static showingDownloadBundleModal(state: PlaybackStateModel): boolean {
    return state.showingDownloadBundleModal
  }

  @Selector()
  static canOrderTranscript(state: PlaybackStateModel): boolean {
    return state.canOrderTranscript
  }

  @Selector()
  static canShareAudioOrder(state: PlaybackStateModel): boolean {
    return state.playbackType === PlaybackType.AudioOrder && state.canShareAudioOrder
  }

  // TODO: decouple
  @Selector([PlaybackState.sealingMode, SearchState.backToSearchResultsFullUrl])
  static isSealingModeOnAndNoBackToSearch(
    sealingMode: boolean,
    backToSearchResultsFullUrl: string | undefined,
  ): boolean {
    return sealingMode && !backToSearchResultsFullUrl
  }

  @Selector()
  static externalTranscriptOrderUrl(state: PlaybackStateModel): string | undefined {
    return state.externalTranscriptOrderUrl
  }

  @Selector()
  static audioChannelCount(state: PlaybackStateModel): number {
    return state.audioChannelCount || 0
  }

  @Selector()
  static hasMultiChannel(state: PlaybackStateModel): boolean {
    return state.hasMultiChannel
  }

  @Selector()
  static showingChannels(state: PlaybackStateModel): boolean {
    return state.showingChannels
  }

  @Selector()
  static showingSideMenu(state: PlaybackStateModel): boolean {
    return state.showingSideMenu
  }

  @Selector()
  static hasRealTimeVideo(state: PlaybackStateModel): boolean {
    return state.hasRealTimeVideo
  }

  @Selector()
  static realTimeSecondaryContent(state: PlaybackStateModel): RealTimeSecondaryContent | undefined {
    return state.realTimeSecondaryContent
  }

  @Selector()
  static playbackContext(state: PlaybackStateModel): PlaybackContext | undefined {
    return state.playbackContext
  }

  @Selector()
  static playbackContextBarIsExpanded(state: PlaybackStateModel): boolean {
    return state.showingExpandedContextBar
  }

  @Selector()
  static showingDebugInfo(state: PlaybackStateModel): boolean {
    return state.showingDebugInfo
  }

  @Selector()
  static canAccessSealedContent(state: PlaybackStateModel): boolean | undefined {
    return state.canAccessSealedContent
  }

  @Selector()
  static canAccessOffTheRecordContent(state: PlaybackStateModel): boolean | undefined {
    return state.canAccessOffTheRecordContent
  }

  @Selector()
  static canReadStt(state: PlaybackStateModel): boolean | undefined {
    return state.canReadStt
  }

  @Selector()
  static canReadLogSheets(state: PlaybackStateModel): boolean | undefined {
    return state.canReadLogSheets
  }

  @Selector()
  static canDownloadBundle(state: PlaybackStateModel): boolean | undefined {
    return state.canDownloadBundle
  }

  @Selector()
  static contentJumpButtonsVisible(state: PlaybackStateModel): boolean {
    return state.contentJumpButtonsVisible
  }

  @Selector()
  static canManagePastSessions(state: PlaybackStateModel): boolean | undefined {
    return state.canManagePastSessions
  }

  @Action(SetPlaybackInfo)
  setPlaybackInfo(
    { patchState }: StateContext<PlaybackStateModel>,
    {
      courtSystemId,
      recordingId,
      recordingName,
      location,
      playbackType,
      canOrderTranscript,
      canShareAudioOrder,
      audioChannelCount,
      hasMultiChannel,
      showingChannels,
      playbackContext,
      canReadStt,
      canReadLogSheets,
      canDownloadBundle,
      canAccessSealedContent,
      canAccessOffTheRecordContent,
      canManagePastSessions,
    }: SetPlaybackInfo,
  ): void {
    patchState({
      courtSystemId,
      recordingId,
      recordingName,
      exportData: { location },
      playbackType,
      canOrderTranscript,
      canShareAudioOrder,
      audioChannelCount,
      hasMultiChannel,
      showingChannels,
      playbackContext,
      canReadStt,
      canReadLogSheets,
      canDownloadBundle,
      canAccessSealedContent,
      canAccessOffTheRecordContent,
      canManagePastSessions,
    })
  }

  @Action(SetRecordingTimezone)
  setRecordingTimezone(
    { patchState }: StateContext<PlaybackStateModel>,
    { recordingTimezone }: SetRecordingTimezone,
  ): void {
    patchState({ timezone: recordingTimezone })
  }

  @Action(SetSttHasAtLeastOneUtterance)
  setSttHasAtLeastOneUtterance(
    { patchState }: StateContext<PlaybackStateModel>,
    { sttHasAtLeastOneUtterance }: SetSttHasAtLeastOneUtterance,
  ): void {
    patchState({ sttHasAtLeastOneUtterance })
  }

  @Action(ResetCourtRecording)
  resetCourtRecording({ patchState }: StateContext<PlaybackStateModel>): void {
    patchState({
      courtRecording: undefined,
      onRecordTimeframes: undefined,
      recordingSegments: undefined,
      availableTimeframes: undefined,
      sealedTimeframes: undefined,
      onRecordState: undefined,
      stoppingSttForPreviousSession: false,
    })
  }

  @Action(FetchCourtRecording)
  fetchCourtRecording(
    { patchState, getState }: StateContext<PlaybackStateModel>,
    { recordingId, courtSystemId, dataStore, force }: FetchCourtRecording,
  ): void {
    const recording = getState().courtRecording
    const hasRecording = recording?.isSuccess()
    const isDifferentRecording = recording?.data?.id !== recordingId
    if (!hasRecording || isDifferentRecording || force) {
      ApiResult.combine([
        this.coreConfigurationService.getByCourtSystem(courtSystemId),
        dataStore === DataStore.Regional
          ? this.realTimeLiveStreamService.getRecordingWithSegments(courtSystemId, recordingId)
          : // courtSystemId is required because JobPlaybackComponent loads core and regional recordings via this call
            this.courtRecordingService.getWithSegments(recordingId, courtSystemId),
      ])
        .pipe(
          filter(remoteData => {
            return remoteData.isCompleted()
          }),
        )
        .subscribe(remoteData => {
          remoteData.map(([configuration, courtRecording]) => {
            const timezone = calculateSupportedTimezone(courtRecording, configuration)
            const onRecordState = this.calculateOnRecordState(
              timezone,
              courtRecording.segments,
              courtRecording.onTheRecordTimeframes,
            )

            patchState({
              courtRecording: new Success(courtRecording),
              timezone,
              onRecordTimeframes: courtRecording.onTheRecordTimeframes,
              availableTimeframes: courtRecording.availableTimeframes,
              sealedTimeframes: courtRecording.sealedSegments,
              onRecordState,
              stoppingSttForPreviousSession: false,
            })
          })
        })
    }
  }

  @Action(SetIsPlaybackPage)
  setIsPlaybackPage({ patchState }: StateContext<PlaybackStateModel>, { isPlaybackPage }: SetIsPlaybackPage): void {
    patchState({ isPlaybackPage })
  }

  @Action(ResetPlaybackState)
  resetPlaybackState({ setState }: StateContext<PlaybackStateModel>): void {
    setState(defaultPlaybackState())
  }

  @Action(SetShowingRecordingDiagnosticsModal)
  setShowingRecordingDiagnosticsModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingRecordingDiagnosticsModal }: SetShowingRecordingDiagnosticsModal,
  ): void {
    patchState({ showingRecordingDiagnosticsModal })
  }

  @Action(SetShowingAudioOrderShareModal)
  setShowingAudioOrderShareModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingAudioOrderShareModal }: SetShowingAudioOrderShareModal,
  ): void {
    patchState({ showingAudioOrderShareModal })
  }

  @Action(SetShowingFootPedalSettingsModal)
  setShowingFootPedalSettingsModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingFootPedalSettingsModal }: SetShowingFootPedalSettingsModal,
  ): void {
    patchState({ showingFootPedalSettingsModal })
  }

  @Action(SetShortcuts)
  setShortcuts({ patchState }: StateContext<PlaybackStateModel>, { shortcuts, failedShortcuts }: SetShortcuts): void {
    patchState({ shortcuts, failedShortcuts })
  }

  @Action(SetShowingPlayerShortcutConfigurationModal)
  setShowingPlayerShortcutConfigurationModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingPlayerShortcutConfigurationModal }: SetShowingPlayerShortcutConfigurationModal,
  ): void {
    patchState({ showingPlayerShortcutConfigurationModal })
  }

  @Action(SetShowingShareRecordingModal)
  setShowingShareRecordingModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingShareRecordingModal }: SetShowingShareRecordingModal,
  ): void {
    patchState({ showingShareRecordingModal })
  }

  @Action(SetShowingSharedWithModal)
  setShowingSharedWithModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingSharedWithModal }: SetShowingSharedWithModal,
  ): void {
    patchState({ showingSharedWithModal })
  }

  @Action(SetShowingDownloadBundleModal)
  setShowingDownloadBundleModal(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingDownloadBundleModal }: SetShowingDownloadBundleModal,
  ): void {
    patchState({ showingDownloadBundleModal })
  }

  @Action(UpdateSealedTimeframes)
  updateSealedTimeframes(
    { patchState }: StateContext<PlaybackStateModel>,
    { sealedTimeframes }: UpdateSealedTimeframes,
  ): void {
    patchState({ sealedTimeframes })
  }

  @Action(SetSealingMode)
  setSealingMode({ patchState }: StateContext<PlaybackStateModel>, { sealingMode }: SetSealingMode): void {
    patchState({ sealingMode })
  }

  @Action(SetShowingChannels)
  setShowingChannels({ patchState }: StateContext<PlaybackStateModel>, { showingChannels }: SetShowingChannels): void {
    patchState({ showingChannels })
  }

  @Action(ToggleShowingChannels)
  toggleShowingChannels({ patchState, getState }: StateContext<PlaybackStateModel>): void {
    patchState({ showingChannels: !getState().showingChannels })
  }

  @Action(SetSealingTimeRange)
  setSealingTimeRange({ patchState }: StateContext<PlaybackStateModel>, { timeRange }: SetSealingTimeRange): void {
    patchState({ sealingTimeRange: timeRange })
  }

  @Action(SetExternalTranscriptOrderingUrl)
  setExternalTranscriptOrderingUrl(
    { patchState }: StateContext<PlaybackStateModel>,
    {
      courtSystemId,
      hearingDate,
      feature,
      courthouseName,
      courtroomName,
      departmentName,
    }: SetExternalTranscriptOrderingUrl,
  ): void {
    ;(feature ? ApiResult.success(feature) : this.transcriptOrderingFeatureService.getForCourtSystem(courtSystemId))
      .pipe(
        unwrapData(),
        catchError(_ => EMPTY),
        tap(config => {
          if (!config.externalUrl) {
            return
          }

          const queryParams: [string, string | undefined][] = [
            ['departmentName', departmentName],
            ['courthouseName', courthouseName],
            ['courtroomName', courtroomName],
            ['hearingDate', hearingDate],
          ]
          const params = queryParams.filter(param => param[1] !== undefined).map(param => `${param[0]}=${param[1]}`)
          const externalTranscriptOrderUrl = encodeURI(`${config.externalUrl}?${params.join('&')}`)
          patchState({ externalTranscriptOrderUrl })
        }),
      )
      .subscribe()
  }

  @Action(SetShowingSideMenu)
  setShowingSideMenu({ patchState }: StateContext<PlaybackStateModel>, { showingSideMenu }: SetShowingSideMenu): void {
    patchState({ showingSideMenu })
  }

  @Action(ToggleShowingSideMenu)
  toggleShowingSideMenu({ patchState, getState }: StateContext<PlaybackStateModel>): void {
    patchState({ showingSideMenu: !getState().showingSideMenu })
  }

  @Action(SetHasRealTimeVideo)
  setHasRealTimeVideo(
    { patchState }: StateContext<PlaybackStateModel>,
    { hasRealTimeVideo }: SetHasRealTimeVideo,
  ): void {
    patchState({
      hasRealTimeVideo,
    })
  }

  @Action(SetRealTimeSecondaryContent)
  setRealTimeSecondaryContent(
    { patchState }: StateContext<PlaybackStateModel>,
    { content }: SetRealTimeSecondaryContent,
  ): void {
    patchState({
      realTimeSecondaryContent: content,
    })
  }

  @Action(UpdateLiveAudioSegmentPlaybackData)
  updateLiveAudioSegmentPlaybackData(
    { patchState, getState, dispatch }: StateContext<PlaybackStateModel>,
    { audioSegment, realTimeSttEnabled }: UpdateLiveAudioSegmentPlaybackData,
  ): Observable<void> {
    if (audioSegment) {
      const { recordingSegments, sealedSegments, onTheRecordTimeframes, availableTimeframes } = audioSegment
      const recordingTimezone = recordingSegments[0].zoneId

      patchState({
        timezone: recordingTimezone ?? getState().timezone,
        recordingSegments: [...audioSegment.recordingSegments],
        onRecordTimeframes: onTheRecordTimeframes,
        availableTimeframes,
        sealedTimeframes: sealedSegments,
        isAudioSegmentLive: audioSegment.isLive,
      })
      return dispatch(new ReevaluateOnRecordState(realTimeSttEnabled))
    } else {
      patchState({ onRecordState: OnRecordState.NotRecording })
      return of()
    }
  }

  @Action(ReevaluateOnRecordState)
  async reevaluateOnRecordState(
    { patchState, getState, dispatch }: StateContext<PlaybackStateModel>,
    { realTimeSttEnabled }: ReevaluateOnRecordState,
  ): Promise<void> {
    const {
      onRecordTimeframes,
      recordingSegments,
      onRecordState,
      stoppingSttForPreviousSession,
      timezone,
      playbackType,
      isAudioSegmentLive,
      sealedTimeframes,
    } = getState()
    if (!recordingSegments || !onRecordTimeframes) {
      return
    }

    // If the audio segment is not live on an audio segment playback page, set to NotRecording
    const newOnRecordState =
      isAudioSegmentPlaybackType(playbackType) && !isAudioSegmentLive
        ? OnRecordState.NotRecording
        : this.calculateOnRecordState(timezone, recordingSegments, onRecordTimeframes)
    const goingOffRecord = onRecordState === OnRecordState.OnRecord && newOnRecordState === OnRecordState.OffRecord
    if (goingOffRecord && realTimeSttEnabled) {
      dispatch(new ScheduleStoppingSttForSessionTimeout(onRecordTimeframes))
    }

    patchState({
      onRecordState: newOnRecordState,
      stoppingSttForPreviousSession: goingOffRecord && realTimeSttEnabled ? true : stoppingSttForPreviousSession,
      liveSealing: isLiveSealing(recordingSegments, sealedTimeframes, timezone),
    })
  }

  @Action(UpdateLiveRecordingInfo)
  updateLiveRecordingInfo(
    { patchState, dispatch }: StateContext<PlaybackStateModel>,
    { recording, realTimeSttEnabled }: UpdateLiveRecordingInfo,
  ): Observable<void> {
    if (recording) {
      // ensure the onRecordTimeframes and sealedTimeframes are sorted when put into state
      const sortedOnTheRecordTimeframes = new SortedTimeframeCollection(recording.onTheRecordTimeframes).timeframes
      const sortedSealedTimeframes = new SortedTimeframeCollection(recording.sealedSegments).timeframes

      patchState({
        recordingSegments: [...recording.segments],
        onRecordTimeframes: sortedOnTheRecordTimeframes,
        availableTimeframes: [...recording.availableTimeframes],
        sealedTimeframes: sortedSealedTimeframes,
      })
      return dispatch(new ReevaluateOnRecordState(realTimeSttEnabled))
    } else {
      patchState({ onRecordState: OnRecordState.NotRecording })
      return of()
    }
  }

  @Action(ScheduleStoppingSttForSessionTimeout)
  async scheduleStoppingSessionTimeout(
    { patchState, getState }: StateContext<PlaybackStateModel>,
    { onTheRecordTimeframesWhenSessionStopped }: ScheduleStoppingSttForSessionTimeout,
  ): Promise<void> {
    await sleep(STOPPING_SESSION_TIMEOUT)

    const { stoppingSttForPreviousSession, onRecordTimeframes } = getState()
    // We only update if we are still stopping and the timeframes have not changed since when scheduled the timeout
    if (stoppingSttForPreviousSession && isEqual(onRecordTimeframes, onTheRecordTimeframesWhenSessionStopped)) {
      patchState({ stoppingSttForPreviousSession: false })
    }
  }

  @Action(MarkSttStoppedForSession)
  markSttStoppedForSession({ patchState }: StateContext<PlaybackStateModel>): void {
    patchState({
      stoppingSttForPreviousSession: false,
    })
  }

  @Action(ToggleExpandedContextBar)
  toggleExpandedContextBar({ patchState, getState }: StateContext<PlaybackStateModel>): void {
    patchState({
      showingExpandedContextBar: !getState().showingExpandedContextBar,
    })
  }

  @Action(SetShowingDebugInfo)
  setShowingDebugInfo(
    { patchState }: StateContext<PlaybackStateModel>,
    { showingDebugInfo }: SetShowingDebugInfo,
  ): void {
    patchState({ showingDebugInfo })
  }

  @Action(SetContentJumpButtonsVisibilityAction)
  setContentJumpButtonsVisibility(
    { patchState }: StateContext<PlaybackStateModel>,
    { contentJumpButtonsVisible }: SetContentJumpButtonsVisibilityAction,
  ): void {
    patchState({ contentJumpButtonsVisible })
  }

  @Action(ToggleContentJumpButtonsVisibilityAction)
  toggleContentJumpButtonsVisibility(
    { patchState, getState }: StateContext<PlaybackStateModel>,
    _action: ToggleContentJumpButtonsVisibilityAction,
  ): void {
    patchState({ contentJumpButtonsVisible: !getState().contentJumpButtonsVisible })
  }

  @Action(UpdateAvailableTimeframes)
  updateAvailableTimeframes(
    { patchState }: StateContext<PlaybackStateModel>,
    { availableTimeframes }: UpdateAvailableTimeframes,
  ): void {
    patchState({ availableTimeframes })
  }

  private calculateOnRecordState(
    timeZoneId: ZoneId | undefined,
    segments: RecordingSegment[],
    onTheRecordTimeframes: Timeframe[],
  ): OnRecordState {
    if (!isTheRecorderRunning(segments)) {
      return OnRecordState.NotRecording
    }

    // timeZoneId is not guaranteed for recordings produced by recorders up to v 0.29, hence an additional check using
    // recording segments. At the time of writing (Apr 2024) only one customer is left using an older recorder version
    const onRecord = timeZoneId
      ? isOnTheRecordByDeviceClock(onTheRecordTimeframes, timeZoneId)
      : isOnTheRecordBySegments(onTheRecordTimeframes, segments)

    return onRecord ? OnRecordState.OnRecord : OnRecordState.OffRecord
  }
}

export const isRecordingLive = (state: OnRecordState): boolean =>
  state === OnRecordState.OnRecord || state === OnRecordState.OffRecord

export function calculateSupportedTimezone(
  courtRecording: RecordingWithSegments,
  configuration: PublicConfiguration,
): ZoneId {
  const courtTimezone = courtRecording.courtroom?.courthouse?.timeZoneId ?? configuration.timeZoneId
  const recordingZoneId = collectFirst(courtRecording.segments, x => x.zoneId)

  // Check that this is supported by the moment timezones available, otherwise fallback to courtTimezone
  if (recordingZoneId && moment.tz.zone(recordingZoneId.toString())) {
    return recordingZoneId
  }

  return courtTimezone
}

function isAudioSegmentPlaybackType(playbackType?: PlaybackType): boolean {
  if (!playbackType) {
    return false
  }

  return [PlaybackType.AudioOrder, PlaybackType.RealTimeOrder, PlaybackType.SharedRecording].includes(playbackType)
}
