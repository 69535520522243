<ftr-page [pageStyle]="pageStyle">
  @if (isHandlingNativeAuth) {
    <ftr-loader
      [isLoading]="true"
      [fullPage]="true"
      [loaderText]="'Redirecting to ' + this.identityProvider + '...'"
    ></ftr-loader>
  } @else {
    <article class="login-component">
      <div class="card card--borderless card--shadowless password-card">
        @if (isAccessingSharedAudio) {
          <div class="login__shared-audio-notification">
            <ftr-notification [type]="notificationType.Info">
              Please either log in, or sign up with For The Record to be able to access the shared link
            </ftr-notification>
          </div>
        }
        <header class="login__header">
          <h1 class="password-card__title">Log In</h1>
          <p class="password-card__subtitle">
            For The Record provides access to the court record, securely and in real-time.
          </p>
        </header>
        <ftr-notification-flash></ftr-notification-flash>
        <ftr-login-form [returnUrl]="returnUrl"></ftr-login-form>
        <ftr-divider [loose]="true">OR</ftr-divider>
        <ftr-single-sign-on [returnUrl]="returnUrl" [showMockLogin]="showMockLogin"></ftr-single-sign-on>
        <div class="login__sign-up-container">
          Don't have an account?
          <a [routerLink]="['/signup']" [queryParams]="{ returnUrl: returnUrl }" queryParamsHandling="merge">
            Create account
          </a>
        </div>
        <div class="login__instructions">
          <a class="elevio__create-an-account" [routerLink]="['./']" data-track>How do I create an account?</a>
        </div>
      </div>
    </article>
  }
</ftr-page>
