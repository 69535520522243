import { Timecode } from '@ftr/contracts/type/shared'
import { IntervalTree } from 'node-interval-tree'
import { RealTimeTimecodeInterval } from '../types'

export function createIntervalTreeFromTimecodes(timecodes: Timecode[]): IntervalTree<RealTimeTimecodeInterval> {
  const tree = new IntervalTree<RealTimeTimecodeInterval>()
  let last = 0
  timecodes.forEach(timecode => {
    const high = last + timecode.duration / 1000
    tree.insert({ low: last, high, timecode })
    last = high
  })
  return tree
}
