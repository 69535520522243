import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { IconComponent } from '@ftr/foundation'
import { ValidationErrorHintDirective } from '../../directives'
import { validateOptionalMultiLineText } from '../../validators'

/*
  When we get to 60% of the max-length, we show a tip on the max length of the field.

  We didn't want to always display this tip as the current order instructions field allows up to 800 characters as a
  workaround to court clerk's usage of it to type in the history of changes to an order (rather than just for the
  customer's use to provide instructions). We were cautious that explicitly showing customers placing orders the high
  max length of the field would provide an incentive for them to type more than what's needed.
 */
const MAX_LENGTH_CLOSE_RATIO = 0.6

/**
 * A wrapper for a textarea element, including rendering of elements essential to validation display.
 */
@Component({
  standalone: true,
  selector: 'ftr-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.css'],
  imports: [ValidationErrorHintDirective, ReactiveFormsModule, ValidationErrorHintDirective, IconComponent],
  encapsulation: ViewEncapsulation.None,
})
export class FormTextareaComponent implements OnInit, AfterViewInit {
  @Input() name?: string
  @Input() label?: string
  @Input() placeholder: string
  /**
   * Label to be used to display validation. If not set, this defaults to <code>label</code>.
   */
  @Input() validationLabel?: string
  @Input() required = false
  @Input() maxLength = 800
  @Input() showMarkdownHint = false
  @Input() ngFormControl: UntypedFormControl
  @Input() submitAttempted: boolean
  @Input() notrack = false
  @Input() validateSupportedText: boolean

  @ViewChild('textarea', { static: true }) textarea: ElementRef
  hasValue = false

  ngOnInit(): void {
    this.onChange(this.ngFormControl.value)
    if (this.validateSupportedText) {
      this.ngFormControl.setValidators(validateOptionalMultiLineText)
    }
    this.ngFormControl.valueChanges.subscribe(value => {
      this.onChange(value)
    })
  }

  ngAfterViewInit(): void {
    this.resizeToFitContent()
  }

  onChange(value: string): void {
    this.hasValue = !!value
    this.resizeToFitContent()
  }

  resizeToFitContent(): void {
    // We need to set the height back to auto to handle shrinking down
    this.setHeight('auto')
    this.setHeight(`${this.textareaElement.scrollHeight}px`)
  }

  setHeight(value: string): void {
    this.textareaElement.style.height = value
  }

  isCloseToMaxLength(): boolean {
    return this.ngFormControl.value?.length >= this.maxLength * MAX_LENGTH_CLOSE_RATIO
  }

  isMaxLength(): boolean {
    return this.ngFormControl.value?.length === this.maxLength
  }

  get textareaElement(): HTMLTextAreaElement {
    return this.textarea.nativeElement
  }
}
