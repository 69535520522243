import { CommonModule } from '@angular/common'
import { Component, computed, input } from '@angular/core'
import { SearchedHearing, SearchedHearingSection } from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField, VocabularyTerms } from '@ftr/contracts/type/core'
import { BadgeColors, DateComponent, DateFormat, DatePipe, Join, PathsToPrimitiveProps } from '@ftr/foundation'
import { CourtSystemTimezoneModule } from '@ftr/ui-court-system'
import { SearchTextWithHighlightingAndTruncationComponent, SearchTextWithHighlightingComponent } from '@ftr/ui-rtstt'
import { CardTypeLabelComponent } from '~app/features/search/search-results/card-type-label/card-type-label.component'

type DotNotatedSearchedHearingLookup = Join<PathsToPrimitiveProps<SearchedHearing>, '.'>

@Component({
  selector: 'ftr-search-results-hearings-item',
  templateUrl: './search-results-hearings-item.component.html',
  styleUrls: ['./search-results-hearings-item.component.css'],
  standalone: true,
  imports: [
    DateComponent,
    SearchTextWithHighlightingComponent,
    CardTypeLabelComponent,
    CourtSystemTimezoneModule,
    CommonModule,
    DatePipe,
    SearchTextWithHighlightingAndTruncationComponent,
  ],
})
export class SearchResultsHearingsItemComponent {
  searchResult = input.required<SearchedHearing>()
  vocabularyTerms = input.required<VocabularyTerms>()
  highlight = input.required<ElasticsearchHighlightField>()

  readonly dateFormat = DateFormat
  readonly badgeColor = BadgeColors.Primary
  readonly timeFormat = DateFormat.Time

  readonly caseReference = computed(() => this.getHighlightFromSection('caseReference') ?? '')
  readonly title = computed(() => this.getHighlightFromSection('title') ?? '')

  readonly firstHearingTime = computed(() => this.searchResult().hearingSections[0]?.startTime)

  protected hasHighlight(key: DotNotatedSearchedHearingLookup): boolean {
    return !!this.highlight()[key]
  }

  protected getHighlightFromSection<T extends keyof SearchedHearingSection>(sectionKey: T): SearchedHearingSection[T] {
    for (const [key, value] of Object.entries(this.highlight())) {
      if (key.includes(sectionKey) && value.length) {
        return value[0] as SearchedHearingSection[T]
      }
    }
    return this.searchResult().hearingSections[0]?.[sectionKey]
  }
}
