<div #resultsList class="card-list">
  @for (item of searchItems(); track item) {
    <ng-template #childTemplate>
      <ftr-search-results-item
        [isSearchWithinResource]="isSearchWithinResource()"
        [hasLink]="!!item.link"
        [searchResult]="item.content"
        [courtSystemConfig]="configuration()"
        [vocabularyTerms]="vocabularyTerms()"
      >
      </ftr-search-results-item>
    </ng-template>

    <ng-template #additionalTemplate>
      @if (activeSearchIdLoading() === item.id) {
        <ftr-line-loader message="" [fitToBottom]="true"> </ftr-line-loader>
      }
    </ng-template>

    <ftr-complex-list-item
      [link]="item.link ?? []"
      [queryParams]="item.queryParams"
      [displayMode]="'single-child'"
      [childTemplate]="childTemplate"
      [additionalTemplate]="additionalTemplate"
      [isAbsolutePositionAdditionalTemplate]="true"
      (click)="onItemClick(item)"
      (anchorFocus)="onItemFocus()"
      (anchorKeyDown)="onResultKeyDown($event, item)"
    >
    </ftr-complex-list-item>
  }
</div>
