import { CommonModule } from '@angular/common'
import { Component, computed, input } from '@angular/core'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { IndexedOrder } from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField, VocabularyTerms } from '@ftr/contracts/type/core'
import { LineItemType } from '@ftr/contracts/type/order'
import { DateComponent, DateFormat, Join, PathsToPrimitiveProps } from '@ftr/foundation'
import { CourtSystemTimezoneModule } from '@ftr/ui-court-system'
import { SearchTextWithHighlightingAndTruncationComponent, SearchTextWithHighlightingComponent } from '@ftr/ui-rtstt'
import { get } from 'lodash-es'
import {
  CardType,
  CardTypeLabelComponent,
} from '~app/features/search/search-results/card-type-label/card-type-label.component'

type DotNotatedIndexedOrderHighlightLookup = Join<PathsToPrimitiveProps<IndexedOrder>, '.'>

const HIGHLIGHT_DISPLAY_ORDER = [
  'caseType',
  'customer.email',
  'customer.name',
  'sharedWithUsers.name',
  'sharedWithUsers.email',
  'hearingPortions',
  'department.name',
  'judges',
  'languages',
  'appearances.name',
  'appearances.role',
  'languagesSpellingsInstructions',
  'transcriber.name',
  'transcriber.email',
  'claimedAdmin.name',
  'claimedAdmin.email',
  'turnaround',
  'uploadedDocuments.fileName',
  'placementDate',
] satisfies DotNotatedIndexedOrderHighlightLookup[]

const indexedOrderLabelMap: Record<(typeof HIGHLIGHT_DISPLAY_ORDER)[number], string> = {
  'claimedAdmin.email': 'Claimed By',
  'claimedAdmin.name': 'Claimed By',
  'customer.email': 'Customer Email',
  'customer.name': 'Customer',
  languagesSpellingsInstructions: 'Notes',
  hearingPortions: 'Portion Types',
  'sharedWithUsers.email': 'Shared With Email',
  'sharedWithUsers.name': 'Shared With',
  'transcriber.email': 'Transcriber Email',
  'transcriber.name': 'Transcriber',
  turnaround: 'Turnaround',
  'uploadedDocuments.fileName': 'File',
  judges: 'Judges',
  languages: 'Languages',
  'appearances.name': 'Appearances - Name',
  'appearances.role': 'Appearances - Role',
  caseType: 'Case Type',
  'department.name': 'Department',
  placementDate: 'Placement Date',
}

interface AdditionalField {
  label: string
  highlightedValue: string
  isDate: boolean
}

@Component({
  selector: 'ftr-search-results-orders-item',
  templateUrl: './search-results-orders-item.component.html',
  styleUrls: ['./search-results-orders-item.component.css'],
  standalone: true,
  imports: [
    DateComponent,
    SearchTextWithHighlightingComponent,
    CardTypeLabelComponent,
    CourtSystemTimezoneModule,
    CommonModule,
    SearchTextWithHighlightingAndTruncationComponent,
  ],
})
export class SearchResultsOrdersItemComponent {
  searchResult = input.required<IndexedOrder>()
  courtSystemConfig = input.required<PublicConfiguration>()
  highlight = input.required<ElasticsearchHighlightField>()
  vocabularyTerms = input.required<VocabularyTerms>()

  orderType = computed(() => this.searchResult().lineItemType)

  orderCardType = computed<CardType>(() => {
    switch (this.orderType()) {
      case LineItemType.CertifiedTranscript:
        return 'transcript-order'
      case LineItemType.Audio:
        return 'audio-order'
      case LineItemType.RealTime:
        return 'realtime-order'
      default:
        return 'transcript-order'
    }
  })

  hearingDetailsHtml = computed(() => {
    return [this.getValue('location.courthouse'), this.getValue('location.courtroom')].filter(Boolean).join(' - ')
  })

  /**
   * We only display a single additional field depending on a set priority
   */
  priorityDynamicHighlight = computed<AdditionalField | undefined>(() => {
    // Try to grab a key based priority
    for (const priorityKey of HIGHLIGHT_DISPLAY_ORDER) {
      if (this.highlight()[priorityKey]?.length > 0) {
        const isDate = priorityKey === 'placementDate'
        return {
          label: indexedOrderLabelMap[priorityKey],
          // Date highlighting is handled differently
          highlightedValue: isDate ? get(this.searchResult(), priorityKey) : this.highlight()[priorityKey][0],
          isDate: priorityKey === 'placementDate',
        }
      }
    }
    return undefined
  })

  lineItemType = LineItemType

  readonly dateFormat = DateFormat

  protected getValue(key: DotNotatedIndexedOrderHighlightLookup): string {
    // If there is a highlighted value, use that (at index 0 since we only care about the first value)
    return this.highlight()[key]?.[0] ?? get(this.searchResult(), key) ?? ''
  }

  protected hasHighlight(key: DotNotatedIndexedOrderHighlightLookup): boolean {
    return !!this.highlight()[key]
  }
}
