import { AsyncPipe, NgClass } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { generateUuid } from '@ftr/contracts/type/shared/Uuid'
import {
  DateFormat,
  DatePipe,
  DefaultTimeStringParseMethod,
  IconComponent,
  TimeStringParseMethod,
  TimeStringParser,
} from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import { Observable, of } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'

export const INVALID_TIME_MESSAGE = 'The time provided is invalid.'

@Component({
  standalone: true,
  selector: 'ftr-time-input',
  imports: [IconComponent, NgClass, AsyncPipe, ReactiveFormsModule, ValidationErrorHintDirective, DatePipe],
  templateUrl: './time-input.component.html',
})
/**
 * An input for entering times. It will automatically try to interpret non-default time formats
 * like "8pm" and convert them to "8:00 PM".
 */
export class TimeInputComponent {
  readonly validators = Validators

  @Input() label: string = 'Time'

  @Input() id: string = generateUuid()

  @Input() name: string = 'time'

  /**
   * Whether submission of the form this component is part of has been attempted. When submission is attempted, the
   * underlying validation control (i.e.: ValidationHint) may choose to display validation states for fields which have
   * not yet been touched.
   */
  @Input() submitAttempted = false

  /**
   * The form control driving the value in this component.
   */
  @Input() control: FormControl<LocalTime | null>

  /**
   * Whether to highlight field errors, as per ftr-form.
   */
  @Input() highlightError: Observable<boolean> = of(false)

  /**
   * The placeholder for the input.
   */
  @Input() placeholder: string = 'HH:MM AM/PM'

  /**
   * The format to use
   */
  @Input() timeFormat: DateFormat = DateFormat.Time

  @Input() invalidTimeMessage: string = INVALID_TIME_MESSAGE

  @Input() timeStringParseMethods: TimeStringParseMethod[] = [DefaultTimeStringParseMethod]

  @Output() inputChanged = new EventEmitter<void>()

  inputChange(value: string): void {
    this.control.markAsTouched()
    if (value.trim() === '') {
      this.control.setValue(null)
      this.inputChanged.emit()
      return
    }

    this.control.markAsTouched()

    try {
      this.control.setValue(TimeStringParser.parse(value.trim(), this.timeStringParseMethods))
      this.control.updateValueAndValidity()
    } catch (err) {
      this.control.setErrors({
        invalidFormat: this.invalidTimeMessage,
      })
    }
    this.inputChanged.emit()
  }
}
