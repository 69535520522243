import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { GenericTrackingEvent, TrackingEventType, TrackingService } from '@ftr/foundation'
import { Subject, distinctUntilChanged, map, takeUntil } from 'rxjs'

interface ValidationState {
  validationMessage: string
  hasValidationErrors: boolean
}

export interface ValidationErrorTracking extends GenericTrackingEvent {
  event: TrackingEventType.ValidationError
  formName: string | null
  formFieldName: string | undefined
  validationMessage: string
  inputValue: string | undefined
}

@Directive({
  standalone: true,
  selector: '[ftrValidationErrorHint]',
})
export class ValidationErrorHintDirective implements OnInit, OnChanges, OnDestroy {
  @Input() label: string
  @Input() ngFormControl: AbstractControl
  /**
   * This is the same as ngFormControl.touched and is required as a workaround to
   * Angular not offering listeners to ngFormControl.touched.
   */
  @Input() controlTouched: boolean
  @Input() submitAttempted: boolean
  @Input() showAllErrors: boolean

  readonly finalize = new Subject<void>()

  private readonly updateValidationState = new Subject<void>()
  private readonly validationState = this.updateValidationState.pipe(
    map(() => this.getValidationState()),
    distinctUntilChanged(compareValidationStates),
  )

  constructor(
    private readonly elementRef: ElementRef,
    private readonly trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.validationState.pipe(takeUntil(this.finalize)).subscribe(v => this.updateState(v))
    this.ngFormControl.statusChanges.pipe(takeUntil(this.finalize)).subscribe(() => this.updateValidationState.next())

    this.updateValidationState.next()
  }

  ngOnChanges(): void {
    this.updateValidationState.next()
  }

  ngOnDestroy(): void {
    this.finalize.next()
    this.finalize.complete()
  }

  trackError(validationMessage: string): void {
    const formField =
      this.elementRef.nativeElement.parentNode &&
      this.elementRef.nativeElement.parentNode.querySelector('input, select, textarea')

    if (!formField) {
      return
    }
    /**
     *  If you do not want to track the value inputted by the user,
     *  add data-notrack="true" as an attribute to the form field
     *  ftr-input has a 'notrack' input that can be used.
     */
    const inputValue = formField.dataset.notrack !== 'true' ? formField.value : undefined

    const trackingData: ValidationErrorTracking = {
      event: TrackingEventType.ValidationError,
      formName: formField.form && formField.form.name,
      formFieldName: formField!.name,
      validationMessage,
      inputValue,
    }
    this.trackingService.track(trackingData)
  }

  private updateState({ validationMessage, hasValidationErrors }: ValidationState): void {
    const nativeElement = this.elementRef.nativeElement
    nativeElement.innerHTML = validationMessage ? `<span class="warn" role="alert">${validationMessage}</span>` : ''
    nativeElement.hidden = !hasValidationErrors

    if (hasValidationErrors) {
      this.trackError(validationMessage)
    }
  }

  private getValidationState(): ValidationState {
    const validationMessage = this.showAllErrors === true ? this.getAllErrors() : this.getFirstError()
    return {
      hasValidationErrors: this.hasValidationErrors(),
      validationMessage,
    }
  }

  private hasValidationErrors(): boolean {
    return !!this.getValidationErrors().length
  }

  private getValidationErrors(): string[] {
    if (!this.shouldShowValidationErrors()) {
      return []
    }

    const errors = this.ngFormControl.errors
    if (!errors) {
      return []
    }
    return Object.keys(errors)
      .filter(key => errors[key])
      .map(key => (typeof errors[key] === 'string' ? errors[key] : this.getDefaultErrorMessage(key, errors[key])))
  }

  private getFirstError(): string {
    const validationErrors = this.getValidationErrors()
    return validationErrors.length ? validationErrors[0] : ''
  }

  private getAllErrors(): string {
    const validationErrors = this.getValidationErrors()
    return validationErrors.length ? validationErrors.join('. ') : ''
  }

  private shouldShowValidationErrors(): boolean {
    return !this.ngFormControl.valid && (this.controlTouched || this.submitAttempted)
  }

  private getDefaultErrorMessage(error: string, errorDetails?: any): string {
    if (error === 'required') {
      return `You must enter a value for ${this.label}.`
    } else if (error === 'min' && isMinValidationDetails(errorDetails)) {
      return `Must be ${errorDetails.min} or more.`
    } else if (error === 'max' && isMaxValidationDetails(errorDetails)) {
      return `Must be ${errorDetails.max} or less.`
    } else if (error === 'minlength' && isLengthValidationDetails(errorDetails)) {
      return `You must enter at least ${errorDetails.requiredLength} characters for ${this.label}.`
    } else if (error === 'maxlength' && isLengthValidationDetails(errorDetails)) {
      return `You can only enter a maximum of ${errorDetails.requiredLength} characters for ${this.label}.`
    } else {
      return `You must enter a valid ${this.label}.`
    }
  }
}

/**
 * @see https://angular.io/api/forms/Validators#min
 */
function isMinValidationDetails(errorDetails: any): errorDetails is { min: number } {
  return typeof errorDetails.min === 'number'
}

/**
 * @see https://angular.io/api/forms/Validators#max
 */
function isMaxValidationDetails(errorDetails: any): errorDetails is { max: number } {
  return typeof errorDetails.max === 'number'
}

/**
 * @see https://angular.io/api/forms/Validators#minLength and https://angular.io/api/forms/Validators#maxLength
 */
function isLengthValidationDetails(errorDetails: any): errorDetails is { requiredLength: number } {
  return typeof errorDetails.requiredLength === 'number'
}

function compareValidationStates(a: ValidationState, b: ValidationState): boolean {
  return a.validationMessage === b.validationMessage && a.hasValidationErrors === b.hasValidationErrors
}
