import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { MissingCognitoUserError } from '@ftr/api-shared'
import { ButtonColor, NotificationDisplayType, PageStyle, RemoteData } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import {
  AuthenticationService,
  CognitoAuthenticationService,
  MultifactorAuthenticationService,
  SignOutReason,
  isErrorMfaSessionExpiry,
} from '@ftr/ui-user'
import { ReplaySubject } from 'rxjs'

@Component({
  selector: 'ftr-mfa-challenge',
  templateUrl: './mfa-challenge.component.html',
  styleUrls: ['./mfa-challenge.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MfaChallengeComponent implements OnInit {
  @Input()
  returnUrl?: string

  multifactorAuthenticationChallengeForm: UntypedFormGroup
  submissionSubject = new ReplaySubject<RemoteData<void>>()
  submission = this.submissionSubject.asObservable()
  submitAttempted = false

  readonly pageStyle = PageStyle.Narrow
  readonly buttonColor = ButtonColor
  readonly recoverAccountPath = `/${AppPaths.MultiFactorAuthentication}/recover-account`

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly multifactorAuthenticationService: MultifactorAuthenticationService,
    private readonly cognitoAuthenticationService: CognitoAuthenticationService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.setupForm()
  }

  setupForm(): void {
    this.multifactorAuthenticationChallengeForm = this.formBuilder.group({
      mfaCode: ['', this.multifactorAuthenticationService.totpValidatorRules],
    })
  }

  submitForm(): void {
    this.submissionSubject.next(RemoteData.loading())
    this.submitAttempted = true
    const totpCode = this.multifactorAuthenticationChallengeForm.controls.mfaCode.value
    this.cognitoAuthenticationService
      .completeMFAChallenge(totpCode)
      .then(() => {
        return this.handleSuccess()
      })
      .catch(err => {
        if (err instanceof MissingCognitoUserError) {
          this.authenticationService.signOut(SignOutReason.EXPIRED)
          return
        }

        if (isErrorMfaSessionExpiry(err as Error)) {
          this.authenticationService.setNotificationMessage(
            SignOutReason.EXPIRED,
            NotificationDisplayType.AfterNextNavigation,
          )
          const extras = this.returnUrl ? { queryParams: { returnUrl: this.sanatizedReturnUrl } } : {}
          this.router.navigate([`/${AppPaths.Login}`], extras)
          return
        }
        this.submissionSubject.next(RemoteData.failure(new Error()))
        this.multifactorAuthenticationChallengeForm.controls.mfaCode.setErrors({
          externalErrorMessage: err?.message,
        })
      })
  }

  ngOnInit(): void {
    const mfaChallengeUser = this.cognitoAuthenticationService.mfaChallengeUser
    // If the user is not in the middle of a MFA challenge or email is not set, sign out user
    if (!mfaChallengeUser) {
      this.authenticationService.signOut(SignOutReason.UNAUTHORIZED)
    }
  }

  get sanatizedReturnUrl(): string | undefined {
    if (!this.returnUrl) {
      return this.returnUrl
    }

    if (this.returnUrl.startsWith(`/${AppPaths.MultiFactorAuthentication}?returnUrl=`)) {
      return decodeURIComponent(this.returnUrl.replace(`/${AppPaths.MultiFactorAuthentication}?returnUrl=`, ''))
    }

    return this.returnUrl
  }

  navigateHome(): void {
    this.router.navigateByUrl(this.sanatizedReturnUrl || AppPaths.Login)
  }

  private async handleSuccess(): Promise<void> {
    // Set the current user state to the user that just logged in
    await this.authenticationService.initialize()
    // then kick off the post login process
    const { result: postLoginResult, getValidReturnUrl } = await this.authenticationService.postLoginProcess()
    if (!postLoginResult) {
      await this.router.navigateByUrl(getValidReturnUrl(this.sanatizedReturnUrl))
    }
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
