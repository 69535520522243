import { Injectable } from '@angular/core'
import {
  LINE_HEIGHT,
  LayoutService,
  ScreenSize,
  ScreenSizeService,
  SizesService,
  VERTICAL_SPACER_PX,
} from '@ftr/foundation'
import { PlaybackState } from '@ftr/ui-playback'
import { Store } from '@ngxs/store'
import { Subject, combineLatest, distinctUntilChanged } from 'rxjs'
import { WindowRefService } from '~app/services/window/window-ref.service'

const WAVEFORM_BAR_OFFSET = 180

@Injectable()
export class HeaderSizeService extends SizesService {
  private readonly spacer: number
  /**
   * Used to identify when the header is present on the Court Recording Page
   */
  readonly headerIsRendered = new Subject<boolean>()

  private headerSize: number | undefined

  constructor(
    private readonly windowRef: WindowRefService,
    private readonly store: Store,
    private readonly screenSize: ScreenSizeService,
    private readonly layoutService: LayoutService,
  ) {
    super()
    this.spacer = VERTICAL_SPACER_PX
    combineLatest([
      this.store.select(PlaybackState.isPlaybackPage),
      this.screenSize.size,
      this.headerIsRendered,
      this.layoutService.isLegacyLayout$(),
    ])
      .pipe(distinctUntilChanged())
      .subscribe(([isPlaybackPage, _size, _headerRendered, isLegacyLayout]) => {
        if (isLegacyLayout) {
          this.headerSize = this.windowRef.getElementHeight('header')
          /**
           * On the recording playback page, add the height of the waveform and player bar.
           * This code is reached early when loading the page and the player has not had
           * time to render yet. The top player's height is constant so rather than get the
           * height via DOM, it is hardcoded.
           */
          if (this.headerSize && isPlaybackPage) {
            this.headerSize += WAVEFORM_BAR_OFFSET
          }
        } else {
          // The header is outside of the content area in the new layout, we can assume top: 0 when dealing with the
          this.headerSize = 0
          // But we do want to jump over the waveform/playback bar on the recording pages
          if (_size >= ScreenSize.Medium && isPlaybackPage) {
            this.headerSize += WAVEFORM_BAR_OFFSET
          }
        }
      })
  }

  // Add the +1 as we do not want to render elements whose bottom is exactly at the offset point.
  isElementAtLeastPartiallyInViewportUnderHeader(el: HTMLElement): boolean {
    const currentHeaderSizeWithOffsetPx = this.getCurrentPlaybackHeaderSizePx()
    const offset = currentHeaderSizeWithOffsetPx ? currentHeaderSizeWithOffsetPx : VERTICAL_SPACER_PX
    return this.windowRef.isElementInViewport(el, offset + 1)
  }

  // Full Header since including all playback controls
  //
  // Performance: This is not as slow as i thought it would be.
  // We could cache this value
  getCurrentPlaybackHeaderSizePx(): number | undefined {
    return this.headerSize
  }

  // Full Header since including all playback controls
  getCurrentHeaderSizeWithOffsetPx(): number | undefined {
    const currentPlaybackHeaderSizePx = this.getCurrentPlaybackHeaderSizePx()
    if (currentPlaybackHeaderSizePx === undefined) {
      return undefined
    }
    const bottomPadding = this.spacer * 1.5
    const heightOfThreeLines = LINE_HEIGHT * 3
    return currentPlaybackHeaderSizePx + bottomPadding + heightOfThreeLines
  }
}
