import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SearchRequestType } from '@ftr/contracts/api/search'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { FeatureName } from '@ftr/contracts/type/core'
import { SelectItem } from '@ftr/forms'
import { ArrayUtils, RemoteData, unwrapData } from '@ftr/foundation'
import { FeatureFlagState, LaunchDarklyService } from '@ftr/ui-feature-flags'
import { PlaybackState } from '@ftr/ui-playback'
import { SearchState } from '@ftr/ui-search'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { isEqual } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs'
import { SearchParams } from '~app/pages/search.params'
import { RestrictedPermissionsService } from '~app/services/restricted-permissions/restricted-permissions.service'

/**
 * The designs for search are fairly complicated, so to improve readability, we have separated concerns for search.
 * This service is used to determine:
 * 1. Whether the search bar should be displayed (true/false)
 * 2. The options for the search type dropdown (SearchRequestType[])
 * 3. The default value for the search type (SearchRequestType)
 */
@Injectable({
  providedIn: 'root',
})
export class SearchBarDisplayService {
  readonly searchTypeOptions$ = new BehaviorSubject<SelectItem<SearchRequestType>[]>([])
  readonly defaultSearchType$ = new BehaviorSubject<SearchRequestType | undefined>(undefined)

  // Whether the search bar should be displayed is based on OR of any of the valid conditions
  readonly displaySearchBar$ = new BehaviorSubject(false)

  constructor(
    route: ActivatedRoute,
    private readonly store: Store,
    private readonly restrictedPermissionsService: RestrictedPermissionsService,
    private readonly featureFlagService: LaunchDarklyService,
  ) {
    combineLatest({
      showOrders: this.showOrders(),
      showAllRecordings: this.showAllRecordings(),
      showThisRecording: this.showThisRecording(),
      showThisAudioSegment: this.showThisAudioSegment(),
      showCasesAndHearings: this.showCasesAndHearings(),
      searchTypeQueryParam: route.queryParamMap.pipe(
        map(params => (params.get(SearchParams.SearchType) as SearchRequestType) || undefined),
        distinctUntilChanged(),
      ),
      searchEverythingEnabled: this.store.select(FeatureFlagState.featureFlag('search-everything-by-default')),
      navigationRedesignEnabled: this.store.select(FeatureFlagState.featureFlag('navigation-redesign')),
    })
      .pipe(distinctUntilChanged(isEqual))
      .subscribe(
        ({
          showOrders,
          showAllRecordings,
          showThisRecording,
          showThisAudioSegment,
          searchTypeQueryParam,
          showCasesAndHearings,
          searchEverythingEnabled,
          navigationRedesignEnabled,
        }) => {
          this.searchTypeOptions$.next(
            buildSearchTypeSelectItems(
              showAllRecordings,
              showThisRecording,
              showOrders,
              showThisAudioSegment,
              showCasesAndHearings,
              showCasesAndHearings,
              searchEverythingEnabled,
              navigationRedesignEnabled,
            ),
          )
          this.defaultSearchType$.next(
            searchTypeQueryParam ||
              getDefaultSearchType(showAllRecordings, showThisRecording, showOrders, showThisAudioSegment),
          )
          this.displaySearchBar$.next(
            showAllRecordings || showThisRecording || showThisAudioSegment || showOrders || showCasesAndHearings,
          )
        },
      )
  }

  /**
   * Users with process transcript orders OR process audio orders, can search orders.
   * Note: we currently don't check audio/transcript ordering features are enabled for some reason.
   * @private
   */
  private showOrders(): Observable<boolean> {
    return combineLatest([
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ProcessAudioOrders))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ProcessRealTimeOrders))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ProcessTranscriptOrders))),
    ]).pipe(
      distinctUntilChanged(ArrayUtils.shallowEquals),
      map(
        ([hasProcessAudioOrders, hasProcessRealTimeOrders, hasProcessTranscriptOrders]) =>
          !!(hasProcessAudioOrders || hasProcessRealTimeOrders || hasProcessTranscriptOrders),
      ),
    )
  }

  /**
   * Users with recording playback permissions in a court with audio playback enabled, can see all recording search.
   * All recordings searches the courtroom name, log sheets (if you have permission) and stt (if you have permission)
   * @private
   */
  private showAllRecordings(): Observable<boolean> {
    const isAudioPlaybackFeatureEnabled$ = this.store.select(UserState.currentCourtSystemEnabledFeatures).pipe(
      unwrapData(),
      map(feature => !!feature?.find(f => f.name === FeatureName.AudioPlayback)?.isEnabled),
    )
    return combineLatest([
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.PlaybackRecordings))),
      isAudioPlaybackFeatureEnabled$.pipe(startWith(false)),
    ]).pipe(
      distinctUntilChanged(ArrayUtils.shallowEquals),
      map(
        ([hasPlaybackRecordings, isAudioPlaybackFeatureEnabled]) =>
          !!hasPlaybackRecordings && isAudioPlaybackFeatureEnabled,
      ),
    )
  }

  /**
   * As lawyers have no permissions, we are relying on two checks:
   * 1. They are on the playback page (they have an stt context)
   * 2. The audio playback component being the authority on determining whether the user can search.
   * @private
   */
  private showThisAudioSegment(): Observable<boolean> {
    return combineLatest([
      this.isOnAudioOrderPlaybackPage(),
      this.store.select(SearchState.permittedToSearchThisAudioSegment),
    ]).pipe(
      distinctUntilChanged(ArrayUtils.shallowEquals),
      map(([isPlaybackPage, permittedToSearchAudioSegment]) => isPlaybackPage && !!permittedToSearchAudioSegment),
    )
  }

  /**
   * There are a few checks for this recording too:
   * 1. You must be on the playback page
   * 2. AND If location restrictions are enabled, you must have read stt access to that recording's location
   * 3.     OR you must have read log sheets permissions (currently does not check location restrictions).
   * @private
   */
  private showThisRecording(): Observable<boolean> {
    return combineLatest([
      this.canAccessEntityWithPermissionId(UserGroupPermissionId.ReadStt),
      this.canAccessEntityWithPermissionId(UserGroupPermissionId.ReadLogSheets),
      this.isOnCourtRecordingPlaybackPage(),
    ]).pipe(
      distinctUntilChanged(ArrayUtils.shallowEquals),
      map(([canAccessSpeechToText, canAccessLogSheets, isPlaybackPage]) => {
        return (canAccessSpeechToText || canAccessLogSheets) && isPlaybackPage
      }),
    )
  }

  private showCasesAndHearings(): Observable<boolean> {
    return combineLatest({
      hasPermission: this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.PlaybackHearings))),
      newSearchEnabled: this.featureFlagService.observeFlagChanges<boolean>('search-everything-by-default'),
      annotationsEnabled: this.featureFlagService.observeFlagChanges<boolean>('annotations-first-release'),
    }).pipe(
      map(({ hasPermission, newSearchEnabled, annotationsEnabled }) =>
        Boolean(hasPermission && newSearchEnabled && annotationsEnabled),
      ),
      distinctUntilChanged(),
    )
  }

  /**
   * This does a location based permission check for a particular permission.
   * @param permissionId
   * @private
   */
  private canAccessEntityWithPermissionId(permissionId: UserGroupPermissionId): Observable<boolean> {
    return combineLatest([
      this.store.select(UserState.hasPermissionInCourtSystem).pipe(map(fn => fn(permissionId))),
      this.isOnCourtRecordingPlaybackPage(),
      this.store.select(UserState.currentCourtSystemConfiguration).pipe(unwrapData()),
      this.store.select(PlaybackState.courtRecording).pipe(startWith(RemoteData.success(undefined)), unwrapData()),
    ]).pipe(
      distinctUntilChanged(ArrayUtils.shallowEquals),
      switchMap(([hasPermission, isPlaybackPage, configuration, courtRecordingWithSegments]) => {
        /**
         * Note: this check also returns true if *not* on a court recording page,
         * as PlaybackState.courtRecording should return undefined if not on court playback page.
         */
        const canAccessEntity$ = isPlaybackPage
          ? this.restrictedPermissionsService.canAccessEntity(
              courtRecordingWithSegments?.courtroom?.id,
              'location',
              configuration,
              permissionId,
            )
          : of(false)
        return combineLatest([of(hasPermission), canAccessEntity$])
      }),
      map(([hasPermission, hasRestrictedAccess]) => !!(hasPermission && hasRestrictedAccess)),
      startWith(false),
    )
  }

  /**
   * Note: we are assuming that having an sttContext means we are on the playback page.
   * @private
   */
  private isOnCourtRecordingPlaybackPage(): Observable<boolean> {
    return this.store.select(SearchState.recordingId).pipe(
      distinctUntilChanged(),
      map(d => !!d),
    )
  }

  /**
   * Note: we are assuming that having an sttContext means we are on the playback page.
   * @private
   */
  private isOnAudioOrderPlaybackPage(): Observable<boolean> {
    return this.store.select(SearchState.audioSegmentId).pipe(
      distinctUntilChanged(),
      map(d => !!d),
    )
  }
}

function buildSearchTypeSelectItems(
  shouldShowAllRecordings: boolean,
  shouldShowThisRecording: boolean,
  shouldShowOrders: boolean,
  shouldShowThisAudioSegment: boolean,
  shouldShowCases: boolean,
  shouldShowHearings: boolean,
  searchEverythingEnabled: boolean,
  navigationRedesignEnabled: boolean,
): SelectItem<SearchRequestType>[] {
  const searchItems = []

  if (shouldShowOrders) {
    searchItems.push({ key: 'Orders', value: SearchRequestType.Orders })
  }

  if (shouldShowAllRecordings) {
    searchItems.push({ key: 'All Recordings', value: SearchRequestType.AllRecordings })
  }

  if (shouldShowThisRecording) {
    searchItems.push({ key: 'This Recording', value: SearchRequestType.ThisRecording })
  }

  if (shouldShowThisAudioSegment) {
    searchItems.push({ key: 'This Recording', value: SearchRequestType.ThisAudioSegment })
  }

  if (shouldShowCases) {
    searchItems.push({ key: 'Cases', value: SearchRequestType.Cases })
  }

  if (shouldShowHearings) {
    searchItems.push({ key: 'Hearings', value: SearchRequestType.Hearings })
  }

  /*
   * We only need to check the length in the legacy search, where the dropdown is not shown
   * if the user has access to only on item type.
   */
  return searchEverythingEnabled || navigationRedesignEnabled || searchItems.length > 1 ? searchItems : []
}

function getDefaultSearchType(
  shouldShowAllRecordings: boolean,
  shouldShowThisRecording: boolean,
  shouldShowOrders: boolean,
  shouldShowThisAudioSegment: boolean,
): SearchRequestType | undefined {
  if (shouldShowThisRecording) {
    return SearchRequestType.ThisRecording
  } else if (shouldShowThisAudioSegment) {
    return SearchRequestType.ThisAudioSegment
  } else if (shouldShowOrders) {
    return SearchRequestType.Orders
  } else if (shouldShowAllRecordings) {
    return SearchRequestType.AllRecordings
  }
  return undefined
}
