import { Injectable } from '@angular/core'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { SearchRequestType, SearchResponse, SearchResultType } from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, RemoteData } from '@ftr/foundation'
import { SttContext, matchSttContext } from '@ftr/stt-search'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { SearchService } from '../../services/search/search.service'
import {
  GetSearchResultsAction,
  ResetSearchFocusStateToState,
  SetBackToSearchResultsFullUrlAction,
  SetPermittedToSearchThisAudioSegment,
  SetRecordingCourtSystemAction,
  SetSearchBarInputStateAction,
  SetSearchFocusStateAction,
  SetSearchResultsScrollPositionAction,
  SetSearchScopeCourtSystemAction,
  SetSearchTermAction,
  SetSelectedSearchItemAction,
  SetSttContextAction,
  SetUniqueSearchAction,
} from './search.actions'
import { LastSearchContext, SearchStateModel } from './search.model'

export enum SearchFocusState {
  SEARCH_INPUT = 'searchInput', // current focus is on the search form
  RESULTS_LIST = 'searchList', // current focus is on the search result list
  SEARCH_TYPE = 'searchType', // current focus is on the search type dropdown
  SEARCH_BUTTON = 'searchButton', // current focus is on the 'search' button
  NONE = 'none', // current focus is not on search
}

export enum SearchBarInputState {
  Collapsed = 'collapsed', // Search bar input is not accessible to the user
  Expanded = 'expanded', // Search bar input is open and ready to receive input
}

export function defaultSearchState(): SearchStateModel {
  return {
    searchEntityTypeToNumberOfMatches: new Map<SearchRequestType, number>(),
    // The last set of search results that were fetched
    lastSearchResults: undefined,
    // The last search's details
    lastSearchContext: undefined,
    // 'expanded' when the search bar is visible (i.e. search input is available for use)
    searchBarInputState: SearchBarInputState.Collapsed,
    // The most recent string that was searched for
    lastSearchTerm: undefined,
    // Identifying number for each search performed to ensure searching the same term returns new results
    uniqueSearch: undefined,
    // The search item that was selected by the user
    selectedSearchItem: undefined,
    // How far the search results list was scrolled vertically, used for returning back to that scroll position
    searchResultsScrollPosition: undefined,
    // Whether the search results has focus on the input, list or neither, for keyboard navigation
    searchFocusState: SearchFocusState.NONE,
    // The context of the currently viewed playback page
    sttContext: undefined,
    // The court system in which the current recording is located
    recordingCourtSystem: undefined,
    // The selected court system of the user
    searchScopeCourtSystem: undefined,
    // The URL of the last rendered search results page
    backToSearchResultsFullUrl: undefined,
    // A way for users without court permissions, to search the currently displayed audio segment.
    permittedToSearchThisAudioSegment: undefined,
  }
}

const searchNotAsked = RemoteData.notAsked()

@State<SearchStateModel>({
  name: 'searchState',
  defaults: defaultSearchState(),
})
@Injectable()
export class SearchState {
  constructor(private readonly searchService: SearchService) {}

  @Selector()
  static state(state: SearchStateModel): SearchStateModel {
    return state
  }

  @Selector()
  static searchEntityTypeToNumberOfMatches(state: SearchStateModel): Map<SearchRequestType, number> {
    return state.searchEntityTypeToNumberOfMatches
  }

  @Selector()
  static searchBarInputState(state: SearchStateModel): SearchBarInputState {
    return state.searchBarInputState
  }

  @Selector()
  static uniqueSearch(state: SearchStateModel): number | undefined {
    return state.uniqueSearch
  }

  @Selector()
  static lastSearchTerm(state: SearchStateModel): string | undefined {
    return state.lastSearchTerm
  }

  @Selector()
  static backToSearchResultsFullUrl(state: SearchStateModel): string | undefined {
    return state.backToSearchResultsFullUrl
  }

  @Selector()
  static selectedSearchItem(state: SearchStateModel): SearchResultType | undefined {
    return state.selectedSearchItem
  }

  @Selector()
  static searchResultsScrollPosition(state: SearchStateModel): number | undefined {
    return state.searchResultsScrollPosition
  }

  @Selector()
  static searchResults(state: SearchStateModel): RemoteData<SearchResponse> {
    return state.lastSearchResults || searchNotAsked
  }

  @Selector()
  static lastSearchContext(state: SearchStateModel): LastSearchContext | undefined {
    return state.lastSearchContext
  }

  @Selector()
  static searchFocusState(state: SearchStateModel): SearchFocusState {
    return state.searchFocusState
  }

  @Selector()
  static sttContext(state: SearchStateModel): SttContext | undefined {
    return state.sttContext
  }

  @Selector()
  static audioSegmentId(state: SearchStateModel): Uuid | undefined {
    return state.sttContext === undefined
      ? undefined
      : matchSttContext(state.sttContext, {
          mapAudioOrder: ({ audioSegmentId }) => audioSegmentId,
          mapRecording: () => undefined,
          mapSharedRecording: ({ audioSegmentId }) => audioSegmentId,
        })
  }

  @Selector()
  static recordingId(state: SearchStateModel): Uuid | undefined {
    return state.sttContext === undefined
      ? undefined
      : matchSttContext(state.sttContext, {
          mapAudioOrder: () => undefined,
          mapRecording: ({ recordingId }) => recordingId,
          mapSharedRecording: () => undefined,
        })
  }

  @Selector()
  static recordingCourtSystem(state: SearchStateModel): CourtSystem | undefined {
    return state.recordingCourtSystem
  }

  @Selector()
  static searchScopeCourtSystem(state: SearchStateModel): CourtSystem | undefined {
    return state.searchScopeCourtSystem
  }

  @Selector()
  static permittedToSearchThisAudioSegment(state: SearchStateModel): boolean | undefined {
    return state.permittedToSearchThisAudioSegment
  }

  @Action(SetSearchBarInputStateAction)
  setSearchBarInputState(
    { patchState }: StateContext<SearchStateModel>,
    { searchBarInputState }: SetSearchBarInputStateAction,
  ): void {
    patchState({ searchBarInputState })
  }

  @Action(SetUniqueSearchAction)
  setUniqueSearch({ patchState }: StateContext<SearchStateModel>, { uniqueSearch }: SetUniqueSearchAction): void {
    patchState({ uniqueSearch })
  }

  @Action(SetSearchTermAction)
  setLastSearchTerm(
    { getState, patchState }: StateContext<SearchStateModel>,
    { lastSearchTerm }: SetSearchTermAction,
  ): void {
    // If we do not patch 'not asked' into the state at the same time as a new search term, when searching for an
    // order reference, it doesn't redirect properly.
    // I.E. Without setting lastSearchResults to notAsked, searching TS9523 will load that order, then
    // searching TS9520 will load TS9523
    // When navigating back to search results (via the back-to-search-results component) the lastSearchResults store
    // value is used to populate the search results list, and in this case we don't want to clear lastSearchResults
    patchState(
      getState().searchResultsScrollPosition !== undefined
        ? { lastSearchTerm }
        : { lastSearchTerm, lastSearchResults: searchNotAsked },
    )
  }

  @Action(SetBackToSearchResultsFullUrlAction)
  setBackToSearchResultsParamsAction(
    { patchState }: StateContext<SearchStateModel>,
    { backToSearchResultsFullUrl }: SetBackToSearchResultsFullUrlAction,
  ): void {
    patchState({ backToSearchResultsFullUrl })
  }

  @Action(SetSelectedSearchItemAction)
  setSelectedSearchItemAction(
    { patchState }: StateContext<SearchStateModel>,
    { selectedSearchItem }: SetSelectedSearchItemAction,
  ): void {
    patchState({ selectedSearchItem })
  }

  @Action(SetSearchResultsScrollPositionAction)
  setSearchResultsScrollPositionAction(
    { patchState }: StateContext<SearchStateModel>,
    { searchResultsScrollPosition }: SetSearchResultsScrollPositionAction,
  ): void {
    patchState({ searchResultsScrollPosition })
  }

  @Action(GetSearchResultsAction, { cancelUncompleted: true })
  getSearchResults(
    { patchState }: StateContext<SearchStateModel>,
    { searchTerm, page, searchType, resourceId, pageSize, courtSystemId }: GetSearchResultsAction,
  ): ApiResult<SearchResponse> {
    // If there's no search term...
    if (!searchTerm) {
      return ApiResult.notAsked()
    }

    return this.searchService.doSearch(searchTerm, searchType, courtSystemId, page, resourceId, pageSize).pipe(
      tap((lastSearchResults: RemoteData<SearchResponse>) => {
        patchState({
          lastSearchResults,
          lastSearchContext: {
            searchTerm,
            searchType,
            resourceId,
          },
        })
      }),
    )
  }

  @Action(SetSearchFocusStateAction)
  setSearchFocusState(
    { patchState }: StateContext<SearchStateModel>,
    { searchFocusState }: SetSearchFocusStateAction,
  ): void {
    patchState({ searchFocusState })
  }

  @Action(SetSttContextAction)
  setSttContext({ patchState }: StateContext<SearchStateModel>, { sttContext }: SetSttContextAction): void {
    patchState({ sttContext })
  }

  @Action(SetRecordingCourtSystemAction)
  setRecordingCourtSystem(
    { patchState }: StateContext<SearchStateModel>,
    { recordingCourtSystem }: SetRecordingCourtSystemAction,
  ): void {
    patchState({ recordingCourtSystem })
  }

  @Action(SetSearchScopeCourtSystemAction)
  setSearchScopeCourtSystem(
    { patchState }: StateContext<SearchStateModel>,
    { searchScopeCourtSystem }: SetSearchScopeCourtSystemAction,
  ): void {
    patchState({ searchScopeCourtSystem })
  }

  @Action(ResetSearchFocusStateToState)
  resetSearchFocusStateToInput(
    { patchState }: StateContext<SearchStateModel>,
    { searchFocusState }: ResetSearchFocusStateToState,
  ): void {
    patchState({ searchFocusState: SearchFocusState.NONE })
    patchState({ searchFocusState })
  }

  @Action(SetPermittedToSearchThisAudioSegment)
  setPermittedToSearchThisAudioSegment(
    { patchState }: StateContext<SearchStateModel>,
    { permittedToSearchThisAudioSegment }: SetPermittedToSearchThisAudioSegment,
  ): void {
    patchState({ permittedToSearchThisAudioSegment })
  }
}
