import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, filter, first } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SearchOverlayService {
  visible = new BehaviorSubject<boolean>(false)

  /**
   * Use in debounce...
   *
   * eg...
   *
   * something.pipe(debounce(() => awaitHide))
   *
   * Necessary for scrolling places after the search overlay has closed
   */
  awaitHide(): Observable<boolean> {
    return this.visible.pipe(
      filter(v => !v),
      first(),
    )
  }
}
