import { Component, Input, OnInit } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { IndexedOrder, IndexedOrderPortionToTranscribe } from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField } from '@ftr/contracts/type/core'
import { LineItemType } from '@ftr/contracts/type/order'
import { DateFormat, toMoment } from '@ftr/foundation'
import { HighlightField } from '~app/features/search-legacy/search-results/search-legacy-results-item/search-legacy-results-item.component'

@Component({
  selector: 'ftr-search-legacy-results-orders-item',
  templateUrl: './search-legacy-results-orders-item.component.html',
  styleUrls: ['./search-legacy-results-orders-item.component.css'],
})
export class SearchResultsOrdersItemComponent implements OnInit {
  @Input() hasLink: boolean
  @Input() searchResult: IndexedOrder
  @Input() getValue: Function
  @Input() courtSystemConfig: PrivateConfiguration
  @Input() highlight: ElasticsearchHighlightField
  @Input() dynamicHighlight: HighlightField<any> | undefined

  portionToTranscribe: IndexedOrderPortionToTranscribe | undefined
  fallbackLineItemType = LineItemType.CertifiedTranscript

  readonly dateFormat = DateFormat

  ngOnInit(): void {
    this.portionToTranscribe = this.getHearingTimes(this.searchResult.portionsToTranscribe)
  }

  isToday(date: string, currDate = new Date()): boolean {
    return toMoment(date).moment.isSame(currDate, 'day')
  }

  getHearingTimes(portions: IndexedOrderPortionToTranscribe[]): IndexedOrderPortionToTranscribe | undefined {
    if (!portions || !portions.length) {
      return undefined
    }

    const { 0: first, [portions.length - 1]: last } = portions

    return {
      startTime: first.startTime,
      endTime: last.endTime,
    }
  }
}
