/* eslint-disable max-lines */
import { Segment } from '@ftr/api-core'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { JsJodaUtils, isNotNullOrUndefined, iterateAllSorted, iterateReversed, mapIt, minBy } from '@ftr/foundation'
import { Duration, LocalDateTime, LocalTime, convert } from '@js-joda/core'
import { isEqual, uniq } from 'lodash-es'
import DataIntervalTree from 'node-interval-tree'
import { RealTimeSttRemarkWithMetadataBuilder } from '../builders'
import { HearingSectionModel } from '../store'
import { isTimeRangeWithinTimeRanges, isTimeWithinTimeRanges } from '../utils'
import { RealTimeSttHearingMarker } from './real-time-stt-hearing-marker'
import { RealTimeSttPause, RealTimeSttPauseConfiguration, pausesEqual, shouldIgnorePause } from './real-time-stt-pause'
import { RealTimeSttRemark } from './real-time-stt-remark'
import { RealTimeSearchHighlights, RealTimeSttRemarkWithMetadata } from './real-time-stt-remark-with-metadata'
import { RealTimeSttSealedSegment } from './real-time-stt-sealed-segment'
import { RealTimeSttSealingMarker } from './real-time-stt-sealing-marker'
import { RealTimeSttSessionMarker } from './real-time-stt-session-marker'
import {
  RealTimeSttTranscriptDivider,
  RealTimeSttTranscriptDividerData,
  toTranscriptDivider,
} from './real-time-stt-transcript-divider'
import {
  RealTimeSttMetadata,
  RealTimeSttTranscriptElement,
  RealTimeSttTranscriptElementWithMetadata,
  isHearingMarker,
  isPause,
  isSealingMarker,
  isSessionMarker,
  isTranscriptDivider,
} from './real-time-stt-transcript-element'
import { compareTranscriptElements } from './real-time-stt-transcript-elements-comparator'

type ReadonlyDataIntervalTree<T> = Omit<DataIntervalTree<T>, 'insert' | 'remove'>
type ReadonlyMap<K, V> = Omit<Map<K, V>, 'set' | 'clear' | 'delete'>

export interface RealTimeSttSegmentPauseMetadata {
  readonly configuration: RealTimeSttPauseConfiguration
  readonly firstPauseBreakingElement: PauseBreakingTranscriptElement | undefined
  readonly lastPauseBreakingElement: PauseBreakingTranscriptElement | undefined
}

type RealTimeSttSegmentPauseMetadataCalculationContainer = RealTimeSttSegmentPauseMetadata & {
  readonly pausesBetweenRemarksMutable: DataIntervalTree<RealTimeSttPause>
}

// Pauses are calculated between the following transcript elements
export type PauseBreakingTranscriptElement =
  | RealTimeSttRemarkWithMetadata
  | RealTimeSttTranscriptDivider
  | RealTimeSttSessionMarker
  | RealTimeSttSealingMarker
  | RealTimeSttHearingMarker

/**
 * A RealTimeSttSegment is an immutable object and is the data that is stored in the RealTimeSttSegmentMap. Remarks are
 * stored in a few structures to optimise the different ways that we query them. We use an interval tree for querying
 * based on time, and a simple array for in order traversal.
 */
export class RealTimeSttSegment extends Segment {
  /**
   * @see createEmpty
   * @see rebuildWithRemarks
   */
  private constructor(
    override readonly high: number,
    override readonly low: number,
    override readonly id: string,
    private readonly remarksTree: ReadonlyDataIntervalTree<RealTimeSttRemarkWithMetadata>,
    private readonly liveResultToRemarkMap: ReadonlyMap<string, readonly RealTimeSttRemarkWithMetadata[]>,
    readonly lastRemark: RealTimeSttRemarkWithMetadata | undefined,
    readonly overflows: boolean,
    readonly hasRemarkContent: boolean,
    readonly pauseMetadata: RealTimeSttSegmentPauseMetadata,
    readonly leadingPause: RealTimeSttPause | undefined,
    private readonly pausesBetweenRemarks: ReadonlyDataIntervalTree<RealTimeSttPause>,
    readonly sealedSegments: readonly RealTimeSttSealedSegment[],
    readonly searchHighlights: RealTimeSearchHighlights,
    readonly transcriptDividers: readonly RealTimeSttTranscriptDivider[],
    readonly sessionMarkers: readonly RealTimeSttSessionMarker[],
    readonly sealingMarkers: readonly RealTimeSttSealingMarker[],
    readonly hearings: readonly HearingSectionModel[],
    readonly hearingMarkers: readonly RealTimeSttHearingMarker[],
    readonly allSealingMarkers: readonly RealTimeSttSealingMarker[],
  ) {
    super(high, low, id)
  }

  static createEmpty(
    id: string,
    low: number,
    high: number,
    pauseConfiguration: RealTimeSttPauseConfiguration,
    searchHighlights: RealTimeSearchHighlights,
  ): RealTimeSttSegment {
    return new RealTimeSttSegment(
      high,
      low,
      id,
      new DataIntervalTree<RealTimeSttRemarkWithMetadata>(),
      new Map(),
      undefined,
      false,
      false,
      {
        configuration: pauseConfiguration,
        firstPauseBreakingElement: undefined,
        lastPauseBreakingElement: undefined,
      },
      undefined,
      new DataIntervalTree<RealTimeSttPause>(),
      [],
      searchHighlights,
      [],
      [],
      [],
      [],
      [],
      [],
    )
  }

  rebuildWithRemarks(remarks: RealTimeSttRemarkWithMetadata[]): RealTimeSttSegment {
    const allRemarksSorted = RealTimeSttRemarkWithMetadataBuilder.regenerateMetadata(
      remarks.sort(compareTranscriptElements),
      this.searchHighlights,
    )

    const sortedPauseBreakingTranscriptElements = [
      ...allRemarksSorted,
      ...this.transcriptDividers,
      ...this.hearingMarkers,
      ...this.sessionMarkers,
      ...this.sealingMarkers,
    ].sort(compareTranscriptElements)

    const liveResultToRemarkMap = new Map<string, RealTimeSttRemarkWithMetadata[]>()
    const intervalTree = new DataIntervalTree<RealTimeSttRemarkWithMetadata>()
    let lastRemark: RealTimeSttRemarkWithMetadata | undefined = undefined
    let hasRemarkContent = false

    const sealedTimeRanges = uniq(this.allSealingMarkers.map(sm => sm.sealedTimeRange))

    let pauseMetadata: RealTimeSttSegmentPauseMetadataCalculationContainer = {
      configuration: this.pauseMetadata.configuration,
      firstPauseBreakingElement: undefined,
      lastPauseBreakingElement: undefined,
      pausesBetweenRemarksMutable: new DataIntervalTree<RealTimeSttPause>(),
    }
    for (const pauseBreakingTranscriptElement of sortedPauseBreakingTranscriptElements) {
      if (pauseBreakingTranscriptElement.type === 'Remark') {
        intervalTree.insert(
          convert(pauseBreakingTranscriptElement.startTime).toEpochMilli(),
          convert(pauseBreakingTranscriptElement.endTime).toEpochMilli(),
          pauseBreakingTranscriptElement,
        )

        if (pauseBreakingTranscriptElement.liveResult) {
          const liveRemarksWithId = liveResultToRemarkMap.get(pauseBreakingTranscriptElement.liveResult.resultId) ?? []
          liveRemarksWithId.push(pauseBreakingTranscriptElement)
          liveResultToRemarkMap.set(pauseBreakingTranscriptElement.liveResult.resultId, liveRemarksWithId)
        }
        if (!lastRemark || pauseBreakingTranscriptElement.endTime.isAfter(lastRemark.endTime)) {
          lastRemark = pauseBreakingTranscriptElement
        }
        hasRemarkContent = hasRemarkContent || pauseBreakingTranscriptElement.content.length > 0
      }

      pauseMetadata = RealTimeSttSegment.calculatePauseMetadataForRemark(
        pauseMetadata,
        pauseBreakingTranscriptElement,
        sealedTimeRanges,
      )
    }
    const overflows = lastRemark ? convert(lastRemark.endTime).toEpochMilli() > this.high : false

    return new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      intervalTree,
      liveResultToRemarkMap,
      lastRemark,
      overflows,
      hasRemarkContent,
      {
        configuration: pauseMetadata.configuration,
        firstPauseBreakingElement: pauseMetadata.firstPauseBreakingElement,
        lastPauseBreakingElement: pauseMetadata.lastPauseBreakingElement,
      },
      this.leadingPause,
      pauseMetadata.pausesBetweenRemarksMutable,
      this.sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )
  }

  withRemovedRemark(id: string): RealTimeSttSegment {
    return this.rebuildWithRemarks([...this.remarksTree.inOrder()].map(x => x.data).filter(remark => remark.id !== id))
  }

  /**
   * TODO OR-2212 remove when removing `release-sealed-segments` flag
   * @deprecated will be removed with the removal of 'release-sealed-segments' flag
   */
  withSealedSegments(sealedSegments: readonly RealTimeSttRemark[]): RealTimeSttSegment {
    return new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )
  }

  static calculatePauseMetadataForRemark(
    {
      configuration,
      firstPauseBreakingElement,
      lastPauseBreakingElement,
      pausesBetweenRemarksMutable,
    }: RealTimeSttSegmentPauseMetadataCalculationContainer,
    pauseBreakingTranscriptElement: PauseBreakingTranscriptElement,
    sealedTimeRanges: LocalTimeRange[],
  ): RealTimeSttSegmentPauseMetadataCalculationContainer {
    const isRemark = pauseBreakingTranscriptElement.type === 'Remark'

    if (!isRemark || isRemarkUsedForPause(pauseBreakingTranscriptElement, configuration)) {
      if (
        !firstPauseBreakingElement ||
        compareTranscriptElements(firstPauseBreakingElement, pauseBreakingTranscriptElement) > 0
      ) {
        firstPauseBreakingElement = pauseBreakingTranscriptElement
      }
      if (
        lastPauseBreakingElement !== undefined &&
        pauseBreakingTranscriptElement.startTime.isAfter(lastPauseBreakingElement.endTime)
      ) {
        const pauseBetweenRemarks = RealTimeSttPause.betweenPauseBreakingElements(
          lastPauseBreakingElement,
          pauseBreakingTranscriptElement,
        )

        const ignorePause = shouldIgnorePause(
          configuration,
          pauseBetweenRemarks,
          lastPauseBreakingElement,
          pauseBreakingTranscriptElement,
          sealedTimeRanges,
        )
        if (!ignorePause) {
          pausesBetweenRemarksMutable.insert(
            convert(pauseBetweenRemarks.startTime).toEpochMilli(),
            convert(pauseBetweenRemarks.endTime).toEpochMilli(),
            pauseBetweenRemarks,
          )
        }
      }
      if (
        lastPauseBreakingElement === undefined ||
        compareTranscriptElements(pauseBreakingTranscriptElement, lastPauseBreakingElement, 'endTime') > 0
      ) {
        lastPauseBreakingElement = pauseBreakingTranscriptElement
      }
    }

    return {
      configuration,
      firstPauseBreakingElement,
      lastPauseBreakingElement,
      pausesBetweenRemarksMutable,
    }
  }

  get numRemarks(): number {
    return this.remarksTree.count
  }

  getLastNTranscriptElements(numRemarks: number): RealTimeSttTranscriptElementWithMetadata[] {
    const iter = this.getReversedTranscriptElements()
    let current = iter.next()
    let i = 0
    const result: RealTimeSttTranscriptElementWithMetadata[] = []
    while (!current.done && i < numRemarks) {
      result.push(current.value)
      current = iter.next()
      i++
    }
    // The current result is as if we traversed the remarks/pauses backwards,
    // however we want the result to br in order, just taking the lastN - so reverse
    return result.reverse()
  }

  rebuildWithReplacedOrAddedRemarks(newRemarks: RealTimeSttRemarkWithMetadata[]): RealTimeSttSegment {
    const updatedRemarks = Array.from(this.remarksTree.inOrder())
      .map(x => x.data)
      // Remove any previous remarks that will be replaced by newer ones
      .filter(remark => !newRemarks.find(newRemark => remark.id === newRemark.id))
      .concat(newRemarks)
    return this.rebuildWithRemarks(updatedRemarks)
  }

  withLeadingPause(leadingPause: RealTimeSttPause | undefined): RealTimeSttSegment {
    if (leadingPause === this.leadingPause || pausesEqual(leadingPause, this.leadingPause)) {
      return this
    }
    return new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )
  }

  getRemarksDuring(start: LocalDateTime, end: LocalDateTime): RealTimeSttRemarkWithMetadata[] {
    const startEpoch = convert(start).toEpochMilli()
    const endEpoch = convert(end).toEpochMilli()
    return this.remarksTree.search(startEpoch, endEpoch)
  }

  getRemarksAt(time: LocalDateTime): RealTimeSttRemarkWithMetadata[] {
    const epochTime = convert(time).toEpochMilli()
    return this.remarksTree.search(epochTime, epochTime)
  }

  getPauseAt(time: LocalDateTime): RealTimeSttPause | undefined {
    const epochTime = convert(time).toEpochMilli()
    const pausesBetweenRemarks = this.pausesBetweenRemarks.search(epochTime, epochTime)

    // There can only ever be one pause at a given time
    if (pausesBetweenRemarks.length) {
      return pausesBetweenRemarks[0]
    }

    if (this.leadingPause && pauseIntersectsWithTime(this.leadingPause, time)) {
      return this.leadingPause
    }

    return undefined
  }

  rebuildWithUpdatedSearchHighlights(searchHighlights: RealTimeSearchHighlights): RealTimeSttSegment {
    const withUpdatedSearchHighlights = new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )

    return withUpdatedSearchHighlights.rebuildWithRemarks([...this.remarksTree.inOrder()].map(x => x.data))
  }

  /**
   * Rebuilds the segment with the given transcript dividers for the entire transcript
   * @deprecated to be replaced with rebuildWithSessionMarkers()
   */
  rebuildWithTranscriptDividers(allTranscriptDividers: RealTimeSttTranscriptDividerData[]): RealTimeSttSegment {
    const dividersRelevantToSegment: RealTimeSttTranscriptDivider[] = allTranscriptDividers
      .filter(
        divider =>
          (divider.time.isEqual(this.getLocalStart()) || divider.time.isAfter(this.getLocalStart())) &&
          divider.time.isBefore(this.getLocalEnd()),
      )
      .map(data => toTranscriptDivider(data))
      .sort(compareTranscriptElements)

    if (isEqual(dividersRelevantToSegment, this.transcriptDividers)) {
      return this
    }

    const withUpdatedTranscriptDividers = new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      this.searchHighlights,
      dividersRelevantToSegment,
      this.sessionMarkers,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )

    return withUpdatedTranscriptDividers.rebuildWithRemarks([...this.remarksTree.inOrder()].map(x => x.data))
  }

  /**
   * Rebuilds the segment with the given session markers for the entire transcript
   */
  rebuildWithSessionMarkers(allSessionMarkers: RealTimeSttSessionMarker[]): RealTimeSttSegment {
    const markersRelevantToSegment: RealTimeSttSessionMarker[] = allSessionMarkers
      .filter(marker => this.markerIsWithinSegmentBounds(marker))
      .sort(compareTranscriptElements)

    if (isEqual(markersRelevantToSegment, this.sessionMarkers)) {
      return this
    }

    const withUpdatedSessionMarkers = new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      markersRelevantToSegment,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )

    return withUpdatedSessionMarkers.rebuildWithRemarks([...this.remarksTree.inOrder()].map(x => x.data))
  }

  /**
   * Rebuilds the segment with the given hearing markers for the entire transcript.
   * We intentionally save hearing markers relevant to the segment and all hearings regardless of the segment.
   * Relevant hearing markers are used to display and sort transcript elements within the current segment.
   * All hearings are used to mark remarks with isHearing for styling, even if a hearing spans across multiple segments.
   */
  rebuildWithHearingMarkers(
    hearingMarkers: RealTimeSttHearingMarker[],
    hearings: HearingSectionModel[],
  ): RealTimeSttSegment {
    if (isEqual(hearings, this.hearings)) {
      return this
    }

    const hearingMarkersRelevantToSegment: RealTimeSttHearingMarker[] = hearingMarkers
      .filter(marker => this.markerIsWithinSegmentBounds(marker))
      // Hide the end marker of an ongoing hearing
      .filter(marker => !(marker.hearingAnnotation.showAsOngoing && marker.dividerType === 'hearingEnd'))
      .sort(compareTranscriptElements)

    const withUpdatedHearingElements = new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      this.sealingMarkers,
      hearings,
      hearingMarkersRelevantToSegment,
      this.allSealingMarkers,
    )

    return withUpdatedHearingElements.rebuildWithRemarks(
      [...this.remarksTree.inOrder()].map(x => ({
        ...x.data,
        isHearing: shouldMarkAsHearing(x.data, hearings),
      })),
    )
  }

  rebuildWithSealingMarkers(allSealingMarkers: RealTimeSttSealingMarker[]): RealTimeSttSegment {
    if (isEqual(allSealingMarkers, this.allSealingMarkers)) {
      return this
    }

    const markersRelevantToSegment: RealTimeSttSealingMarker[] = allSealingMarkers
      .filter(marker => this.markerIsWithinSegmentBounds(marker))
      .sort(compareTranscriptElements)

    const withUpdatedSealingMarkers = new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      this.pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      markersRelevantToSegment,
      this.hearings,
      this.hearingMarkers,
      allSealingMarkers,
    )

    return withUpdatedSealingMarkers.rebuildWithRemarks(
      [...this.remarksTree.inOrder()].map(x => ({
        ...x.data,
        isSealed: shouldMarkAsSealed(x.data, allSealingMarkers),
      })),
    )
  }

  rebuildWithPauseConfiguration(pauseConfiguration: RealTimeSttPauseConfiguration): RealTimeSttSegment {
    if (isEqual(pauseConfiguration, this.pauseMetadata.configuration)) {
      return this
    }

    const pauseMetadata = {
      configuration: pauseConfiguration,
      firstPauseBreakingElement: this.pauseMetadata.firstPauseBreakingElement,
      lastPauseBreakingElement: this.pauseMetadata.lastPauseBreakingElement,
    }

    return new RealTimeSttSegment(
      this.high,
      this.low,
      this.id,
      this.remarksTree,
      this.liveResultToRemarkMap,
      this.lastRemark,
      this.overflows,
      this.hasRemarkContent,
      pauseMetadata,
      this.leadingPause,
      this.pausesBetweenRemarks,
      this.sealedSegments,
      this.searchHighlights,
      this.transcriptDividers,
      this.sessionMarkers,
      this.sealingMarkers,
      this.hearings,
      this.hearingMarkers,
      this.allSealingMarkers,
    )
  }

  rebuildWithSealedSegments(sealedSegmentRemarks: RealTimeSttRemark[]): RealTimeSttSegment {
    if (isEqual(sealedSegmentRemarks, this.sealedSegments)) {
      return this
    }

    const sealedRemarksRelevantToSegment = sealedSegmentRemarks.filter(r =>
      JsJodaUtils.isDuring(this.getLocalStart(), this.getLocalEnd(), r.startTime),
    )

    const updatedSegment = this.withSealedSegments(sealedSegmentRemarks)

    return updatedSegment.rebuildWithReplacedOrAddedRemarks(
      RealTimeSttRemarkWithMetadataBuilder.buildFromRemarks(
        this.id,
        sealedRemarksRelevantToSegment,
        this.searchHighlights,
      ),
    )
  }

  *getTranscriptElements(): IterableIterator<RealTimeSttTranscriptElementWithMetadata> {
    if (this.leadingPause) {
      yield addMetadata(
        this.leadingPause,
        this.shouldBeSealed(this.leadingPause),
        this.id,
        shouldMarkAsHearing(this.leadingPause, this.hearings),
      )
    }
    const sortedTranscriptElements = iterateAllSorted<RealTimeSttTranscriptElement>(
      [
        mapIt(this.remarksTree.inOrder(), x => x.data),
        mapIt(this.pausesBetweenRemarks.inOrder(), x => x.data),
        this.hearingMarkers[Symbol.iterator](),
        this.transcriptDividers[Symbol.iterator](),
        this.sessionMarkers[Symbol.iterator](),
        this.sealingMarkers[Symbol.iterator](),
      ],
      compareTranscriptElements,
    )
    for (const item of sortedTranscriptElements) {
      if (isRemarkWithMetadata(item)) {
        yield item
      } else {
        yield addMetadata(item, this.shouldBeSealed(item), this.id, shouldMarkAsHearing(item, this.hearings))
      }
    }
  }

  *getReversedTranscriptElements(): IterableIterator<RealTimeSttTranscriptElementWithMetadata> {
    const sortedTranscriptElements = iterateAllSorted<RealTimeSttTranscriptElement>(
      [
        mapIt(this.remarksTree.reverseInOrder(), x => x.data),
        mapIt(this.pausesBetweenRemarks.reverseInOrder(), x => x.data),
        iterateReversed(this.hearingMarkers),
        iterateReversed(this.transcriptDividers),
        iterateReversed(this.sessionMarkers),
      ],
      (header, divider) => (header.startTime.isBefore(divider.startTime) ? 1 : -1),
    )
    for (const item of sortedTranscriptElements) {
      if (isRemarkWithMetadata(item)) {
        yield item
      } else {
        yield addMetadata(item, this.shouldBeSealed(item), this.id, shouldMarkAsHearing(item, this.hearings))
      }
    }
    if (this.leadingPause) {
      yield addMetadata(
        this.leadingPause,
        this.shouldBeSealed(this.leadingPause),
        this.id,
        shouldMarkAsHearing(this.leadingPause, this.hearings),
      )
    }
  }

  getClosestTranscriptElement(
    time: LocalDateTime,
    sequence?: 'before' | 'after',
  ): RealTimeSttTranscriptElementWithMetadata | undefined {
    const diffBetweenElementAndTime = (element: RealTimeSttTranscriptElementWithMetadata): number => {
      if (sequence === 'before' && element.startTime.isAfter(time)) return LocalTime.MAX.toNanoOfDay()
      if (sequence === 'after' && element.startTime.isBefore(time)) return LocalTime.MAX.toNanoOfDay()
      return Duration.between(element.startTime, time).abs().toMillis()
    }

    if (time.isBefore(this.getLocalStart()) || time.isEqual(this.getLocalStart())) {
      if (sequence === 'before') {
        return undefined
      }
      const firstRemark: RealTimeSttRemarkWithMetadata | undefined = this.remarksTree.inOrder().next().value?.data
      return minBy(
        [
          firstRemark,
          this.leadingPause
            ? addMetadata(
                this.leadingPause,
                this.shouldBeSealed(this.leadingPause),
                this.id,
                shouldMarkAsHearing(this.leadingPause, this.hearings),
              )
            : undefined,
        ].filter(isNotNullOrUndefined),
        diffBetweenElementAndTime,
      )
    }

    if (time.isAfter(this.getLocalEnd())) {
      if (sequence === 'after') {
        return undefined
      }
      return this.remarksTree.reverseInOrder().next().value?.data
    }

    const closestElement = minBy(this.getTranscriptElements(), diffBetweenElementAndTime)
    if (closestElement && sequence === 'before' && closestElement.startTime.isAfter(time)) return undefined
    if (closestElement && sequence === 'after' && closestElement.startTime.isBefore(time)) return undefined
    return closestElement
  }

  private markerIsWithinSegmentBounds(marker: RealTimeSttTranscriptElement): boolean {
    return (
      (marker.startTime.isEqual(this.getLocalStart()) || marker.startTime.isAfter(this.getLocalStart())) &&
      marker.startTime.isBefore(this.getLocalEnd())
    )
  }

  private shouldBeSealed(item: RealTimeSttTranscriptElement): boolean {
    // sealedSegments property is only populated when `release-sealed-segments` is off, the sealedTimeRanges property is
    // only populated when the flag is on. This method will be deleted once the flag is removed.
    if (this.sealedSegments.length) {
      return isWithinSealedSegments(item.startTime, this.sealedSegments)
    }
    return shouldMarkAsSealed(item, this.allSealingMarkers)
  }
}

/**
 * Checks start/end inclusive to match behaviour of the 'search' in the interval tree. Useful when checking if the
 * leading pause intersects with a time.
 * @param pause RealTimeSttPause to check if time intersects with
 * @param time The time which to check if the pause intersects with
 */
function pauseIntersectsWithTime(pause: RealTimeSttPause, time: LocalDateTime): boolean {
  const timeIsInsideLeadingPause = pause.startTime.isBefore(time) && pause.endTime.isAfter(time)
  const timeEqualsStartOrEndOfLeadingPause = pause.startTime.isEqual(time) || pause.endTime.isEqual(time)

  return timeIsInsideLeadingPause || timeEqualsStartOrEndOfLeadingPause
}

/**
 * TODO OR-2212 remove when removing `release-sealed-segments` flag
 * @deprecated will be removed with the removal of 'release-sealed-segments' flag
 */
function isRemarkUsedForPause(
  remark: RealTimeSttRemarkWithMetadata,
  pauseConfiguration: RealTimeSttPauseConfiguration,
): boolean {
  if (pauseConfiguration.sealedSegmentMarkerBehavior === 'TreatAsRemark') {
    return true
  }
  return !remark.isSealedContentMarker
}

function isRemarkWithMetadata(item: RealTimeSttTranscriptElement): item is RealTimeSttRemarkWithMetadata {
  return 'type' in item && item.type === 'Remark'
}

function isWithinSealedSegments(time: LocalDateTime, sealedSegments: readonly RealTimeSttSealedSegment[]): boolean {
  return sealedSegments.some(segment => segment.startTime.isBefore(time) && segment.endTime.isAfter(time))
}

function shouldMarkAsSealed(
  item: RealTimeSttTranscriptElement,
  sealingMarkers: readonly RealTimeSttSealingMarker[],
): boolean {
  // Avoid marking sealing and hearing markers
  if (isSealingMarker(item) || isHearingMarker(item)) {
    return false
  }

  const sealedTimeRanges = sealingMarkers.map(s => s.sealedTimeRange)

  return shouldMarkItemWithinTimeRanges(item, sealedTimeRanges)
}

function shouldMarkAsHearing(item: RealTimeSttTranscriptElement, hearings: readonly HearingSectionModel[]): boolean {
  // Avoid marking hearing markers
  if (isHearingMarker(item)) {
    return false
  }

  const hearingTimeRanges = hearings.map(h => new LocalTimeRange(h.startTime, h.endTime))

  if (isSealingMarker(item)) {
    // Mark sealing markers if sealed segment is within a hearing (e.g. for sealing markers
    // we check if sealed time range is within a hearing time range, not just a start time)
    return isTimeRangeWithinTimeRanges(item.sealedTimeRange, hearingTimeRanges)
  }

  return shouldMarkItemWithinTimeRanges(item, hearingTimeRanges)
}

function shouldMarkItemWithinTimeRanges(
  item: RealTimeSttTranscriptElement,
  timeRanges: readonly LocalTimeRange[],
): boolean {
  // Avoid marking session markers
  if (isSessionMarker(item) || isTranscriptDivider(item)) {
    return false
  }

  // Avoid marking breaks between sessions
  if (isPause(item) && item.pauseType === 'session-break') {
    return false
  }

  return isTimeWithinTimeRanges(item.startTime.toLocalTime(), timeRanges)
}

function addMetadata<T extends RealTimeSttTranscriptElement>(
  item: T,
  isSealed: boolean,
  segmentId: string,
  isHearing: boolean,
): T & RealTimeSttMetadata {
  return { ...item, isSealed, segmentId, isHearing }
}
