import { Injectable } from '@angular/core'
import {
  FullApiClientConfiguration,
  MultiRegionApiConfiguration,
  ServerConfiguration,
  SocketConfiguration,
} from '@ftr/api-shared'
import { LaunchDarklyConfig } from '@ftr/ui-feature-flags'
import { LoggingConfiguration, ObservabilityConfiguration } from '@ftr/ui-observability'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { WindowRef } from '~app/services/window/window-ref.service'
import { environment } from '~environments/environment'
import { WindowConfig } from '~environments/web-environment'

const API_VERSION_SUFFIX = '/v1'
const API_HEALTH_SUFFIX = '/health'

/**
 * Provides configuration values for the web app. Currently this
 * assumes configuration is stored against window.config, however
 * this could move to the server and be downloaded on app startup.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationService
  implements FullApiClientConfiguration, LaunchDarklyConfig, ObservabilityConfiguration
{
  socket: SocketConfiguration
  server: ServerConfiguration
  annotationsApi: MultiRegionApiConfiguration
  regionalApi: MultiRegionApiConfiguration
  restApi: MultiRegionApiConfiguration
  courtUploaderServer: MultiRegionApiConfiguration
  digitalJusticeApi: MultiRegionApiConfiguration
  digitalJusticeWeb: MultiRegionApiConfiguration
  auditApi: MultiRegionApiConfiguration
  logging: LoggingConfiguration
  userPool: CognitoUserPool
  cognitoAuthUrl: string
  cognitoLogoutUrl: string
  microsoftAuthAppId: string
  microsoftAuthUrl: string
  mockAuthUrl: string
  trackingContainerCode: string
  recaptchaPublicKey: string
  amChartsLicense: string
  newRelicAppId: string
  launchDarklyClientId: string
  desktopAppUrl: string
  desktopUriScheme: string
  globalAdminUrl: string
  webAppUrl: string
  cognitoProxyViaApi: boolean

  private config: WindowConfig | undefined

  constructor() {
    const windowRef = window as unknown as WindowRef
    this.config = windowRef.config || environment.config
    this.loadConfiguration()
  }

  private loadConfiguration(): void {
    if (!this.config) {
      throw new Error('Unable to fetch app configuration from API')
    }

    this.socket = {
      url: this.config.SocketUrl,
    }
    this.logging = {
      url: this.config.LoggingUrl,
      debug: environment.debugLogging,
    }
    this.server = {
      rawUrl: this.config.ApiUrl,
      url: this.config.ApiUrl + API_VERSION_SUFFIX,
      healthUrl: this.config.ApiUrl + API_HEALTH_SUFFIX,
    }
    this.regionalApi = {
      endpointMap: {
        'ap-southeast-2': this.config.RegionalApiApSoutheast2Endpoint,
        'eu-west-2': this.config.RegionalApiEuWest2Endpoint,
        'us-west-2': this.config.RegionalApiUsWest2Endpoint,
        'ca-central-1': this.config.RegionalApiCaCentral1Endpoint,
      },
    }

    this.restApi = {
      endpointMap: {
        'ap-southeast-2': this.config.RestApiApSoutheast2Endpoint,
        'eu-west-2': this.config.RestApiEuWest2Endpoint,
        'us-west-2': this.config.RestApiUsWest2Endpoint,
        'ca-central-1': this.config.RestApiCaCentral1Endpoint,
      },
    }
    this.courtUploaderServer = {
      endpointMap: {
        'ap-southeast-2': this.config.CourtUploaderApSoutheast2Endpoint,
        'eu-west-2': this.config.CourtUploaderEuWest2Endpoint,
        'us-west-2': this.config.CourtUploaderUsWest2Endpoint,
        'ca-central-1': this.config.CourtUploaderCaCentral1Endpoint,
      },
    }
    this.digitalJusticeApi = {
      endpointMap: {
        'ap-southeast-2': this.config.DigitalJusticeApiApSoutheast2Endpoint,
        'eu-west-2': this.config.DigitalJusticeApiEuWest2Endpoint,
        'us-west-2': this.config.DigitalJusticeApiUsWest2Endpoint,
        'ca-central-1': this.config.DigitalJusticeApiCaCentral1Endpoint,
      },
    }
    this.digitalJusticeWeb = {
      endpointMap: {
        'ap-southeast-2': this.config.DigitalJusticeWebApSoutheast2Endpoint,
        'eu-west-2': this.config.DigitalJusticeWebEuWest2Endpoint,
        'us-west-2': this.config.DigitalJusticeWebUsWest2Endpoint,
        'ca-central-1': this.config.DigitalJusticeWebCaCentral1Endpoint,
      },
    }
    this.auditApi = {
      endpointMap: this.config.AuditApiEndpoint
        ? {
            'ap-southeast-2': getRegionalEndpoint(this.config.AuditApiEndpoint, 'ap-southeast-2'),
            'eu-west-2': getRegionalEndpoint(this.config.AuditApiEndpoint, 'eu-west-2'),
            'us-west-2': getRegionalEndpoint(this.config.AuditApiEndpoint, 'us-west-2'),
            'ca-central-1': getRegionalEndpoint(this.config.AuditApiEndpoint, 'ca-central-1'),
          }
        : {},
    }
    this.annotationsApi = {
      endpointMap: {
        'ap-southeast-2': this.config.AnnotationsApSoutheast2Endpoint,
        'eu-west-2': this.config.AnnotationsEuWest2Endpoint,
        'us-west-2': this.config.AnnotationsUsWest2Endpoint,
        'ca-central-1': this.config.AnnotationsCaCentral1Endpoint,
      },
    }
    this.cognitoProxyViaApi = this.config.EnableCognitoProxyViaApi || false
    this.userPool = new CognitoUserPool({
      UserPoolId: this.config.UserPoolId,
      ClientId: this.config.ClientId,
      ...(this.cognitoProxyViaApi ? { endpoint: `${this.config.ApiUrl}/v1/api/auth-proxy` } : {}),
    })
    this.cognitoAuthUrl = this.config.CognitoAuthUrl
    this.cognitoLogoutUrl = this.config.CognitoLogoutUrl
    this.microsoftAuthAppId = this.config.MicrosoftAuthAppId
    this.microsoftAuthUrl = this.config.MicrosoftAuthUrl
    this.mockAuthUrl = this.config.MockAuthUrl
    this.trackingContainerCode = this.config.TrackingContainerCode
    this.recaptchaPublicKey = this.config.RecaptchaPublicKey
    this.amChartsLicense = this.config.AmChartsLicense
    this.newRelicAppId = this.config.NewRelicWebAppId
    this.launchDarklyClientId = this.config.LaunchDarklyClientId
    this.desktopAppUrl = this.config.DesktopAppUrl
    this.desktopUriScheme = this.config.DesktopUriScheme
    this.globalAdminUrl = this.config.GlobalAdminUrl
    this.webAppUrl = this.config.WebAppUrl
  }
}

// Replaces the region placeholder to obtain the concrete endpoint
function getRegionalEndpoint(endpointPattern: string, region: string): string {
  return endpointPattern.replace('${region}', region)
}
