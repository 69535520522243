import { Component, Input, OnInit, effect, model, signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { ActivatedRoute, Router } from '@angular/router'
import { SearchRequestType } from '@ftr/contracts/api/search'
import { DestroySubscribers } from '@ftr/foundation'
import { isValidSearchPage, isValidSearchTerm, isValidSearchType } from '@ftr/ui-search'
import { filter, takeUntil } from 'rxjs'
import { SearchParams } from '~app/pages/search.params'

@Component({
  selector: 'ftr-search-container-legacy',
  templateUrl: './search-container-legacy.component.html',
})
export class SearchContainerLegacyComponent extends DestroySubscribers implements OnInit {
  @Input() isMobile: boolean
  @Input() isTablet: boolean
  @Input() isDesktop: boolean
  isSearching = model.required<boolean>()

  protected isSearching$ = toObservable(this.isSearching)
  private readonly searchTerm = signal('')
  protected readonly searchType = signal(SearchRequestType.Orders)
  private readonly searchPage = signal(1)

  readonly searchTerm$ = toObservable(this.searchTerm)
  readonly searchType$ = toObservable(this.searchType)
  readonly searchPage$ = toObservable(this.searchPage)

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super()
    this.keepUrlInSync()
  }

  ngOnInit(): void {
    this.watchSearchParams()
  }

  private keepUrlInSync(): void {
    effect(() => {
      const searchTerm = this.searchTerm()
      const searchType = this.searchType()
      const searchPage = this.searchPage()
      if (this.isSearching()) {
        if (searchTerm)
          this.router.navigate([], {
            queryParams: {
              [SearchParams.SearchType]: searchType,
              [SearchParams.SearchPageNumber]: searchPage,
              [SearchParams.SearchTerm]: searchTerm,
            },
            queryParamsHandling: 'merge',
          })
        else {
          this.router.navigate([], {
            queryParams: {
              [SearchParams.SearchType]: undefined,
              [SearchParams.SearchTerm]: undefined,
              [SearchParams.SearchPageNumber]: undefined,
            },
            queryParamsHandling: 'merge',
          })
        }
      }
    })
  }

  protected onFilterItemSelected(type: SearchRequestType): void {
    this.searchType.set(type)
    this.searchPage.set(1)
  }

  /**
   * Handles the state of the search bar
   */
  private watchSearchParams(): void {
    this.route.queryParams
      .pipe(
        takeUntil(this.finalize),
        filter(p => p[SearchParams.SearchTerm] && isValidSearchTerm(p[SearchParams.SearchTerm])),
      )
      .subscribe(p => this.searchTerm.set(p[SearchParams.SearchTerm].trim()))

    this.route.queryParams
      .pipe(
        takeUntil(this.finalize),
        filter(p => p[SearchParams.SearchType] && isValidSearchType(p[SearchParams.SearchType])),
      )
      .subscribe(p => this.searchType.set(p[SearchParams.SearchType].trim()))

    this.route.queryParams
      .pipe(
        takeUntil(this.finalize),
        filter(p => p[SearchParams.SearchPageNumber] && isValidSearchPage(p[SearchParams.SearchPageNumber])),
      )
      .subscribe(p => this.searchPage.set(parseInt(p[SearchParams.SearchPageNumber], 10)))

    this.route.queryParams
      .pipe(
        takeUntil(this.finalize),
        filter(p => !p[SearchParams.SearchTerm]),
      )
      .subscribe(() => this.searchTerm.set(''))
  }
}
