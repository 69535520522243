<div class="top-row">
  <ftr-search-text-with-highlighting
    class="case-reference"
    [searchTextHtml]="getValue('caseReference')"
  ></ftr-search-text-with-highlighting>
  <ftr-card-type-label [type]="'case'" [vocabularyTerms]="vocabularyTerms()"></ftr-card-type-label>
</div>

<div class="bottom-row">
  <ftr-search-text-with-highlighting-and-truncation
    class="case-title"
    [searchTextHtml]="caseTitle()"
  ></ftr-search-text-with-highlighting-and-truncation>

  <div class="secondary-details">
    <span class="last-heard-on">
      <span class="label">Last heard</span>
      <span class="date">
        @if (hasHighlight('lastHeardDate')) {
          <em class="highlight">
            <ng-container *ngTemplateOutlet="lastHeardDateTemplate"> </ng-container>
          </em>
        } @else {
          <ng-container *ngTemplateOutlet="lastHeardDateTemplate"> </ng-container>
        }
      </span>
    </span>

    <ftr-badge [text]="searchResult().hearingCount.toString()" icon="FolderOpen" [color]="badgeColor"></ftr-badge>
  </div>
</div>

<ng-template #lastHeardDateTemplate>
  <ftr-date [date]="searchResult().lastHeardDate" [format]="dateFormat.Date"></ftr-date>
</ng-template>
