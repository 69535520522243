<ng-template #lastLoginMessage
  >Was at <ftr-date [date]="lastLogin?.lastLoginDate" [format]="dateFormat.TimeWithSeconds"></ftr-date>&nbsp;
  <ftr-date [date]="lastLogin?.lastLoginDate" [format]="dateFormat.Date"></ftr-date> from
  <ftr-flag [locationCode]="lastLogin?.countryCode || ''"></ftr-flag>
  {{ lastLogin?.location }}
</ng-template>

<ng-template #failedAttemptsMessage>
  @if (loginHistory.loginAttempts === 1) {
    <div>There has been an unsuccessful login attempt since you last logged in.</div>
  } @else {
    <div>There have been {{ loginHistory.loginAttempts }} unsuccessful login attempts since you last logged in.</div>
  }
  <a data-track [routerLink]="['/', auditLogPath]">Review your audit log</a>
</ng-template>

<ng-template #permissionsChangedMessage
  >Your access to a court system has changed.
  <a data-track [routerLink]="['/', auditLogPath]">Review your audit log</a>
</ng-template>
