import { Component, Input } from '@angular/core'
import { Illustration } from '@ftr/foundation'

@Component({
  selector: 'ftr-search-legacy-results-not-found',
  templateUrl: './search-legacy-results-not-found.component.html',
  styleUrls: ['./search-legacy-results-not-found.component.css'],
})
export class SearchResultsNotFoundComponent {
  @Input() searchTerms: string

  readonly illustration = Illustration
}
