import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AnchorComponent, IconComponent, StopPropagationDirective } from '@ftr/foundation'
import { ValidationErrorHintDirective } from '../../directives'
import { DatePickerComponent } from './date-picker.component'
import { FlatpickrFactory } from './flatpickr.factory'

@NgModule({
  imports: [CommonModule, IconComponent, ValidationErrorHintDirective, StopPropagationDirective, AnchorComponent],
  declarations: [DatePickerComponent],
  providers: [FlatpickrFactory],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
