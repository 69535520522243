import { A11yModule } from '@angular/cdk/a11y'
import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { OnClickOutsideDirective } from '../../directives'
import { GlobalScrollblockService } from '../../services/global-scrollblock/global-scrollblock.service'
import { ButtonColor, ButtonComponent, ButtonDisplayType, ButtonSize } from '../button'
import { IconComponent } from '../icon'
import { LineLoaderComponent } from '../line-loader'
import { LoaderComponent } from '../loader'
import { ModalService } from './modal.service'

export enum ModalType {
  Default = 'default',
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
}

export enum ModalSize {
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extra-large',
  Fullscreen = 'fullscreen',
}

export enum ModalLoaderStyle {
  OverlaySpinner = 'overlay-spinner',
  AnimatedSubmitButton = 'animated-submit-button',
}

@Component({
  standalone: true,
  selector: 'ftr-modal',
  imports: [
    A11yModule,
    ButtonComponent,
    CommonModule,
    IconComponent,
    OnClickOutsideDirective,
    LoaderComponent,
    LineLoaderComponent,
  ],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() type = ModalType.Default
  @Input() modalTitle = 'Modal title'
  @Input() actionText = 'Action'
  @Input() cancelText = 'Cancel'
  @Input() closeOnAction = false
  @Input() disableAnimation = false
  @Input() fullWidthButtons = false
  /**
   * Prevent modal from closing: the 'close' and `cancel` buttons are hidden, and clicking outside the modal
   * will not close it.
   */
  @Input() preventClose = false
  /**
   * Allows customization of close functionality via closeModal emitter. This is helpful when we need to keep
   * the dialog open in certain conditions. Similar to `preventClose` but does not hide `close` buttons.
   *
   * Please note that the closeModal emitter handler will need to explicitly invoke ModalService.close().
   */
  @Input() overrideCloseBehavior = false
  @Input() showLoaderOnAction = false
  @Input() showOpenInNewIcon = false
  @Input() hideActions = false
  @Input() size = ModalSize.Large
  @Input() fixedHeight = false
  @Input() disable = false

  /**
   * Disable automatic vertical scrolling
   */
  @Input() noScroll = false

  @Input() actionButtonColor? = ButtonColor.Primary

  @Input() loaderStyle = ModalLoaderStyle.OverlaySpinner

  @Output() action = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('close') closeModal = new EventEmitter()

  isOpen = false
  showLoader = false

  readonly buttonColor = ButtonColor
  readonly buttonDisplayType = ButtonDisplayType
  readonly buttonSize = ButtonSize
  readonly modalType = ModalType
  readonly modalSize = ModalSize

  constructor(
    private readonly modalService: ModalService,
    private globalScrollblockService: GlobalScrollblockService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => (this.isOpen = true))
  }

  ngAfterViewInit(): void {
    this.globalScrollblockService.enable()
  }

  ngOnDestroy(): void {
    this.globalScrollblockService.disable()
    this.closeModal.emit()
  }

  close(): void {
    if (!this.preventClose && !this.overrideCloseBehavior) {
      this.modalService.close()
    }
    if (this.overrideCloseBehavior) {
      this.closeModal.emit()
    }
  }

  onAction(): void {
    if (this.showLoaderOnAction) {
      this.preventClose = true
      this.showLoader = true
    }

    this.action.emit()

    if (this.closeOnAction) {
      this.close()
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydown($event: KeyboardEvent): void {
    if (this.isOpen) {
      $event.stopPropagation()
      this.close()
    }
  }

  protected readonly modalLoaderStyle = ModalLoaderStyle
}
