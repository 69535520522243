import { Injectable } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { AccessibleEntityMap, EntityType } from '@ftr/contracts/api/permissions'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { Uuid } from '@ftr/contracts/type/shared'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, map, of } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class RestrictedPermissionsService {
  constructor(private readonly store: Store) {}

  canAccessEntity(
    id: Uuid | undefined,
    entityType: EntityType,
    configuration: PrivateConfiguration,
    userGroupPermissionId: UserGroupPermissionId,
  ): Observable<boolean> {
    if (!id) {
      return of(true)
    }
    const accessibleEntityMapFn$ = this.store.select(UserState.restrictedEntitiesInCourtSystemForPermission)
    return accessibleEntityMapFn$.pipe(
      map(fn => {
        const accessibleEntityMap = fn(userGroupPermissionId, configuration, configuration.courtSystemId)
        const { isDepartmentsEnabled, restrictedByDepartment, restrictedByLocation } = configuration
        const departmentsRestricted = isDepartmentsEnabled && restrictedByDepartment

        // Bail early with true if configuration required for restricted permissions is not enabled
        if (entityType === 'department') {
          if (!departmentsRestricted) {
            // No restrictions on department so can access everything
            return true
          }
          return this.checkEntityAccess(id, entityType, accessibleEntityMap!)
        } else if (entityType === 'location') {
          if (!restrictedByLocation) {
            // No restrictions on location so can access everything
            return true
          }
          return this.checkEntityAccess(id, entityType, accessibleEntityMap!)
        }

        return true
      }),
    )
  }
  private checkEntityAccess(id: Uuid, entityType: EntityType, accessibleEntityMap: AccessibleEntityMap): boolean {
    const entityIds = accessibleEntityMap.get(entityType)
    if (entityIds) {
      return entityIds.has(id)
    }

    // Unlikely we get here - but the entity map was not defined in the map.
    return true
  }
}
