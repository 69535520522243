import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { ButtonComponent, ButtonDisplayType } from '../button'
import { IconComponent } from '../icon'

@Component({
  selector: 'ftr-error-retry',
  templateUrl: './error-retry.component.html',
  styleUrls: ['./error-retry.component.css'],
  standalone: true,
  imports: [ButtonComponent, IconComponent],
})
/**
 * A component to display an error with a retry link
 * @deprecated Use ErrorState instead
 */
export class ErrorRetryComponent {
  @Input() text: string
  @Input() icon = true
  @Output() retry = new EventEmitter()
  @HostBinding('style.display') display = 'block'

  readonly buttonDisplayType = ButtonDisplayType

  onRetry(): void {
    this.retry.emit()
  }
}
