<div class="search-results-overlay">
  @if (isSearching$ | async) {
    <ftr-search-legacy-results
      [query$]="searchTerm$"
      [page$]="searchResultsPageNumber$"
      [searchRequestType$]="searchType$"
      [sttContext$]="sttContext$"
      [isSearching$]="isSearching$"
    >
    </ftr-search-legacy-results>
  }
</div>
