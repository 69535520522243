<div class="search-result">
  <div class="search-result__order-details">
    <div class="search-result__order-details__ref card__item card__item--header">
      <ftr-icon
        [icon]="searchResult.lineItemType || fallbackLineItemType | ftrLineItemTypeIcon"
        gapRight="Regular"
        color="GreyLight"
      ></ftr-icon>
      <span class="card__item-heading">Order Ref.</span>
      <a
        class="card__item-data"
        [class.no-link]="!hasLink"
        [innerHTML]="getValue('orderReference') | ftrHighlightHtml"
      ></a>
    </div>
    <ftr-pill [isNotClickable]="true" class="search-result__order-details__status">
      {{ searchResult.orderStatus }}
    </ftr-pill>
    <ng-container *ngTemplateOutlet="highlightTpl"></ng-container>
  </div>

  @if (searchResult.caseNumber || searchResult.caseTitle) {
    <div class="search-result__case-details">
      <div class="search-result__case-details__item ellipsis">
        <span class="search-result__case-details__item__label">Case: </span>
        <span
          class="search-result__case-details__item__content"
          [innerHTML]="[getValue('caseNumber'), getValue('caseTitle')].join(' / ') | ftrHighlightHtml"
        ></span>
      </div>
      <div class="search-result__case-details__item">
        <span class="search-result__case-details__item__label">For: </span>
        <ng-container
          *ngTemplateOutlet="
            dateHighlightTpl;
            context: {
              date: searchResult.hearingDate! | ftrCourtSystemTimeZone: { courtSystemConfiguration: courtSystemConfig },
              format: dateFormat.Date,
              hl: highlight.hearingDate,
            }
          "
          class="search-result__case-details__item__content"
        >
        </ng-container>
        <span class="search-result__case-details__item__label"> at </span>
        <span
          class="search-result__case-details__item__content"
          [innerHTML]="getValue('location.courthouse') | ftrHighlightHtml"
        ></span
        >.
        @if (portionToTranscribe) {
          <span class="search-result__case-details__item__content">
            <ftr-date
              [date]="
                portionToTranscribe.startTime | ftrCourtSystemTimeZone: { courtSystemConfiguration: courtSystemConfig }
              "
              [format]="dateFormat.Time"
            ></ftr-date>
            to
            <ftr-date
              [date]="
                portionToTranscribe.endTime | ftrCourtSystemTimeZone: { courtSystemConfiguration: courtSystemConfig }
              "
              [format]="dateFormat.Time"
            ></ftr-date
            >.
          </span>
        }
      </div>
      <div class="search-result__case-details__item">
        <span class="search-result__case-details__item__label">Placed</span>
        @if (searchResult.customer) {
          <span class="search-result__case-details__item__label"> by </span>
        }
        @if (searchResult.customer) {
          <span
            class="search-result__case-details__item__content"
            [innerHTML]="getValue('customer.name') | ftrHighlightHtml"
          ></span>
        }
        @if (searchResult.placementDate) {
          <span class="search-result__case-details__item__label"> on </span>
        }
        <ng-container
          *ngTemplateOutlet="
            dateHighlightTpl;
            context: {
              date: searchResult.placementDate,
              format: dateFormat.Date,
              hl: highlight.placementDate,
            }
          "
          class="search-result__case-details__item__content"
        >
        </ng-container>
        <!-- Autoformatter places this on a new line creating whitespace, hence span -->
        <span>.</span>
      </div>
      @if (searchResult.dueDate) {
        <div class="search-result__case-details__item">
          @if (!isToday(searchResult.dueDate)) {
            <span>
              <span class="search-result__case-details__item__label">Due on </span>
              <ng-container
                *ngTemplateOutlet="
                  dateHighlightTpl;
                  context: {
                    date: searchResult.dueDate,
                    format: dateFormat.Date,
                    hl: highlight.dueDate,
                  }
                "
                class="search-result__case-details__item__content"
              >
              </ng-container>
            </span>
          }
          @if (isToday(searchResult.dueDate)) {
            <span>
              <span class="search-result__case-details__item__label">Due </span>
              <span class="search-result__case-details__item__content--due-today">
                <!-- If today's date was our search query we want to highlight it -->
                @if (highlight.dueDate) {
                  <em class="ftr-em">Today</em>
                }
                @if (!highlight.dueDate) {
                  <span>Today</span>
                }
              </span>
            </span>
          }
          <!-- Autoformatter places this on a new line creating whitespace, hence span -->
          <span>.</span>
        </div>
      }
    </div>
  }

  <ng-container *ngTemplateOutlet="highlightTpl"></ng-container>

  <ng-template #highlightTpl>
    @if (dynamicHighlight) {
      <div class="dynamic-highlight">
        @if (!!dynamicHighlight.label) {
          <div class="search-result__case-details__item__label">
            {{ dynamicHighlight.label + ':' }}
          </div>
        }
        <div
          class="search-result__case-details__item__content ellipsis"
          [innerHTML]="dynamicHighlight.value | ftrHighlightHtml"
        ></div>
      </div>
    }
  </ng-template>

  <ng-template #dateHighlightTpl let-date="date" let-format="format" let-hl="hl">
    @if (hl) {
      <em class="ftr-em">
        <ftr-date [date]="date" [format]="format"></ftr-date>
      </em>
    }
    @if (!hl) {
      <ftr-date [date]="date" [format]="format"></ftr-date>
    }
  </ng-template>
</div>
