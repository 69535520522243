import { Pipe, PipeTransform } from '@angular/core'
import { capitalize } from '../../util'

/**
 * There are edge cases where it makes better sense to use use capitalize
 * over TitleCase pipe because the TitleCase pipe lower cases the where you don't want it to.
 * For example:
 * - TitleCase  - `judge/Justice` - returns Judge/justice.
 * - Capitalize - `judge/Justice` - returns Judge/Justice.
 */
@Pipe({ name: 'ftrCapitalizeFirstLetter', standalone: true })
export class CapitalizeFirstLetterPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return ''
    }

    return capitalize(value)
  }
}
