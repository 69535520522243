export enum MediaStatus {
  // @deprecated: InitialMetadata is not required in the new player service.
  // It appears to have been used after loaded metadata and playing could have potentially been run as well.
  InitialMetadata,
  LoadedMetadata,
  Playing,
  Paused,
  Waiting,
  Ended,
  Errored,
  /**
   * The user has started to seek to a different position
   * in the audio (i.e. starting dragging the playhead)
   */
  SeekStarted,
  /**
   * The user has continued to seek to a different position
   * in the audio (i.e. dragged the playhead to the left or right)
   */
  SeekUpdated,
  /**
   * The user has finished seeking to a different position
   * in the audio (i.e. stopped dragging the playhead)
   */
  SeekFinished,
  /**
   * The user has chosen a different position in the audio
   * to skip to (i.e. clicked on a new location on the playback bar)
   */
  JumpedToTime,
  /**
   * The user clicked on the utterance's timestamp
   * to jump to its position in the audio. In this one case we don't
   * want to scroll the stt or use jump to time
   */
  JumpedOnClick,
  /**
   * The user has chosen to seek to the end of the recording,
   * needs to update the playhead unlike regular seeking which relies on user input
   */
  SeekToEnd,
}
