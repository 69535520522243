import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DatePickerModule, LegacyTimeRangeComponent, TimeRangeTextComponent } from '@ftr/forms'
import { ButtonComponent, CollapsibleComponent, IconComponent } from '@ftr/foundation'
import { CheckboxListComponent } from '@ftr/lists'
import { MoneyModule } from '@ftr/ui-money'
import { HearingPortionsSelectComponent, HearingSegmentsEditComponent } from '@ftr/ui-ordering'
import { HearingPortionsTimeframesComponent } from '~app/features/hearing-segments/hearing-portions-timeframes/hearing-portions-timeframes.component'
import { HearingSegmentsCostBreakdownComponent } from '~app/features/hearing-segments/hearing-segments-cost-breakdown/hearing-segments-cost-breakdown.component'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'

@NgModule({
  imports: [
    ButtonComponent,
    CheckboxListComponent,
    CollapsibleComponent,
    CommonModule,
    DatePickerModule,
    LegacyTimeRangeComponent,
    FtrFormsModule,
    IconComponent,
    MoneyModule,
    TimeRangeTextComponent,
  ],
  declarations: [
    HearingSegmentsCostBreakdownComponent,
    HearingPortionsSelectComponent,
    HearingSegmentsEditComponent,
    HearingPortionsTimeframesComponent,
  ],
  exports: [HearingSegmentsEditComponent, HearingPortionsSelectComponent, HearingPortionsTimeframesComponent],
})
export class HearingSegmentsModule {}
