<div class="app" [ngClass]="{ 'app--ready': status === 'READY', 'app--not-ready': status !== 'READY' }">
  <ng-container #designSystemOverlayContainer></ng-container>

  <div class="app__display">
    <ftr-app-display
      [showNavigationRedesign]="showNavigationRedesign"
      [logoData]="logoData"
      [routeData]="routeData"
      [backToSearchResultsFullUrl]="backToSearchResultsFullUrl$ | async"
      [isSearching]="(isSearching$ | async) ?? false"
      [isPlaybackPage]="(isPlaybackPage$ | async) ?? false"
      [isFooterVisible]="(footerVisible$ | async) ?? true"
      (konami)="showTippy()"
    >
      <router-outlet></router-outlet>
    </ftr-app-display>
  </div>
  <div class="app__loader">
    <ftr-app-loader></ftr-app-loader>
  </div>

  @defer {
    <ftr-dialog></ftr-dialog>
    <ftr-portal></ftr-portal>
  }

  <ftr-toast-container></ftr-toast-container>
  @if (isInDesktopApp) {
    <ftr-update-app-toast></ftr-update-app-toast>
  }
  <ftr-post-login-notification></ftr-post-login-notification>
  <ftr-mfa-setup-modal></ftr-mfa-setup-modal>
  <ftr-confirmation-modal></ftr-confirmation-modal>

  @if (isTippyShown) {
    <ftr-tippy (clickOutside)="isTippyShown = false"></ftr-tippy>
  }

  <div class="inlineSVG" [inlineSVG]="'/assets/images/svgs/svgs.svg'"></div>
</div>
