import { Component, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { DestroySubscribers, PageStyle, isOfNonZeroLength } from '@ftr/foundation'
import { CourtSystemPaths } from '@ftr/routing-paths'
import { SearchState, SetBackToSearchResultsFullUrlAction } from '@ftr/ui-search'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, filter, map, take, takeUntil } from 'rxjs'

@Component({
  selector: 'ftr-back-to-search-results-legacy',
  templateUrl: './back-to-search-results-legacy.component.html',
  styleUrls: ['./back-to-search-results-legacy.component.css'],
  host: { class: 'back-to-search-results' },
})
export class BackToSearchResultsComponent extends DestroySubscribers implements OnInit {
  @Input() pageStyle = PageStyle.Default
  @Input() isSealingModeOn = false

  searchTerm: Observable<string | undefined>

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {
    super()
  }

  ngOnInit(): void {
    this.searchTerm = this.store.select(SearchState.lastSearchTerm)

    combineLatest([
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map(e => e.url),
      ),
      this.store.select(SearchState.selectedSearchItem),
    ])
      .pipe(
        takeUntil(this.finalize),
        filter(([url, _]) => !isBackButtonRequired(url)),
        // If back button is not required, we can remove backToSearchResultsParams
      )
      .subscribe(() => this.store.dispatch(new SetBackToSearchResultsFullUrlAction()))
  }

  onClick(): void {
    this.store
      .select(SearchState.backToSearchResultsFullUrl)
      .pipe(filter(isOfNonZeroLength), take(1))
      .subscribe(url => this.router.navigateByUrl(url))
  }
}

/**
 * This function determines whether the current route url is one of the paths
 * that require 'Back to Search Results' link
 * @param url
 */
export function isBackButtonRequired(url: string): boolean {
  // Regex for uuid
  const uuid = '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}'

  // Patterns for routes that require 'Back to search results' link
  const patterns = [
    `\/${CourtSystemPaths.AudioOrders}\/${uuid}`,
    `\/${CourtSystemPaths.TranscriptOrders}\/${uuid}`,
    `\/${CourtSystemPaths.RecordingPlayback}\/${uuid}\/${uuid}\/${uuid}`,
  ]

  // Create one big regex out of all patterns
  const matched = url.match(new RegExp(patterns.join('|'), 'g'))

  // And match it with our current url
  return !!(matched && matched.length)
}
