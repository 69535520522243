<div
  class="input input--icon"
  [ngClass]="{
    'input--highlight': highlightError | async,
    'input--invalid': !control.valid,
    'input--touched': control.touched || submitAttempted,
  }"
>
  <ftr-icon class="input__icon" icon="Schedule"></ftr-icon>
  <input
    #elem
    [attr.name]="name"
    [required]="control.hasValidator(validators.required)"
    [id]="id"
    type="text"
    class="input__control"
    autocomplete="off"
    [placeholder]="placeholder"
    [value]="control.value | ftrDate: { format: timeFormat }"
    [class.input__control--has-value]="!!control.value"
    (change)="inputChange(elem.value)"
  />
  <label class="input__label" [htmlFor]="id">{{ label }}</label>
  <span class="input__bar"></span>
  <span
    class="input__validation"
    ftrValidationErrorHint
    [ngFormControl]="control"
    [controlTouched]="control.touched"
    [submitAttempted]="submitAttempted"
    [label]="label"
  ></span>
</div>
