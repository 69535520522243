/* eslint-disable max-classes-per-file */
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { SearchRequestType, SearchResultType } from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { SttContext } from '@ftr/stt-search'
import { SearchBarInputState, SearchFocusState } from './search.state'

export class GetSearchResultsAction {
  static readonly type = '[Search] Get Search Results'

  constructor(
    public searchTerm: string | undefined,
    public searchType: SearchRequestType,
    public courtSystemId: Uuid,
    public page?: number | undefined,
    public resourceId?: Uuid | undefined,
    public pageSize?: number | undefined,
  ) {}
}

export class SetSearchTermAction {
  static readonly type = '[Search] Set Last Search Term'

  constructor(public lastSearchTerm: string) {}
}

/**
 * Unique search is a unique value each time the search is called to allow for users to
 * hit 'search' multiple times for the exact same search term. It's the only way to get
 * the observables to emit.
 */
export class SetUniqueSearchAction {
  static readonly type = '[Search] Setting Unique Search'

  constructor(public uniqueSearch: number) {}
}

export class SetSearchBarInputStateAction {
  static readonly type = '[Search] Set searchBarInputState'

  constructor(public searchBarInputState: SearchBarInputState) {}
}

export class SetBackToSearchResultsFullUrlAction {
  static readonly type = '[Search] Set backToSearchResultsFullUrl'

  constructor(public backToSearchResultsFullUrl?: string) {}
}

export class SetSelectedSearchItemAction {
  static readonly type = '[Search] Set selectedSearchItem'

  constructor(public selectedSearchItem?: SearchResultType) {}
}

export class SetSearchResultsScrollPositionAction {
  static readonly type = '[Search] Set SearchResultsScrollPosition'

  constructor(public searchResultsScrollPosition?: number) {}
}

export class SetSearchFocusStateAction {
  static readonly type = '[Search] Set SearchFocusState'

  constructor(public searchFocusState: SearchFocusState) {}
}

export class SetSttContextAction {
  static readonly type = '[Search] Set SttContext'

  constructor(public sttContext: SttContext | undefined) {}
}

export class SetRecordingCourtSystemAction {
  static readonly type = '[Search] Set RecordingCourtSystem'

  constructor(public recordingCourtSystem: CourtSystem | undefined) {}
}

export class SetSearchScopeCourtSystemAction {
  static readonly type = '[Search] Set SearchScopeCourtSystem'

  constructor(public searchScopeCourtSystem: CourtSystem | undefined) {}
}

export class ResetSearchFocusStateToState {
  static readonly type = '[Search] Reset SearchFocusStateToState'

  constructor(public searchFocusState: SearchFocusState) {}
}

export class SetPermittedToSearchThisAudioSegment {
  static readonly type = '[Search] Set PermittedToSearchThisAudioSegment'

  constructor(public permittedToSearchThisAudioSegment: boolean) {}
}
