import { Component, Input, computed, effect, input, signal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { ActivatedRoute, Router } from '@angular/router'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { SearchEntityType, SearchRequestType } from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { DestroySubscribers, isNotNullOrUndefined, selectWith, unwrapData } from '@ftr/foundation'
import { CoreConfigurationService } from '@ftr/ui-court-system'
import {
  FetchSearchEntityTypeToNumberOfMatchesAction,
  FetchSearchManyResultsAction,
  SearchFocusState,
  SearchManyState,
  SearchState,
  SetSearchFocusStateAction,
  SetSearchResultsScrollPositionAction,
  isValidSearchEntities,
  isValidSearchTerm,
} from '@ftr/ui-search'
import { VocabularyTermsService } from '@ftr/ui-vocab'
import { Store } from '@ngxs/store'
import { filter, map, takeUntil } from 'rxjs'
import { SearchBarDisplayService } from '~app/core/header/search-container-legacy/search-bar-legacy/search-bar-display.service'
import { SearchParams } from '~app/pages/search.params'

@Component({
  selector: 'ftr-search-container',
  templateUrl: './search-container.component.html',
})
export class SearchContainerComponent extends DestroySubscribers {
  @Input() isMobile: boolean
  @Input() isTablet: boolean
  @Input() isDesktop: boolean
  courtSystemId = input.required<Uuid>()
  isSearching = input.required<boolean>()

  readonly searchTerm = toSignal(
    this.route.queryParams.pipe(map(p => (p[SearchParams.SearchTerm]?.trim() as string) ?? null)),
    { initialValue: null },
  )

  readonly selectedEntities = toSignal(
    this.route.queryParams.pipe(
      map(x => x[SearchParams.EntitiesToSearch]),
      map(x => (typeof x === 'string' ? [x] : x) as SearchEntityType[]),
      filter(p => Array.isArray(p) && isValidSearchEntities(p)),
    ),
    { initialValue: [] },
  )

  private readonly pageToken = toSignal(
    this.route.queryParams.pipe(map(p => p[SearchParams.SearchPageToken] ?? null)),
    {
      initialValue: null,
    },
  )

  protected readonly searchFocusState = selectWith(() => SearchState.searchFocusState)

  protected readonly searchResponse = selectWith(() => SearchManyState.searchResponse)

  protected readonly searchEntityTypeToNumberOfMatches = selectWith(
    () => SearchManyState.searchEntityTypeToNumberOfMatches,
  )

  protected readonly vocabularyTerms = this.vocabularyTermsService.terms(() => this.courtSystemId())

  protected configuration = signal<PublicConfiguration | undefined>(undefined)

  // TODO this should come from a non legacy service ST-3252
  private searchRequestTypeOptions = toSignal(this.searchBarDisplayService.searchTypeOptions$, { initialValue: [] })
  protected availableEntities = computed(() => {
    const options = this.searchRequestTypeOptions()
    return options
      .map(x => x.value)
      .map(toSearchEntityType)
      .filter(isNotNullOrUndefined)
  })

  protected entitiesToSearch = computed(() => {
    return this.selectedEntities().length > 0 ? this.selectedEntities() : this.availableEntities()
  })

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly coreConfigurationService: CoreConfigurationService,
    private readonly searchBarDisplayService: SearchBarDisplayService,
    private readonly vocabularyTermsService: VocabularyTermsService,
  ) {
    super()
    this.searchOnChange()
    effect(
      () => {
        const courtSystemId = this.courtSystemId()
        this.coreConfigurationService
          .getByCourtSystem(courtSystemId)
          .pipe(unwrapData(), takeUntil(this.finalize))
          .subscribe(configuration => {
            this.configuration.set(configuration)
          })
      },
      { allowSignalWrites: true },
    )
  }

  private searchOnChange(): void {
    // When the search term changes, fetch the number of matches for each entity type
    effect(() => {
      const searchTerm = this.searchTerm()
      const availableEntities = this.availableEntities()
      const courtSystemId = this.courtSystemId()
      if (isValidSearchTerm(searchTerm)) {
        this.store.dispatch(
          new FetchSearchEntityTypeToNumberOfMatchesAction(searchTerm, availableEntities, courtSystemId),
        )
      }
    })

    // Clear the query params when no longer searching
    effect(() => {
      if (!this.isSearching()) {
        this.router.navigate([], {
          queryParams: {
            [SearchParams.SearchTerm]: null,
            [SearchParams.EntitiesToSearch]: null,
            [SearchParams.SearchPageToken]: null,
          },
          queryParamsHandling: 'merge',
        })
      }
    })

    // Fetch the current page when the params change
    effect(() => {
      const searchTerm = this.searchTerm()
      const pageToken = this.pageToken()
      const entitiesToSearch = this.entitiesToSearch()
      const selectedEntities = this.selectedEntities()
      const courtSystemId = this.courtSystemId()
      this.fetchResults(searchTerm, pageToken, entitiesToSearch, selectedEntities, courtSystemId)
    })
  }

  protected onFilterItemSelected(type: SearchEntityType): void {
    const selectedEntities = this.selectedEntities()
    const newSelectedEntities = selectedEntities.includes(type)
      ? selectedEntities.filter(x => x !== type)
      : [...selectedEntities, type]
    this.router.navigate([], {
      queryParams: {
        [SearchParams.EntitiesToSearch]: newSelectedEntities,
        [SearchParams.SearchPageToken]: null,
      },
      queryParamsHandling: 'merge',
    })
  }

  onNewSearchFocusState(newState: SearchFocusState): void {
    this.store.dispatch(new SetSearchFocusStateAction(newState))
  }

  onRetry(): void {
    this.fetchResults(
      this.searchTerm(),
      this.pageToken(),
      this.entitiesToSearch(),
      this.selectedEntities(),
      this.courtSystemId(),
    )
  }

  private fetchResults(
    searchTerm: string | null,
    pageToken: string | null,
    entitiesToSearch: SearchEntityType[],
    selectedEntities: SearchEntityType[],
    courtSystemId: Uuid,
  ): void {
    if (isValidSearchTerm(searchTerm)) {
      this.store.dispatch(new SetSearchResultsScrollPositionAction(0))
      this.store.dispatch(
        new FetchSearchManyResultsAction(searchTerm, entitiesToSearch, selectedEntities, courtSystemId, pageToken),
      )
    }
  }
}

function toSearchEntityType(type: SearchRequestType): SearchEntityType | undefined {
  switch (type) {
    case SearchRequestType.AllRecordings:
      return SearchEntityType.Recordings
    case SearchRequestType.Orders:
      return SearchEntityType.Orders
    case SearchRequestType.Cases:
      return SearchEntityType.Cases
    case SearchRequestType.Hearings:
      return SearchEntityType.Hearings
    default:
      return undefined
  }
}
