import { AsyncPipe } from '@angular/common'
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, NavigationEnd, NavigationSkipped, NavigationStart, Router } from '@angular/router'
import { User } from '@ftr/contracts/api/user'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  BreadcrumbService,
  ButtonColor,
  ButtonComponent,
  ButtonDisplayType,
  DestroySubscribers,
  IconComponent,
  ScreenSize,
  ScreenSizeService,
  isMobileScreenSize,
  unwrapData,
} from '@ftr/foundation'
import { UserAvatarComponent, UserState } from '@ftr/ui-user'
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngxs/store'
import { Options } from '@popperjs/core'
import { Observable, takeUntil } from 'rxjs'
import { PersonalMenuComponent } from '~app/features/personal-menu/personal-menu.component'

@Component({
  selector: 'ftr-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [PersonalMenuComponent, NgbPopoverModule, IconComponent, UserAvatarComponent, AsyncPipe, ButtonComponent],
})
export class AccountDropdownComponent extends DestroySubscribers implements OnInit {
  @Input() currentCourtSystemId: Uuid | undefined
  @ViewChild('personalMenuPopOver') popover: NgbPopover

  user$: Observable<User>
  showingBreadcrumbs = false

  private focusTimeout: ReturnType<typeof setTimeout>

  readonly buttonDisplayType = ButtonDisplayType
  readonly buttonColor = ButtonColor

  protected readonly popperOptions = (options: Partial<Options>): Partial<Options> => {
    // Use default options for desktop
    if (!isMobileScreenSize(this.currentScreenSize)) {
      return options
    }
    // Delegate positioning to custom css class
    options.modifiers = [
      {
        name: 'applyStyles',
        enabled: false,
      },
    ]
    return options
  }

  @ViewChild(NgbPopover)
  protected personalMenuPopover: NgbPopover

  private currentScreenSize: ScreenSize

  constructor(
    private readonly store: Store,
    private readonly screenSizeService: ScreenSizeService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.user$ = this.store.select(UserState.user).pipe(unwrapData())
    this.watchScreenSize()
    this.watchRouterEvents()
  }

  onFocusIn(): void {
    clearTimeout(this.focusTimeout)
  }

  // Hide popover when focus is lost
  onFocusOut(): void {
    this.focusTimeout = setTimeout(() => {
      if (this.popover) {
        this.popover.close()
      }
    }, 0)
  }

  private watchScreenSize(): void {
    this.screenSizeService.size.pipe(takeUntil(this.finalize)).subscribe(size => {
      const shouldReopen = !!this.personalMenuPopover?.isOpen() && this.currentScreenSize !== size
      this.currentScreenSize = size
      // Popper modifiers/styles aren't dynamic, that's why we close the popover when
      // resizing to and from isMobile for a smoother experience
      if (shouldReopen) {
        this.personalMenuPopover.close()
        this.personalMenuPopover.open()
      }
    })
  }

  private watchRouterEvents(): void {
    // Close the personal menu if we try to navigate away from the current page
    this.router.events.pipe(takeUntil(this.finalize)).subscribe(event => {
      if (
        (event instanceof NavigationStart || event instanceof NavigationSkipped) &&
        this.personalMenuPopover.isOpen()
      ) {
        this.personalMenuPopover.close()
      }
      if (event instanceof NavigationEnd) {
        this.showingBreadcrumbs = this.breadcrumbService.isShowingBreadcrumbs(this.activatedRoute)
      }
    })
  }
}
