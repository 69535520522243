import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared/Uuid'
import { AppPaths } from '@ftr/routing-paths'

@Injectable({
  providedIn: 'root',
})
export class ErrorPageService {
  constructor(
    private readonly router: Router,
    private readonly ngZone: NgZone,
  ) {}

  /**
   * Note: userMessage and developerMessage can be undefined
   * @param err
   */
  showForbiddenPage(err?: any): void {
    this.ngZone.run(() => {
      const courtSystemId = this.getCourtSystemIdFromUrl()
      const forbiddenUrl = courtSystemId
        ? [`/${AppPaths.CourtSystem}/${courtSystemId}/${AppPaths.Forbidden}`]
        : [`/${AppPaths.Forbidden}`]
      this.router.navigate(forbiddenUrl, {
        state: {
          userMessage: err?.details?.userMessage || err?.userMessage,
          developerMessage: err?.details?.developerMessage || err?.developerMessage,
        },
      })
    })
  }

  showForbiddenPageWithMessage(message: string): void {
    this.showForbiddenPage({
      details: {
        userMessage: message,
      },
    })
  }

  showNotFoundPage(): void {
    this.ngZone.run(() => {
      this.router.navigate([`/${AppPaths.NotFound}`], {
        skipLocationChange: true,
      })
    })
  }

  private getCourtSystemIdFromUrl(): Uuid | undefined {
    const urlSegments = this.router.url.split('/')
    const courtSystemIndex = urlSegments.findIndex(p => p === AppPaths.CourtSystem)
    if (courtSystemIndex === -1 || urlSegments.slice(courtSystemIndex).length <= 1) {
      return undefined
    }
    return urlSegments[courtSystemIndex + 1]
  }
}
