import { BrowserSnifferService } from '@ftr/foundation'
import { MAX_CONFIGURABLE_CHANNELS } from '../../types/PlaybackConfiguration'
import { ChannelMapperStrategyAbstract } from './strategy/channel-mapper-strategy.abstract'

export abstract class ChannelMapperAbstract {
  protected mapper: ChannelMapperStrategyAbstract
  protected browserSnifferService: BrowserSnifferService

  public remapChannelNumber(totalNumberOfChannels: number, channelNumber: number): number {
    return this.mapper.remapChannelNumber(totalNumberOfChannels, channelNumber)
  }

  public remapChannelIndex(totalNumberOfChannels: number, channelIndex: number): number {
    return this.mapper.remapChannelIndex(totalNumberOfChannels, channelIndex)
  }

  public abstract determineMapperStrategy(disableChromeRemapping: boolean, disableChannelRemapping: boolean): void

  public getMappingStrategyAsString(): string {
    const perChannelCount = (channelCount: number): string =>
      [...new Array(channelCount).keys()]
        .map(x => x + 1)
        .map(x => `${x} => ${this.mapper.remapChannelNumber(channelCount, x)}`)
        .join('\n')

    return [...new Array(MAX_CONFIGURABLE_CHANNELS).keys()]
      .map(x => {
        const channelCount = x + 1
        return `
${channelCount} channel
---------------
in => out
${perChannelCount(channelCount)}
`
      })
      .join('\n')
  }

  // TODO: Remove if we ever resolve AR-1401 and Chromium no longer has screwy channel mapping
  protected isChromeLike(): boolean {
    return this.browserSnifferService.isChromeLike()
  }

  protected isFirefox(): boolean {
    return this.browserSnifferService.isFirefox()
  }
}
