import { HttpHeaders as AngularHeaders, HttpParams as AngularParams, HttpParameterCodec } from '@angular/common/http'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { ApiResult, mapData, mapFailure } from '@ftr/foundation'
import { ClassConstructor, plainToClass } from '@ftr/serialization'
import { FullApiClientConfiguration, MultiRegionApiConfiguration } from '../configuration'
import {
  HttpClientRemoteData,
  HttpHeaders,
  HttpResponseType,
  SKIP_AUTHENTICATION_HEADER,
  WithResponseHeaders,
} from '../http-client'
import { SerializedHttpParams } from '../util'
import { ApiClientConfig, ApiClientType } from './api-client-config'
import { ApiErrorMapper } from './api-error.mapper'

/**
 * Options for making an HTTP request without a body
 */
export interface Request<TResponseBody> {
  /**
   * Sub path of the request. Do not include a leading '/'
   * If undefined, will make a request to the service root path
   */
  path?: string

  /**
   * Parameters to send with this request. These must be serialized with `serializeHttpParams`.
   * @see serializeHttpParams
   */
  params?: SerializedHttpParams

  /**
   * A custom codec to set HttpParams. Overrides Angular's built in encodeURIComponent + additional replacements.
   */
  encoder?: HttpParameterCodec

  /**
   * Headers to send with this request
   */
  headers?: HttpHeaders

  /**
   * Allows specifying the encoding returned by the request
   */
  responseType?: HttpResponseType

  /**
   * If specified, the response body will be strongly typed to this class type
   */
  responseBodyType?: ClassConstructor<TResponseBody>
  /**
   * Whether this route requires an Auth header e.g. register, resendConfirmation
   */
  skipAuthentication?: boolean
  /**
   * Indicates whether or not cross-site requests should be made using cookies.
   * Used by CookieAuthenticationService
   */
  withCredentials?: boolean
}

/**
 * Options for making an HTTP request with a body
 */
export interface BodyRequest<TResponseBody, TRequestBody> extends Request<TResponseBody> {
  /**
   * An object to send in the body of the request
   */
  body?: TRequestBody
}

/**
 * Represents a controller endpoint on Core ftr-api and allows interacting with the actions on that controller.
 */
export class ApiClient {
  /**
   * Subpath of the url for the specific controller service (eg: `/courtroom')
   */
  readonly baseUrl: string

  /**
   * A base definition for a service that interacts with the api
   * @param http Http client to be used for all api requests
   * @param config Global configuration to resolve the server url
   * @param clientConfig Client configuration that indicates API type and region
   * @param urlPath A subpath of the api url that all requests will be sent to. Include leading slash. Eg: '/courthouse'
   * @param apiErrorMapper A mapper for response errors
   */
  constructor(
    private readonly http: HttpClientRemoteData,
    private readonly config: FullApiClientConfiguration,
    private readonly apiErrorMapper: ApiErrorMapper,
    readonly clientConfig: ApiClientConfig,
    urlPath: string,
  ) {
    let endpoint

    switch (clientConfig.clientType) {
      case ApiClientType.api:
        this.baseUrl = `${this.config.server.url}/api${urlPath}`
        return
      case ApiClientType.regional:
        endpoint = validateAndGetClientEndpoint(clientConfig, this.config.regionalApi)
        this.baseUrl = `${endpoint}${urlPath}`
        return
      case ApiClientType.courtUploaderServer:
        endpoint = validateAndGetClientEndpoint(clientConfig, this.config.courtUploaderServer)
        this.baseUrl = `${endpoint}${urlPath}`
        return
      case ApiClientType.digitalJustice:
        endpoint = validateAndGetClientEndpoint(clientConfig, this.config.digitalJusticeApi)
        this.baseUrl = `${endpoint}${urlPath}`
        return
      case ApiClientType.audit:
        endpoint = validateAndGetClientEndpoint(clientConfig, this.config.auditApi)
        this.baseUrl = `${endpoint}${urlPath}`
        return
      case ApiClientType.rest:
        endpoint = validateAndGetClientEndpoint(clientConfig, this.config.restApi)
        this.baseUrl = `${endpoint}${urlPath}`
        return
      default:
        return assertUnreachable(clientConfig.clientType)
    }
  }

  get<TDeserializedBody, TResponseBody = TDeserializedBody>(
    request: Request<TDeserializedBody> = {},
  ): ApiResult<TResponseBody> {
    return this.http
      .get(
        this.generateUrl(request.path || ''),
        createHttpParams(request.params, request.encoder),
        createHttpHeaders(request.headers, request.skipAuthentication),
        request.withCredentials,
        request.responseType,
      )
      .pipe(
        mapFailure(x => this.apiErrorMapper.mapErrorResponse(x)),
        mapData(result => this.deserializeToCorrectType(request, result)),
      )
  }

  getWithResponseHeaders<TDserializedBody, TResponseBody = TDserializedBody>(
    request: Request<TDserializedBody>,
  ): ApiResult<WithResponseHeaders<TResponseBody>> {
    return this.http
      .getWithResponseHeaders(
        this.generateUrl(request.path || ''),
        createHttpParams(request.params, request.encoder),
        createHttpHeaders(request.headers, request.skipAuthentication),
        request.withCredentials,
        request.responseType,
      )
      .pipe(
        mapFailure(x => this.apiErrorMapper.mapErrorResponse(x)),
        mapData(({ data, headers }) => ({
          data: this.deserializeToCorrectType(request, data),
          headers,
        })),
      )
  }

  private deserializeToCorrectType<TDeserializedBody, TResponseBody = TDeserializedBody>(
    request: Request<TDeserializedBody>,
    result: any,
  ): TResponseBody {
    return request.responseBodyType
      ? (plainToClass(request.responseBodyType, result) as unknown as TResponseBody)
      : (result as TResponseBody)
  }

  post<TDeserializedBody, TResponseBody = TDeserializedBody, TRequestBody = {}>(
    request: BodyRequest<TResponseBody, TRequestBody> = {},
  ): ApiResult<TDeserializedBody> {
    return this.http
      .post(
        this.generateUrl(request.path || ''),
        request.body,
        createHttpParams(request.params, request.encoder),
        createHttpHeaders(request.headers, request.skipAuthentication),
        request.withCredentials,
        request.responseType,
      )
      .pipe(
        mapFailure(this.apiErrorMapper.mapErrorResponse),
        mapData(result => this.deserializeToCorrectType(request, result)),
      )
  }

  put<TResponseBody, TRequestBody = {}>(
    request: BodyRequest<TResponseBody, TRequestBody> = {},
  ): ApiResult<TResponseBody> {
    return this.http
      .put(
        this.generateUrl(request.path || ''),
        request.body,
        createHttpParams(request.params, request.encoder),
        createHttpHeaders(request.headers, request.skipAuthentication),
        request.withCredentials,
        request.responseType,
      )
      .pipe(
        mapFailure(this.apiErrorMapper.mapErrorResponse),
        mapData(result => this.deserializeToCorrectType(request, result)),
      )
  }

  patch<TResponseBody, TRequestBody = {}>(
    request: BodyRequest<TResponseBody, TRequestBody> = {},
  ): ApiResult<TResponseBody> {
    return this.http
      .patch(
        this.generateUrl(request.path || ''),
        request.body,
        createHttpParams(request.params, request.encoder),
        createHttpHeaders(request.headers, request.skipAuthentication),
        request.withCredentials,
        request.responseType,
      )
      .pipe(
        mapFailure(this.apiErrorMapper.mapErrorResponse),
        mapData(result => this.deserializeToCorrectType(request, result)),
      )
  }

  delete<TResponseBody, TRequestBody = {}>(
    request: BodyRequest<TResponseBody, TRequestBody> = {},
  ): ApiResult<TResponseBody> {
    return this.http
      .delete(
        this.generateUrl(request.path || ''),
        request.body,
        createHttpParams(request.params, request.encoder),
        createHttpHeaders(request.headers, request.skipAuthentication),
        request.withCredentials,
        request.responseType,
      )
      .pipe(
        mapFailure(this.apiErrorMapper.mapErrorResponse),
        mapData(result => this.deserializeToCorrectType(request, result)),
      )
  }

  private generateUrl(subPath: string): string {
    const formattedSubPath = subPath.replace(/^\//, '')
    return [this.baseUrl, formattedSubPath].join('/')
  }
}

/**
 * Angular HTTP Client HttpParams will send parameters that are undefined as 'undefined'.
 */
function createHttpParams(params: SerializedHttpParams | undefined, encoder?: HttpParameterCodec): AngularParams {
  let httpParams: AngularParams = new AngularParams({ encoder })
  if (params) {
    Object.keys(params).forEach(param => {
      if (params[param] !== undefined) {
        httpParams = httpParams.set(param, params[param])
      }
    })
  }
  return httpParams
}

/**
 * The skipAuthentication header is caught by the AuthRedirectInterceptor in order to handle requests to API
 * that do not require auth headers. It is also removed from the request.
 */
function createHttpHeaders(headers: HttpHeaders | undefined, skipAuthentication = false): AngularHeaders {
  let httpHeaders: AngularHeaders = new AngularHeaders(headers)
  if (skipAuthentication) {
    httpHeaders = httpHeaders.set(SKIP_AUTHENTICATION_HEADER, 'true')
  }
  return httpHeaders
}

/**
 * Validates and returns the endpoint configured for an api client type
 *
 * @param clientConfig Client configuration that indicates API type and region
 * @param apiConfig API configuration that includes an endpoint map
 */
function validateAndGetClientEndpoint(clientConfig: ApiClientConfig, apiConfig: MultiRegionApiConfiguration): string {
  if (!clientConfig.region) {
    throw new Error(`Region must be provided with clientConfig to hit the ${clientConfig.clientType} API`)
  }

  const endpoint = apiConfig.endpointMap[clientConfig.region]

  if (!endpoint) {
    throw new Error(`No ${clientConfig.clientType} API endpoint configured for region ${clientConfig.region}`)
  }

  return endpoint
}
