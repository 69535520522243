import { AsyncPipe, NgClass } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import {
  IconComponent,
  TIME_FORMAT,
  TimeStringParser,
  TrackingAction,
  TrackingEventType,
  TrackingService,
  getMomentDiffInSeconds,
} from '@ftr/foundation'
import moment from 'moment-timezone'
import { Observable, of } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'
import { INVALID_TIME_MESSAGE } from '../time-input'

const DAY_IN_SECONDS = 86400
const TIME_DELIMINATOR = ':'
let nextId = 0

/**
 * @deprecated Use ftr-time-input instead
 */
@Component({
  standalone: true,
  selector: 'ftr-legacy-time-input',
  templateUrl: './legacy-time-input.component.html',
  imports: [IconComponent, AsyncPipe, ReactiveFormsModule, ValidationErrorHintDirective, NgClass],
})
/**
 * An input for entering times. It will automatically try to interpret non-default time formats
 * like "8pm" and convert them to "8:00 PM".
 *
 * It can be prefilled with a `moment` value, and will return a `moment` value to its consumers.
 *
 * @deprecated Use ftr-time-input
 */
export class LegacyTimeInputComponent implements OnInit {
  /**
   * The time format to display within the time input (if a time not in this format is entered
   * the input will automatically try to convert it to this format).
   */
  @Input() timeFormat = TIME_FORMAT.TWELVE_HOUR.MINUTE_PRECISION.DEFAULT

  /**
   * The label to display to the user, it should be a brief description of the input, i.e. "Start time".
   */
  @Input() label: string

  /**
   * Whether or not this input is required to be filled in before its parent form can be submitted.
   */
  @Input() required = false

  /**
   * The form elements name
   */
  @Input() name?: string

  /**
   * The form element id
   */
  @Input() id = `ftr-time-input-${nextId++}`

  /**
   * Whether submission of the form this component is part of has been attempted. When submission is attempted, the
   * underlying validation control (i.e.: ValidationHint) may choose to display validation states for fields which have
   * not yet been touched.
   */
  @Input() submitAttempted = false

  /**
   * The form control driving the value in this component. This is used to populate the internal control
   */
  @Input() control: AbstractControl

  /**
   * Whether to highlight field errors, as per ftr-form.
   */
  @Input() highlightError: Observable<boolean> = of(false)

  /**
   * The placeholder for the input.
   */
  @Input() placeholder: string = 'HH:MM AM/PM'

  /**
   * A custom validation message for the input.
   */
  @Input() invalidTimeMessage: string = INVALID_TIME_MESSAGE

  @Input() trackData = false

  /**
   * Allows formatted date to display '24' as the hour.
   */
  @Input() useAsDuration = false

  /**
   * Emit a moment when the input changes.
   */

  @Output() onChange = new EventEmitter<moment.Moment | undefined>()

  internalControl: UntypedFormControl

  constructor(private readonly trackingService: TrackingService) {}

  ngOnInit(): void {
    const initialValue = this.formatInitialValue(this.control)
    const validators = [invalidTimeFormat(this.invalidTimeMessage)]
    if (this.required) {
      validators.push(Validators.required)
    }
    this.internalControl = new UntypedFormControl(initialValue, validators)
  }

  inputChange(value: string): void {
    if (value.trim() === '') {
      this.onChange.emit(undefined)
      return
    }

    if (this.trackData) {
      this.trackingService.track({
        event: TrackingEventType.ActivityLogFilter,
        action: TrackingAction.KeyboardInput,
        eventLabel: `Time filter term: ${value}`,
      })
    }

    // Intercept integers to be treated as minutes
    // rather than hours EG 5 becomes 00:05
    if (this.useAsDuration) {
      value = this.mapInputToDuration(value)
    }

    try {
      const parsed = TimeStringParser.parseMoment(value)
      if (this.useAsDuration) {
        this.setInputValue(this.formatAsDuration(parsed))
      } else {
        this.setInputValue(parsed.format(this.timeFormat))
      }
      this.onChange.emit(parsed)
    } catch (err) {
      // Input like 'gg' will emit undefined. Only numbers that can be parsed as times will emit.
      this.onChange.emit(undefined)
    }
  }

  /**
   * Format the moment time value passed in from the parent form to a time value.
   */
  private formatInitialValue(control: AbstractControl): string {
    return moment.isMoment(control.value) ? control.value.format(this.timeFormat) : ''
  }

  private setInputValue(value: string): void {
    this.internalControl.setValue(value)
    this.internalControl.updateValueAndValidity()
  }

  private formatAsDuration(value: moment.Moment): string {
    const durationInSeconds = getMomentDiffInSeconds(value)
    const formatted = value.format(this.timeFormat)
    // The moment format works properly until 23:59.
    // When the duration goes over 24 hours, the hour is displayed as '00'
    if (durationInSeconds < DAY_IN_SECONDS && durationInSeconds > 0) {
      return formatted
    }
    return formatted.replace('00:', '24:')
  }

  private removeNonDigits(input: string | undefined): string {
    return input ? input.replace(/\D/g, '') : ''
  }

  private insertTimeDeliminator(input: string): string {
    return input && input.length >= 4 ? `${input.slice(0, 2)}${TIME_DELIMINATOR}${input.slice(2, 4)}` : ''
  }

  private as2Digits(input: string): string {
    return input.padStart(2, '0').slice(0, 2)
  }

  private mapInputToDuration(input: string): string {
    const haveNumbers = this.removeNonDigits(input)
    if (!haveNumbers) {
      return input
    }

    let working = input

    if (working.includes(TIME_DELIMINATOR)) {
      const leftSide = this.removeNonDigits(input?.split(TIME_DELIMINATOR, 2)[0])
      const rightSide = this.removeNonDigits(input?.split(TIME_DELIMINATOR, 2)[1])
      working = `${this.as2Digits(leftSide)}${this.as2Digits(rightSide)}`
    } else {
      working = this.removeNonDigits(working).padStart(4, '0')
    }
    return this.insertTimeDeliminator(working)
  }
}

/**
 * Whether the input is a valid string that can be parsed to a time.
 */
function invalidTimeFormat(message: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }
    try {
      // Try to parse the value, only applies if there is a value
      TimeStringParser.parse(control.value)
      return null
    } catch (e) {
      return { invalidFormat: message }
    }
  }
}
