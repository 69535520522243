<ng-container
  *ngTemplateOutlet="
    dateHighlightTpl;
    context: {
      date: getTimestamp(resultItem()) | ftrCourtSystemTimeZone: { courtSystemConfiguration: courtSystemConfig() },
      format: dateFormat.Time,
    }
  "
>
</ng-container>

@if (asLogNote(resultItem()); as item) {
  <ftr-search-text-with-highlighting
    class="speaker-name"
    [searchTextHtml]="item.speakerName ?? ''"
  ></ftr-search-text-with-highlighting>
  <ftr-search-text-with-highlighting
    class="content"
    [searchTextHtml]="item.note ?? ''"
  ></ftr-search-text-with-highlighting>
}
@if (asLogSheet(resultItem()); as item) {
  <span class="logsheet-description-title">Description:</span>
  <ftr-search-text-with-highlighting
    class="content"
    [searchTextHtml]="item.description ?? ''"
  ></ftr-search-text-with-highlighting>
}
@if (asUtterance(resultItem()); as item) {
  <ftr-search-text-with-highlighting
    class="speaker-name"
    [searchTextHtml]="item.speakerName"
  ></ftr-search-text-with-highlighting>
  <ftr-search-text-with-highlighting
    class="content"
    [searchTextHtml]="item.content"
  ></ftr-search-text-with-highlighting>
}

<ng-template #dateHighlightTpl let-date="date" let-format="format" let-hl="hl">
  @if (hl) {
    <em class="hl">
      <ftr-date [date]="date" [format]="format"></ftr-date>
    </em>
  }
  @if (!hl) {
    <ftr-date [date]="date" [format]="format"></ftr-date>
  }
</ng-template>
