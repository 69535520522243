<div class="search-results-navigation">
  <p>
    Showing {{ pageStart | number }} to {{ pageEnd | number }} of {{ numResults | number }} results for "<strong>{{
      searchTerm
    }}</strong
    >"
  </p>
  <div class="search-results-navigation__controls">
    <div class="search-results-navigation__control">
      <div class="search-results-navigation__arrow-icons">
        <ftr-icon
          class="search-results-navigation__icon"
          size="Small"
          align="Middle"
          icon="NavigateUp"
          label="Use the up key on your keyboard to navigate"
        ></ftr-icon>
        <ftr-icon
          class="search-results-navigation__icon"
          icon="NavigateDown"
          size="Small"
          align="Middle"
          label="Use the down key on your keyboard to navigate"
        ></ftr-icon>
      </div>
      <div>
        <span class="search-results-navigation__icon-label"> Navigate up/down </span>
      </div>
    </div>
    <div class="search-results-navigation__control">
      <div>
        <span class="search-results-navigation__icon icon__enter">Enter</span>
      </div>
      <div>Enter to go to result</div>
    </div>
    <div class="search-results-navigation__control">
      <div>
        <span class="search-results-navigation__icon icon__esc">Esc</span>
      </div>
      <div>Close the search</div>
    </div>
  </div>
</div>
