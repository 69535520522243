import { CommonModule } from '@angular/common'
import { Component, computed, input, model, output } from '@angular/core'
import { Params, RouterModule } from '@angular/router'
import { IconAlignment, IconComponent } from '@ftr/foundation'
import { paginationComponentTestIds } from '../pagination'

export interface TokenPaginationInfo {
  offset: number
  totalItems: number

  /**
   * A pageToken to be used for the fetching the next page.  Null if there is no next page
   */
  next: string | null
  /**
   * A pageToken to be used for fetching the previous page. Null if there is no previous page
   */
  previous: string | null
}

/*
  Top - paginator above content, sets bottom margin
  Bottom - paginator below content, sets top margin
  Default - no margins set
*/
type PaginationType = 'top' | 'bottom' | 'default'

@Component({
  selector: 'ftr-token-pagination',
  templateUrl: './token-pagination.component.html',
  styleUrls: ['./token-pagination.component.css'],
  standalone: true,
  imports: [CommonModule, IconComponent, RouterModule],
})
export class TokenPaginationComponent {
  protected readonly testIds = paginationComponentTestIds
  /**
   * Pagination details
   */
  meta = model.required<TokenPaginationInfo>()
  pageSize = input.required<number>()

  disabled = input(false)

  iconAlignment = input<IconAlignment>('Middle')

  pageTokenParamName = input('page')

  paginationType = input<PaginationType>('default')
  /**
   * Emits selected page when `emitPages` is `true`
   */
  pageSelected = output<'next' | 'previous'>()

  /**
   * Whether there are further pages after this one
   */
  hasNextPage = computed(() => this.meta().next !== null)
  /**
   * Whether there are pages before the current page
   */
  hasPrevPage = computed(() => this.meta().previous !== null)
  /**
   * The number in the full list of entities of the first entity on the current page
   */
  pageStart = computed(() => this.meta().offset + 1)
  /**
   * The number in the full list of entities of the last entity on the current page
   */
  pageEnd = computed(() => Math.min(this.meta().offset + this.pageSize(), this.meta().totalItems))
  /**
   * The total number of list items across all pages
   */
  totalItems = computed(() => this.meta().totalItems)

  prevPageParams = computed<Params>(() => ({ [this.pageTokenParamName()]: this.meta().previous }))

  nextPageParams = computed<Params>(() => ({ [this.pageTokenParamName()]: this.meta().next }))

  goToPage(page: 'next' | 'previous'): void {
    // update the meta immidiately so it doesn't have to wait for the next page to load
    this.meta.update(meta => {
      const newOffset = page === 'next' ? meta.offset + this.pageSize() : meta.offset - this.pageSize()
      return { ...meta, offset: newOffset, next: null }
    })
    this.pageSelected.emit(page)
  }
}
