import { Component, HostBinding, HostListener, input, output } from '@angular/core'
import { Icon, IconColor, IconComponent } from '@ftr/foundation'

@Component({
  standalone: true,
  selector: 'ftr-search-type-filter-item',
  templateUrl: './search-type-filter-item.component.html',
  styleUrl: './search-type-filter-item.component.css',
  imports: [IconComponent],
})
export class SearchTypeFilterItemComponent {
  selected = input.required<boolean>()
  label = input.required<string>()
  numberOfResults = input.required<number | undefined>()
  icon = input.required<Icon>()

  onFilterItemSelected = output()

  @HostBinding('class.selected')
  get isSelected(): boolean {
    return this.selected()
  }

  @HostListener('click')
  @HostListener('keydown.enter')
  @HostListener('keydown.space')
  onFilterItemInteracted(): void {
    this.onFilterItemSelected.emit()
  }

  iconColor(): IconColor {
    return this.isSelected ? 'Primary' : 'GreyLight'
  }
}
