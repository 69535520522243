import { CommonModule } from '@angular/common'
import { Component, Signal, computed, input } from '@angular/core'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { IndexedLogNote, IndexedLogSheet, IndexedRemark, SearchResult } from '@ftr/contracts/api/search'
import { DateComponent, DateFormat, assertUnreachable } from '@ftr/foundation'
import { CourtSystemTimezoneModule } from '@ftr/ui-court-system'
import { SearchTextWithHighlightingComponent } from '@ftr/ui-rtstt'
import { isIndexedLogNote, isIndexedLogSheet, isIndexedRemark } from '@ftr/ui-search'

type Discriminated<T, V> = {
  [key in keyof V]: V[key]
} & { type: T }

type DiscriminatedSearchResult =
  | Discriminated<'logSheet', IndexedLogSheet>
  | Discriminated<'logNote', IndexedLogNote>
  | Discriminated<'utterance', IndexedRemark>

// The highlight index is always 0, as they have been properly pulled out
const highlightIndex = 0

@Component({
  selector: 'ftr-search-results-this-recording-item',
  templateUrl: './search-results-this-recording-item.component.html',
  styleUrls: ['./search-results-this-recording-item.component.css'],
  imports: [DateComponent, CommonModule, CourtSystemTimezoneModule, SearchTextWithHighlightingComponent],
  standalone: true,
})
export class SearchResultsThisRecordingItemComponent {
  searchResult = input.required<SearchResult<IndexedRemark | IndexedLogSheet | IndexedLogSheet>>()

  courtSystemConfig = input.required<PublicConfiguration>()

  resultItem: Signal<DiscriminatedSearchResult> = computed(() => {
    const body = this.searchResult().body
    if (isIndexedLogNote(body)) {
      return toDiscriminated('logNote', this.prepareLogNote(body))
    }

    if (isIndexedRemark(body)) {
      return toDiscriminated('utterance', this.prepareUtterance(body))
    }

    if (isIndexedLogSheet(body)) {
      return toDiscriminated('logSheet', this.prepareLogSheet(body))
    }

    throw new Error('Unexpected search result type')
  })

  readonly dateFormat = DateFormat

  protected asLogNote(searchResult: DiscriminatedSearchResult): Discriminated<'logNote', IndexedLogNote> | undefined {
    return searchResult.type === 'logNote' ? searchResult : undefined
  }

  protected asLogSheet(
    searchResult: DiscriminatedSearchResult,
  ): Discriminated<'logSheet', IndexedLogSheet> | undefined {
    return searchResult.type === 'logSheet' ? searchResult : undefined
  }

  protected asUtterance(
    searchResult: DiscriminatedSearchResult,
  ): Discriminated<'utterance', IndexedRemark> | undefined {
    return searchResult.type === 'utterance' ? searchResult : undefined
  }

  private prepareUtterance(utterance: IndexedRemark): IndexedRemark {
    const speakerName = this.getUtteranceHighlightValue(utterance, 'transcript.speakerName') || utterance.speakerName
    const content = this.getUtteranceHighlightValue(utterance, 'transcript.content') || utterance.content

    return {
      ...utterance,
      content,
      speakerName,
    }
  }

  private getUtteranceHighlightValue(utterance: IndexedRemark, highlightKey: string): string | undefined {
    return utterance.highlight?.[highlightKey]?.[highlightIndex]
  }

  private prepareLogSheet(logSheet: IndexedLogSheet): IndexedLogSheet {
    return {
      ...logSheet,
      description: logSheet.highlight?.['logSheets.description'][highlightIndex] ?? logSheet.description,
    }
  }

  private prepareLogNote(logNote: IndexedLogNote): IndexedLogNote {
    const highlightedContent = logNote.highlight
    const speakerHighlightedContent = highlightedContent?.['logSheets.logNotes.speakerName']
    const noteHighlightedContent = highlightedContent?.['logSheets.logNotes.note']

    return {
      ...logNote,
      note: noteHighlightedContent?.length ? noteHighlightedContent[highlightIndex] : logNote.note,
      speakerName: speakerHighlightedContent?.length ? speakerHighlightedContent[highlightIndex] : logNote.speakerName,
    }
  }

  protected getTimestamp(obj: DiscriminatedSearchResult): string {
    switch (obj.type) {
      case 'logNote':
        return obj.timestamp ?? ''
      case 'utterance':
        return obj.startTime
      case 'logSheet':
        return obj.timestamp ?? ''
      default:
        assertUnreachable(obj)
    }
  }
}

function toDiscriminated<T, TType>(type: TType, obj: T): Discriminated<TType, T> {
  return { type, ...obj }
}
