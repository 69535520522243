<ng-template #favoritesModalTemplate>
  <ftr-modal
    modalTitle="Edit Favorites"
    actionText="Save"
    (action)="updateFavorites()"
    (close)="closeModal()"
    [closeOnAction]="true"
    [showLoaderOnAction]="true"
    [loaderStyle]="modalLoaderStyle.AnimatedSubmitButton"
    [disable]="hasErrors()"
  >
    @for (favorite of favoriteEditStates(); track favorite) {
      <div class="favorites-edit-modal__item">
        <div class="favorites-edit-modal__item__input-group">
          <ftr-icon
            class="favorites-edit-modal__item__icon"
            [icon]="favorite.icon"
            color="GreyLight"
            size="Small"
            align="Middle"
          ></ftr-icon>
          <ftr-inline-input
            class="favorites-edit-modal__item__input"
            [value]="favorite.label"
            [placeholder]="favorite.label"
            (valueChange)="updateFavoriteLabel(favorite.id, $event)"
            [hasError]="!favorite.label || favorite.label.trim() === ''"
          ></ftr-inline-input>
          <button
            class="favorites-edit-modal__item__delete-button"
            ftrButton
            [displayType]="buttonDisplayType.Icon"
            (click)="deleteFavorite(favorite.id)"
          >
            @if (loadingStates()[favorite.id]) {
              <ftr-loader
                class="favorites-edit-modal__item__delete-loader"
                [isLoading]="true"
                [iconOnly]="true"
              ></ftr-loader>
            } @else {
              <ftr-icon
                class="favorites-edit-modal__item__delete-button__icon"
                icon="Delete"
                color="GreyLight"
                size="Small"
                align="Middle"
              ></ftr-icon>
            }
          </button>
        </div>
        @if (!favorite.label || favorite.label.trim() === '') {
          <span class="input__validation">Please enter a valid name.</span>
        }
      </div>
    }

    @if (!favorites() || favorites()!.length === 0) {
      <div class="favorites-edit-modal__no-content">
        You have no favorites. You can add some via the favorites sidebar.
      </div>
    }
  </ftr-modal>
</ng-template>
