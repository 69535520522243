import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'
import { DateComponent, DateFormat, TooltipModule, extractHtmlHighlightParts } from '@ftr/foundation'
import { SearchHighlightType } from '../search-text-with-highlighting'

@Component({
  standalone: true,
  selector: 'ftr-search-text-with-highlighting-and-truncation',
  templateUrl: './search-text-with-highlighting-and-truncation.component.html',
  styleUrls: ['./search-text-with-highlighting-and-truncation.component.css'],
  imports: [CommonModule, DateComponent, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTextWithHighlightingAndTruncationComponent {
  readonly searchTextHtml = input.required<string>()
  readonly highlightType = input<SearchHighlightType>('Normal')

  protected readonly searchParts = computed(() => extractHtmlHighlightParts(this.searchTextHtml()))
  private readonly firstMatchIndex = computed(() => this.searchParts().findIndex(part => part.isEmBlock))

  protected readonly firstGroup = computed(() =>
    this.firstMatchIndex() !== -1 ? this.searchParts().slice(0, this.firstMatchIndex()) : [],
  )
  protected readonly middleGroup = computed(() =>
    this.firstMatchIndex() !== -1 ? [this.searchParts()[this.firstMatchIndex()]] : [],
  )
  protected readonly lastGroup = computed(() =>
    this.firstMatchIndex() !== -1 ? this.searchParts().slice(this.firstMatchIndex() + 1) : this.searchParts(),
  )
  protected readonly dateFormat = DateFormat
}
