import { Component, Input, OnInit } from '@angular/core'
import { Icon, IconComponent } from '../icon'

export enum BadgeColors {
  Grey20 = 'grey-20',
  GreyLightest = 'grey-lightest',
  Primary = 'primary',
  Blueberry = 'blueberry',
  Apple = 'apple',
  Aqua = 'aqua',
  Lemon = 'lemon',
  Mandarin = 'mandarin',
  Watermelon = 'watermelon',
  Brand = 'brand',
  Grape = 'grape',
}

export interface BadgeConfig {
  color: BadgeColors
  icon: Icon
  text: string
}

@Component({
  standalone: true,
  imports: [IconComponent],
  selector: 'ftr-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css'],
})
export class BadgeComponent implements OnInit {
  @Input() color?: BadgeColors | undefined = BadgeColors.Grey20
  @Input() text?: string | undefined
  @Input() icon?: Icon | undefined

  modifier: string

  ngOnInit(): void {
    this.modifier = this.getModifier()
  }

  private getModifier(): string {
    if (this.text !== undefined && this.icon !== undefined) {
      return 'with-text-and-icon'
    }

    if (this.text !== undefined) {
      return 'only-text'
    }

    if (this.icon !== undefined) {
      return 'only-icon'
    }

    return 'empty'
  }
}
