<button
  class="account-dropdown"
  #accountPopover="ngbPopover"
  [ngbPopover]="personalMenuTemplate"
  [triggers]="'manual'"
  [autoClose]="'outside'"
  [animation]="false"
  [popperOptions]="popperOptions"
  [popoverClass]="
    showingBreadcrumbs
      ? 'account-dropdown__popover account-dropdown__popover--with-breadcrumbs'
      : 'account-dropdown__popover'
  "
  [placement]="'bottom-end'"
  (click)="accountPopover.toggle()"
  ftrButton
  #personalMenuPopOver="ngbPopover"
  [color]="buttonColor.Grey"
  [autoWidth]="true"
  [attr.aria-label]="
    (accountPopover.isOpen() ? 'Opened' : 'Closed') +
    ' Personal Menu, Select to ' +
    (accountPopover.isOpen() ? 'Close' : 'Open')
  "
>
  <ftr-user-avatar size="ExtraLarge" [user]="(user$ | async) ?? undefined"></ftr-user-avatar>
  <ftr-icon align="Middle" [icon]="accountPopover.isOpen() ? 'KeyboardArrowUp' : 'KeyboardArrowDown'"></ftr-icon>
</button>

<ng-template #personalMenuTemplate>
  <ftr-personal-menu
    [currentCourtSystemId]="currentCourtSystemId"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut()"
  ></ftr-personal-menu>
</ng-template>
