<div class="search-result">
  @if (utterance) {
    <div class="search-result__recording-details">
      <ftr-date
        [date]="
          utterance.body.startTime
            | ftrCourtSystemTimeZone
              : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.body.timeZoneId }
        "
        [format]="dateFormat.TimeWithSeconds"
      >
      </ftr-date>
      <div>
        @if (utterance.highlight['transcript.speakerName']) {
          <span [innerHTML]="utterance.highlight['transcript.speakerName'][0] | ftrHighlightHtml"></span>
        } @else {
          <span>{{ utterance.body.speakerName }}</span>
        }
      </div>
    </div>
    <div>
      @if (utterance.highlight['transcript.content']) {
        <span [innerHTML]="utterance.highlight['transcript.content'][0] | ftrHighlightHtml"></span>
      } @else {
        <span>{{ utterance.body.content }}</span>
      }
    </div>
  }

  @if (logSheet) {
    <div class="search-result__log-sheet-details">
      <ftr-date
        [date]="
          logSheet.body.timestamp!
            | ftrCourtSystemTimeZone
              : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.body.timeZoneId }
        "
        [format]="dateFormat.TimeWithSeconds"
      >
      </ftr-date>
    </div>
    <div>
      @if (logSheet.highlight['logSheets.description']) {
        <span [innerHTML]="logSheet.highlight['logSheets.description'][0] | ftrHighlightHtml"></span>
      }
    </div>
  }

  @if (logNote) {
    <div class="search-result__log-note-details">
      <ftr-date
        [date]="
          logNote.body.timestamp!
            | ftrCourtSystemTimeZone
              : { courtSystemConfiguration: courtSystemConfig, timeZoneId: searchResult.body.timeZoneId }
        "
        [format]="dateFormat.TimeWithSeconds"
      >
      </ftr-date>
      <div>
        @if (logNote.highlight['logSheets.logNotes.speakerName']) {
          <span [innerHTML]="logNote.highlight['logSheets.logNotes.speakerName'][0] | ftrHighlightHtml"></span>
        } @else {
          <span>{{ logNote.body.speakerName }}</span>
        }
      </div>
    </div>
    <div>
      @if (logNote.highlight['logSheets.logNotes.note']) {
        <span [innerHTML]="logNote.highlight['logSheets.logNotes.note'][0] | ftrHighlightHtml"></span>
      } @else {
        <span>{{ logNote.body.note }}</span>
      }
    </div>
  }
</div>
