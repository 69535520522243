import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AppPaths } from '@ftr/routing-paths'
import { RedirectGlobalAdmin } from '@ftr/ui-core'
import { AuthenticationGuard } from '@ftr/ui-user'
import { RedirectToLiveStreamRecording } from '~app/features/live-stream-redirect/redirect-to-live-stream-recording.component'
import { HelpPageModule } from '~app/pages/help/help-page.module'
import { MultifactorAuthenticationPagesModule } from '~app/pages/multifactor-authentication/multifactor-authentication-pages.module'
import { NotFoundPage } from './pages/not-found.page'
import { PasswordResetPagesModule } from './pages/password-reset/password-reset-pages.module'
import { RootModule } from './pages/root.module'
import { SignupPagesModule } from './pages/signup/signup-pages.module'

@NgModule({
  imports: [
    RootModule,
    SignupPagesModule,
    PasswordResetPagesModule,
    HelpPageModule,
    MultifactorAuthenticationPagesModule,
    RouterModule.forRoot([
      {
        path: AppPaths.DefaultPage,
        canActivate: [AuthenticationGuard],
        data: {
          breadcrumb: 'Home',
          title: 'Home',
        },
        children: [
          {
            path: AppPaths.DefaultPage,
            loadChildren: () => import('./pages/home/home-page.module').then(m => m.HomePageModule),
          },
          {
            path: `${AppPaths.Admin}/${AppPaths.CourtSystems}/:courtSystemId`,
            canActivate: [RedirectGlobalAdmin],
            component: NotFoundPage, // Dummy Component, guard will always redirect
          },
          {
            path: `${AppPaths.Admin}/livestream`,
            component: RedirectToLiveStreamRecording, // Redirects to a currently live stream recording (particularly useful for local dev)
          },
          {
            path: AppPaths.Admin,
            canActivate: [RedirectGlobalAdmin],
            component: NotFoundPage, // Dummy Component, guard will always redirect
            children: [
              {
                path: '**',
                component: NotFoundPage, // Dummy Component, guard will always redirect
              },
            ],
          },
          {
            path: AppPaths.CourtSystem,
            loadChildren: () =>
              import('./pages/court-system/court-system-pages.module').then(m => m.CourtSystemPagesModule),
          },
          {
            path: AppPaths.CourtSystems,
            loadChildren: () =>
              import('./pages/court-system/court-system-pages.module').then(m => m.CourtSystemPagesModule),
          },
          {
            path: AppPaths.Jobs,
            loadChildren: () => import('./pages/jobs/jobs-pages.module').then(m => m.JobsPagesModule),
          },
          {
            path: AppPaths.Uploads,
            loadChildren: () => import('./pages/uploads/uploads-pages.module').then(m => m.UploadsPagesModule),
          },
          {
            path: AppPaths.Orders,
            loadChildren: () => import('./pages/orders/orders-pages.module').then(m => m.OrdersPagesModule),
          },
          {
            path: AppPaths.SharedWithMe,
            loadChildren: () =>
              import('./pages/shared-with-me/shared-with-me-pages.module').then(m => m.SharedWithMePagesModule),
          },
          {
            path: AppPaths.Account,
            loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
          },
          {
            path: AppPaths.AuditLog,
            loadChildren: () =>
              import('./pages/my-audit-log/my-audit-log-pages.module').then(m => m.MyAuditLogPagesModule),
          },
          {
            path: AppPaths.Transcripts,
            loadChildren: () =>
              import('./pages/transcripts/transcripts-page.module').then(m => m.TranscriptsPageModule),
          },
          {
            path: AppPaths.Search,
            loadChildren: () => import('./pages/search/search-page.module').then(m => m.SearchPageModule),
          },
          {
            path: AppPaths.SharedRecordings,
            loadChildren: () =>
              import('./pages/shared-recordings/shared-recordings-pages.module').then(
                m => m.SharedRecordingsPagesModule,
              ),
          },
        ],
      },
      {
        path: '**',
        component: NotFoundPage,
        canActivate: [AuthenticationGuard],
      },
    ]),
    RootModule,
    SignupPagesModule,
    PasswordResetPagesModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
