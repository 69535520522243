import { Inject, Injectable } from '@angular/core'
import { API_CONFIGURATION, FullApiClientConfiguration } from '@ftr/api-shared'
import { BrowserFeaturesService, UnsupportedBrowserError } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'
import { PlayerConfiguration } from '../../types'
import { MultiChannelPlaylistService } from '../multi-channel-playlist'
import { HlsJsMediaPlayer } from './hls-js-media-player'
import { HlsNativeMediaPlayer } from './hls-native-media-player'
import { MediaFactoryService } from './media-factory.service'
import { MediaPlayerAbstract } from './media-player.abstract'

@Injectable()
export class MediaPlayerFactory {
  constructor(
    private readonly browserFeatures: BrowserFeaturesService,
    private readonly loggingService: LoggingService,
    @Inject(API_CONFIGURATION) readonly configurationService: FullApiClientConfiguration,
    private readonly mediaFactoryService: MediaFactoryService,
    private readonly multiChannelPlaylistService: MultiChannelPlaylistService,
  ) {}

  createMediaPlayer(playerConfiguration: PlayerConfiguration): MediaPlayerAbstract {
    let player: MediaPlayerAbstract
    if (this.isHlsJsPlaylist()) {
      player = new HlsJsMediaPlayer(
        this.mediaFactoryService,
        this.loggingService,
        this.configurationService,
        playerConfiguration.mediaPlayerType,
        this.multiChannelPlaylistService,
      )
    } else if (this.isNativePlaylist()) {
      player = new HlsNativeMediaPlayer(
        this.mediaFactoryService,
        this.loggingService,
        this.configurationService,
        playerConfiguration.mediaPlayerType,
        this.multiChannelPlaylistService,
      )
    } else {
      throw new UnsupportedBrowserError()
    }
    player.setup(playerConfiguration)
    return player
  }

  private isNativePlaylist(): boolean {
    return !this.isHlsJsPlaylist() && this.browserFeatures.isHttpLiveStreamingSupported()
  }

  private isHlsJsPlaylist(): boolean {
    return this.browserFeatures.isMediaSourceExtensionsSupported()
  }
}
