import { Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router'
import { registerContractSerializers } from '@ftr/contracts/shared/registerContractSerializers'
import { DesignSystemOverlayComponent } from '@ftr/forms'
import {
  DestroySubscribers,
  GlobalEventsService,
  PageTitleService,
  RemoteData,
  isInDesktopApp,
  isNotNullOrUndefined,
} from '@ftr/foundation'
import { CourtSystemsState, FetchCourtSystemPersonalizationAction } from '@ftr/ui-court-system'
import { FeatureFlagState, LaunchDarklyService } from '@ftr/ui-feature-flags'
import { StripeService } from '@ftr/ui-money'
import { LoggingService } from '@ftr/ui-observability'
import { PlaybackState } from '@ftr/ui-playback'
import { SearchState, isValidSearchTerm } from '@ftr/ui-search'
import { AnalyticsService, AuthenticationService, UserState } from '@ftr/ui-user'
import { FetchVocabularyTermsAction } from '@ftr/ui-vocab'
import { Store } from '@ngxs/store'
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { DesktopApiService } from '~app/services/desktop-api/desktop-api.service'
import { FooterService } from './features/footer/footer.service'
import { SearchParams } from './pages/search.params'
import { BootstrapNavigationService } from './services/bootstrap-navigation/bootstrap-navigation.service'
import { ForceReloadService } from './services/force-reload-service/force-reload.service'
import { RecaptchaService } from './services/recaptcha/recaptcha.service'

registerContractSerializers()

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'ftr-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent extends DestroySubscribers implements OnInit {
  @ViewChild('designSystemOverlayContainer', { read: ViewContainerRef }) designSystemOverlayContainer: ViewContainerRef

  private readonly isSearching = new BehaviorSubject(false)
  readonly isSearching$ = this.isSearching.asObservable()

  readonly footerVisible$: Observable<boolean>

  readonly backToSearchResultsFullUrl$
  readonly isPlaybackPage$

  readonly isInDesktopApp = isInDesktopApp()

  logoData: RemoteData<string | undefined> = RemoteData.notAsked()
  routeData: Data | undefined
  status: 'INIT' | 'READY' | 'LOADING' = 'INIT'

  isTippyShown: boolean

  protected showNavigationRedesign: boolean = false

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly bootstrapNavigationService: BootstrapNavigationService,
    private readonly analyticsService: AnalyticsService,
    private readonly authenticationService: AuthenticationService,
    private readonly loggingService: LoggingService,
    private readonly pageTitleService: PageTitleService,
    private readonly footerService: FooterService,
    private readonly recaptchaService: RecaptchaService,
    private readonly stripeService: StripeService,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly forceReloadService: ForceReloadService,
    private readonly globalEventsService: GlobalEventsService,
    private readonly desktopApiService: DesktopApiService,
    private readonly launchDarklyService: LaunchDarklyService,
  ) {
    super()
    this.backToSearchResultsFullUrl$ = this.store.select(SearchState.backToSearchResultsFullUrl)
    this.isPlaybackPage$ = this.store.select(PlaybackState.isPlaybackPage)
    this.footerVisible$ = combineLatest([this.isSearching$, this.footerService.getFooterVisibility()]).pipe(
      map(([isSearching, footerVisible]) => !isSearching && footerVisible),
    )
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:focusin', ['$event'])
  handleEvent(event: MouseEvent | FocusEvent): void {
    this.globalEventsService.registerEvent(event)
  }

  ngOnInit(): void {
    this.bootstrapApp()
    this.analyticsService.register()
    this.desktopApiService.initialize()
    this.pageTitleService.initialize()
    this.authenticationService
      .initialize()
      .then(() => this.initDesignSystemOverlayFeature())
      .catch(error => {
        this.loggingService.warn({
          message: 'Failed to initialize authentication service',
          error,
        })
      })
    this.recaptchaService.loadRecaptchaScript()
    this.stripeService.loadStripeScript()
    this.watchSearchParams()

    // This will automatically fetch new vocabulary terms and any personalziation for the court system
    // on switching court systems
    this.store
      .select(UserState.currentCourtSystem)
      .pipe(filter(isNotNullOrUndefined))
      .subscribe(courtSystem => {
        const courtSystemId = courtSystem.id
        this.store.dispatch([
          new FetchVocabularyTermsAction(courtSystemId),
          new FetchCourtSystemPersonalizationAction(courtSystemId),
        ])
      })

    this.store
      .select(UserState.currentCourtSystem)
      .pipe(
        switchMap(courtSystem => {
          return courtSystem
            ? this.store.select(CourtSystemsState.courtSystemPersonalization).pipe(map(f => f(courtSystem.id)))
            : of(RemoteData.success(undefined))
        }),
      )
      .subscribe(config => {
        this.logoData = config ? config.map<string | undefined>(p => p?.logoUrl) : RemoteData.success(undefined)
      })

    this.forceReloadService.reloadOnSocketMessage().subscribe()

    this.handleSecurityPolicyViolation()
  }

  private initDesignSystemOverlayFeature(): void {
    this.launchDarklyService
      .observeFlagChanges<boolean>('design-system-overlay')
      .pipe(
        distinctUntilChanged(),
        filter(designSystemOverlayEnabled => designSystemOverlayEnabled === true),
        first(),
      )
      .subscribe(() => {
        this.designSystemOverlayContainer.createComponent(DesignSystemOverlayComponent)
      })
  }

  private watchSearchParams(): void {
    this.route.queryParams
      .pipe(filter(p => p[SearchParams.SearchTerm] && isValidSearchTerm(p[SearchParams.SearchTerm])))
      .subscribe(() => {
        this.isSearching.next(true)
      })
    this.route.queryParams.pipe(filter(p => !p[SearchParams.SearchTerm])).subscribe(() => {
      this.isSearching.next(false)
    })
  }

  private bootstrapApp(): void {
    // Bootstrap navigation
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        tap(route => {
          while (route.firstChild) {
            if (route.routeConfig?.canActivate) {
              return this.bootstrapNavigationService.loadData()
            }
            route = route.firstChild
          }
          this.bootstrapNavigationService.emitAppIsReady()
        }),
        switchMap(route => {
          while (route.firstChild) {
            route = route.firstChild
          }

          return route.data
        }),
      )
      .subscribe(data => (this.routeData = data))

    // Prepare app display
    combineLatest([
      this.store.select(UserState.user),
      this.store.select(UserState.user).pipe(
        distinctUntilChanged(),
        switchMap(() => this.store.select(FeatureFlagState.resolvedFeatureFlagValue('navigation-redesign'))),
      ),
    ])
      .pipe(
        map(([userState, navRedesignState]) => {
          const newStatus = userState.isNotAsked()
            ? ('READY' as const)
            : userState.isCompleted() && navRedesignState.isCompleted()
              ? ('READY' as const)
              : ('LOADING' as const)

          const newShowNavigationRedesign = navRedesignState.isCompleted()
            ? navRedesignState.isSuccess()
              ? navRedesignState._data
              : false
            : this.showNavigationRedesign

          return {
            status: newStatus,
            showNavigationRedesign: newShowNavigationRedesign,
          }
        }),
        tap(({ status, showNavigationRedesign }) => {
          this.status = status
          this.showNavigationRedesign = showNavigationRedesign
        }),
        takeUntil(this.finalize),
      )
      .subscribe()
  }

  private handleSecurityPolicyViolation(): void {
    window.addEventListener('securitypolicyviolation', (event: SecurityPolicyViolationEvent) => {
      const violation = {
        isTrusted: event.isTrusted,
        blockedURI: event.blockedURI,
        disposition: event.disposition,
        documentURI: event.documentURI,
        effectiveDirective: event.effectiveDirective,
        lineNumber: event.lineNumber,
        referrer: event.referrer,
        sample: event.sample,
        sourceFile: event.sourceFile,
        violatedDirective: event.violatedDirective,
      }
      if (window.location.hostname !== 'localhost') {
        this.loggingService.info({
          message: 'SecurityPolicyViolation',
          violation,
        })
      }
    })
  }

  showTippy(): void {
    this.isTippyShown = true
  }
}
