import { animate, style, transition, trigger } from '@angular/animations'
import { NgClass } from '@angular/common'
import { Component, ElementRef, Input, OnDestroy, OnInit, computed, effect, input, viewChild } from '@angular/core'
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { ValidationErrorHintDirective } from '@ftr/forms'
import {
  ButtonColor,
  ButtonComponent,
  ButtonDisplayType,
  DestroySubscribers,
  IconComponent,
  ScreenSizeService,
  isMobileScreenSize,
} from '@ftr/foundation'
import { AppPaths, CourtSystemPaths } from '@ftr/routing-paths'
import { ClearSearchManyResultsAction, SearchFocusState, SearchState, SetSearchFocusStateAction } from '@ftr/ui-search'
import { Store } from '@ngxs/store'
import { debounce } from 'lodash-es'
import { takeUntil } from 'rxjs'
import { FormGroupMembers, SearchBarService } from '~app/core/header/search-container/search-bar/search-bar.service'
import { SearchParams } from '~app/pages/search.params'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'

const SEARCH_BAR_TRANSITION_MS = 300
const TYPEAHEAD_DEBOUNCE_MS = 300

@Component({
  standalone: true,
  selector: 'ftr-search-bar-new',
  templateUrl: './search-bar-new.component.html',
  styleUrls: ['./search-bar-new.component.css'],
  animations: [
    trigger('expandSearch', [
      transition(':enter', [style({ width: 0 }), animate(SEARCH_BAR_TRANSITION_MS, style({ width: '100%' }))]),
    ]),
  ],
  imports: [IconComponent, ValidationErrorHintDirective, ButtonComponent, FtrFormsModule, NgClass],
})
export class SearchBarNewComponent extends DestroySubscribers implements OnInit, OnDestroy {
  @Input() courtSystemId: Uuid

  searchTerms = input.required<string>()
  searchIpt = viewChild<ElementRef<HTMLInputElement>>('searchIpt')

  formGroup: FormGroup

  protected readonly buttonDisplayType = ButtonDisplayType
  protected readonly buttonColor = ButtonColor
  protected readonly isMobileScreenSize = isMobileScreenSize

  protected mobileViewport = computed(() => {
    return isMobileScreenSize(this.screenSizeService.sizeSignal())
  })

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store,
    private readonly searchBarService: SearchBarService,
    private readonly screenSizeService: ScreenSizeService,
  ) {
    super()
    this.formGroup = this.formBuilder.group({
      [FormGroupMembers.SearchTerm]: [''],
    })
    this.syncFormGroup()
  }

  ngOnInit(): void {
    this.syncFocusState()
  }

  ngOnDestroy(): void {
    this.formGroup.reset()
    super.ngOnDestroy()
  }

  onSearchButtonClick(): void {
    this.updateSearchParams()
  }

  onSearchInputChange(): void {
    const sanitizedFormValue = this.getSanitizedFormValue()

    if (sanitizedFormValue.length === 0) {
      this.clearSearch()
    } else if (sanitizedFormValue.length > 2) {
      this.debouncedSearch()
    }
  }

  onDown(e: Event): void {
    // prevent arrow key scroll
    e.preventDefault()
    this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.RESULTS_LIST))
  }

  clearSearch(): void {
    this.store.dispatch(new ClearSearchManyResultsAction())
    this.formGroup.controls.searchTerm.reset()
    this.searchIpt()?.nativeElement.focus()
    this.updateSearchParams()
  }

  closeSearch(): void {
    this.store.dispatch(new ClearSearchManyResultsAction())
    const priorURL = this.searchBarService.getSearchedFromUrl()
    this.searchBarService.setLastUrlBeforeSearching('')
    this.formGroup.controls.searchTerm.reset()
    const searchUrl = [`/${AppPaths.CourtSystem}`, this.courtSystemId, CourtSystemPaths.Search].join('/')
    if (priorURL && !priorURL.startsWith(searchUrl)) {
      this.router.navigateByUrl(priorURL)
    } else {
      const urlTree = this.router.parseUrl(this.router.url)
      // Preserve any non-search related query params
      delete urlTree.queryParams[SearchParams.SearchTerm]
      delete urlTree.queryParams[SearchParams.SearchPageNumber]
      this.router.navigate(['/', AppPaths.CourtSystem, this.courtSystemId])
    }
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }

  private syncFormGroup(): void {
    effect(() => {
      const search = this.searchTerms()
      this.formGroup.patchValue({
        [FormGroupMembers.SearchTerm]: search,
      })
    })
  }

  private syncFocusState(): void {
    // Set focus on input on init
    const searchInput = this.searchIpt()?.nativeElement
    if (!searchInput) {
      return
    }
    searchInput.focus()
    // Set focus from keyboard navigation
    this.store
      .select(SearchState.searchFocusState)
      .pipe(takeUntil(this.finalize))
      .subscribe(searchFocusState => {
        if (searchFocusState === SearchFocusState.SEARCH_INPUT) {
          searchInput.focus()
        }
      })
  }

  private updateSearchParams(): void {
    // We override query parameters here so that we force all page and filter's
    // related to search to be reset when the search term changes.
    this.router.navigate([], {
      queryParams: {
        [SearchParams.SearchTerm]: this.getSanitizedFormValue(),
      },
    })
  }

  private getSanitizedFormValue(): string {
    const formValue = this.formGroup.value[FormGroupMembers.SearchTerm]
    return formValue?.trim() ?? ''
  }

  private debouncedSearch = debounce(this.onSearchButtonClick, TYPEAHEAD_DEBOUNCE_MS)
}
