import { Component, input, output } from '@angular/core'
import { SearchEntityType, SearchRequestType } from '@ftr/contracts/api/search'
import { Icon } from '@ftr/foundation'
import { SearchTypeFilterItemComponent } from '~app/features/search/search-type-filter/search-type-filter-item/search-type-filter-item.component'

export interface SearchTypeFilterItemMetadata {
  type: SearchEntityType
  label: string
  icon: Icon
  selected: boolean
  numberOfResults: number | undefined
}

@Component({
  standalone: true,
  selector: 'ftr-search-type-filter',
  templateUrl: './search-type-filter.component.html',
  styleUrl: './search-type-filter.component.css',
  imports: [SearchTypeFilterItemComponent],
})
export class SearchTypeFilterComponent {
  protected readonly searchRequestType = SearchRequestType

  availableFilterItems = input.required<SearchTypeFilterItemMetadata[]>()
  onFilterItemSelected = output<SearchEntityType>()

  protected getFilterItemMetadata(type: SearchEntityType): SearchTypeFilterItemMetadata | undefined {
    return this.availableFilterItems().find(item => item.type === type)
  }
}
