import { Component, computed, input } from '@angular/core'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { Icon, IconComponent, assertUnreachable, titleCase } from '@ftr/foundation'

export type CardType = 'transcript-order' | 'audio-order' | 'realtime-order' | 'recording' | 'hearing' | 'case'

@Component({
  standalone: true,
  selector: 'ftr-card-type-label',
  imports: [IconComponent],
  styles: [
    `
      :host {
        font-size: var(--fontSizeSmall);
        line-height: var(--lineHeightSmall);
        color: var(--colorGreyLight);
        font-weight: var(--fontWeightBold);
        white-space: nowrap;
      }
    `,
  ],
  template: '<ftr-icon [icon]="icon()" [align]="\'Bottom\'" [size]="\'Regular\'"> </ftr-icon> {{ label() }}',
})
export class CardTypeLabelComponent {
  type = input.required<CardType>()
  vocabularyTerms = input.required<VocabularyTerms>()

  label = computed(() => {
    const type = this.type()
    switch (type) {
      case 'transcript-order':
        return 'Transcript Order'
      case 'audio-order':
        return 'Audio Order'
      case 'realtime-order':
        return 'Realtime Order'
      case 'recording':
        return 'Recording'
      case 'case':
        return titleCase(this.vocabularyTerms().case.singular)
      case 'hearing':
        return titleCase(this.vocabularyTerms().hearing.singular)
      default:
        assertUnreachable(type)
    }
  })

  icon = computed<Icon>(() => {
    const type = this.type()
    switch (type) {
      case 'transcript-order':
        return 'OrdersTranscript'
      case 'audio-order':
        return 'OrdersAudio'
      case 'realtime-order':
        return 'OrdersRealtime'
      case 'recording':
        return 'Waveform'
      case 'case':
        return 'Work'
      case 'hearing':
        return 'FolderOpen'
      default:
        assertUnreachable(type)
    }
  })
}
