@if (totalItems > pageSize) {
  <div class="pagination" [ngClass]="'pagination--' + paginationType">
    <span id="pagination__desc" class="pagination__desc" [attr.data-testid]="testIds.pageInfoText"
      >{{ pageStart | number }} - {{ pageEnd | number }} of {{ totalItems | number
      }}{{ exceededMaxResultsLimit ? '+' : '' }}</span
    >
    @if (!hasPrevPage) {
      <ftr-icon
        [attr.data-testid]="testIds.previousPageButton"
        class="pagination__prev pagination__prev--disabled"
        icon="KeyboardArrowLeft"
        [align]="iconAlignment"
      ></ftr-icon>
    }
    @if (hasPrevPage) {
      @if (!emitPages) {
        <a
          [attr.data-testid]="testIds.previousPageButton"
          class="pagination__prev"
          [ngClass]="{ 'pagination__prev--disabled': disabled }"
          [routerLink]="[]"
          queryParamsHandling="merge"
          [queryParams]="prevPageParams"
          (click)="$event.preventDefault()"
          data-track
          data-track-group="Pagination Prev"
        >
          <ftr-icon icon="KeyboardArrowLeft" [align]="iconAlignment"></ftr-icon>
          Previous Page. Currently showing {{ pageStart | number }} - {{ pageEnd | number }} of {{ totalItems | number
          }}{{ exceededMaxResultsLimit ? '+' : '' }}</a
        >
      }
      @if (emitPages) {
        <a
          [attr.data-testid]="testIds.previousPageButton"
          (click)="goToPage(page - 1)"
          (keydown.enter)="goToPage(page - 1)"
          class="pagination__prev"
          [ngClass]="{ 'pagination__prev--disabled': disabled }"
          tabindex="0"
          data-track
          data-track-group="Pagination Prev"
        >
          <ftr-icon icon="KeyboardArrowLeft" [align]="iconAlignment"></ftr-icon>
          Previous Page. Currently showing {{ pageStart | number }} - {{ pageEnd | number }} of {{ totalItems | number
          }}{{ exceededMaxResultsLimit ? '+' : '' }}</a
        >
      }
    }
    @if (!hasNextPage) {
      <ftr-icon
        [attr.data-testid]="testIds.nextPageButton"
        class="pagination__next pagination__next--disabled"
        icon="KeyboardArrowRight"
        [align]="iconAlignment"
      ></ftr-icon>
    }
    @if (hasNextPage) {
      @if (!emitPages) {
        <a
          [attr.data-testid]="testIds.nextPageButton"
          class="pagination__next"
          [ngClass]="{ 'pagination__next--disabled': disabled }"
          [routerLink]="[]"
          queryParamsHandling="merge"
          [queryParams]="nextPageParams"
          (click)="$event.preventDefault()"
          data-track
          data-track-group="Pagination Next"
        >
          <ftr-icon icon="KeyboardArrowRight" [align]="iconAlignment"></ftr-icon>
          Next Page. Currently showing {{ pageStart | number }} - {{ pageEnd | number }} of {{ totalItems | number
          }}{{ exceededMaxResultsLimit ? '+' : '' }}</a
        >
      }
      @if (emitPages) {
        <a
          [attr.data-testid]="testIds.nextPageButton"
          (click)="goToPage(page + 1)"
          (keydown.enter)="goToPage(page + 1)"
          class="pagination__next"
          [ngClass]="{ 'pagination__next--disabled': disabled }"
          tabindex="0"
          data-track
          data-track-group="Pagination Next"
        >
          <ftr-icon icon="KeyboardArrowRight" [align]="iconAlignment"></ftr-icon>
          Next Page. Currently showing {{ pageStart | number }} - {{ pageEnd | number }} of {{ totalItems | number
          }}{{ exceededMaxResultsLimit ? '+' : '' }}</a
        >
      }
    }
  </div>
}
