<div class="time-range">
  <div
    [class.time-range__controls]="!verticalAlign"
    [class.time-range__controls--vertical]="verticalAlign"
    [formGroup]="internalRangeForm"
  >
    <div class="time-range__controls__time">
      <ftr-legacy-time-input
        label="{{ startLabel || 'From' }}"
        name="start"
        [required]="required"
        [control]="internalRangeForm.controls['start']"
        [submitAttempted]="submitAttempted"
        [highlightError]="highlightError"
        [timeFormat]="timeFormat"
        [trackData]="true"
        (onChange)="onTimeInputChange($event, 'start')"
        [placeholder]="placeholder!"
        [useAsDuration]="useAsDuration"
      ></ftr-legacy-time-input>
    </div>
    <div class="time-range__controls__time">
      <ftr-legacy-time-input
        label="{{ endLabel || 'To' }}"
        name="end"
        [required]="required"
        [control]="internalRangeForm.controls['end']"
        [submitAttempted]="submitAttempted"
        [highlightError]="highlightError"
        [timeFormat]="timeFormat"
        [trackData]="true"
        (onChange)="onTimeInputChange($event, 'end')"
        [placeholder]="placeholder!"
        [useAsDuration]="useAsDuration"
      ></ftr-legacy-time-input>
    </div>
  </div>
  @if (shouldDisplayTimeRangeValidation()) {
    <span
      ftrValidationErrorHint
      class="input__validation time-range__range-validation"
      [ngFormControl]="internalRangeForm"
      [controlTouched]="internalRangeForm.controls['start'].touched || internalRangeForm.controls['end'].touched"
      [submitAttempted]="submitAttempted"
      label="Time Range"
    ></span>
  }
</div>
