<ftr-page [pageStyle]="pageStyle">
  <div class="card card--borderless card--shadowless password-card confirmation-sent-card">
    <article class="reset-request">
      @if (showResetPasswordWarning) {
        <ftr-notification [type]="notificationType.Warn">
          Please reset your password before continuing
        </ftr-notification>
      }
      <h1 class="password-card__title">Forgot Your password?</h1>
      <p class="password-card__subtitle">
        Please enter your email address below. We will send you an email with instructions on how to change your
        password.
      </p>
      <ftr-untyped-form
        name="reset"
        class="form-group"
        [submission]="submission"
        [formGroup]="resetForm"
        (formSubmit)="submitForm(resetForm.value)"
        submitLabel="Request Email"
        submittingLabel="Sending Email"
        [formTemplate]="form"
        [formFooterTemplate]="footer"
        [formInstructionsTemplate]="instructions"
        [submitFullWidth]="true"
      >
        <ng-template #form let-highlightErrors="highlightErrors">
          <ul class="form-list">
            <li>
              <ftr-form-input
                name="email"
                label="Email"
                type="email"
                [notrack]="true"
                [spellcheck]="false"
                [ngFormControl]="asFormControl(resetForm.controls['email'])"
                [required]="true"
                [highlightError]="highlightErrors"
              >
              </ftr-form-input>
              &nbsp;
            </li>
          </ul>
        </ng-template>
        <ng-template #footer>
          <a [routerLink]="loginPath" ftrButton [color]="buttonColor.Secondary" [fullWidth]="true" class="cancel-button"
            >Cancel</a
          >
        </ng-template>
        <ng-template #instructions>
          <p class="reset-request__instructions">
            For help logging in, please email
            <a data-track data-track-group="Support" href="mailto:support@fortherecord.com"
              >support&#64;fortherecord.com</a
            >
          </p>
        </ng-template>
      </ftr-untyped-form>
    </article>
  </div>
</ftr-page>
