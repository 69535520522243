import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { SEARCH_MAX_LENGTH, SEARCH_MIN_LENGTH } from '@ftr/contracts/api/search'

export const searchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value: string = control.value
  return value && value.length >= SEARCH_MIN_LENGTH && value.length <= SEARCH_MAX_LENGTH
    ? null
    : {
        error: `Search between ${SEARCH_MIN_LENGTH} - ${SEARCH_MAX_LENGTH} characters`,
      }
}
