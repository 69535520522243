import { Component, Input, OnInit, input } from '@angular/core'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import {
  IndexedLogNote,
  IndexedLogSheet,
  IndexedOrder,
  IndexedRecording,
  IndexedRemark,
  SearchRequestType,
  SearchResult,
  SearchResultType,
  SearchedCase,
  SearchedHearing,
} from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField, ElasticsearchIndex, VocabularyTerms } from '@ftr/contracts/type/core'
import { LineItemType } from '@ftr/contracts/type/order'
import { ZoneId } from '@js-joda/core'

export interface HighlightField<T> {
  key: string
  label: string
  value: T
}

@Component({
  selector: 'ftr-search-results-item',
  templateUrl: './search-results-item.component.html',
})
export class SearchResultsItemComponent implements OnInit {
  isSearchWithinResource = input.required<boolean>()
  @Input() searchResult: SearchResult
  @Input() courtSystemTimeZoneId: ZoneId
  @Input() courtSystemConfig: PublicConfiguration
  /**
   * If we were able to generate a destination link for this search result
   */
  @Input() hasLink: boolean
  vocabularyTerms = input.required<VocabularyTerms>()

  readonly searchIndexType = ElasticsearchIndex
  readonly searchRequestTypes = SearchRequestType
  readonly lineItemType = LineItemType

  highlight: ElasticsearchHighlightField

  ngOnInit(): void {
    this.highlight = this.searchResult.highlight
  }

  asIndexedRecording(searchResult: SearchResultType): IndexedRecording {
    return searchResult as IndexedRecording
  }

  asIndexedOrder(searchResult: SearchResultType): IndexedOrder {
    return searchResult as IndexedOrder
  }

  asSearchedCase(searchResult: SearchResultType): SearchedCase {
    return searchResult as SearchedCase
  }

  asSearchedHearing(searchResult: SearchResultType): SearchedHearing {
    return searchResult as SearchedHearing
  }

  asThisRecordingSearchResult(
    searchResult: SearchResult,
  ): SearchResult<IndexedRemark | IndexedLogSheet | IndexedLogNote> {
    return searchResult as SearchResult<IndexedRemark | IndexedLogSheet | IndexedLogNote>
  }
}
