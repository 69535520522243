import { Injectable } from '@angular/core'
import { UserGroup } from '@ftr/contracts/api/user-group'
import { ApiResult } from '@ftr/foundation'
import { FeatureFlagState } from '@ftr/ui-feature-flags'
import {
  AuthenticationService,
  GetCurrentUserAction,
  GetCurrentUserGroupsAction,
  GetInitialCurrentUserAction,
  GetInitialCurrentUserGroupsAction,
  UserState,
} from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { BehaviorSubject, combineLatest, filter, switchMap, take, tap, timer } from 'rxjs'
import { WindowRefService } from '../window/window-ref.service'

// Can't move this module quiet yet - relies on getElectronApi
const APP_READY_EVENT_TYPE = 'appready'
export const REFRESH_INTERVAL = 60000
export const appReadyEvent = new CustomEvent(APP_READY_EVENT_TYPE, {
  bubbles: true,
  cancelable: false,
})

@Injectable({
  providedIn: 'root',
})
export class BootstrapNavigationService {
  private readonly refreshInterval = REFRESH_INTERVAL
  private readonly refreshTimer = new BehaviorSubject(this.refreshInterval)
  // Set this to true to enable auto-refresh of this data.
  private readonly isRefreshingData = new BehaviorSubject(false)
  private readonly currentUserGroups$: ApiResult<UserGroup[]>
  private appIsReady = false

  constructor(
    private readonly store: Store,
    private readonly windowRefService: WindowRefService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.currentUserGroups$ = this.store.select(UserState.currentUserGroups)
    this.setupDataRefresh()
    this.windowRefService.onPageVisibility(this.checkAndReloadLoggedInUser.bind(this))
    combineLatest([
      this.currentUserGroups$.pipe(filter(d => d && d.isSuccess() && !!d.data)),
      this.store
        .select(FeatureFlagState.resolvedFeatureFlagValue('navigation-redesign'))
        .pipe(filter(d => d && d.isCompleted())),
    ]).subscribe(() => this.emitAppIsReady())
  }

  loadData(): void {
    this.store.dispatch(new GetCurrentUserAction())
    this.store.dispatch(new GetCurrentUserGroupsAction())
  }

  emitAppIsReady(): void {
    if (!this.appIsReady) {
      this.appIsReady = this.windowRefService.dispatchEvent(appReadyEvent)
      this.windowRefService.getElectronApi()?.appReady?.()
    }
  }

  startDataRefresh(): void {
    this.isRefreshingData.next(true)
    this.refreshTimer.next(this.refreshInterval)
  }

  stopDataRefresh(): void {
    this.isRefreshingData.next(false)
  }

  checkAndReloadLoggedInUser(): void {
    if (this.authenticationService.currentUser && this.authenticationService.hasUserChanged()) {
      this.windowRefService.location().reload()
    }
  }

  private setupDataRefresh(): void {
    this.refreshTimer
      .pipe(
        filter(() => this.isRefreshingData.value),
        switchMap(v => timer(v)),
        tap(() => {
          this.store.dispatch(new GetInitialCurrentUserAction())
          this.store.dispatch(new GetInitialCurrentUserGroupsAction())
          this.currentUserGroups$.pipe(take(1)).subscribe(() => this.refreshTimer.next(this.refreshInterval))
        }),
      )
      .subscribe()
  }
}
