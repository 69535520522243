import { AsyncPipe, NgClass, TitleCasePipe } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  computed,
  effect,
  input,
} from '@angular/core'
import { RouterLink } from '@angular/router'
import { CourtSystemWithMetadata } from '@ftr/contracts/api/court-system'
import { ProductionStatus } from '@ftr/contracts/type/court/ProductionStatus'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  DestroySubscribers,
  IconComponent,
  TooltipDisplay,
  TooltipModule,
  TooltipPosition,
  unwrapData,
} from '@ftr/foundation'
import { DropdownMenuComponent, DropdownMenuItemsComponent, StaticMenuItem } from '@ftr/menus'
import { AppPaths } from '@ftr/routing-paths'
import { COURT_SYSTEM_NAV_ITEMS, FtrLogoComponent, LogoSize, LogoTheme, SideNavDisplayMode } from '@ftr/ui-core'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, map, of, takeUntil } from 'rxjs'

@Component({
  selector: 'ftr-court-system-dropdown',
  templateUrl: './court-system-dropdown.component.html',
  styleUrls: ['./court-system-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    IconComponent,
    AsyncPipe,
    TooltipModule,
    DropdownMenuComponent,
    DropdownMenuItemsComponent,
    TitleCasePipe,
    RouterLink,
    FtrLogoComponent,
    NgClass,
  ],
})
export class CourtSystemDropdownComponent extends DestroySubscribers {
  currentCourtSystemId = input<Uuid | undefined>()

  @Input() collapsed: boolean
  @Input() displayMode: SideNavDisplayMode = 'DEFAULT'
  @Output() dropdownOpened = new EventEmitter<CourtSystemWithMetadata | undefined>()

  protected courtSystem: CourtSystemWithMetadata | undefined

  protected courtSystemMenuItems = computed<Observable<StaticMenuItem[]>>(() => {
    return of(this.currentCourtSystemId() ? COURT_SYSTEM_NAV_ITEMS : COURT_SYSTEM_NAV_ITEMS.slice(0, 1))
  })

  @ViewChild(DropdownMenuComponent) protected dropdown: DropdownMenuComponent

  protected readonly productionStatus = ProductionStatus
  protected readonly tooltipDisplay = TooltipDisplay
  protected readonly tooltipPosition = TooltipPosition
  protected readonly appPaths = AppPaths
  protected readonly logoSize = LogoSize
  protected readonly logoTheme = LogoTheme
  protected readonly componentSelector = 'ftr-court-system-dropdown'

  constructor(private readonly store: Store) {
    super()
    effect(() => {
      const courtSystemId = this.currentCourtSystemId()
      this.syncCourtSystem(courtSystemId)
    })
  }

  toggle(): void {
    this.dropdown.toggle()
  }

  private syncCourtSystem(courtSystemId: Uuid | undefined): void {
    this.store
      .select(UserState.courtSystems)
      .pipe(
        takeUntil(this.finalize),
        unwrapData(),
        map(courtSystems => courtSystems.find(c => c.id === courtSystemId) as CourtSystemWithMetadata),
      )
      .subscribe(courtSystem => (this.courtSystem = courtSystem))
  }
}
