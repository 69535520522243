<!-- We should center this when the new search is added to the new nav redesign -->
<ftr-search-type-filter
  [availableFilterItems]="availableFilterItems()"
  (onFilterItemSelected)="handleFilterItemSelected($event)"
>
</ftr-search-type-filter>
@if (availableFilterItems().length > 0) {
  <div class="divider" [class.divider--legacy]="isLegacyLayout()"></div>
}
<ftr-page-layout
  name="group"
  [pageStyle]="isLegacyLayout() ? pageStyle.Flat : pageStyle.Fluid"
  [notAskedTemplate]="isLegacyLayout() ? null : notAsked"
  [bodyTemplate]="body"
  [loadingTemplate]="loading"
  [displayMode]="isLegacyLayout() ? 'FILL' : 'DEFAULT'"
  [emptyBodyTemplate]="empty"
  [horizontalOuterMargin]="false"
  [entity]="viewModel$"
  [isEmptyFunction]="isEmpty"
  errorMessage="Failed to load the search results. Please try again or contact support@fortherecord.com for advice."
  (onRetry)="retry()"
>
  <ng-template #notAsked>
    <div
      class="global-search-container-content global-search-not-asked"
      [class.global-search-container-content--legacy]="isLegacyLayout()"
    >
      <!--  Not Found Template renders Not Asked if we don't provide a search term  -->
      <ftr-search-results-not-asked-not-found></ftr-search-results-not-asked-not-found>
    </div>
  </ng-template>
  <ng-template #loading>
    <div class="global-search-container-content" [class.global-search-container-content--legacy]="isLegacyLayout()">
      <ftr-complex-list-loading loadingSize="search-results"></ftr-complex-list-loading>
    </div>
  </ng-template>
  <ng-template #empty>
    @if (!isLegacyLayout()) {
      <div class="global-search-container-content global-search-not-asked">
        <ftr-search-results-not-asked-not-found [searchTerms]="searchTerm()"></ftr-search-results-not-asked-not-found>
      </div>
    } @else {
      <div class="empty-state-legacy">
        <!-- Remove underlay from module when removing flag -->
        <ftr-real-time-nav-underlay> </ftr-real-time-nav-underlay>
        <div class="empty-state-legacy-not-found">
          <ftr-search-results-not-asked-not-found [searchTerms]="searchTerm()"></ftr-search-results-not-asked-not-found>
        </div>
      </div>
    }
  </ng-template>
  <ng-template #body let-data [ftrTypedContext]="viewModel$">
    <div class="global-search-container-content" [class.global-search-container-content--legacy]="isLegacyLayout()">
      @if (data.searchResults.pagination && data.searchResults.pagination.totalItems > pageSize) {
        <ftr-token-pagination
          [pageSize]="pageSize"
          [meta]="data.searchResults.pagination"
          paginationType="default"
          (pageSelected)="trackPaginationEvent($event, data.searchResults.pagination)"
        ></ftr-token-pagination>
      } @else {
        <span class="count"
          >{{ data.searchResults.pagination.totalItems }}
          {{ data.searchResults.pagination.totalItems === 1 ? 'Result' : 'Results' }}</span
        >
      }
      <ftr-search-results-list
        [searchResults]="data.searchResults.items"
        [configuration]="data.configuration"
        [searchFocusState]="data.searchFocusState"
        [isSearchWithinResource]="false"
        [vocabularyTerms]="terms()"
        (onNewSearchFocusState)="onNewSearchFocusState.emit($event)"
        (onItemSelected)="trackSearchItemSelected($event, data.searchResults.pagination)"
      ></ftr-search-results-list>
      @if (data.searchResults.pagination && data.searchResults.pagination.totalItems > pageSize) {
        <ftr-token-pagination
          [pageSize]="pageSize"
          [meta]="data.searchResults.pagination"
          paginationType="top"
          (pageSelected)="trackPaginationEvent($event, data.searchResults.pagination)"
        ></ftr-token-pagination>
      }
    </div>
  </ng-template>
</ftr-page-layout>
