@switch (searchResult.type) {
  @case (searchIndexType.Recording) {
    @if (isSearchWithinResource()) {
      <ftr-search-results-this-recording-item
        [searchResult]="asThisRecordingSearchResult(searchResult)"
        [courtSystemConfig]="courtSystemConfig"
      >
      </ftr-search-results-this-recording-item>
    } @else {
      <ftr-search-results-all-recordings-item
        [searchResult]="asIndexedRecording(searchResult.body)"
        [courtSystemConfig]="courtSystemConfig"
        [highlight]="highlight"
        [vocabularyTerms]="vocabularyTerms()"
      >
      </ftr-search-results-all-recordings-item>
    }
  }
  @case (searchIndexType.Order) {
    @if (asIndexedOrder(searchResult.body); as searchResultBody) {
      <ftr-search-results-orders-item
        [courtSystemConfig]="courtSystemConfig"
        [highlight]="highlight"
        [searchResult]="asIndexedOrder(searchResult.body)"
        [vocabularyTerms]="vocabularyTerms()"
      >
      </ftr-search-results-orders-item>
    }
  }
  @case ('Cases') {
    @if (asSearchedCase(searchResult.body); as searchResultBody) {
      <ftr-search-results-cases-item
        [searchResult]="asSearchedCase(searchResultBody)"
        [vocabularyTerms]="vocabularyTerms()"
        [highlight]="highlight"
      ></ftr-search-results-cases-item>
    }
  }
  @case ('Hearings') {
    @if (asSearchedHearing(searchResult.body); as hearing) {
      <ftr-search-results-hearings-item
        [highlight]="highlight"
        [searchResult]="hearing"
        [vocabularyTerms]="vocabularyTerms()"
      >
      </ftr-search-results-hearings-item>
    }
  }
}
