import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { DesktopDownloadUrlService } from '@ftr/api-core'
import { BadgeColors, Colors, isInDesktopApp } from '@ftr/foundation'
import { HelpSubMenuItem } from '../item/help-sub-menu-flyout-item.component'

export const HELP_CENTER_NAV_TITLE = 'Help Center'

@Component({
  selector: 'ftr-help-sub-menu-flyout-card',
  templateUrl: './help-sub-menu-flyout-card.component.html',
  styleUrl: './help-sub-menu-flyout-card.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpSubMenuFlyoutCardComponent implements OnInit {
  items: HelpSubMenuItem[] = []

  constructor(private readonly desktopDownloadUrlService: DesktopDownloadUrlService) {}

  ngOnInit(): void {
    this.items = [
      {
        title: HELP_CENTER_NAV_TITLE,
        description: 'Access knowledge base articles for FTR Justice Cloud',
        icon: 'HelpCircle',
        iconColor: BadgeColors.Grape,
        badgeColor: Colors.Grape10,
        visible: true,
      },
      {
        title: 'Support Portal',
        description: 'Submit or track a support request, or search the FTR Gold knowledge base',
        icon: 'ActivityLog',
        iconColor: BadgeColors.Primary,
        badgeColor: Colors.Primary10,
        link: 'https://ftrgroup.service-now.com/customer',
        visible: true,
      },
      {
        title: 'Product Updates',
        description: 'The latest release notes for FTR Justice Cloud and patches for FTR Gold',
        icon: 'Error',
        iconColor: BadgeColors.Aqua,
        badgeColor: Colors.Aqua10,
        link: 'https://fortherecord.releasenotes.io',
        visible: true,
      },
      {
        title: 'System Status',
        description: 'Check the status of FTR Justice Cloud and subscribe to incident updates',
        icon: 'CheckCircle',
        iconColor: BadgeColors.Apple,
        badgeColor: Colors.Apple10,
        link: 'https://fortherecord.statuspage.io',
        visible: true,
      },
      {
        title: 'Trust Center',
        description: "Public access to FTR Justice Cloud's compliance and security posture",
        icon: 'VerifiedUser',
        iconColor: BadgeColors.Lemon,
        badgeColor: Colors.Lemon10,
        link: 'https://trust.fortherecord.com',
        visible: true,
      },
      {
        title: 'Desktop App',
        description: 'Get the FTR Justice Cloud Desktop App for a faster experience',
        icon: 'RemoteParticipant',
        iconColor: BadgeColors.Watermelon,
        badgeColor: Colors.Watermelon10,
        link: this.desktopDownloadUrlService.getDownloadUrl(),
        visible: !isInDesktopApp(),
      },
    ].filter(i => i.visible === true) as HelpSubMenuItem[]
  }
}
