<article class="personal-menu" aria-label="Personal Menu">
  <ftr-animated-remote
    [remote]="viewModel$"
    [loadingTemplate]="loading"
    [failureTemplate]="failure"
    [emptyTemplate]="failure"
    [successTemplate]="success"
  >
    <ng-template #loading>
      <div class="personal-menu__state-container">
        <ftr-loader [isLoading]="true" [iconOnly]="true" [fullPage]="true"></ftr-loader>
        Loading menu
      </div>
    </ng-template>
    <ng-template #failure>
      <div class="personal-menu__state-container">
        <ftr-icon icon="Error" color="Brand" size="ExtraLarge"></ftr-icon>
        <p>
          Personal menu failed to load.<br />
          Please <a href class="link" (click)="$event.preventDefault(); retry()">refresh</a> or try again later.
        </p>
      </div>
    </ng-template>
    <ng-template #success let-viewModel [ftrTypedContext]="viewModel$">
      <div class="personal-menu__header">
        <span>
          <ftr-user-avatar [user]="viewModel.user" size="ExtraLarge"></ftr-user-avatar>
          <div>
            <p class="personal-menu__header__name">{{ viewModel.user.givenName }} {{ viewModel.user.familyName }}</p>
            <p>{{ viewModel.user.email }}</p>
            <p><a class="link--no-underline" [routerLink]="globalNavItems.accountDetails().path">Manage Account</a></p>
          </div>
        </span>
        <a class="link--no-underline" [routerLink]="globalNavItems.myAuditLog().path">My Audit Log</a>
      </div>
      <div class="personal-menu__card">
        <div>
          @if (viewModel.identityProviderIcon) {
            <ftr-icon
              class="personal-menu__card__logo"
              [icon]="viewModel.identityProviderIcon"
              size="Small"
              align="Sub"
            ></ftr-icon>
          } @else {
            <img class="personal-menu__card__logo" alt="For The Record logo" src="{{ ftrLogoPath }}" />
          }
          <span class="personal-menu__card__title">{{ viewModel.identityProviderTitle }} account</span>
        </div>
        <div class="personal-menu__card__row">
          <span
            ><ftr-icon
              class="personal-menu__location-icon"
              icon="LocationOn"
              color="GreyLight"
              size="Small"
              align="Sub"
            ></ftr-icon
            >{{ viewModel.locationString }}</span
          >
        </div>
        @if (viewModel.isMfaEnabled !== undefined) {
          <div class="personal-menu__card__row">
            <span class="personal-menu__card__title">Multi-factor authentication</span>
            <ftr-mfa-status [isMfaEnabled]="viewModel.isMfaEnabled"></ftr-mfa-status>
          </div>
        }
      </div>
      @if (viewModel.courtSystemCount > 0) {
        <div class="personal-menu__card personal-menu__card">
          <div class="personal-menu__card__title">
            Member of {{ viewModel.courtSystemCount }} Court {{ viewModel.courtSystemCount > 1 ? 'Systems' : 'System' }}
          </div>
          @if (viewModel.courtSystem) {
            <div class="personal-menu__card__courtSystem">
              <ftr-court-system-selector-item
                [courtSystem]="viewModel.courtSystem"
                [skipBadges]="skipBadges"
                (onSelect)="onCourtSystemSelect($event)"
              ></ftr-court-system-selector-item>
            </div>
          }
          <a class="link--no-underline" [routerLink]="globalNavItems.selectCourtSystem().path"
            >{{ viewModel.courtSystem ? 'Change' : 'Select a' }} Court System</a
          >
        </div>
      }
      <div class="personal-menu__card">
        <div class="personal-menu__card__title">Personal Content</div>
        <ul class="unstyled-list">
          <li>
            <a class="link--no-underline" [routerLink]="globalNavItems.myUploads().path">
              Upload & Play
              @if (currentCourtSystemId) {
                <ftr-icon icon="SendToWaitingRoom" size="Text" align="Sub"></ftr-icon>
              }
            </a>
          </li>
          <li>
            <a class="link--no-underline" [routerLink]="globalNavItems.myOrders().path">
              My Orders
              @if (currentCourtSystemId) {
                <ftr-icon icon="SendToWaitingRoom" size="Text" align="Sub"></ftr-icon>
              }
            </a>
          </li>
          <li>
            <a class="link--no-underline" [routerLink]="globalNavItems.sharedRecordings().path">
              Shared Recordings
              @if (currentCourtSystemId) {
                <ftr-icon icon="SendToWaitingRoom" size="Text" align="Sub"></ftr-icon>
              }
            </a>
          </li>
        </ul>
      </div>
      <div class="personal-menu__footer">
        <a data-track href="https://www.fortherecord.com/terms-and-conditions/" target="_blank">Terms</a>
        <a data-track href="https://www.fortherecord.com/privacy-policy/" target="_blank">Privacy</a>
        <a [routerLink]="globalNavItems.logout().path"
          ><ftr-icon icon="Logout" size="Large" align="TextTop"></ftr-icon> Logout</a
        >
      </div>
    </ng-template>
  </ftr-animated-remote>
</article>
