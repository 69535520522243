// TODO OR-2107 remove this file

import { Timeframe } from '@ftr/contracts/api/shared'
import { HearingSegment } from '@ftr/contracts/type/court'
import { TimeFrame } from '@ftr/contracts/type/shared'
import { LocalDate, LocalDateTime, LocalTime } from '@js-joda/core'
import { RealTimeSttTranscriptElementBase } from './real-time-stt-transcript-element-base'

// TODO OR-2107 replace this with session marker types in real-time-stt-session-marker.ts
export const ALL_TRANSCRIPT_DIVIDER_TYPES = ['sessionStart', 'sessionEnd', 'futureSessionStart'] as const
export type TranscriptDividerType = (typeof ALL_TRANSCRIPT_DIVIDER_TYPES)[number]

export type TimeIndependentTranscriptDividerType = Exclude<TranscriptDividerType, 'futureSessionStart'>

/**
 * A transcript element representing a divider in the transcript. This type is used in the segment map and within the
 * transcript views itself. As such it contains view level concerns, such as id, segment id & start/end times to conform
 * with the transcript element spec.
 * @deprecated Being replaced with more specific divider types: Session Marker, Sealing Marker and Hearing Marker
 */
export interface RealTimeSttTranscriptDivider extends RealTimeSttTranscriptElementBase {
  type: 'Divider'
  dividerType: TranscriptDividerType
  /**
   * The session number that the divided is associated with. No session number means the divider occurs out of session.
   */
  sessionNumber: number | undefined
}

/**
 * A type representing the data that is needed to create a transcript divider. This is used in interfaces to allow other
 * components/pages to specify dividers & dispatch actions to create them.
 * @deprecated being replaced with RealTimeSttSessionMarker
 */
export interface RealTimeSttTranscriptDividerData {
  dividerType: TranscriptDividerType
  /**
   * The session number that the divided is associated with. No session number means the divider occurs out of session.
   */
  sessionNumber: number | undefined
  time: LocalDateTime
}

/**
 * A subset of the transcript divider data that is not dependent on the current time,
 * i.e. there is no concept of "future"
 */
export type RealTimeSttTimeIndependentTranscriptDividerData = Omit<RealTimeSttTranscriptDividerData, 'dividerType'> & {
  dividerType: TimeIndependentTranscriptDividerType
}

export function toTranscriptDivider(data: RealTimeSttTranscriptDividerData): RealTimeSttTranscriptDivider {
  return {
    dividerType: data.dividerType,
    id: `divider-${data.dividerType}-${data.time.toString()}`,
    sessionNumber: data.sessionNumber,
    startTime: data.time,
    endTime: data.time,
    type: 'Divider',
  }
}

export function getTranscriptDividersFromOnRecordTimeframes(
  onTheRecordTimeframes: Timeframe[],
  recordingDate: LocalDate,
): RealTimeSttTimeIndependentTranscriptDividerData[] {
  const timeFrames = timeframesToTimeFrames(onTheRecordTimeframes, recordingDate)
  return getTranscriptDividersFromSessionTimeFrames(timeFrames)
}

function getTranscriptDividersFromSessionTimeFrames(
  sessionTimeFrames: TimeFrame[],
): RealTimeSttTimeIndependentTranscriptDividerData[] {
  return sessionTimeFrames.flatMap((timeFrame, index) => [
    {
      dividerType: 'sessionStart',
      time: timeFrame.startTime,
      sessionNumber: index + 1,
    },
    {
      dividerType: 'sessionEnd',
      time: timeFrame.endTime,
      sessionNumber: index + 1,
    },
  ])
}

interface RecordingSessionData {
  onTheRecordTimeframes: Timeframe[]
  recordingDate: LocalDate
}

export function getTranscriptDividersDuringHearingSegments(
  recordingSessionData: RecordingSessionData,
  hearingSegments: HearingSegment[],
): RealTimeSttTimeIndependentTranscriptDividerData[] {
  const { onTheRecordTimeframes, recordingDate } = recordingSessionData
  const sessionTimeFrames = timeframesToTimeFrames(onTheRecordTimeframes, recordingDate)
  const sessionDividers = getTranscriptDividersFromSessionTimeFrames(sessionTimeFrames)

  const sessionDividersDuringHearingSegments: RealTimeSttTimeIndependentTranscriptDividerData[] =
    sessionDividers.filter(divider =>
      // Only show session dividers with time contained in a hearing segment
      hearingSegments.some(
        segment =>
          (segment.timeRange.start.isEqual(divider.time) || segment.timeRange.start.isBefore(divider.time)) &&
          segment.timeRange.end.isAfter(divider.time),
      ),
    )

  return sessionDividersDuringHearingSegments
}

function timeframesToTimeFrames(timeframes: Timeframe[], recordingDate: LocalDate): TimeFrame[] {
  return timeframes.map(timeframe => ({
    startTime: recordingDate.atTime(LocalTime.of().plusSeconds(timeframe.start)),
    // We ensure that the endTime is still within the recordingDate by limiting it to 23:59:59
    endTime: recordingDate.atTime(LocalTime.of().plusSeconds(Math.min(timeframe.end, 86399))),
  }))
}
