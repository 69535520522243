<ng-container>
  <div class="favorites-actions-dropdown" [class.favorites-menu-dropdown--collapsed]="collapsed()">
    @if (favorites()?.length ?? 0 > 0) {
      @if (displayMode() === 'OVERLAY') {
        <ftr-icon
          icon="KeyboardArrowRight"
          size="Small"
          align="Middle"
          tabindex="0"
          class="court-system-dropdown__icon"
          (click)="dropDownOpened.emit(this.menuSideNavItems())"
        ></ftr-icon>
      } @else {
        <div
          class="favorites-actions-dropdown__opener"
          [class.favorites-actions-dropdown__opener--collapsed]="collapsed()"
          [class.favorites-actions-dropdown__opener--active]="popover.isOpen()"
          ftrTooltip
          message="Favorites actions"
          [showDash]="false"
          [position]="tooltipPosition.RIGHT"
          [forceDisplayState]="collapsed() ? undefined : tooltipDisplay.Hide"
          [ngbPopover]="menuTemplate"
          #popover="ngbPopover"
          placement="bottom-start"
          container="body"
          [animation]="false"
          popoverClass="favorites-popover"
        >
          <ftr-icon align="Sub" size="Small" icon="More"></ftr-icon>
        </div>
      }
    } @else {
      <div
        class="favorites-actions-dropdown__opener"
        ftrTooltip
        message="Favorite this page"
        [showDash]="false"
        [position]="collapsed() ? tooltipPosition.RIGHT : tooltipPosition.ABOVE"
        (click)="addFavoriteAction()"
      >
        <ftr-icon align="Sub" size="Small" icon="Add"></ftr-icon>
      </div>
    }
  </div>
</ng-container>
<ng-template #menuTemplate>
  <ftr-dropdown-menu-items [menuItems]="menuItems"></ftr-dropdown-menu-items>
</ng-template>
