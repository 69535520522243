import { Timeframe } from '@ftr/contracts/api/shared'
import { LiveSttResult, LiveSttResultStatus } from '@ftr/contracts/regional-api/live-stt'
import { SEALED_CONTENT_PLACEHOLDER } from '@ftr/contracts/shared/SealedContentPlaceholder'
import { ArrayUtils, Inclusivity, JsJodaUtils, isNotNullOrUndefined, toMoment } from '@ftr/foundation'
import { LocalDate, LocalDateTime, LocalTime, convert } from '@js-joda/core'
import { isEqual, minBy, uniq } from 'lodash-es'
import { RealTimeSttRemarkWithMetadataBuilder } from '../builders'
import { HearingSectionModel } from '../store'
import { isTimeInHearings, isTimeSealed } from '../utils'
import { RealTimeSttHearingMarker } from './real-time-stt-hearing-marker'
import {
  RealTimeSttPause,
  RealTimeSttPauseConfiguration,
  RealTimeSttPauseType,
  isDividerRepresentingSessionStart,
  shouldIgnorePause,
} from './real-time-stt-pause'
import { RealTimeSttRemark } from './real-time-stt-remark'
import { RealTimeSearchHighlights, RealTimeSttRemarkWithMetadata } from './real-time-stt-remark-with-metadata'
import { RealTimeSttSealingMarker } from './real-time-stt-sealing-marker'
import { PauseBreakingTranscriptElement, RealTimeSttSegment } from './real-time-stt-segment'
import { RealTimeSttSessionMarker } from './real-time-stt-session-marker'
import { RealTimeSttTranscriptDividerData } from './real-time-stt-transcript-divider'
import { RealTimeSttTranscriptElementWithMetadata } from './real-time-stt-transcript-element'

export const SEGMENT_INTERVAL_MINUTES = 15
export const SEGMENT_INTERVAL_MILLIS = SEGMENT_INTERVAL_MINUTES * LocalTime.SECONDS_PER_MINUTE * 1000

/**
 * An immutable class responsible for grouping remarks into fixed intervals, known as segments. The segments are stored
 * in an array for easy indexing. Segments should be contiguous and each one should start immediately after the previous
 * ends. Remarks belong to a particular segment based only on its start time - this means that remarks can potentially
 * overflow past the end of the segment.
 */
export class RealTimeSttSegmentMap {
  private constructor(
    readonly recordingStartTime: LocalDateTime,
    readonly pauseConfiguration: RealTimeSttPauseConfiguration,
    readonly segments: readonly RealTimeSttSegment[],
    readonly endTime: LocalDateTime | undefined,
    readonly hasContent: boolean,
    /**
     * TODO OR-2212 remove `sealedSegmentRemarks` property when removing `release-sealed-segments` flag
     * @deprecated will be removed with the removal of 'release-sealed-segments' flag
     */
    readonly sealedSegmentRemarks: readonly RealTimeSttRemark[],
    readonly searchHighlights: RealTimeSearchHighlights,
    readonly remarkIdToSequenceNumber: ReadonlyMap<string, number>,
    readonly hearings: HearingSectionModel[],
    readonly sealingMarkers: RealTimeSttSealingMarker[],
    readonly releaseSealedMarkersFlag?: boolean,
  ) {}

  // TODO OR-2212 remove `sealed` argument when removing `release-sealed-segments` flag
  static createSegmentMapWithFullDayOfSegments(
    recordingStartTime: LocalDateTime,
    initialPauseConfiguration: RealTimeSttPauseConfiguration,
    remarks: RealTimeSttRemark[],
    sealed: Timeframe[] | undefined,
    searchHighlights: RealTimeSearchHighlights,
  ): RealTimeSttSegmentMap {
    const sealedSegmentRemarks = RealTimeSttSegmentMap.createSealedContentRemarks(
      recordingStartTime.toLocalDate(),
      sealed,
    )

    const pauseConfiguration = {
      sealedSegmentMarkerBehavior: initialPauseConfiguration.sealedSegmentMarkerBehavior,
      pauseThresholdMillis: initialPauseConfiguration.pauseThresholdMillis,
      segmentMapHasSttContent: remarks.length > 0,
    }

    const firstSegmentStartTime = recordingStartTime.toLocalDate().atStartOfDay()
    let currentSegmentStartTime = firstSegmentStartTime
    // Fill interval tree with empty segments
    let i = 0
    const segments: RealTimeSttSegment[] = []
    while (currentSegmentStartTime.dayOfYear() === firstSegmentStartTime.dayOfYear()) {
      const nextSegmentStartTime = currentSegmentStartTime.plusMinutes(SEGMENT_INTERVAL_MINUTES)
      segments.push(
        RealTimeSttSegment.createEmpty(
          `stt-segment-${i++}`,
          convert(currentSegmentStartTime).toEpochMilli(),
          convert(nextSegmentStartTime).toEpochMilli() - 1,
          pauseConfiguration,
          searchHighlights,
        ).withSealedSegments(sealedSegmentRemarks),
      )
      currentSegmentStartTime = nextSegmentStartTime
    }
    return new RealTimeSttSegmentMap(
      recordingStartTime,
      pauseConfiguration,
      segments,
      undefined,
      false,
      sealedSegmentRemarks,
      searchHighlights,
      new Map(),
      [],
      [],
    ).rebuildWithAdditionalRemarks(remarks.concat(sealedSegmentRemarks))
  }

  processLiveUpdate(update: LiveSttResult): RealTimeSttSegmentMap {
    const previousSequenceNumber = this.remarkIdToSequenceNumber.get(update.id) ?? -1
    if (previousSequenceNumber >= update.sequenceNumber) {
      return this
    }

    let updatedSegmentMap: RealTimeSttSegmentMap
    if (update.status === LiveSttResultStatus.Removed) {
      updatedSegmentMap = this.removeRemark(update)
    } else {
      updatedSegmentMap = this.rebuildWithAdditionalRemarks([
        mapLiveResultToRemark(
          update,
          isTimeInHearings(update.startTime.toLocalTime(), this.hearings),
          isTimeSealed(update.startTime.toLocalTime(), this.sealingMarkers),
        ),
      ])
    }

    return updatedSegmentMap.withUpdatedSequenceNumber(update.id, update.sequenceNumber).withUpdatedPauseConfiguration()
  }

  rebuildWithTranscriptDividers(transcriptDividers: RealTimeSttTranscriptDividerData[]): RealTimeSttSegmentMap {
    const updatedSegments = this.segments.map(x => x.rebuildWithTranscriptDividers(transcriptDividers))

    return this.replaceSegments(updatedSegments).recalculateSegmentLeadingPauses()
  }

  rebuildWithSessionMarkers(sessionMarkers: RealTimeSttSessionMarker[]): RealTimeSttSegmentMap {
    const updatedSegments = this.segments.map(x => x.rebuildWithSessionMarkers(sessionMarkers))

    return this.replaceSegments(updatedSegments).recalculateSegmentLeadingPauses()
  }

  rebuildWithSealingMarkers(sealingMarkers: RealTimeSttSealingMarker[]): RealTimeSttSegmentMap {
    const updatedSegments = this.segments.map(x => x.rebuildWithSealingMarkers(sealingMarkers))

    const segmentMap = isEqual(sealingMarkers, this.sealingMarkers)
      ? this
      : this.withUpdatedSealedMarkers(sealingMarkers)

    return segmentMap.replaceSegments(updatedSegments).recalculateSegmentLeadingPauses()
  }

  rebuildWithHearingMarkers(hearingMarkers: RealTimeSttHearingMarker[]): RealTimeSttSegmentMap {
    const hearings = uniq(hearingMarkers.flatMap(h => h.hearingAnnotation))
    const updatedSegments = this.segments.map(x => x.rebuildWithHearingMarkers(hearingMarkers, hearings))

    const segmentMap = isEqual(hearings, this.hearings) ? this : this.withUpdatedHearings(hearings)
    return segmentMap.replaceSegments(updatedSegments).recalculateSegmentLeadingPauses()
  }

  /**
   * TODO OR-2212 remove when removing `release-sealed-segments` flag
   * @deprecated will be removed with the removal of 'release-sealed-segments' flag
   */
  rebuildWithUpdatedSealedSegments(sealedSegments: Timeframe[]): RealTimeSttSegmentMap {
    const sealedSegmentRemarks = RealTimeSttSegmentMap.createSealedContentRemarks(
      this.recordingStartTime.toLocalDate(),
      sealedSegments,
    )
    const updatedSegments = this.segments.map(x => x.rebuildWithSealedSegments(sealedSegmentRemarks))

    const segmentMap = isEqual(sealedSegmentRemarks, this.sealedSegmentRemarks)
      ? this
      : this.withUpdatedSealedSegments(sealedSegmentRemarks)
    return segmentMap.replaceSegments(updatedSegments).recalculateSegmentLeadingPauses()
  }

  private removeRemark(update: LiveSttResult): RealTimeSttSegmentMap {
    const segmentIndex = this.getSegmentIndex(update.startTime)
    const segment = this.segments[segmentIndex]
    if (!segment) {
      return this
    }
    const updatedSegment = segment.withRemovedRemark(update.id)

    return this.replaceSegments([updatedSegment]).recalculateSegmentLeadingPauses()
  }

  /**
   * Allocate each remark to a segment, determined by the remark's start time. Each remark will also have metadata
   * added to it depending on its position in relation to other remarks and how much text the remark content has.
   */
  private rebuildWithAdditionalRemarks(remarks: RealTimeSttRemark[]): RealTimeSttSegmentMap {
    if (this.sealedSegmentRemarks.length) {
      // TODO OR-2212 remove when removing `release-sealed-segments` flag
      remarks = this.tagRemarksAsSealed(remarks)
    }
    const updatedSegments = ArrayUtils.groupByDefined(remarks, r => this.getSegmentIndex(r.startTime)).map(
      ([segmentIndex, rs]) => {
        const segment = this.segments[segmentIndex]
        return segment.rebuildWithReplacedOrAddedRemarks(
          RealTimeSttRemarkWithMetadataBuilder.buildFromRemarks(segment.id, rs, this.searchHighlights),
        )
      },
    )

    return this.replaceSegments(updatedSegments).recalculateSegmentLeadingPauses()
  }

  private withUpdatedSequenceNumber(remarkId: string, sequenceNumber: number): RealTimeSttSegmentMap {
    const withUpdatedSequenceNumbers = new Map(this.remarkIdToSequenceNumber)
    withUpdatedSequenceNumbers.set(remarkId, sequenceNumber)
    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      this.pauseConfiguration,
      this.segments,
      this.endTime,
      this.hasContent,
      this.sealedSegmentRemarks,
      this.searchHighlights,
      withUpdatedSequenceNumbers,
      this.hearings,
      this.sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  private withUpdatedPauseConfiguration(): RealTimeSttSegmentMap {
    const updatedPauseConfiguration = {
      pauseThresholdMillis: this.pauseConfiguration.pauseThresholdMillis,
      sealedSegmentMarkerBehavior: this.pauseConfiguration.sealedSegmentMarkerBehavior,
      segmentMapHasSttContent: this.segments.some(s => s.numRemarks > 0),
    }

    if (isEqual(updatedPauseConfiguration, this.pauseConfiguration)) {
      return this
    }

    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      updatedPauseConfiguration,
      this.segments.map(s => s.rebuildWithPauseConfiguration(updatedPauseConfiguration)),
      this.endTime,
      this.hasContent,
      this.sealedSegmentRemarks,
      this.searchHighlights,
      this.remarkIdToSequenceNumber,
      this.hearings,
      this.sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  rebuildWithUpdatedSearchHighlights(searchHighlights: RealTimeSearchHighlights): RealTimeSttSegmentMap {
    const currentAndNewMatches = [
      this.searchHighlights.selected,
      this.searchHighlights.hovered,
      searchHighlights.selected,
      searchHighlights.hovered,
    ]
    const segmentIndexesToUpdate = currentAndNewMatches
      .filter(isNotNullOrUndefined)
      .map(match => this.getSegmentIndex(match.remarkMatchInfo.startTime))

    const uniqueSegmentIndexesToUpdate = new Set(segmentIndexesToUpdate)
    const updatedSegments: RealTimeSttSegment[] = []
    for (const segmentIndex of uniqueSegmentIndexesToUpdate) {
      const segment = this.segments[segmentIndex]
      updatedSegments.push(segment.rebuildWithUpdatedSearchHighlights(searchHighlights))
    }

    const segmentMapWithSearchHighlighting = this.withSearchHighlights(searchHighlights)
    return segmentMapWithSearchHighlighting.replaceSegments(updatedSegments)
  }

  getSegmentAt(time: LocalDateTime): RealTimeSttSegment | undefined {
    return this.segments[this.getSegmentIndex(time)]
  }

  getSegmentsDuring(startTime: LocalDateTime, endTime: LocalDateTime): RealTimeSttSegment[] {
    const startIndex = this.getSegmentIndex(startTime)
    const endIndex = this.getSegmentIndex(endTime)
    const matchingSegments: RealTimeSttSegment[] = []
    for (let i = startIndex; i <= endIndex; i++) {
      const segment = this.segments[i]
      if (segment) {
        matchingSegments.push(this.segments[i])
      }
    }
    // we also add the prior segment too, if it's last remark overlaps
    if (startIndex > 0 && this.segments[startIndex - 1]?.overflows) {
      matchingSegments.push(this.segments[startIndex - 1])
    }
    return matchingSegments
  }

  getRemarksAt(time: LocalDateTime): RealTimeSttRemarkWithMetadata[] {
    const segments = this.getSegmentsDuring(time, time)
    return segments.flatMap(segment => segment.getRemarksAt(time))
  }

  getPauseAt(time: LocalDateTime): RealTimeSttPause | undefined {
    // Pauses that span segments are placed in the segment where the pause ends we must loop all segments
    for (const segment of this.segments) {
      const matchingPause = segment.getPauseAt(time)
      if (matchingPause) {
        return matchingPause
      }
    }
    return undefined
  }

  getRemarksDuring(startTime: LocalDateTime, endTime: LocalDateTime): RealTimeSttRemarkWithMetadata[] {
    const segments = this.getSegmentsDuring(startTime, endTime)
    return segments.flatMap(segment => segment.getRemarksDuring(startTime, endTime))
  }

  /**`
   * Gets the closest transcript element by startTime
   * Note this element may not overlap with the time that is given, it will just be the closest.
   * Will only return undefined on an empty segment map with no pauses (possible, but extremely unlikely)
   */
  getClosestTranscriptElement(time: LocalDateTime): RealTimeSttTranscriptElementWithMetadata | undefined {
    const segmentClosestElements = this.segments.map(x => x.getClosestTranscriptElement(time))
    return minBy(segmentClosestElements, x =>
      x === undefined ? Number.MAX_SAFE_INTEGER : Math.abs(toMoment(x.startTime).moment.diff(toMoment(time).moment)),
    )
  }

  /**
   * TODO OR-2212 remove when removing `release-sealed-segments` flag
   * @deprecated will be removed with the removal of 'release-sealed-segments' flag
   */
  private static createSealedContentRemarks(date: LocalDate, sealedSegments: Timeframe[] = []): RealTimeSttRemark[] {
    return sealedSegments.map(({ start, end }) =>
      Object.assign<RealTimeSttRemark, RealTimeSttRemark>(new RealTimeSttRemark(), {
        startTime: JsJodaUtils.secondsToLocalDateTime(date, start),
        endTime: JsJodaUtils.secondsToLocalDateTime(date, end),
        isSealedContentMarker: true,
        content: SEALED_CONTENT_PLACEHOLDER,
        isSealed: false,
        id: `sealed-content-marker_${start}`,
      }),
    )
  }

  /**
   * TODO OR-2212 remove when removing `release-sealed-segments` flag
   * @deprecated will be removed with the removal of 'release-sealed-segments' flag
   */
  private tagRemarksAsSealed(remarks: RealTimeSttRemark[]): RealTimeSttRemark[] {
    const boundedSealedContentRemarks = this.remarkBoundsOfSealedSegments(remarks, this.sealedSegmentRemarks)
    return remarks.map(r => {
      const containingSealedRemark = boundedSealedContentRemarks.find(s =>
        JsJodaUtils.isDuring(s.startTime, s.endTime, r.startTime, undefined, Inclusivity.OpenClosed),
      )
      if (!containingSealedRemark) {
        return { ...r, isSealed: false, grouping: undefined }
      }

      let grouping = r.grouping
      if (containingSealedRemark.grouping?.firstStartsAt?.equals(r.startTime)) {
        grouping = { isFirst: true }
      }

      if (containingSealedRemark.grouping?.lastEndsAt?.equals(r.endTime)) {
        grouping = { isLast: true }
      }

      return { ...r, isSealed: true, grouping } as RealTimeSttRemark
    })
  }

  private remarkBoundsOfSealedSegments(
    remarks: RealTimeSttRemark[],
    sealedContentRemarks: readonly RealTimeSttRemark[],
  ): RealTimeSttRemark[] {
    return sealedContentRemarks.map(scr => ({
      ...scr,
      grouping: {
        firstStartsAt: remarks.find(r => JsJodaUtils.isSameOrAfter(r.startTime, scr.startTime))?.startTime,
        // We check that the *start time* of an included remark is before the sealed segment close
        lastEndsAt: ArrayUtils.findLast(remarks, r => JsJodaUtils.isBeforeWithTolerance(r.startTime, scr.endTime))
          ?.endTime,
      },
    }))
  }

  private replaceSegments(newSegments: readonly RealTimeSttSegment[]): RealTimeSttSegmentMap {
    if (!newSegments.length) {
      return this
    }

    let updatedAny = false
    const updatedSegments = [...this.segments]
    for (const newSegment of newSegments) {
      if (newSegment === updatedSegments[this.getSegmentIndexEpoch(newSegment.low)]) continue
      updatedSegments[this.getSegmentIndexEpoch(newSegment.low)] = newSegment
      updatedAny = true
    }
    if (!updatedAny) {
      return this
    }
    const lastSegmentWithRemark = updatedSegments.filter(s => s.lastRemark).pop()
    const hasContent = updatedSegments.some(
      // intentionally not including transcript dividers so that existing empty state behaviour is maintained
      s => s.lastRemark || s.hearingMarkers.length || s.sessionMarkers.length || s.sealingMarkers.length,
    )
    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      this.pauseConfiguration,
      updatedSegments,
      lastSegmentWithRemark?.lastRemark?.endTime,
      hasContent,
      this.sealedSegmentRemarks,
      this.searchHighlights,
      this.remarkIdToSequenceNumber,
      this.hearings,
      this.sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  private getSegmentIndex(time: LocalDateTime): number {
    const epochTime = convert(time).toEpochMilli()
    return this.getSegmentIndexEpoch(epochTime)
  }

  private getSegmentIndexEpoch(epochTime: number): number {
    if (this.segments.length === 0) return -1
    const millisSinceStart = epochTime - this.segments[0].low
    return Math.floor(millisSinceStart / SEGMENT_INTERVAL_MILLIS)
  }

  private withSearchHighlights(searchHighlights: RealTimeSearchHighlights): RealTimeSttSegmentMap {
    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      this.pauseConfiguration,
      this.segments,
      this.endTime,
      this.hasContent,
      this.sealedSegmentRemarks,
      searchHighlights,
      this.remarkIdToSequenceNumber,
      this.hearings,
      this.sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  private withUpdatedSealedSegments(sealedSegmentRemarks: RealTimeSttRemark[]): RealTimeSttSegmentMap {
    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      this.pauseConfiguration,
      this.segments,
      this.endTime,
      this.hasContent,
      sealedSegmentRemarks,
      this.searchHighlights,
      this.remarkIdToSequenceNumber,
      this.hearings,
      this.sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  private withUpdatedSealedMarkers(sealingMarkers: RealTimeSttSealingMarker[]): RealTimeSttSegmentMap {
    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      this.pauseConfiguration,
      this.segments,
      this.endTime,
      this.hasContent,
      this.sealedSegmentRemarks,
      this.searchHighlights,
      this.remarkIdToSequenceNumber,
      this.hearings,
      sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  private withUpdatedHearings(hearings: HearingSectionModel[]): RealTimeSttSegmentMap {
    return new RealTimeSttSegmentMap(
      this.recordingStartTime,
      this.pauseConfiguration,
      this.segments,
      this.endTime,
      this.hasContent,
      this.sealedSegmentRemarks,
      this.searchHighlights,
      this.remarkIdToSequenceNumber,
      hearings,
      this.sealingMarkers,
      this.releaseSealedMarkersFlag,
    )
  }

  private recalculateSegmentLeadingPauses(): RealTimeSttSegmentMap {
    const segmentsToReplace: RealTimeSttSegment[] = []
    const sealedTimeRanges = uniq(this.sealingMarkers.map(sm => sm.sealedTimeRange))

    let previousPauseBreakingElement: PauseBreakingTranscriptElement | undefined = undefined

    for (const segment of this.segments) {
      // Segments which don't have remarks don't contain pauses either - handled by the next segment which has a remark
      if (!segment.pauseMetadata.firstPauseBreakingElement || !segment.pauseMetadata.lastPauseBreakingElement) {
        segmentsToReplace.push(segment.withLeadingPause(undefined))
        continue
      }

      let pauseBetweenSegments: RealTimeSttPause | undefined

      // Get the pause from the previous segment's last pause breaking element (might be over multiple segments, or the start of the recording)
      if (!previousPauseBreakingElement) {
        // When configuration determines it we treat a pause between recording start and session start as a 'break'
        const pauseType: RealTimeSttPauseType = isDividerRepresentingSessionStart(
          segment.pauseMetadata.firstPauseBreakingElement,
        )
          ? 'session-break'
          : 'in-session-pause'
        pauseBetweenSegments = new RealTimeSttPause(
          this.recordingStartTime,
          segment.pauseMetadata.firstPauseBreakingElement.startTime,
          pauseType,
        )
      } else {
        pauseBetweenSegments = RealTimeSttPause.betweenPauseBreakingElements(
          previousPauseBreakingElement,
          segment.pauseMetadata.firstPauseBreakingElement,
        )
      }

      const ignorePause = shouldIgnorePause(
        segment.pauseMetadata.configuration,
        pauseBetweenSegments,
        previousPauseBreakingElement,
        segment.pauseMetadata.firstPauseBreakingElement,
        sealedTimeRanges,
      )

      if (ignorePause) {
        pauseBetweenSegments = undefined
      }

      previousPauseBreakingElement = segment.pauseMetadata.lastPauseBreakingElement

      segmentsToReplace.push(segment.withLeadingPause(pauseBetweenSegments))
    }
    return this.replaceSegments(segmentsToReplace)
  }
}

function mapLiveResultToRemark(update: LiveSttResult, isHearing: boolean, isSealed: boolean): RealTimeSttRemark {
  return {
    id: update.id,
    startTime: update.startTime,
    endTime: update.endTime ?? update.startTime,
    content: update.content,
    speakerId: update.speakerId,
    speakerName: update.speakerName,
    liveResult: {
      resultId: update.id,
      status: update.status,
    },
    isHearing,
    isSealed,
  }
}
