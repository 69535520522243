<div class="court-details">
  <ftr-search-text-with-highlighting
    class="court-details__location"
    [searchTextHtml]="locationHtml()"
  ></ftr-search-text-with-highlighting>

  <ftr-card-type-label type="recording" [vocabularyTerms]="vocabularyTerms()"></ftr-card-type-label>
</div>

<div class="recording-details">
  <span class="recording-details__date">
    <ng-container
      *ngTemplateOutlet="
        dateHighlightTpl;
        context: {
          date:
            searchResult().recordingStart | ftrCourtSystemTimeZone: { courtSystemConfiguration: courtSystemConfig() },
          format: dateFormat.Date,
        }
      "
    >
    </ng-container>
  </span>
  @if (isLiveStreaming()) {
    <span ftr-live-indicator></span>
  }
  @if (excerpts().length && isLegacyLayout()) {
    <span class="recording-details__excerpts-counter">
      @switch (excerpts().length) {
        @case (1) {
          Top result
        }
        @default {
          Top {{ excerpts().length }} results
        }
      }
    </span>
  }
</div>

@if (departmentDetails(); as departmentDetails) {
  <div class="department-details">
    <span>Department:</span>
    <ftr-search-text-with-highlighting
      class="department-details__name"
      [searchTextHtml]="departmentDetails"
    ></ftr-search-text-with-highlighting>
  </div>
}

@for (item of excerpts(); track $index; let last = $last) {
  <ftr-search-results-this-recording-item
    [searchResult]="item"
    [courtSystemConfig]="courtSystemConfig()"
  ></ftr-search-results-this-recording-item>
  @if (!last) {
    <div class="divider"></div>
  }
}

<ng-template #dateHighlightTpl let-date="date" let-format="format" let-hl="hl">
  @if (hl) {
    <em class="hl">
      <ftr-date [date]="date" [format]="format"></ftr-date>
    </em>
  }
  @if (!hl) {
    <ftr-date [date]="date" [format]="format"></ftr-date>
  }
</ng-template>
