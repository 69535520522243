<div class="order-details">
  <div class="order-details__values">
    <ftr-search-text-with-highlighting
      class="order-details__reference"
      [searchTextHtml]="'#' + getValue('orderReference')"
    ></ftr-search-text-with-highlighting>
    <ftr-search-text-with-highlighting
      class="order-details__case-number"
      [searchTextHtml]="getValue('caseNumber')"
    ></ftr-search-text-with-highlighting>
    <ftr-search-text-with-highlighting-and-truncation
      class="order-details__case-title"
      [searchTextHtml]="getValue('caseTitle')"
    ></ftr-search-text-with-highlighting-and-truncation>
  </div>
  <ftr-card-type-label [type]="orderCardType()" [vocabularyTerms]="vocabularyTerms()"></ftr-card-type-label>
</div>

<div class="hearing-details">
  <ftr-search-text-with-highlighting [searchTextHtml]="hearingDetailsHtml()"></ftr-search-text-with-highlighting>
  <span class="hearing-details__date">
    <ng-container
      *ngTemplateOutlet="
        dateHighlightTpl;
        context: {
          date: searchResult().hearingDate! | ftrCourtSystemTimeZone: { courtSystemConfiguration: courtSystemConfig() },
          format: dateFormat.Date,
          hl: highlight().hearingDate,
        }
      "
    >
    </ng-container>
  </span>
</div>

@if (priorityDynamicHighlight(); as priorityDynamicHighlight) {
  <div class="priority-dynamic-highlight">
    <span class="priority-dynamic-highlight__key">{{ priorityDynamicHighlight.label }}</span>
    @if (priorityDynamicHighlight.isDate) {
      <span class="priority-dynamic-highlight__value">
        <ng-container
          *ngTemplateOutlet="
            dateHighlightTpl;
            context: {
              date: priorityDynamicHighlight.highlightedValue,
              format: dateFormat.Date,
              hl: true,
            }
          "
        >
        </ng-container>
      </span>
    } @else {
      <ftr-search-text-with-highlighting
        class="priority-dynamic-highlight__value"
        [searchTextHtml]="priorityDynamicHighlight.highlightedValue"
      ></ftr-search-text-with-highlighting>
    }
  </div>
}

<ng-template #dateHighlightTpl let-date="date" let-format="format" let-hl="hl">
  @if (hl) {
    <em class="hl">
      <ftr-date [date]="date" [format]="format"></ftr-date>
    </em>
  }
  @if (!hl) {
    <ftr-date [date]="date" [format]="format"></ftr-date>
  }
</ng-template>
