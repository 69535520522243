<div class="list__item complex-list-item" [attr.aria-label]="ariaLabel" [attr.key]="key">
  <a
    class="complex-list-item__details"
    [ngClass]="{
      'complex-list-item__details--with-additional': !!additionalTemplate && !isAbsolutePositionAdditionalTemplate,
      'complex-list-item__details--no-additional': !additionalTemplate || isAbsolutePositionAdditionalTemplate,
    }"
    #linkElement
    tabindex="0"
    [routerLink]="link"
    [queryParams]="queryParams"
    (focus)="anchorFocus.emit($event)"
    (keydown)="anchorKeyDown.emit($event)"
  >
    @if (displayMode === 'single-child' && childTemplate) {
      <div class="complex-list-item__child">
        <ng-container *ngTemplateOutlet="childTemplate"></ng-container>
      </div>
    } @else {
      <div class="complex-list-item__left">
        @if (leftTemplate) {
          <ng-container *ngTemplateOutlet="leftTemplate"></ng-container>
        }
      </div>
      <div class="complex-list-item__right">
        @if (rightTemplate) {
          <ng-container *ngTemplateOutlet="rightTemplate"></ng-container>
        }
      </div>
    }
  </a>

  <ng-container *ngTemplateOutlet="additionalTemplate"></ng-container>
</div>
