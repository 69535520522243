import { ConnectedPosition } from '@angular/cdk/overlay'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { ALIGNMENTS_CLASS_PREFIX, OFFSET } from './tooltip-contants'

export enum TooltipPosition {
  LEFT = 'left',
  RIGHT = 'right',
  ABOVE = 'above',
  BELOW = 'below',
}

/**
 * This is relative to vertical and horizontal positions of the tooltip
 * When position is above/below
 * Start = Left, End = Right
 *
 * When position is left/right
 * Start = Top, End = Bottom
 */
export enum TooltipAlignment {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  // The directive will position the chevron for you
  AUTO = 'auto',
  // Follow the mouse
  MOUSE = 'mouse',
}

export interface Point {
  x: number
  y: number
}

export function getOppositeAxisOffset(
  tooltipPosition: TooltipPosition,
  tooltipAlignment: TooltipAlignment,
  relativeMouseCoords?: Point,
): number {
  if (tooltipAlignment === TooltipAlignment.MOUSE && relativeMouseCoords) {
    switch (tooltipPosition) {
      case TooltipPosition.LEFT:
      case TooltipPosition.RIGHT:
        return relativeMouseCoords.y
      case TooltipPosition.ABOVE:
      case TooltipPosition.BELOW:
        return relativeMouseCoords.x
      default:
        assertUnreachable(tooltipPosition)
    }
  }

  return 0
}

export function getVerticalAlignment(tooltipAlignment: TooltipAlignment): 'top' | 'center' | 'bottom' {
  switch (tooltipAlignment) {
    case TooltipAlignment.START:
      return 'top'
    case TooltipAlignment.AUTO:
    case TooltipAlignment.CENTER:
      return 'center'
    case TooltipAlignment.END:
      return 'bottom'
    case TooltipAlignment.MOUSE:
      return 'top'
    default:
      assertUnreachable(tooltipAlignment)
  }
}
export function getOverlayVerticalAlignment(tooltipAlignment: TooltipAlignment): 'top' | 'center' | 'bottom' {
  if (tooltipAlignment === TooltipAlignment.MOUSE) {
    return 'center'
  }
  return getVerticalAlignment(tooltipAlignment)
}

export function getHorizontalAlignment(tooltipAlignment: TooltipAlignment): 'start' | 'center' | 'end' {
  switch (tooltipAlignment) {
    case TooltipAlignment.START:
      return 'start'
    case TooltipAlignment.AUTO:
    case TooltipAlignment.CENTER:
      return 'center'
    case TooltipAlignment.END:
      return 'end'
    case TooltipAlignment.MOUSE:
      return 'start'
    default:
      assertUnreachable(tooltipAlignment)
  }
}

export function getOverlayHorizontalAlignment(tooltipAlignment: TooltipAlignment): 'start' | 'center' | 'end' {
  if (tooltipAlignment === TooltipAlignment.MOUSE) {
    return 'center'
  }
  return getHorizontalAlignment(tooltipAlignment)
}

export function invertPosition(tooltipPosition: TooltipPosition): TooltipPosition {
  switch (tooltipPosition) {
    case TooltipPosition.LEFT:
      return TooltipPosition.RIGHT
    case TooltipPosition.RIGHT:
      return TooltipPosition.LEFT
    case TooltipPosition.ABOVE:
      return TooltipPosition.BELOW
    case TooltipPosition.BELOW:
      return TooltipPosition.ABOVE
    default:
      assertUnreachable(tooltipPosition)
  }
}

export function getPosition(
  position: TooltipPosition,
  tooltipAlignment: TooltipAlignment,
  relativeMouseCoords?: Point,
): ConnectedPosition {
  const panelClass = `${ALIGNMENTS_CLASS_PREFIX}-${position}-${tooltipAlignment}`
  switch (position) {
    case TooltipPosition.LEFT:
      return {
        originX: 'start',
        originY: getVerticalAlignment(tooltipAlignment),
        overlayX: 'end',
        overlayY: getOverlayVerticalAlignment(tooltipAlignment),
        offsetX: OFFSET * -1,
        offsetY: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        panelClass,
      }
    case TooltipPosition.RIGHT:
      return {
        originX: 'end',
        originY: getVerticalAlignment(tooltipAlignment),
        overlayX: 'start',
        overlayY: getOverlayVerticalAlignment(tooltipAlignment),
        offsetX: OFFSET,
        offsetY: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        panelClass,
      }
    case TooltipPosition.ABOVE:
      return {
        originX: getHorizontalAlignment(tooltipAlignment),
        originY: 'top',
        overlayX: getOverlayHorizontalAlignment(tooltipAlignment),
        overlayY: 'bottom',
        offsetX: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        offsetY: OFFSET * -1,
        panelClass,
      }
    case TooltipPosition.BELOW:
      return {
        originX: getHorizontalAlignment(tooltipAlignment),
        originY: 'bottom',
        overlayX: getOverlayHorizontalAlignment(tooltipAlignment),
        overlayY: 'top',
        offsetX: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        offsetY: OFFSET,
        panelClass,
      }
    default:
      assertUnreachable(position)
  }
}
