export class CombinedError<ErrorType extends Error = Error> extends Error {
  originalErrors: ErrorType[]

  constructor(errors: ErrorType[]) {
    super(buildErrorMessage(errors))
    Object.setPrototypeOf(this, CombinedError.prototype)
    this.originalErrors = errors
  }
}

function buildErrorMessage(errors: Error[]): string {
  const messages = errors.map(e => e.message)
  const firstMessage = upperCaseFirstChar(messages[0])
  const otherMessages = messages.slice(1).map(m => lowerCaseFirstChar(m))
  return [firstMessage, ...otherMessages].join(', ') + '.'
}

function upperCaseFirstChar(str: string): string {
  return (str && str.slice(0, 1).toLocaleUpperCase() + str.slice(1)) || ''
}

function lowerCaseFirstChar(str: string): string {
  return (str && str.slice(0, 1).toLocaleLowerCase() + str.slice(1)) || ''
}
