import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { SearchRequestType } from '@ftr/contracts/api/search'
import { SearchState } from '@ftr/ui-search'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { SearchOverlayService } from '~app/services/search-overlay/search-overlay.service'

@Component({
  selector: 'ftr-search-results-overlay-legacy',
  templateUrl: './search-results-overlay.component.html',
  styleUrls: ['./search-results-overlay.component.css'],
})
export class SearchResultsOverlayLegacy implements OnInit, OnDestroy {
  @Input() isSearching$: Observable<boolean>
  @Input() searchType$: Observable<SearchRequestType>
  @Input() searchTerm$: Observable<string>
  @Input() searchResultsPageNumber$: Observable<number>
  readonly sttContext$ = this.store.select(SearchState.sttContext)

  constructor(
    private readonly store: Store,
    readonly overlay: SearchOverlayService,
  ) {}

  ngOnDestroy(): void {
    // Set timeout to avoid ExpressionChanged. We want to wait for
    // change detection to finish before updating anything
    setTimeout(() => this.overlay.visible.next(false))
  }

  ngOnInit(): void {
    this.overlay.visible.next(true)
  }
}
