export type TrackingEvent = GenericTrackingEvent | SearchTrackingEvent | AnnotationTrackingEvent
export interface GenericTrackingEvent {
  /**
   * Pass an event if you want to trigger a 'Custom Event' of "event" name
   */
  event?: TrackingEventType

  /**
   * Pass an optional value for the Event Action in the tag
   */
  action?: TrackingAction

  /**
   * This is an event label that can be generically used for Event tags in GTM.
   */
  eventLabel?: string

  [key: string]: string | number | Object | null | undefined
}

/**
 * Typically, Google Analytics Events consist of a category, action, label and value.
 * The action usually is a verb followed be a descriptor e.g. 'Click Play' or 'Pan Forward'
 * Events can be constructed in GTM using multiple variables.
 */
export enum TrackingAction {
  Click = 'Click',
  Slide = 'Slide',
  Submit = 'Submit',
  Open = 'Open',
  Close = 'Close',
  Success = 'Success',
  Failure = 'Failure',
  Drag = 'Drag',
  LongKeyPress = 'Long Key Press',
  KeyboardInput = 'KeyboardInput',
}

/**
 * Adding a new Event means you need to create a new Trigger in GTM, otherwise it will do nothing.
 * The data layer looks at the 'event' as a 'trigger' for a 'Custom Event' tag (e.g. Player Control trigger in GTM)
 * The following events are defined as custom events in Google Tag Manager.
 * They are also defined as constants in GTM Variables, to ensure consistency between web and GTM.
 * Vars: https://tagmanager.google.com/#/container/accounts/2763578674/containers/8654484/workspaces/119/variables
 * Events: https://tagmanager.google.com/#/container/accounts/2763578674/containers/8654484/workspaces/119/triggers
 */

export enum TrackingEventType {
  // The value of this enum maps to an 'Event Name' in a 'Custom Event' Trigger in GTM.
  PageView = 'PageView',
  FormSubmissionSuccess = 'FormSubmissionSuccess',
  ValidationError = 'ValidationError',
  Waveform = 'Waveform',
  PlayerControl = 'PlayerControl',
  // @deprecated This is only used for the old player component.
  PlayerSeekBar = 'PlayerSeekBar',
  ModalInteraction = 'ModalInteraction',
  ActivityLogFilter = 'ActivityLogFilter',
  Autoscript = 'Autoscript',
  Resize = 'Resize',
  ConfigurePermissionAccess = 'ConfigurePermissionAccess',
  DevNull = 'DevNull', // Deliberately not added to GTM
  PlaybackForceRefresh = 'PlaybackForceRefresh',
  UserParameterUpdate = 'UserParameterUpdate', // To react to user parameter changes, i.e. courtSystem or user changes
}

export type SearchTrackingEvent =
  | SearchPerformedEvent
  | SearchResultSelectedEvent
  | SearchPaginationEvent
  | SearchFilterToggledEvent

export interface SearchPerformedEvent {
  event: 'Search'
  searchTerm: string
  resultCount: number
}

export type TrackingSearchCategory = 'Orders' | 'Recordings' | 'Cases' | 'Hearings'
export interface SearchResultSelectedEvent {
  event: 'SearchResultSelected'
  searchCategory: TrackingSearchCategory
  pageNumber: number
  itemOrder: number
}

export interface SearchPaginationEvent {
  pageNumber: number
  event: 'SearchPagination'
  eventLabel: 'next' | 'previous'
}

export interface SearchFilterToggledEvent {
  event: 'SearchFilterToggled'
  searchCategory: TrackingSearchCategory
  eventLabel: 'toggled-on' | 'toggled-off'
}

export type AnnotationTrackingEvent =
  | AddHearingAnnotationEvent
  | EditHearingAnnotationEvent
  | DeleteHearingAnnotationEvent
  | HearingPopoverInteractionEvent
  | RecordingTimelineInteractionEvent
  | HearingPlaybackNavigationEvent
  | HearingEditedEvent
  | CaseEditedEvent

export interface AddHearingAnnotationEvent {
  event: 'AddHearingAnnotation'
  eventLabel: 'right-click stt' | 'live clock' | 'playback clock'
  isRecordingLive: boolean
}

type CommonAnnotationEditLabel = 'title' | 'case reference'
export interface EditHearingAnnotationEvent {
  event: 'EditHearingAnnotation'
  eventLabel: CommonAnnotationEditLabel | 'start time' | 'end time'
  isRecordingLive: boolean
}

export interface DeleteHearingAnnotationEvent {
  event: 'DeleteHearingAnnotation'
  eventLabel: 'popover' | 'conflict'
  isRecordingLive: boolean
}

export interface HearingPopoverInteractionEvent {
  event: 'HearingPopoverInteraction'
  eventLabel: 'open case' | 'jump to start' | 'jump to end' | 'jump to ongoing' | 'play'
}

export interface RecordingTimelineInteractionEvent {
  event: 'RecordingTimelineInteraction'
  eventLabel:
    | 'open'
    | 'close'
    | 'jump to session start'
    | 'jump to session end'
    | 'jump to session ongoing'
    | 'jump to sealed start'
    | 'jump to sealed end'
    | 'jump to sealed ongoing'
    | 'jump to hearing start'
    | 'jump to hearing end'
    | 'jump to hearing ongoing'
}

export interface HearingPlaybackNavigationEvent {
  event: 'HearingPlaybackNavigation'
  eventLabel: 'case page' | 'search' | 'hearing header previous' | 'hearing header next'
}

export interface HearingEditedEvent {
  event: 'HearingEdited'
  eventLabel: CommonAnnotationEditLabel
}

export interface CaseEditedEvent {
  event: 'CaseEdited'
  eventLabel: CommonAnnotationEditLabel
}
