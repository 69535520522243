import { Timeframe } from '@ftr/contracts/api/shared'
import { LocalTimeRange, TimeFrame } from '@ftr/contracts/type/shared'
import { InlineSaveStatus } from '@ftr/inline-forms'
import { LocalDate, LocalDateTime, LocalTime } from '@js-joda/core'
import { RealTimeSttMarkerEditSource } from './real-time-stt-marker'
import { TranscriptDividerType } from './real-time-stt-transcript-divider'
import { RealTimeSttTranscriptElementBase } from './real-time-stt-transcript-element-base'

export interface RealTimeSttSessionMarker extends RealTimeSttTranscriptElementBase {
  type: 'SessionMarker'
  //TODO OR-2107 rename dividerType to markerType when removing transcript dividers
  dividerType: TranscriptDividerType
  sessionTimes: LocalTimeRange
  sessionNumber: number
  status: InlineSaveStatus<RealTimeSttSessionMarker>
  showAsOngoing: boolean
  source?: RealTimeSttMarkerEditSource
  forceResetFormKey?: string
}

export function getSessionMarkersFromOnRecordTimeframes(
  onTheRecordTimeframes: Timeframe[],
  recordingDate: LocalDate,
): RealTimeSttSessionMarker[] {
  const timeFrames = timeframesToTimeFrames(onTheRecordTimeframes, recordingDate)
  return getSessionMarkersFromSessionTimeFrames(timeFrames)
}

export function getSessionMarkerId(
  dividerType: TranscriptDividerType,
  timeRange: LocalTimeRange,
  recordingDate: LocalDate,
): string {
  const startTime = (dividerType === 'sessionEnd' ? timeRange.end : timeRange.start).atDate(recordingDate)
  return `marker-${dividerType}-${startTime.toString()}`
}

function toSessionMarker(
  startTime: LocalDateTime,
  dividerType: TranscriptDividerType,
  sessionTimes: LocalTimeRange,
  sessionNumber: number,
): RealTimeSttSessionMarker {
  return {
    type: 'SessionMarker' as const,
    dividerType,
    id: getSessionMarkerId(dividerType, sessionTimes, startTime.toLocalDate()),
    //These are the same because this marker is only used to indicate the start/end of a session
    startTime,
    endTime: startTime,
    sessionTimes,
    sessionNumber,
    status: 'loaded',
    showAsOngoing: false,
  }
}

function getSessionMarkersFromSessionTimeFrames(sessionTimeFrames: TimeFrame[]): RealTimeSttSessionMarker[] {
  return sessionTimeFrames.flatMap((timeFrame, index) => {
    const sessionNumber = index + 1
    const sessionTimes = new LocalTimeRange(timeFrame.startTime.toLocalTime(), timeFrame.endTime.toLocalTime())

    return [
      toSessionMarker(timeFrame.startTime, 'sessionStart', sessionTimes, sessionNumber),
      toSessionMarker(timeFrame.endTime, 'sessionEnd', sessionTimes, sessionNumber),
    ]
  })
}

function timeframesToTimeFrames(timeframes: Timeframe[], recordingDate: LocalDate): TimeFrame[] {
  return timeframes.map(timeframe => ({
    startTime: recordingDate.atTime(LocalTime.of().plusSeconds(timeframe.start)),
    // We ensure that the endTime is still within the recordingDate by limiting it to 23:59:59
    endTime: recordingDate.atTime(LocalTime.of().plusSeconds(Math.min(timeframe.end, 86399))),
  }))
}
