@if (!isSearching) {
  <button class="search__button" aria-label="Search" (click)="navigateToSearchResults()">
    <ftr-icon icon="Search" color="Grey" align="Top"></ftr-icon>
  </button>
} @else {
  <ftr-search-bar-new
    [courtSystemId]="courtSystemId"
    [searchTerms]="searchContext()?.searchTerms ?? ''"
  ></ftr-search-bar-new>
}
