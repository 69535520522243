/* eslint-disable max-lines */
import { Injectable } from '@angular/core'
import { Timeframe } from '@ftr/contracts/api/shared'
import {
  excludeTimeFrames,
  Timecode,
  timecodeToTimeFrame,
  timeFrameToTimecode,
  timeframeToTimeFrame,
} from '@ftr/contracts/type/shared'
import {
  isInDesktopApp,
  isNotNullOrUndefined,
  isOfNonZeroLength,
  SimpleWindowRefService,
  UnsupportedBrowserError,
} from '@ftr/foundation'
import { ChronoUnit, LocalDateTime, LocalTime } from '@js-joda/core'
import { Store } from '@ngxs/store'
import { clamp, fill, includes, range } from 'lodash-es'
import { IntervalTree } from 'node-interval-tree'
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  interval,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  take,
  takeUntil,
} from 'rxjs'
import { PlaybackState, ToggleShowingChannels } from '../../store'
import {
  AudioChannelStates,
  AudioPipeline,
  DEFAULT_PLAYBACK_RATE,
  DEFAULT_VOLUME,
  MAX_CONFIGURABLE_CHANNELS,
  MAX_VOLUME,
  MediaProgress,
  MediaStatus,
  MIN_VOLUME,
  PlaybackTimeChange,
  PlayerConfiguration,
  PlayerFocusElement,
  PlayerState,
  TimecodeInterval,
  VideoPlayerState,
} from '../../types'
import { createIntervalTreeFromTimecodes } from '../../utils'
import { AuthCookiePollService } from '../auth-cookie-poll'
import { MediaPlayerAbstract, MediaPlayerFactory } from '../media-player'
import { MultiChannelPlaylistService } from '../multi-channel-playlist'
import { TrmChannelMapperService } from '../trm-channel-mapper'

@Injectable()
export class PlayerService {
  /**
   * The instance of the controllable media player.
   * Suggest keeping this public so consumers can access methods on it.
   * It can be undefined because components outside of player component can be instantiated earlier, and still want
   * to use this property.
   */
  public mediaPlayer: MediaPlayerAbstract | undefined
  /**
   * Components that are not part of PlayerComponent often rely on `mediaPlayer` events or values.
   * Where we rely on mediaPlayer existing, we should subscribe to this observable and wait until it emits true
   * before listening to events from mediaPlayer.
   * Needs to be manually reset onDestroy because otherwise its always true when you navigate to new recordings
   */
  public mediaPlayerReady = new BehaviorSubject<boolean>(false)
  /**
   * The player component subscribes to the auth cookie check and returns true when its been updated.
   * Doing it in this way avoids needing to subscribe to the cookie update before calling playerService.setup
   * from the component. Waiting to instantiate a media player would have meant that child components would have not
   * been able to access mediaPlayer in ngOnInit.
   */
  readonly isAuthenticated = new BehaviorSubject<boolean>(false)
  /**
   * The start time and duration (timecodes) for each segment in the recording.
   */
  readonly timecodes = new BehaviorSubject<Timecode[] | undefined>(undefined)
  /**
   * Same as timecodes - but have not be processed (i.e had their sealed segments removed)
   * Used by stream recordings to generate loudness data.
   */
  readonly originalTimecodes = new BehaviorSubject<Timecode[] | undefined>(undefined)

  /**
   * The latest mediaElement.currentTime value in seconds and milliseconds e.g. 00.00
   */
  currentPlaybackTime = 0.0

  playbackTimeUpdates$ = new ReplaySubject<PlaybackTimeChange>(2)

  /**
   * The current playback date time object.
   */
  currentPlaybackDateTime: LocalDateTime | undefined

  /**
   * Indicates if the browser supports HLS playback, whether live or past stream recordings, from TRM or otherwise.
   */
  supportsHLSPlayback = true

  timecodeIntervalTree: IntervalTree<TimecodeInterval> = new IntervalTree<TimecodeInterval>()

  /**
   * The current state of the media player e.g. loading, loaded, errored, playing, paused, buffering.
   * These statuses should be exclusive of each other.
   */
  readonly playerState = new BehaviorSubject<PlayerState>(PlayerState.Loading)

  readonly videoPlayerState = new BehaviorSubject<VideoPlayerState>({})

  readonly isFullySealed$: Observable<boolean>

  /**
   * The wall clock start/end time of the recording
   */
  startTime: LocalDateTime | undefined
  endTime: LocalDateTime | undefined

  // We currently only pause on seek when there is a transcript.
  pauseDuringSeek = false

  // used internally to restart the player
  private playOnSeekEnd = false

  /**
   * This prevents the player service from being setup twice.
   * The player service was initially scoped to each player component, to allow for different usages.
   * PlayerService is now a singleton in root scope, meaning its state is shared by all usages of player component.
   * So if we have player component on the page twice, it will call setupAndPlay twice.
   * This is a little hack to avoid the major issue with the current setup to support two player instances at once.
   * It does not fix all the duplicate call issues, caused by having
   * two players on the one page (e.g. setting timecodes twice)
   * A future potential improvement might be instantiating the player once at a higher level than player component.
   */
  private playerServiceSetup = false
  /**
   * Is the user currently interacting with the player (scrubbing, seeking, using keys etc)
   */
  private readonly pausingUpdatesToPlayerInterface = new BehaviorSubject(false)

  /**
   * Current volume of the media player.
   */
  private readonly volume = new BehaviorSubject(DEFAULT_VOLUME)

  /**
   * The current playback rate of the media player.
   */
  private readonly playbackRate = new BehaviorSubject(DEFAULT_PLAYBACK_RATE)

  /**
   * Are we currently autoscrolling
   */
  private readonly autoscrolling = new BehaviorSubject(false)

  /**
   * The element we should attempt to focus on
   */
  private readonly focusElement = new Subject<PlayerFocusElement>()

  /**
   * Current player configuration
   */
  private playerConfig: PlayerConfiguration
  /**
   * How often to run the interval to determine if the page needs to be reloaded to handle
   * playlist segment access
   */
  private reloadCheckInterval = 30000
  readonly finalize = new Subject<void>()

  private audioPipeline: AudioPipeline | undefined
  private channelStates: AudioChannelStates | undefined
  private multiChannelEnabled: boolean

  constructor(
    private readonly mediaPlayerFactory: MediaPlayerFactory,
    private windowRefService: SimpleWindowRefService,
    private readonly authCookiePollService: AuthCookiePollService,
    private readonly store: Store,
    private readonly multiChannelPlaylistService: MultiChannelPlaylistService,
    private readonly trmMapperService: TrmChannelMapperService,
  ) {
    this.isFullySealed$ = this.observeSealedRecordingToggle()
  }

  /**
   * Constructs an instance of an Audio Element with the required configuration to start playback.
   * Also initializes playback if autoplay is set
   */
  setupAndPlay(
    playerConfiguration: PlayerConfiguration,
    disableChromiumRemapping: boolean = false,
    disableChannelRemapping: boolean = false,
  ): void {
    if (this.playerServiceSetup) {
      return
    }

    this.playerServiceSetup = true
    // Playlists are cached for 12 hours. When a playlist is generated, segment access
    // is set. If a user leaves their browser open on the playback page, we need to
    // force a page reload 12 hours later. This is managed by setting a reload time,
    // and checking it on an interval
    const playerRequiresReloadAt = LocalDateTime.now().plusHours(12).plusSeconds(10)
    this.configureReloadTracking(playerRequiresReloadAt)
    this.playerConfig = playerConfiguration

    // If the cookie expires in the future, HLS will fail and throw its own error state.
    this.authCookiePollService
      .updateAuthCookie()
      .pipe(take(1))
      .subscribe(auth => {
        this.authenticate(auth)
      })

    try {
      this.mediaPlayer = this.mediaPlayerFactory.createMediaPlayer(playerConfiguration)
      this.mediaPlayerReady.next(true)
    } catch (e) {
      if (e instanceof UnsupportedBrowserError) {
        this.supportsHLSPlayback = false
        return
      }
    }

    this.multiChannelEnabled = this.multiChannelPlaylistService.canPlayMultiChannel(this.playerConfig)

    // If we're in the desktop app we are not limited by autoplay policy, so init multichannel for shortcuts straight away
    // This ensures they can be used even before playback has started
    if (this.multiChannelEnabled && isInDesktopApp()) {
      this.initMultiChannel(disableChromiumRemapping, disableChannelRemapping)
    }

    // Set the volume on the media player as displayed, as the user may have changed it previously in this app instance.
    // The service's volume is not persisted beyond the current instance, but this would support such a feature.
    this.mediaPlayer!.setVolume(this.volume.value)

    this.mediaPlayer!.mediaEvents.subscribe(message => this.onMediaProgress(message))

    this.mediaPlayer!.mediaEvents.subscribe(message => {
      if (message.status === MediaStatus.Playing && this.multiChannelEnabled && !this.audioPipeline) {
        this.audioPipeline = this.getAudioPipeline(
          this.getBoundedAudioChannelCount(),
          disableChromiumRemapping,
          disableChannelRemapping,
        )
      }
    })

    this.setupAndPlayVod(playerConfiguration)
  }

  /**
   * Observe when a whole recording is sealed and the user cannot access sealed content.
   * Only emits when the PlayerState changes to or from PlayerState.Sealed
   */
  private observeSealedRecordingToggle(): Observable<boolean> {
    return this.playerState.pipe(
      distinctUntilChanged((a, b) => a === b || (a !== PlayerState.Sealed && b !== PlayerState.Sealed)),
      map(s => s === PlayerState.Sealed),
      shareReplay(1),
    )
  }

  reloadPlaylist(): void {
    // reload playlist from source because a new session has started; media player will decide if it should go live
    this.mediaPlayer!.reloadSource()
  }

  // This is an observable to avoid needing to
  // wait until the auth cookie has arrived to set *everything* up.
  // Components that rely on the media player having content should subscribe to playerState and wait until it is
  // loaded or playing.
  // Components that just need access to the mediaPlayer instance should subscribe to mediaPlayerReady
  private setupAndPlayVod(playerConfiguration: PlayerConfiguration): void {
    this.observeWhenTimecodesAreReady().subscribe(([_, timecodes, sealedTimeframes, canAccessSealedContent]) => {
      this.setTimecodes(timecodes, sealedTimeframes, canAccessSealedContent)
      this.mediaPlayer!.setupStartFromSeconds(playerConfiguration.startFromDateTime, this.timecodeIntervalTree)
      this.setupSourceAndStartPlayback(playerConfiguration)
    })
  }

  /**
   * Only emits when there are timecodes for the audio that the user can play back
   */
  private observeWhenTimecodesAreReady(): Observable<[boolean, Timecode[] | undefined, Timeframe[], boolean]> {
    return combineLatest([
      this.isAuthenticated,
      this.timecodes,
      this.store.select(PlaybackState.sealedTimeframes).pipe(filter(isNotNullOrUndefined)),
      this.store.select(PlaybackState.canAccessSealedContent).pipe(filter(isNotNullOrUndefined)),
    ]).pipe(
      filter(([authenticated, timecodes]) => authenticated && isOfNonZeroLength(timecodes)),
      // We only want to set it up one time, once it is authenticated and we have timecodes.
      first(),
    )
  }

  /**
   * Note that (with hls.js at least) most events aren't emitted until after startPlayback is called, including
   * duration updates. Further, setSource() creates a new instance of hls.js, so it shouldn't be called more than once.
   * As a result, this function isn't really divisible, and we must handle events after playback has started.
   */
  private async setupSourceAndStartPlayback(playerConfiguration: PlayerConfiguration): Promise<void> {
    this.mediaPlayer!.setSource(playerConfiguration.url)
    // Force browsers without auto play to at least show the play button
    this.mediaPlayer!.forceLoad()
    await this.mediaPlayer!.startPlayback()
  }

  jumpToDateTime(startFromDateTime: LocalDateTime): void {
    const newTimeInSeconds = this.getPlayerTime(startFromDateTime)
    if (newTimeInSeconds >= 0) {
      this.mediaPlayer?.updateTime(newTimeInSeconds)
      this.mediaPlayer?.onJumpedToTime(newTimeInSeconds)
    }
  }

  jumpToTimeString(newTimeString: string): void {
    if (newTimeString) {
      const newTime = LocalTime.parse(newTimeString)
      const newDateTime = this.currentPlaybackDateTime!.toLocalDate().atTime(newTime)
      this.jumpToDateTime(newDateTime)
    }
  }

  getPlayerTime(startFromDateTime: LocalDateTime): number {
    return this.mediaPlayer?.getPlaybackTime(startFromDateTime, this.timecodeIntervalTree)!
  }

  authenticate(isAuthenticated: boolean): void {
    if (!isAuthenticated) {
      this.playerState.next(PlayerState.Error)
    }
    this.isAuthenticated.next(isAuthenticated)
  }

  /**
   * The first timecode is considered the 'start time' of the recording.
   * The start time is used to calculate distance from the ?start=timestamp in the URL.
   * If sealedIntervals are provided - we break down timecodes into a timeframe collection (in their ms form),
   * remove sealed intervals and rebuild back into timecodes.
   */
  setTimecodes(timecodes: Timecode[] | undefined, sealedIntervals: Timeframe[], canAccessSealedContent: boolean): void {
    if (!timecodes || !timecodes.length) {
      return
    }
    const processedTimecodes = removeSealedIntervalsFromTimecodes(
      timecodes,
      filterSealedTimeframesByAccess(sealedIntervals, canAccessSealedContent),
    )

    if (!processedTimecodes.length) {
      this.playerState.next(PlayerState.Sealed)
    }

    this.startTime = timecodes[0].start
    this.endTime = timecodes[timecodes.length - 1].end
    this.createTimecodeIntervalTree(processedTimecodes)
    this.timecodes.next(processedTimecodes)
    this.originalTimecodes.next(timecodes)
  }

  /**
   * Whether all UI controls of the player should be disabled. This is used while the player's media
   * is being loaded in, or if an error has been encountered.
   */
  shouldDisableControls(): boolean {
    const currentState = this.playerState.value
    return (
      (this.mediaPlayer && !this.mediaPlayer.isSeekable()) ||
      [PlayerState.Error, PlayerState.Loading].includes(currentState)
    )
  }

  /**
   * Pausing updates is used when you want to change media element properties without updating the interface.
   * Resume updates when you are ready for changes to take effect.
   */
  pauseUpdates(): void {
    this.pausingUpdatesToPlayerInterface.next(true)
  }

  resumeUpdates(): void {
    this.pausingUpdatesToPlayerInterface.next(false)
  }

  getVolume(): Observable<number> {
    return this.volume.asObservable()
  }
  getVolumeValue(): number {
    return this.volume.getValue()
  }
  // Clamp the volume to 1 (max) or 0 (minimum/muted)
  setVolume(volume: number): void {
    if (volume < MIN_VOLUME) {
      volume = MIN_VOLUME
    } else if (volume > MAX_VOLUME) {
      volume = MAX_VOLUME
    }

    this.volume.next(volume)
    this.mediaPlayer?.setVolume(volume)
  }

  getPlaybackRate(): Observable<number> {
    return this.playbackRate.asObservable()
  }
  getPlaybackRateValue(): number {
    return this.playbackRate.getValue()
  }
  setPlaybackRate(playbackRate: number): void {
    this.playbackRate.next(playbackRate)
    this.mediaPlayer?.setPlaybackRate(playbackRate)
  }

  getAutoscrolling(): Observable<boolean> {
    return this.autoscrolling.asObservable()
  }
  getAutoscrollingValue(): boolean {
    return this.autoscrolling.getValue()
  }
  setAutoscrolling(autoscrolling: boolean): void {
    this.autoscrolling.next(autoscrolling)
  }

  // Check if the time provided falls between the start and end times of the recording (measuring seconds)
  timeIsWithinRecordingBounds(time: LocalDateTime): boolean {
    const secondsFromStart = this.startTime?.until(time, ChronoUnit.SECONDS)
    const secondsFromEnd = this.endTime?.until(time, ChronoUnit.SECONDS)
    return !!secondsFromStart && !!secondsFromEnd && secondsFromStart > 0 && secondsFromEnd < 0
  }

  updateVideoPlayerState(stateUpdate: Partial<VideoPlayerState>): void {
    this.videoPlayerState.next({
      ...this.videoPlayerState,
      ...stateUpdate,
    })
  }
  getVideoPlayerState(): Observable<VideoPlayerState> {
    return this.videoPlayerState.asObservable()
  }

  getFocusElement(): Observable<PlayerFocusElement> {
    return this.focusElement.asObservable()
  }
  setFocusElement(element: PlayerFocusElement): void {
    this.focusElement.next(element)
  }

  // Just a pass through to the mediaPlayer
  get durationInSeconds(): number {
    return (this.mediaPlayer && this.mediaPlayer.durationInSeconds) || Infinity
  }

  /**
   * Use this when you do not require sub-ms time values
   * The native browser audio element itself shows the floored value of the milliseconds in the current time.
   */
  get currentPlaybackTimeSeconds(): number {
    return parseInt(Math.floor(this.currentPlaybackTime).toFixed(0), 10)
  }

  /**
   * Player configuration to be consumed by playback components
   */
  get playerConfiguration(): PlayerConfiguration {
    return this.playerConfig
  }

  /**
   * Are updates to the interface paused?
   *
   * This includes scrolling events.
   */
  get hasPausedUpdates(): boolean {
    return this.pausingUpdatesToPlayerInterface.value
  }

  get playbackFailed(): boolean {
    return this.playerState.value === PlayerState.Error
  }

  get playbackLoading(): boolean {
    return this.playerState.value === PlayerState.Loading
  }

  get playbackBuffering(): boolean {
    return this.playerState.value === PlayerState.Buffering
  }

  get playbackPaused(): boolean {
    return this.playerState.value === PlayerState.Paused
  }

  get playbackSealed(): boolean {
    return this.playerState.value === PlayerState.Sealed
  }

  /**
   * Audio channel count can never be greater than MAX_CONFIGURABLE_CHANNELS
   */
  getBoundedAudioChannelCount(): number {
    return clamp(this.playerConfig.audioChannelCount, 0, MAX_CONFIGURABLE_CHANNELS)
  }

  getAudioPipeline(
    numberOfChannels: number,
    disableChromiumRemapping: boolean,
    disableChannelRemapping: boolean,
  ): AudioPipeline {
    if (!this.audioPipeline) {
      this.audioPipeline = this.createAudioPipeline(numberOfChannels, disableChromiumRemapping, disableChannelRemapping)
    }
    return this.audioPipeline
  }

  getChannelStates(numberOfChannels: number): AudioChannelStates {
    if (!this.audioPipeline) {
      throw new Error('Cannot create multi-channel states for uninitialised AudioPipeline')
    }
    if (!this.channelStates) {
      this.channelStates = this.createChannelStates(numberOfChannels)
    }
    return this.channelStates
  }

  setGainForChannel(channelIndex: number, value: number): void {
    const gainNode = this.audioPipeline?.gainNodes[channelIndex]
    if (gainNode && this.channelStates) {
      // Hold onto the gain value in channel states to support mute/unmute
      this.channelStates.gainValues[channelIndex] = value
      // If we're changing the gain, unmute
      this.channelStates.mutes[channelIndex] = false

      // If solo is active, and we are not the soloed channel, do not set real gain
      if (this.hasActiveSoloChannel() && !this.channelStates.solos[channelIndex]) {
        return
      }
      gainNode.gain.value = value
    }
  }

  toggleMuteForChannel(channelIndex: number): void {
    if (!(this.channelStates && this.audioPipeline)) {
      return
    }
    const gainNode = this.audioPipeline.gainNodes[channelIndex]
    if (!gainNode) {
      return
    }
    const currentChannelIsMuted = this.channelStates.mutes[channelIndex]
    if (currentChannelIsMuted) {
      gainNode.gain.value = this.channelStates.gainValues[channelIndex]
    } else {
      this.channelStates.gainValues[channelIndex] = this.audioPipeline.gainNodes[channelIndex].gain.value
      gainNode.gain.value = 0
    }

    this.channelStates.mutes[channelIndex] = !currentChannelIsMuted
  }

  isChannelSolo(channelIndex: number): boolean {
    if (!this.channelStates) {
      return false
    }
    return this.channelStates.solos[channelIndex]
  }
  toggleSoloForChannel(channelIndex: number): void {
    this.setChannelSolo(channelIndex, !this.isChannelSolo(channelIndex))
  }
  setChannelSolo(channelIndex: number, enableSolo: boolean, exclusiveSolo = true): void {
    // Ignore channel set if we aren't set up for multichannel or we're outside the range expected
    if (!this.channelStates || !this.audioPipeline || this.channelStates.solos.length < channelIndex) {
      return
    }

    // Clear all existing solo'd channels if this is an exclusive solo
    if (exclusiveSolo) {
      this.channelStates.solos = this.channelStates.solos.map(_ => false)
    }

    if (enableSolo) {
      this.channelStates.solos[channelIndex] = true

      // Then set all gains based on if they are solo'd or not
      this.audioPipeline.gainNodes.forEach((node, index) => {
        node.gain.value = this.channelStates?.solos[index] ? this.channelStates?.gainValues[index] : 0
      })
    } else {
      this.audioPipeline.gainNodes.forEach((node, index) => {
        if (this.channelStates!.mutes[index]) {
          node.gain.value = 0
        } else {
          node.gain.value = this.channelStates!.gainValues[index]
        }
      })
    }
  }

  turnAllChannelsOn(): void {
    // Ignore this command if we aren't set up for multichannel
    if (!this.channelStates || !this.audioPipeline) {
      return
    }

    this.channelStates.solos = this.channelStates.solos.map(_ => false)
    this.audioPipeline.gainNodes.forEach((node, index) => {
      if (this.channelStates!.mutes[index]) {
        node.gain.value = 0
      } else {
        node.gain.value = this.channelStates!.gainValues[index]
      }
    })
  }

  hasActiveSoloChannel(): boolean {
    return includes(this.channelStates?.solos, true)
  }

  toggleShowingChannels(): void {
    if (!this.multiChannelEnabled) {
      return
    }
    this.store.dispatch(new ToggleShowingChannels())
  }

  onDestroy(): void {
    this.finalize.next()
    this.finalize.complete()
    // Reset media player ready
    this.mediaPlayerReady.next(false)
    this.mediaPlayer?.pause()
    this.mediaPlayer?.destroy()
    this.authCookiePollService.onDestroy()
    this.playerServiceSetup = false
    this.timecodes.next(undefined)
    this.originalTimecodes.next(undefined)
    this.destroyAudioPipeline()
    this.channelStates = undefined
  }

  /**
   * node-interval-tree is an augmented AVL binary tree to store the segment ranges as numbers so we can search
   * efficiently I think it would be more efficient to search a red-black tree (which has O(log n) search complexity),
   * while this has O(min(n, klogn)) search complexity. The purpose of this tree is to make it easy to find the current
   * Timecode (for the start time) given the number of seconds into the recording the player is at. Example: Timecode
   * 1: 10:00:00AM - 300s long - 10:05:00AM Timecode 2: 11:00:00AM - 20s long - 11:00:20AM The current playback local
   * time when the player is at 305 seconds, is 11:00:05AM. By searching {low: 305, high: 305} we find the Timecode 2
   * object, and add the difference to the timecode's start time (i.e. 305 - 300 = 5 seconds into 11:00:00AM i.e.
   * 11:00:05AM)
   */
  private createTimecodeIntervalTree(timecodes: Timecode[]): void {
    this.timecodeIntervalTree = createIntervalTreeFromTimecodes(timecodes)
  }

  /**
   * This relies on the sorted time code's Interval Tree that can be searched,
   * given the current playback time.
   * It ensures that we are displaying times relative to the start time of the segment that is currently playing.
   * WARNING: It is vital that this function is highly performant, as it runs multiple times per second.
   *
   * @param timeInSeconds The time in seconds since the start of the stream
   */
  private getDateTimeFromSeconds(timeInSeconds: number): LocalDateTime | undefined {
    const search = this.timecodeIntervalTree.search(timeInSeconds, timeInSeconds)

    if (!search.length) {
      // This could only happen if we add new timecodes and the tree hasn't rebuilt in time, so suggesting using the
      // end time of the last timecode.
      if (this.timecodes.value && this.timecodes.value.length > 0) {
        return this.timecodes.value[this.timecodes.value.length - 1].end
      }

      // Livestream before the playlist has loaded correctly and build the interval tree
      return undefined
    }

    const currentIntervalTreeTimecode = search[0]
    const secondsSinceStartOfTimecode = timeInSeconds - currentIntervalTreeTimecode.low

    return currentIntervalTreeTimecode.timecode.start.plus(secondsSinceStartOfTimecode * 1000, ChronoUnit.MILLIS)
  }

  private configureReloadTracking(playerRequiresReloadAt: LocalDateTime): void {
    interval(this.reloadCheckInterval)
      .pipe(
        takeUntil(this.finalize),
        filter(() => LocalDateTime.now().isAfter(playerRequiresReloadAt)),
      )
      .subscribe(() => this.windowRefService.location().reload())
  }

  private onMediaProgress(message: MediaProgress): void {
    const dateTimeFromSeconds = this.getDateTimeFromSeconds(message.progress)

    // Should only happen for livestreams first segment
    if (!dateTimeFromSeconds) {
      return
    }

    if (!this.hasPausedUpdates) {
      this.currentPlaybackTime = message.progress
      this.currentPlaybackDateTime = dateTimeFromSeconds
    }

    this.playbackTimeUpdates$.next({
      time: dateTimeFromSeconds,
      category: message.getStatusCategory(),
    })

    switch (message.status) {
      case MediaStatus.LoadedMetadata:
        this.playerState.next(PlayerState.Loaded)
        break
      case MediaStatus.Playing:
        this.playerState.next(PlayerState.Playing)
        break
      case MediaStatus.Paused:
      case MediaStatus.Ended:
        this.playerState.next(PlayerState.Paused)
        break
      case MediaStatus.Waiting:
        this.playerState.next(PlayerState.Buffering)
        break
      case MediaStatus.Errored:
        this.playerState.next(PlayerState.Error)
        break
      case MediaStatus.SeekStarted:
        if (this.pauseDuringSeek) {
          if (this.playerState.getValue() === PlayerState.Playing) {
            this.playOnSeekEnd = true
          }
          this.mediaPlayer?.pause()
        }
        break
      case MediaStatus.SeekFinished:
        if (this.playOnSeekEnd) {
          this.playOnSeekEnd = false
          this.mediaPlayer?.play(false)
        }
        break
      default:
        break
    }
  }

  private initMultiChannel(disableChromiumRemapping: boolean, disableChannelRemapping: boolean): void {
    const channelCount = this.getBoundedAudioChannelCount()
    this.audioPipeline = this.createAudioPipeline(channelCount, disableChromiumRemapping, disableChannelRemapping)
    this.channelStates = this.createChannelStates(channelCount)
  }

  private createAudioPipeline(
    numberOfChannels: number,
    disableChromiumRemapping: boolean,
    disableChannelRemapping: boolean,
  ): AudioPipeline {
    const audioMediaSource = this.mediaPlayer!.getMediaElementSourceNode()
    const audioContext = this.mediaPlayer!.getAudioContext()
    const splitter = audioContext.createChannelSplitter(numberOfChannels)
    audioMediaSource.connect(splitter)

    const gainNodes = range(numberOfChannels).map(() => {
      return audioContext.createGain()
    })
    this.trmMapperService.determineMapperStrategy(disableChromiumRemapping, disableChannelRemapping)
    gainNodes.forEach((gainNode, index) => {
      gainNode.gain.value = this.getVolumeValue()
      const outputIndex = this.trmMapperService.remapChannelIndex(numberOfChannels, index)
      splitter.connect(gainNode, outputIndex)

      // connect gain node to all merger inputs
      gainNode.connect(audioContext.destination)
    })

    audioContext.resume()

    return {
      audioContext,
      splitter,
      gainNodes,
    }
  }

  private destroyAudioPipeline(): void {
    if (this.audioPipeline) {
      this.audioPipeline.gainNodes.forEach(node => {
        node.disconnect()
      })
      this.audioPipeline.splitter.disconnect()
      this.audioPipeline = undefined
    }
  }

  private createChannelStates(numberOfChannels: number): AudioChannelStates {
    return {
      gainValues: this.audioPipeline?.gainNodes.map(node => node.gain.value) || fill(Array(numberOfChannels), 0),
      mutes: this.audioPipeline?.gainNodes.map(node => node.gain.value === 0) || fill(Array(numberOfChannels), false),
      solos: fill(Array(numberOfChannels), false),
    }
  }
}

/**
 * We only show sealed timeframes if the user can't access the content
 */
function filterSealedTimeframesByAccess(sealedTimeframes: Timeframe[], canAccessSealedContent: boolean): Timeframe[] {
  return canAccessSealedContent ? [] : sealedTimeframes
}

export function removeSealedIntervalsFromTimecodes(timecodes: Timecode[], sealedTimeframes: Timeframe[]): Timecode[] {
  if (!sealedTimeframes.length || !timecodes.length) {
    return timecodes
  }

  const date = timecodes[0].start.toLocalDate()
  const sealedTimeFrames = sealedTimeframes.map(t => timeframeToTimeFrame(t, date))
  const timeFrames = timecodes.map(timecodeToTimeFrame)
  const adjustedTimeFrames = timeFrames.flatMap(x => excludeTimeFrames(sealedTimeFrames, x))
  return adjustedTimeFrames.map(timeFrameToTimecode)
}
