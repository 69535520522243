import { Component, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { DestroySubscribers, IconComponent, selectWith } from '@ftr/foundation'
import { AppPaths, CourtSystemPaths } from '@ftr/routing-paths'
import { SearchManyState } from '@ftr/ui-search'
import { SearchBarService } from '~app/core/header/search-container/search-bar/search-bar.service'
import { SearchBarNewComponent } from '~app/features/search-new/search-bar-new/search-bar-new.component'
import { SearchParams } from '~app/pages/search.params'

@Component({
  standalone: true,
  selector: 'ftr-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  imports: [SearchBarNewComponent, IconComponent],
})
export class SearchComponent extends DestroySubscribers {
  @Input() courtSystemId: Uuid
  @Input() isSearching: boolean

  protected searchContext = selectWith(() => SearchManyState.searchContext)

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly searchBarService: SearchBarService,
  ) {
    super()
  }

  navigateToSearchResults(): void {
    this.searchBarService.setLastUrlBeforeSearching(this.router.url)
    const searchUrl = ['', AppPaths.CourtSystem, this.courtSystemId, CourtSystemPaths.Search]
    const searchContext = this.searchContext()
    this.router.navigate(searchUrl, {
      queryParams: {
        [SearchParams.SearchTerm]: searchContext?.searchTerms,
        [SearchParams.EntitiesToSearch]: searchContext?.selectedEntities,
        [SearchParams.SearchPageToken]: searchContext?.pageToken,
      },
    })
  }
}
