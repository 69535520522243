import { Component, Input, OnInit } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import {
  IndexedLogNote,
  IndexedLogSheet,
  IndexedOrder,
  IndexedRecording,
  IndexedRemark,
  SearchRequestType,
  SearchResult,
  SearchResultType,
} from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField, ElasticsearchIndex } from '@ftr/contracts/type/core'
import { DateFormat, HIGHLIGHT_TAG, HIGHLIGHT_TAG_CLOSING, toMomentAndFormatIfDate } from '@ftr/foundation'
import { ZoneId } from '@js-joda/core'
import { get as getByStringPath } from 'lodash-es'
import { getLabelByKey, indexedOrderLabelMap } from '../../search-result-labels'

// We don't want these fields to be included in highlights
const EXCLUDED_FIELDS = ['id', 'jobId', 'lineItemType', 'accessPolicy.read', 'orderReference']

// Core fields already used in the right side of the template
// Whatever is not in this array but in the highlights should be shown on the left side
const CORE_FIELDS = [
  'caseNumber',
  'caseTitle',
  'location.courthouse',
  'customer.name',
  'dueDate',
  'placementDate',
  'hearingDate',
  'startTime',
  'endTime',
]

// Minimal length of the highlighted string
const MIN_HIGHLIGHT_LENGTH = 32

const HIGHLIGHT_DISPLAY_ORDER = [
  'caseType',
  'customer.email',
  'sharedWithUsers.name',
  'sharedWithUsers.email',
  'hearingPortions',
  'department.name',
  'judges',
  'languages',
  'appearances.name',
  'appearances.role',
  'languagesSpellingsInstructions',
  'transcriber.name',
  'transcriber.email',
  'claimedAdmin.name',
  'claimedAdmin.email',
  'turnaround',
  'uploadedDocuments.fileName',
]

export interface HighlightField<T> {
  key: string
  label: string
  value: T
}

@Component({
  selector: 'ftr-search-legacy-results-item',
  templateUrl: './search-legacy-results-item.component.html',
  styleUrls: ['./search-legacy-results-item.component.css'],
})
export class SearchResultsItemComponent implements OnInit {
  @Input() searchRequestType: SearchRequestType
  @Input() searchResult: SearchResult
  @Input() courtSystemTimeZoneId: ZoneId
  @Input() courtSystemConfig: PrivateConfiguration
  /**
   * If we were able to generate a destination link for this search result
   */
  @Input() hasLink: boolean

  readonly searchIndexType = ElasticsearchIndex
  readonly searchRequestTypes = SearchRequestType

  highlight: ElasticsearchHighlightField
  dynamicHighlight: HighlightField<any> | undefined

  ngOnInit(): void {
    this.highlight = this.searchResult.highlight
    this.dynamicHighlight = this.getDynamicHighlight()
  }

  /**
   * Gets a highlighted value if it's available
   */
  getValue(key: string): any {
    if (this.highlight[key] && Array.isArray(this.highlight[key])) {
      return this.highlight[key][0]
    }

    return getByStringPath(this.searchResult.body, key)
  }

  /**
   * Returns the first available highlight for this item
   */
  getDynamicHighlight(): HighlightField<any> | undefined {
    // set a default highlight key
    let key = Object.keys(this.highlight).find(k => !(EXCLUDED_FIELDS.includes(k) || CORE_FIELDS.includes(k)))

    // Try to grab a key based priority
    for (const priorityKey of HIGHLIGHT_DISPLAY_ORDER) {
      if (this.highlight[priorityKey] && this.highlight[priorityKey].length > 0) {
        key = priorityKey
        break
      }
    }

    if (key) {
      // Getting the string value of the highlight
      // We only want to display the first highlighted line
      let value = this.highlight[key] && this.highlight[key].length ? this.highlight[key][0] : ''
      // Getting the match between highlight tags
      const match = value.match(new RegExp(HIGHLIGHT_TAG + '(.*?)' + HIGHLIGHT_TAG_CLOSING))
      if (match && match.length && match[1]) {
        // If it's a date apply formatting. Doesn't take into account any other format requirements
        // Using this in place of ftr-date component
        const formatted = toMomentAndFormatIfDate(match[1], DateFormat.Date)
        value = value.replace(match[1], formatted)
        // Getting the index of the middle of the match, including opening and closing tags
        const middle =
          value.indexOf(HIGHLIGHT_TAG) + (HIGHLIGHT_TAG.length + HIGHLIGHT_TAG_CLOSING.length + formatted.length) / 2
        let sliceIndex = 0
        if (value.length - middle > MIN_HIGHLIGHT_LENGTH / 2) {
          // Match is closer to the start of the string, slice from the middle
          sliceIndex = middle - MIN_HIGHLIGHT_LENGTH / 2
        } else {
          // Match is closer to the end of the string, just slice from the end
          sliceIndex = value.length - MIN_HIGHLIGHT_LENGTH
        }

        if (sliceIndex > 0) {
          value = `...${value.slice(sliceIndex)}`
        }
      }

      return {
        key,
        label: mapHighlightKeyToLabel(key),
        value,
      }
    }

    return undefined
  }

  asIndexedRecording(searchResult: SearchResultType): IndexedRecording {
    return searchResult as IndexedRecording
  }

  asIndexedOrder(searchResult: SearchResultType): IndexedOrder {
    return searchResult as IndexedOrder
  }

  asThisRecordingSearchResult(
    searchResult: SearchResult,
  ): SearchResult<IndexedRemark | IndexedLogSheet | IndexedLogNote> {
    return searchResult as SearchResult<IndexedRemark | IndexedLogSheet | IndexedLogNote>
  }
}

function mapHighlightKeyToLabel(key: string): string {
  return getLabelByKey(indexedOrderLabelMap, key) || ''
}
