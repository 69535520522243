import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { DepartmentService } from '@ftr/api-core'
import { RecordingTags } from '@ftr/contracts/api/recordings-index'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { LocationService } from '@ftr/data-location'
import { titleCase, unwrapData } from '@ftr/foundation'
import { VocabularyTermsService } from '@ftr/ui-vocab'
import { Duration, LocalDate, LocalTime, convert } from '@js-joda/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { firstValueFrom } from 'rxjs'
import {
  ClearRecordingsFilterStateAction,
  ClearRecordingsFilterTypeStateAction,
  SetRecordingsFilterStateAction,
  SyncRecordingsFilterState,
} from './recordings-filter.actions'
import {
  RecordingsFilterDateCountInterval,
  RecordingsFilterDescriptor,
  RecordingsFilterKey,
  RecordingsFilterLabelData,
  RecordingsFilterStateModel,
  RecordingsFilterType,
  RecordingsFilterValueData,
  RelativeDateRangeKey,
} from './recordings-filter.model'

export const FilterTypeMapping = {
  [RecordingsFilterType.Location]: [RecordingsFilterKey.Courthouse, RecordingsFilterKey.Courtroom],
  [RecordingsFilterType.Department]: [RecordingsFilterKey.Department],
  [RecordingsFilterType.Date]: [
    RecordingsFilterKey.DateFrom,
    RecordingsFilterKey.DateTo,
    RecordingsFilterKey.DateCountInterval,
  ],
  [RecordingsFilterType.AdvancedFilters]: [
    RecordingsFilterKey.DurationFrom,
    RecordingsFilterKey.DurationTo,
    RecordingsFilterKey.TimeFrom,
    RecordingsFilterKey.TimeTo,
    RecordingsFilterKey.Tags,
    RecordingsFilterKey.ReporterLocation,
  ],
}

export function defaultRecordingsFilterState(): RecordingsFilterStateModel {
  // TODO: Set default filter to min duration of 300 seconds
  // Okay to leave for now for testing purposes, but need to update this before release
  return {
    values: {},
    labels: {},
  }
}

@State<RecordingsFilterStateModel>({
  name: 'recordingsFilterState',
  defaults: defaultRecordingsFilterState(),
})
@Injectable()
export class RecordingsFilterState {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly locationService: LocationService,
    private readonly departmentService: DepartmentService,
    private readonly vocabularyTermsService: VocabularyTermsService,
  ) {}

  @Selector()
  static getFilterState(state: RecordingsFilterStateModel): RecordingsFilterStateModel {
    return state
  }

  @Action(SyncRecordingsFilterState)
  async syncRecordingsFilterState(
    { dispatch }: StateContext<RecordingsFilterStateModel>,
    { courtSystemId }: SyncRecordingsFilterState,
  ): Promise<void> {
    const filterValues: RecordingsFilterValueData = {}

    const filterKeys = Object.values(RecordingsFilterKey)
    filterKeys.forEach(key => {
      const values = this.route.snapshot.queryParamMap.getAll(key)
      if (values && values.length > 0) {
        switch (key) {
          case RecordingsFilterKey.DateFrom:
          case RecordingsFilterKey.DateTo:
            filterValues[key as RecordingsFilterKey] = LocalDate.parse(values[0])
            break
          case RecordingsFilterKey.Tags:
            filterValues[key as RecordingsFilterKey] = values
            break
          case RecordingsFilterKey.DurationFrom:
          case RecordingsFilterKey.DurationTo:
            filterValues[key as RecordingsFilterKey] = parseInt(values[0], 10)
            break
          case RecordingsFilterKey.TimeFrom:
          case RecordingsFilterKey.TimeTo:
            filterValues[key as RecordingsFilterKey] = LocalTime.parse(values[0])
            break
          default:
            filterValues[key as RecordingsFilterKey] = values[0]
        }
      }
    })

    await firstValueFrom(
      dispatch(
        new SetRecordingsFilterStateAction(courtSystemId, filterValues, {}, Object.values(RecordingsFilterType), false),
      ),
    )
  }

  @Action(ClearRecordingsFilterStateAction)
  clearFilterState({ patchState }: StateContext<RecordingsFilterStateModel>): void {
    patchState(defaultRecordingsFilterState())
  }

  @Action(ClearRecordingsFilterTypeStateAction)
  clearFilterTypeState(
    { getState, patchState }: StateContext<RecordingsFilterStateModel>,
    { filterType }: ClearRecordingsFilterTypeStateAction,
  ): void {
    const currentState = getState()
    const newFilteredValues = { ...currentState.values }
    const newFilteredLabels = { ...currentState.labels }
    const filterKeys = FilterTypeMapping[filterType as RecordingsFilterType]

    filterKeys.forEach(key => {
      delete newFilteredValues[key as RecordingsFilterKey]
    })
    newFilteredLabels[filterType as RecordingsFilterType] = undefined

    patchState({
      values: newFilteredValues,
      labels: newFilteredLabels,
    })
  }

  @Action(SetRecordingsFilterStateAction)
  async setFilterValueState(
    { getState, patchState }: StateContext<RecordingsFilterStateModel>,
    { courtSystemId, filterValues, filterLabels, generateLabelTypes, patch }: SetRecordingsFilterStateAction,
  ): Promise<void> {
    const currentState = getState()
    const newFilteredValues = patch
      ? removeUndefinedValues({
          ...currentState.values,
          ...filterValues,
        })
      : filterValues

    const newFilteredLabels: RecordingsFilterLabelData = {
      ...currentState.labels,
      ...filterLabels,
    }

    const filterTypeLabelCount = {
      [RecordingsFilterType.Location]: 0,
      [RecordingsFilterType.Department]: 0,
      [RecordingsFilterType.Date]: 0,
      [RecordingsFilterType.AdvancedFilters]: 0,
    }

    // Filter label generation
    const skipKeys: RecordingsFilterKey[] = []
    for (const filterType of generateLabelTypes) {
      // Clear current label for filter type
      newFilteredLabels[filterType as RecordingsFilterType] = undefined

      // Loop over all filter keys of the filter type
      for (const filterKey of FilterTypeMapping[filterType as RecordingsFilterType]) {
        // Skip if filter key is already processed or if filter value is undefined
        if (skipKeys.includes(filterKey) || newFilteredValues[filterKey as RecordingsFilterKey] === undefined) {
          continue
        }

        const [label, processedFilterValueKeys] = await this.generateFilterLabel(
          courtSystemId,
          newFilteredValues,
          filterKey,
        )
        filterTypeLabelCount[filterType as RecordingsFilterType] += 1

        if (filterTypeLabelCount[filterType as RecordingsFilterType] <= 1) {
          newFilteredLabels[filterType as RecordingsFilterType] = label
        } else {
          const fallbackLabel = `${filterTypeLabelCount[filterType as RecordingsFilterType]} Selected`
          newFilteredLabels[filterType as RecordingsFilterType] = {
            label: fallbackLabel,
          }
        }

        // Some labels are generated from multiple filter keys, so we need to skip those keys
        skipKeys.push(...processedFilterValueKeys)
      }
    }

    patchState({
      values: newFilteredValues,
      labels: newFilteredLabels,
    })
  }

  /**
   * Generates a filter label and associated filter keys based on the provided filter values and key.
   *
   * Some Filters have multiple keys that are used to generate a single label, so we need to return the keys that were used.
   * Example:
   *   DurationFrom and DurationTo can be used to generate a single label, so both RecordingsFilterKey.DurationFrom and RecordingsFilterKey.DurationTo are returned.
   *   Generating for the filter key RecordingsFilterKey.DurationFrom would return the label "Greater than 1 hour" or "Duration between 1 hour - 2 hours" depending
   *   if DurationTo was also set, and will also return the keys RecordingsFilterKey.DurationFrom and RecordingsFilterKey.DurationTo either way.
   *
   * @param courtSystemId The current court system.
   * @param filterValues The filter values object.
   * @param filterKey The target filter key to be used to generate the label.
   * @returns A Promise containing the filter label and filter keys used. If a filterKey is not handled "Filter Selected" is returned.
   */

  private async generateFilterLabel(
    courtSystemId: Uuid,
    filterValues: RecordingsFilterValueData,
    filterKey: RecordingsFilterKey,
  ): Promise<[RecordingsFilterDescriptor, RecordingsFilterKey[]]> {
    const fallbackFilterLabel = { label: 'Filter Selected' }
    let filterKeys: RecordingsFilterKey[] = []
    let filterLabel: RecordingsFilterDescriptor | undefined

    switch (filterKey) {
      case RecordingsFilterKey.Courthouse:
        ;[filterLabel, filterKeys] = await this.generateLabelCourthouse(filterValues)
        break
      case RecordingsFilterKey.Courtroom:
        ;[filterLabel, filterKeys] = await this.generateLabelCourtroom(filterValues)
        break
      case RecordingsFilterKey.Department:
        ;[filterLabel, filterKeys] = await this.generateLabelDepartment(filterValues)
        break
      case RecordingsFilterKey.Tags:
        ;[filterLabel, filterKeys] = await this.generateLabelTags(courtSystemId, filterValues)
        break
      case RecordingsFilterKey.ReporterLocation:
        ;[filterLabel, filterKeys] = this.generateLabelReporterLocation(filterValues)
        break
      case RecordingsFilterKey.DurationFrom:
      case RecordingsFilterKey.DurationTo:
        ;[filterLabel, filterKeys] = this.generateLabelDuration(filterValues)
        break
      case RecordingsFilterKey.TimeFrom:
      case RecordingsFilterKey.TimeTo:
        ;[filterLabel, filterKeys] = this.generateLabelContentBetween(filterValues)
        break
      case RecordingsFilterKey.DateFrom:
      case RecordingsFilterKey.DateTo:
      case RecordingsFilterKey.DateCountInterval:
        ;[filterLabel, filterKeys] = this.generateLabelContentWithinDateRange(filterValues)
        break
      default:
        break
    }

    return [filterLabel ?? fallbackFilterLabel, filterKeys]
  }

  private async generateLabelCourthouse(
    filterValues: RecordingsFilterValueData,
  ): Promise<[RecordingsFilterDescriptor, RecordingsFilterKey[]]> {
    const filterKeys = [RecordingsFilterKey.Courthouse]
    try {
      const courthouseLocation = await firstValueFrom(
        this.locationService.get(filterValues[RecordingsFilterKey.Courthouse] as string).pipe(unwrapData()),
      )
      const filterLabel = { label: courthouseLocation!.name }
      return [filterLabel, filterKeys]
    } catch (_) {
      const filterLabel = { label: 'Courthouse Selected' } // Fallback
      return [filterLabel, filterKeys]
    }
  }

  private async generateLabelCourtroom(
    filterValues: RecordingsFilterValueData,
  ): Promise<[RecordingsFilterDescriptor, RecordingsFilterKey[]]> {
    const filterKeys = [RecordingsFilterKey.Courthouse]
    try {
      const courtroomLocation = await firstValueFrom(
        this.locationService.getCourtroom(filterValues[RecordingsFilterKey.Courtroom] as string).pipe(unwrapData()),
      )
      const filterLabel = { label: courtroomLocation!.parent!.name, subLabel: courtroomLocation!.name }
      return [filterLabel, filterKeys]
    } catch (_) {
      const filterLabel = { label: 'Courtroom Selected' } // Fallback
      return [filterLabel, filterKeys]
    }
  }

  private async generateLabelDepartment(
    filterValues: RecordingsFilterValueData,
  ): Promise<[RecordingsFilterDescriptor, RecordingsFilterKey[]]> {
    const filterKeys = [RecordingsFilterKey.Courthouse]
    try {
      const department = await firstValueFrom(
        this.departmentService.get(filterValues[RecordingsFilterKey.Department] as string).pipe(unwrapData()),
      )
      const filterLabel = { label: department!.name }
      return [filterLabel, filterKeys]
    } catch (_) {
      const filterLabel = { label: 'Department Selected' } // Fallback
      return [filterLabel, filterKeys]
    }
  }

  private async generateLabelTags(
    courtSystemId: Uuid,
    filterValues: RecordingsFilterValueData,
  ): Promise<[RecordingsFilterDescriptor, RecordingsFilterKey[]]> {
    const tags = filterValues[RecordingsFilterKey.Tags] as string[]
    let label: string

    if (tags.length > 1) {
      label = `${tags.length} Tags Selected`
    } else {
      try {
        const terms = await firstValueFrom(this.vocabularyTermsService.observeTermsSnapshot(courtSystemId))
        label = getTagLabel(tags[0], terms)
      } catch {
        label = tags[0]
      }
    }

    return [{ label }, [RecordingsFilterKey.Tags]]
  }

  private generateLabelReporterLocation(
    filterValues: RecordingsFilterValueData,
  ): [RecordingsFilterDescriptor, RecordingsFilterKey[]] {
    const filterLabel = { label: `${filterValues[RecordingsFilterKey.ReporterLocation]}` }
    const filterKeys = [RecordingsFilterKey.ReporterLocation]
    return [filterLabel, filterKeys]
  }

  private generateLabelDuration(
    filterValues: RecordingsFilterValueData,
  ): [RecordingsFilterDescriptor | undefined, RecordingsFilterKey[]] {
    const filterKeys = [RecordingsFilterKey.DurationFrom, RecordingsFilterKey.DurationTo]
    const filterLabel = formatTimeBetween(
      'Greater Than',
      'Less Than',
      'Duration Between',
      formatDuration(filterValues[RecordingsFilterKey.DurationFrom] as number | undefined),
      formatDuration(filterValues[RecordingsFilterKey.DurationTo] as number | undefined),
    )
    return [filterLabel ? { label: filterLabel } : undefined, filterKeys]
  }

  private generateLabelContentBetween(
    filterValues: RecordingsFilterValueData,
  ): [RecordingsFilterDescriptor, RecordingsFilterKey[]] {
    const filterKeys = [RecordingsFilterKey.TimeFrom, RecordingsFilterKey.TimeTo]
    let filterLabel = 'Content'
    if (filterValues[RecordingsFilterKey.TimeFrom] && filterValues[RecordingsFilterKey.TimeTo]) {
      filterLabel = `${filterLabel} Between ${filterValues[RecordingsFilterKey.TimeFrom]} - ${
        filterValues[RecordingsFilterKey.TimeTo]
      }`
    } else if (filterValues[RecordingsFilterKey.TimeFrom]) {
      filterLabel = `${filterLabel} From ${filterValues[RecordingsFilterKey.TimeFrom]}`
    } else if (filterValues[RecordingsFilterKey.TimeTo]) {
      filterLabel = `${filterLabel} To ${filterValues[RecordingsFilterKey.TimeTo]}`
    }

    return [{ label: filterLabel }, filterKeys]
  }

  private generateLabelContentWithinDateRange(
    filterValues: RecordingsFilterValueData,
  ): [RecordingsFilterDescriptor, RecordingsFilterKey[]] {
    const filterKeys = [RecordingsFilterKey.DateFrom, RecordingsFilterKey.DateTo, RecordingsFilterKey.DateCountInterval]
    let filterLabel: string
    const rangeType = filterValues[RecordingsFilterKey.DateCountInterval] as string
    if (rangeType === RecordingsFilterDateCountInterval.Month) {
      filterLabel = this.generateMonthRangeLabel(filterValues)
    } else if (rangeType === RecordingsFilterDateCountInterval.Day) {
      filterLabel = this.generateSingleDayLabel(filterValues)
    } else if (rangeType === RecordingsFilterDateCountInterval.DayRange) {
      filterLabel = this.generateDateRangeLabel(filterValues)
    } else if (rangeType.includes(RecordingsFilterDateCountInterval.Relative)) {
      // Relative interval includes a Duration parseable value
      filterLabel = this.generateRelativeRangeLabel(filterValues)
    } else {
      filterLabel = this.generateDateRangeLabel(filterValues)
    }

    return [{ label: filterLabel }, filterKeys]
  }

  private generateMonthRangeLabel(filterValues: RecordingsFilterValueData): string {
    const startDateString = filterValues[RecordingsFilterKey.DateFrom] as LocalDate
    const startDate = convert(startDateString).toDate()
    return startDate.toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    })
  }

  private generateRelativeRangeLabel(filterValues: RecordingsFilterValueData): string {
    const rangeKey = filterValues[RecordingsFilterKey.DateCountInterval] as RelativeDateRangeKey
    switch (rangeKey) {
      case RelativeDateRangeKey.Today:
        return 'Today'
      case RelativeDateRangeKey.Yesterday:
        return 'Yesterday'
      case RelativeDateRangeKey.ThisWeek:
        return 'This Week'
      case RelativeDateRangeKey.LastWeek:
        return 'Last Week'
      default:
        return 'Last X Days'
    }
  }

  private generateSingleDayLabel(filterValues: RecordingsFilterValueData): string {
    const selectedDate = convert(filterValues[RecordingsFilterKey.DateFrom] as LocalDate).toDate()
    return selectedDate.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
  }

  private generateDateRangeLabel(filterValues: RecordingsFilterValueData): string {
    const startDate = convert(filterValues[RecordingsFilterKey.DateFrom] as LocalDate).toDate()
    const endDate = convert(filterValues[RecordingsFilterKey.DateTo] as LocalDate).toDate()
    const formatOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
    const startString = startDate.toLocaleDateString('en-US', formatOptions)
    const endString = endDate.toLocaleDateString('en-US', formatOptions)
    return `${startString} - ${endString}`
  }
}

function removeUndefinedValues(obj: RecordingsFilterValueData): RecordingsFilterValueData {
  const filteredObj: RecordingsFilterValueData = {}

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined && value !== '') {
      filteredObj[key as RecordingsFilterKey] = value
    }
  }

  return filteredObj
}

export function getTagLabel(tag: string, terms: VocabularyTerms): string {
  if (tag === RecordingTags.Realtime) {
    return 'Real-Time'
  }

  if (tag === RecordingTags.Sealed) {
    return titleCase(terms.sealed.singular)
  }

  return tag
}

/**
 * Formats a duration range or single duration given specific prefixes for start, end, and between.
 *
 * @param startPrefix The prefix to be used for the start of the duration range.
 * @param endPrefix The prefix to be used for the end of the duration range.
 * @param betweenPrefix The prefix to be used for the duration range when both start and end are provided.
 * @param start The start of the duration range.
 * @param end The end of the duration range.
 * @returns The formatted duration range as a string, or undefined if both durations were not provided.
 */
function formatTimeBetween(
  startPrefix: string,
  endPrefix: string,
  betweenPrefix: string,
  start?: string,
  end?: string,
): string | undefined {
  if (start && end) {
    return `${betweenPrefix} ${start} - ${end}`
  } else if (start) {
    return `${startPrefix} ${start}`
  } else if (end) {
    return `${endPrefix} ${end}`
  }
  return undefined
}

/**
 * Formats a duration given in seconds into a human-readable string representation.
 *
 * @param durationInSeconds The duration in seconds to be formatted.
 * @returns The formatted duration as a string, or undefined if the input is not provided.
 */
function formatDuration(durationInSeconds?: number): string | undefined {
  if (!durationInSeconds) {
    return undefined
  }

  const duration = Duration.ofSeconds(durationInSeconds)
  const hours = duration.toHours()
  const minutes = duration.toMinutes() % 60

  let result = ''
  if (hours > 0) {
    result += `${hours} hr${hours > 1 ? 's' : ''}`
  }
  if (minutes > 0) {
    result += ` ${minutes} min${minutes > 1 ? 's' : ''}`
  }

  return result.trim()
}
