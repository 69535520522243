import { Injectable } from '@angular/core'
import { interval, Observable, Subject, switchMap, takeUntil } from 'rxjs'
import { CookieAuthenticationService } from '../cookie-authentication'

@Injectable({
  providedIn: 'root',
})
export class AuthCookiePollService {
  static readonly UPDATE_AUTH_COOKIE_INTERVAL_MILLIS = 60000
  private readonly finalize = new Subject<void>()

  private updateAuthCookieObservable = interval(AuthCookiePollService.UPDATE_AUTH_COOKIE_INTERVAL_MILLIS).pipe(
    takeUntil(this.finalize),
    switchMap(() => this.cookieAuthenticationService.updateApiCookie()),
  )

  constructor(private readonly cookieAuthenticationService: CookieAuthenticationService) {
    this.updateAuthCookieObservable.subscribe()
  }

  updateAuthCookie(): Observable<boolean> {
    return this.cookieAuthenticationService.updateApiCookie()
  }

  onDestroy(): void {
    this.finalize.next()
    this.finalize.complete()
  }
}
