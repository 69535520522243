import { Component, Input } from '@angular/core'
import { OnRecordState } from '@ftr/stt-search'

@Component({
  selector: '[ftr-live-indicator]',
  templateUrl: './live-indicator.component.html',
  styleUrls: ['./live-indicator.component.css'],
  standalone: true,
})
export class LiveIndicatorComponent {
  @Input() onRecordState: OnRecordState = OnRecordState.OnRecord
  @Input() text = 'LIVE'

  get liveModeClass(): string {
    return this.onRecordState === OnRecordState.OnRecord ? 'live-indicator__dot--live-mode' : ''
  }
}
