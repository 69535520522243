<ftr-page [pageStyle]="pageStyle">
  <div
    [class.back-to-search-results__container--sealing-mode]="isSealingModeOn"
    class="back-to-search-results__container"
    data-track-group="Back to Search Results"
  >
    <a tabindex="0" class="back-to-search-results__link" data-track (click)="onClick()">
      <ftr-icon icon="KeyboardArrowLeft" align="Top"></ftr-icon>Back to Search results for "<strong>{{
        searchTerm | async
      }}</strong
      >"
    </a>
  </div>
</ftr-page>
