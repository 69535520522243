<div class="top-row">
  <span class="date-time">
    <span class="date">
      @if (hasHighlight('recordingDate')) {
        <em class="highlight">
          <ng-container *ngTemplateOutlet="recordingDateTemplate"> </ng-container>
        </em>
      } @else {
        <ng-container *ngTemplateOutlet="recordingDateTemplate"> </ng-container>
      }
    </span>
    <span class="time">
      {{ firstHearingTime() | ftrDate: { format: timeFormat } }}
    </span>
  </span>
  <ftr-card-type-label [type]="'hearing'" [vocabularyTerms]="vocabularyTerms()"></ftr-card-type-label>
</div>

<div class="bottom-row">
  <span>
    <ftr-search-text-with-highlighting
      class="case-reference"
      [searchTextHtml]="caseReference()"
    ></ftr-search-text-with-highlighting>
  </span>
  <ftr-search-text-with-highlighting-and-truncation
    class="case-title"
    [searchTextHtml]="title()"
  ></ftr-search-text-with-highlighting-and-truncation>
</div>

<ng-template #recordingDateTemplate>
  <ftr-date [date]="searchResult().recordingDate" [format]="dateFormat.Date"></ftr-date>
</ng-template>
