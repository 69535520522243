import { ChannelMapperStrategyAbstract } from './channel-mapper-strategy.abstract'

export class ChannelMapperStrategyService extends ChannelMapperStrategyAbstract {
  public constructor(private readonly channelMap: Map<number, Map<number, number>>) {
    super()
  }

  public remapChannelNumber(totalNumberOfChannels: number, channelNumber: number): number {
    return this._remapChannelNumber(totalNumberOfChannels, channelNumber)
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _remapChannelNumber(totalNumberOfChannels: number, channelNumber: number): number {
    const r = this.channelMap.get(totalNumberOfChannels)?.get(channelNumber)
    return r === undefined ? channelNumber : r
  }
}
