import { Component, computed, input, ViewChild, ViewEncapsulation } from '@angular/core'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { dropRight } from 'lodash-es'
import { ButtonColor, ButtonDisplayType } from '../button'
import { TooltipDisplay } from '../tooltip'
import { Breadcrumb } from './breadcrumb'

interface CollapsedCrumbs {
  collapsed: Breadcrumb[]
  current: Breadcrumb[]
}

@Component({
  selector: 'ftr-breadcrumbs-display',
  templateUrl: 'breadcrumbs-display.component.html',
  styleUrls: ['breadcrumbs-display.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbsDisplayComponent {
  @ViewChild('popOver') popover: NgbPopover
  readonly buttonDisplayType = ButtonDisplayType
  readonly buttonColor = ButtonColor
  breadcrumbs = input.required<Breadcrumb[]>()
  collapse = input<boolean>()
  clampLeaf = input<boolean>()
  clampLink = input<boolean>()

  private focusTimeout: ReturnType<typeof setTimeout>

  protected collapsedBreadcrumbs = computed(() => {
    const crumbs = this.breadcrumbs()
    return {
      collapsed: dropRight(crumbs.slice(0), 2),
      current: crumbs.slice(-2),
    } as CollapsedCrumbs
  })

  onFocusIn(): void {
    clearTimeout(this.focusTimeout)
  }

  // Hide popover when focus is lost
  onFocusOut(): void {
    this.focusTimeout = setTimeout(() => {
      if (this.popover) {
        this.popover.close()
      }
    }, 0)
  }

  handleDropdownOnEnter(): void {
    this.focusDropdownLink()
  }

  private focusDropdownLink(): void {
    const breadcrumbDropDownLinks = Array.from(document.querySelectorAll<HTMLElement>('.breadcrumbs-dropdown__link'))
    const breadcrumbDropDownLink = breadcrumbDropDownLinks.at(0)

    if (breadcrumbDropDownLink) {
      breadcrumbDropDownLink.focus()
    }
  }

  protected readonly tooltipDisplay = TooltipDisplay
}
