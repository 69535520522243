<article class="search-results-not-found">
  <ftr-illustration [illustration]="illustration.Search"></ftr-illustration>
  @if (searchTerms) {
    <span>
      Your search for <strong>"{{ searchTerms }}"</strong> didn't match any records. Please try another search term.
    </span>
  } @else {
    <span>Start typing a search term to generate results.</span>
  }
</article>
