@if (showNavigationRedesign) {
  <ftr-app-layout [routeData]="routeData">
    <ng-container *ngTemplateOutlet="childContent"></ng-container
  ></ftr-app-layout>
} @else {
  <ftr-app-layout-legacy
    [logoData]="logoData"
    [routeData]="routeData"
    [backToSearchResultsFullUrl]="backToSearchResultsFullUrl"
    [isSearching]="isSearching"
    [isPlaybackPage]="isPlaybackPage"
    [isFooterVisible]="isFooterVisible"
  >
    <ng-container *ngTemplateOutlet="childContent"></ng-container>
  </ftr-app-layout-legacy>
}

<ng-template #childContent><ng-content></ng-content></ng-template>
