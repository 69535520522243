import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class IconUrlMapperService {
  private static URL_PATTERNS: { pattern: RegExp; icon: string }[] = [
    { pattern: /\/cases\/[a-z0-9-]+$/, icon: 'Work' },
    { pattern: /\/locations\/[a-z0-9-]+$/, icon: 'Courthouse' },
    { pattern: /\/departments\/[a-z0-9-]+$/, icon: 'Departments' },
    { pattern: /\/courtrooms\/[a-z0-9-]+$/, icon: 'Courtroom' },
    { pattern: /\/cases\/[a-z0-9-]+\/hearing-playback\/[a-z0-9-]+$/, icon: 'Hearing' },
    { pattern: /\/recording-playback\b.*$/, icon: 'Waveform' },
    { pattern: /\/recordings\b.*$/, icon: 'Waveform' },
    { pattern: /\/audio-orders\b.*$/, icon: 'OrdersAudio' },
    { pattern: /\/real-time-orders\b.*$/, icon: 'OrdersRealtime' },
    { pattern: /\/transcript-orders\b.*$/, icon: 'OrdersTranscript' },
    { pattern: /\/reports\b.*$/, icon: 'VerticalBarChart' },
    { pattern: /\/settings\/repositories\b.*$/, icon: 'TrmRepositories' },
    { pattern: /\/settings\/recorders\b.*$/, icon: 'Recorders' },
    { pattern: /\/settings\/users\b.*$/, icon: 'People' },
    { pattern: /\/settings\b.*$/, icon: 'Settings' },
    { pattern: /\/search\b.*$/, icon: 'Search' },
  ]

  getIconForUrl(url: string): string {
    for (const mapping of IconUrlMapperService.URL_PATTERNS) {
      if (mapping.pattern.test(url)) {
        return mapping.icon
      }
    }
    return 'Default'
  }
}
