@switch (searchResult.type) {
  @case (searchIndexType.Recording) {
    @if (searchRequestType === searchRequestTypes.AllRecordings) {
      <ftr-search-legacy-results-all-recordings-item
        class="search-result-item"
        [hasLink]="hasLink"
        [searchResult]="asIndexedRecording(searchResult.body)"
        [courtSystemConfig]="courtSystemConfig"
      >
      </ftr-search-legacy-results-all-recordings-item>
    } @else {
      <ftr-search-legacy-results-this-recording-item
        class="search-result-item"
        [searchResult]="asThisRecordingSearchResult(searchResult)"
        [courtSystemConfig]="courtSystemConfig"
      >
      </ftr-search-legacy-results-this-recording-item>
    }
  }
  @default {
    <ftr-search-legacy-results-orders-item
      class="search-result-item"
      [hasLink]="hasLink"
      [courtSystemConfig]="courtSystemConfig"
      [highlight]="highlight"
      [searchResult]="asIndexedOrder(searchResult.body)"
      [getValue]="getValue.bind(this)"
      [dynamicHighlight]="dynamicHighlight"
    >
    </ftr-search-legacy-results-orders-item>
  }
}
<ng-content></ng-content>
