import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { TooltipAlignment, TooltipPosition } from './tooltip-position'

/**
 * Important: This is an internal component for the Tooltip directive, not to be used alone
 */
@Component({
  selector: 'ftr-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.css'],
})
export class TooltipComponent {
  @ViewChild('tooltip', { static: true })
  tooltip: ElementRef<HTMLElement>

  @Input()
  template?: TemplateRef<any> | undefined

  @Input()
  templateContext?: Object | undefined

  @Input()
  position: TooltipPosition = TooltipPosition.ABOVE

  @Input()
  alignment: TooltipAlignment = TooltipAlignment.CENTER

  @Input()
  indicatorPositionStyle: string = ''

  @Output() onMouseOver = new EventEmitter<void>()

  @Output() onMouseLeave = new EventEmitter<void>()

  @HostBinding('aria-hidden')
  ariaHidden = true

  @HostListener('mouseover')
  mouseOver(): void {
    this.onMouseOver.emit()
  }

  @HostListener('mouseleave')
  mouseLeave(): void {
    this.onMouseLeave.emit()
  }

  /**
   * Used for aria described by, displayed as text if template is not provided
   */
  @Input()
  message: string

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Note: When we dynamically adjust the position of the tooltip, we need to inform change detection to rerender
   * the arrow on the correct size
   */
  detectChanges(): void {
    this.changeDetectorRef.detectChanges()
  }
}
