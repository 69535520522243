import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import {
  DateComponent,
  HighlightHtmlPipe,
  IconComponent,
  IllustrationComponent,
  LineLoaderComponent,
  PillComponent,
  SidePanelPageLayoutComponent,
} from '@ftr/foundation'
import { PaginationComponent } from '@ftr/lists'
import { CourtSystemTimezoneModule } from '@ftr/ui-court-system'
import { LineItemTypeIconPipe } from '@ftr/ui-ordering'
import { LiveIndicatorComponent } from '@ftr/ui-realtime-playback'
import { SearchResultsNavigationComponent } from '~app/features/search-legacy/search-results/navigation/search-legacy-results-navigation.component'
import { SearchResultsNotFoundComponent } from '~app/features/search-legacy/search-results/not-found/search-legacy-results-not-found.component'
import { SearchResultsAllRecordingsItemComponent } from '~app/features/search-legacy/search-results/search-legacy-results-all-recordings-item/search-legacy-results-all-recordings-item.component'
import { SearchResultsItemComponent } from '~app/features/search-legacy/search-results/search-legacy-results-item/search-legacy-results-item.component'
import { SearchResultsListComponent } from '~app/features/search-legacy/search-results/search-legacy-results-list/search-legacy-results-list.component'
import { SearchResultsOrdersItemComponent } from '~app/features/search-legacy/search-results/search-legacy-results-orders-item/search-legacy-results-orders-item.component'
import { SearchResultsThisRecordingItemComponent } from '~app/features/search-legacy/search-results/search-legacy-results-this-recording-item/search-legacy-results-this-recording-item.component'
import { SearchLegacyResultsComponent } from '~app/features/search-legacy/search-results/search-legacy-results.component'

@NgModule({
  imports: [
    CommonModule,
    PillComponent,
    RouterModule,
    CourtSystemTimezoneModule,
    IllustrationComponent,
    HighlightHtmlPipe,
    LineItemTypeIconPipe,
    SidePanelPageLayoutComponent,
    LiveIndicatorComponent,
    PaginationComponent,
    LineLoaderComponent,
    DateComponent,
    IconComponent,
  ],
  declarations: [
    SearchResultsNotFoundComponent,
    SearchLegacyResultsComponent,
    SearchResultsItemComponent,
    SearchResultsListComponent,
    SearchResultsNavigationComponent,
    SearchResultsOrdersItemComponent,
    SearchResultsAllRecordingsItemComponent,
    SearchResultsThisRecordingItemComponent,
  ],
  exports: [
    SearchResultsNotFoundComponent,
    SearchLegacyResultsComponent,
    SearchResultsItemComponent,
    SearchResultsListComponent,
    SearchResultsNavigationComponent,
  ],
})
export class SearchLegacyModule {}
