// eslint-disable-next-line max-classes-per-file
import { Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalDateTime, LocalTime } from '@js-joda/core'
import {
  RealTimeSearchHighlights,
  RealTimeSttHearingMarker,
  RealTimeSttSealingMarker,
  RealTimeSttSessionMarker,
  RealTimeSttTranscriptDividerData,
} from '../../types'
import { RealTimeSttAction } from './real-time-stt.actions'

export class RealTimeSttSetSearchHighlightsCommand {
  static readonly type = '[RealTimeStt] Set Search Highlights'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly searchHighlights: RealTimeSearchHighlights,
  ) {}
}

export class SetRealTimeSttCurrentPlaybackTimeCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Real Time Stt Current Playback Time'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly currentPlaybackTime: LocalDateTime,
  ) {}
}

export class SetRealTimeSttTranscriptDividersCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Real Time Stt Transcript Dividers'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly dividers: RealTimeSttTranscriptDividerData[],
  ) {}
}

export class SetRealTimeSttSessionMarkersCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Real Time Stt Session Markers'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly sessionMarkers: RealTimeSttSessionMarker[],
  ) {}
}

export class SetRealTimeSttSealingMarkersCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Real Time Stt Sealing Markers'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly sealingMarkers: RealTimeSttSealingMarker[],
  ) {}
}

export class SetRealTimeSttHearingMarkersCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Real Time Stt Hearing Markers'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hearingMarkers: RealTimeSttHearingMarker[],
  ) {}
}

export class SetRealTimeRecordingEndTimeCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Recording End Time'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly recordingEndTime?: LocalDateTime,
  ) {}
}

export class UpdateRealTimeLiveLocalTimeCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Update Live Local Time'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly liveLocalTime?: LocalTime,
  ) {}
}

export class FetchRemarksCommand implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Fetch Remarks'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
  ) {}
}
