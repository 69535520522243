import { Injectable } from '@angular/core'

@Injectable()
export class MediaFactoryService {
  createAudio(): HTMLAudioElement {
    return new Audio()
  }

  /*
    A constructor for a video element does not exist
    like it does for Audio / Image etc. However creating the
    element in this manner still satisfying the HTMLVideoElement
    interface
  */
  createVideo(): HTMLVideoElement {
    return document.createElement('video')
  }
}
