import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { ButtonColor, NotificationService, PageStyle, RemoteData } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { AuthenticationService, CognitoAuthenticationService, MultifactorAuthenticationService } from '@ftr/ui-user'
import { ReplaySubject } from 'rxjs'

@Component({
  selector: 'ftr-mfa-recover-account',
  templateUrl: './mfa-recover-account.component.html',
  styleUrls: ['./mfa-recover-account.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MfaRecoverAccountComponent implements OnInit {
  @Input()
  returnUrl: string

  userId: Uuid
  recoverAccountForm: UntypedFormGroup
  submissionSubject = new ReplaySubject<RemoteData<void>>()
  submission = this.submissionSubject.asObservable()
  submitAttempted = false

  readonly pageStyle = PageStyle.Narrow
  readonly buttonColor = ButtonColor
  readonly mfaChallengePath = `/${AppPaths.MultiFactorAuthentication}`
  readonly mfaDisableMessage =
    'Your MFA settings have been disabled successfully. Please use your email and password to Log In.'

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly notificationService: NotificationService,
    private readonly multifactorAuthenticationService: MultifactorAuthenticationService,
    private readonly cognitoAuthenticationService: CognitoAuthenticationService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.setupForm()
  }

  ngOnInit(): void {
    const cognitoUser = this.cognitoAuthenticationService.mfaChallengeUser
    if (cognitoUser) {
      this.userId = cognitoUser.getUsername()
    } else {
      this.router.navigate([this.mfaChallengePath])
    }
  }

  setupForm(): void {
    this.recoverAccountForm = this.formBuilder.group({
      recoveryCode: ['', Validators.required],
    })
  }

  submitForm(): void {
    this.submitAttempted = true
    if (!this.recoverAccountForm.valid) {
      for (const i in this.recoverAccountForm.controls) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.recoverAccountForm.controls.hasOwnProperty(i)) {
          this.recoverAccountForm.controls[i].markAsTouched()
        }
      }
      return
    }

    const formData = this.recoverAccountForm.getRawValue()
    this.multifactorAuthenticationService
      .validateRecoveryCode(this.userId, formData.recoveryCode)
      .subscribe(async remote => {
        if (remote.isSuccess()) {
          const recoveryAuthenticated = await this.cognitoAuthenticationService.recoveryAuthenticate()
          if (recoveryAuthenticated) {
            await this.cognitoAuthenticationService.setMultifactorAuthentication(true)

            const { result: postLoginResult, getValidReturnUrl } = await this.authenticationService.postLoginProcess()
            // Navigate to ReturnURL or default login route
            if (!postLoginResult) {
              await this.router.navigateByUrl(getValidReturnUrl(this.returnUrl))
            }

            this.submissionSubject.next(remote)
            return
          }
          this.notificationService.showSuccessOnNavigate(this.mfaDisableMessage)
          this.submissionSubject.next(remote)
          await this.router.navigate([AppPaths.Login], {
            queryParams: this.returnUrl ? { returnUrl: this.returnUrl } : undefined,
          })
        } else if (remote.isFailure()) {
          this.submissionSubject.next(RemoteData.failure(new Error()))
          this.recoverAccountForm.controls.recoveryCode.setErrors({
            externalErrorMessage: remote.error?.message,
          })
        }
      })
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
