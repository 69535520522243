import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { PageClasses } from '../../page'
import { EmptyFunction, isEmpty } from '../../remote'
import { ApiResult } from '../../remote-data'
import { PageLayoutComponent } from '../page-layout.component'
import { MAIN_CONTAINER_CLASS } from '../page-layout.utils'

export enum SidePanelSize {
  Small = 'small',
  Large = 'large',
}

@Component({
  selector: 'ftr-side-panel-page-layout',
  templateUrl: './side-panel-page-layout.component.html',
  styleUrls: ['./side-panel-page-layout.component.css'],
  standalone: true,
  imports: [CommonModule, PageLayoutComponent],
})
export class SidePanelPageLayoutComponent<T> implements OnInit {
  @Input() size: SidePanelSize
  @Input() name: string
  @Input() entity: ApiResult<T>
  @Input() sideBarTemplate: TemplateRef<unknown>
  @Input() bodyTemplate: TemplateRef<unknown>
  @Input() headerTemplate: TemplateRef<unknown> | null = null
  @Input() errorMessage?: string

  // loadingTemplate, emptyTemplate, isEmptyFn were originally added specifically to support the SearchResultsComponent
  @Input() loadingTemplate: TemplateRef<any> | null = null
  @Input() emptyTemplate: TemplateRef<any> | null = null
  @Input() isEmptyFunction: EmptyFunction<T> = isEmpty

  @Output() onRetry = new EventEmitter<undefined>()

  readonly mainContainerClass = MAIN_CONTAINER_CLASS
  readonly largeSidePanel = SidePanelSize.Large
  readonly smallSidePanel = SidePanelSize.Small

  sideBarClassList: PageClasses = {}
  bodyClassList: PageClasses = {}

  ngOnInit(): void {
    if (this.size === SidePanelSize.Small) {
      this.sideBarClassList = { 'col-12': true, 'col-md-3': true, 'col-lg-2': true }
      this.bodyClassList = { 'col-12': true, 'col-md-9': true, 'col-lg-10': true }
    } else if (this.size === SidePanelSize.Large) {
      this.sideBarClassList = { 'col-12': true, 'col-md-6': true, 'col-lg-4': true }
      this.bodyClassList = { 'col-12': true, 'col-md-6': true, 'col-lg-8': true }
    }
  }
}
