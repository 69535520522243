import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, computed, input, signal } from '@angular/core'
import { NavigationSkipped, NavigationStart, Router, RouterModule } from '@angular/router'
import { CourtSystemWithMetadata } from '@ftr/contracts/api/court-system'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  AnchorComponent,
  ButtonColor,
  ButtonComponent,
  ButtonDisplayType,
  DestroySubscribers,
  IconComponent,
  TooltipDisplay,
  TooltipModule,
  TooltipPosition,
} from '@ftr/foundation'
import { DropdownMenuComponent } from '@ftr/menus'
import {
  BranchNavItem,
  COURT_SYSTEM_NAV_ITEMS,
  FtrLogoComponent,
  HELP_CENTER_NAV_TITLE,
  SideNavDisplayMode,
  SideNavGroup,
  SideNavItem,
} from '@ftr/ui-core'
import { HelpModule } from '@ftr/ui-help'
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { takeUntil } from 'rxjs'
import { CourtSystemDropdownComponent } from '~app/core/court-system-dropdown/court-system-dropdown.component'
import { FavoritesEditModalComponent } from '~app/features/favorites/favorites-edit-modal/favorites-edit-modal.component'
import { FavoritesMenuComponent } from '~app/features/favorites/favorites-menu/favorites-menu.component'

@Component({
  selector: 'ftr-side-nav-display',
  templateUrl: './side-nav-display.component.html',
  styleUrls: ['./side-nav-display.component.css'],
  standalone: true,
  imports: [
    IconComponent,
    AnchorComponent,
    ButtonComponent,
    NgClass,
    TooltipModule,
    FtrLogoComponent,
    NgTemplateOutlet,
    NgbPopoverModule,
    HelpModule,
    CourtSystemDropdownComponent,
    DropdownMenuComponent,
    FavoritesMenuComponent,
    RouterModule,
    AsyncPipe,
    FavoritesEditModalComponent,
  ],
})
export class SideNavDisplayComponent extends DestroySubscribers implements OnInit {
  @Input() displayMode: SideNavDisplayMode = 'DEFAULT'
  @Input() expanded: boolean = true
  @Input() isLoading: boolean = false
  @Input() currentCourtSystemId: Uuid | undefined
  @Input() isOpened: boolean
  @Output() sideBarToggled = new EventEmitter<void>()
  @Output() expandedToggled = new EventEmitter<void>()

  navGroups = input([] as SideNavGroup[])
  footerItems = input([] as SideNavItem[])
  nestedNavItems = signal([] as BranchNavItem[])
  selectedNestedItem = computed(() =>
    this.nestedNavItems().length > 0 ? this.nestedNavItems()[this.nestedNavItems().length - 1] : undefined,
  )

  loadingWidths: string[] = Array.from({ length: 25 }, () => `${Math.floor(Math.random() * (96 - 55)) + 55}%`)
  loadingSideNavGroups: SideNavGroup[] = Array.from({ length: 4 }, () => ({
    isLoading: true,
  }))

  protected isExpandButtonHovered = signal(false)

  readonly buttonDisplayType = ButtonDisplayType
  readonly buttonColor = ButtonColor
  readonly tooltipPosition = TooltipPosition
  readonly tooltipDisplay = TooltipDisplay
  readonly helpCenterNavTitle = HELP_CENTER_NAV_TITLE

  constructor(private readonly router: Router) {
    super()
  }

  ngOnInit(): void {
    this.watchRouterEvents()
  }

  popNestedNavItems(): void {
    this.nestedNavItems.update(previousNavItems => {
      return previousNavItems.slice(0, previousNavItems.length - 1)
    })
  }

  handleOnNavItemClick(sideNavItem: SideNavItem): void {
    if (sideNavItem.type === 'BRANCH') {
      this.nestedNavItems.update(previousNavItems => {
        return [...previousNavItems, sideNavItem]
      })
    } else if (sideNavItem.type === 'BUTTON') {
      if (sideNavItem.onSelect) {
        sideNavItem.onSelect()
      }
      this.closeSideBar()
    }
  }

  closeSideBar(): void {
    this.nestedNavItems.update(() => [])
    this.sideBarToggled.emit()
  }

  onCourtSystemDropdownOpened(courtSystem?: CourtSystemWithMetadata): void {
    if (courtSystem) {
      this.handleOnNavItemClick({
        type: 'BRANCH',
        title: courtSystem?.name,
        children: COURT_SYSTEM_NAV_ITEMS,
      } as SideNavItem)
    } else {
      this.handleOnNavItemClick({
        type: 'BRANCH',
        title: 'For The Record',
        children: COURT_SYSTEM_NAV_ITEMS.slice(0, 1),
      } as SideNavItem)
    }
  }

  onFavoritesDropdownOpened(actions: SideNavItem[]): void {
    this.handleOnNavItemClick({
      type: 'BRANCH',
      title: 'Favorites',
      children: actions,
    })
  }

  private watchRouterEvents(): void {
    // Close the side nav if we try to navigate away from the current page
    this.router.events.pipe(takeUntil(this.finalize)).subscribe(event => {
      if (this.displayMode === 'OVERLAY' && this.isOpened) {
        if (event instanceof NavigationStart || event instanceof NavigationSkipped) {
          this.closeSideBar()
        }
      }
    })
  }
}
