import { NgIf, NgTemplateOutlet } from '@angular/common'
import { Component, OnInit, effect, signal } from '@angular/core'
import { Router } from '@angular/router'
import { RegionalApiClientFactory } from '@ftr/api-regional'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { StreamRecordingSummary } from '@ftr/contracts/regional-api'
import { Uuid } from '@ftr/contracts/type/shared'
import { LocationService } from '@ftr/data-location'
import {
  AnimatedRemoteComponent,
  DestroySubscribers,
  ErrorRetryComponent,
  LineLoaderComponent,
  LocalStorageService,
  RemoteData,
  unwrapData,
} from '@ftr/foundation'
import { AppPaths, CourtSystemPaths, RecordingPlaybackPaths } from '@ftr/routing-paths'
import { LoggingService } from '@ftr/ui-observability'
import { CURRENT_COURT_SYSTEM_KEY } from '@ftr/ui-user'
import { Subject, firstValueFrom, switchMap, takeUntil } from 'rxjs'
import { QuickDraftSpeechToTextCardComponent } from '~app/features/courtroom/speech-to-text/quick-draft/quick-draft-speech-to-text-card.component'
import { RealTimeSpeechToTextCardComponent } from '~app/features/courtroom/speech-to-text/real-time/real-time-speech-to-text-card.component'

/**
 * This component acts as a redirect shortcut to the stream recording playback page. This is particularly useful for
 * local development flows where the stream recording is not added to the ES index and will not appear in the recordings
 * list. This component will redirect to the first stream recording it finds, or a 404 if no recording are found.
 */
@Component({
  standalone: true,
  imports: [
    AnimatedRemoteComponent,
    ErrorRetryComponent,
    LineLoaderComponent,
    QuickDraftSpeechToTextCardComponent,
    RealTimeSpeechToTextCardComponent,
    NgTemplateOutlet,
    NgIf,
  ],
  template: `
    <ng-container>
      @if (liveRecordings().isLoading()) {
        <ftr-line-loader message="Loading livestream recordings"></ftr-line-loader>
      }
      @if (liveRecordings().isSuccess()) {
        @if (liveRecordings().data?.length === 0) {
          <ftr-error-retry
            text="No live recordings found."
            [icon]="false"
            (retry)="recordingFetchTrigger.next()"
          ></ftr-error-retry>
        } @else {
          <ftr-line-loader message="Loading livestream recordings">Redirecting to livestream recording</ftr-line-loader>
        }
      }
    </ng-container>
  `,
})
export class RedirectToLiveStreamRecording extends DestroySubscribers implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly regionalApiClientFactory: RegionalApiClientFactory,
    private readonly locationService: LocationService,
    private readonly localStorageService: LocalStorageService,
    private readonly logger: LoggingService,
  ) {
    super()
    effect(async () => {
      const liveRecordings = this.liveRecordings()
      if (liveRecordings.isFailure()) {
        this.logger.error({ msg: 'Failed to get live recordings', error: liveRecordings._error })
        void this.router.navigate([AppPaths.Forbidden])
      }
      if (!liveRecordings.isSuccess()) {
        return
      }

      const recordings = liveRecordings._data
      if (recordings.length === 0) {
        return
      }

      void this.redirectToRecording(recordings[0])
    })
  }

  protected courtSystemId = signal<string | undefined>(undefined)
  protected liveRecordings = signal<RemoteData<StreamRecordingSummary[]>>(RemoteData.notAsked())

  protected recordingFetchTrigger = new Subject<void>()
  ngOnInit(): void {
    const courtSystem = this.localStorageService.get<CourtSystem | undefined>(CURRENT_COURT_SYSTEM_KEY)

    if (!courtSystem) {
      this.logger.warn('No court system found. Please select a court system and try again')
      void this.router.navigate([''])
      return
    }

    this.courtSystemId.set(courtSystem.id)
    this.setupRecordingFetching(courtSystem.id)
    this.recordingFetchTrigger.next()
  }

  private setupRecordingFetching(courtSystemId: Uuid): void {
    const apiClient$ = this.regionalApiClientFactory.buildForCourt('/live-stream/recording', courtSystemId)

    this.recordingFetchTrigger
      .pipe(
        switchMap(() => apiClient$),
        switchMap(apiClient => apiClient.get<StreamRecordingSummary[]>({ path: '/live', headers: { courtSystemId } })),
        takeUntil(this.finalize),
      )
      .subscribe(liveRecordings => {
        this.liveRecordings.set(liveRecordings)
      })
  }

  private async redirectToRecording(recording: StreamRecordingSummary): Promise<void> {
    const location = await firstValueFrom(
      this.locationService.getCourtroomOrError(recording.locationId).pipe(unwrapData()),
    )

    void this.router.navigate([
      AppPaths.CourtSystem,
      this.courtSystemId(),
      CourtSystemPaths.RecordingPlayback,
      location.parentId!,
      location.id,
      RecordingPlaybackPaths.StreamRecording,
      recording.id,
    ])
  }
}
