import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'
import { extractHtmlHighlightParts } from '@ftr/foundation'

export type SearchHighlightType = 'Normal' | 'Subtle'

@Component({
  standalone: true,
  selector: 'ftr-search-text-with-highlighting',
  templateUrl: './search-text-with-highlighting.component.html',
  styleUrls: ['./search-text-with-highlighting.component.css'],
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTextWithHighlightingComponent {
  readonly searchTextHtml = input.required<string>()
  readonly highlightType = input<SearchHighlightType>('Normal')

  protected readonly searchParts = computed(() => extractHtmlHighlightParts(this.searchTextHtml()))
}
