export enum PlayerState {
  /**
   * Metadata is loading (default state)
   */
  Loading = 'loading',
  /**
   * Metadata has loaded
   */
  Loaded = 'loaded',
  /**
   * An unrecoverable error has occurred
   */
  Error = 'error',
  /**
   * It is currently playing
   */
  Playing = 'playing',
  /**
   * It is currently paused
   */
  Paused = 'paused',
  /**
   * It is currently waiting for more data
   */
  Buffering = 'buffering',
  /**
   * The whole recording is sealed and the user cannot access sealed content
   */
  Sealed = 'sealed',
}
