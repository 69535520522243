<ftr-side-panel-page-layout
  [size]="sidePanelSize.Small"
  name="order"
  [sideBarTemplate]="sideBar"
  [bodyTemplate]="body"
  [loadingTemplate]="loading"
  [emptyTemplate]="empty"
  [entity]="viewModel$"
  [isEmptyFunction]="isEmpty"
  errorMessage="Failed to load the search results. Please try again or contact support@fortherecord.com for advice."
  (onRetry)="retry()"
>
  <ng-template #loading>
    <ftr-line-loader [message]="'Searching for \'' + ((query$ | async) ?? undefined) + '\''"></ftr-line-loader>
  </ng-template>
  <ng-template #empty>
    <ftr-search-legacy-results-not-found [searchTerms]="(query$ | async) ?? ''"></ftr-search-legacy-results-not-found>
  </ng-template>
  <ng-template #sideBar let-data>
    @if (searchRequestType$ | async) {
      @if (data.searchResults.meta.totalItems > 0) {
        <ftr-search-legacy-results-navigation
          [numResults]="data.searchResults.meta.totalItems"
          [searchTerm]="(query$ | async) ?? undefined"
          [pageNumber]="(page$ | async) ?? 1"
        >
        </ftr-search-legacy-results-navigation>
      }
    }
  </ng-template>
  <ng-template #body let-data>
    @if (searchRequestType$ | async; as sRType) {
      <ftr-search-legacy-results-list
        [searchResponse]="data.searchResults"
        [searchRequestType]="sRType"
        [sttContext$]="sttContext$"
        [configuration]="data.configuration"
      ></ftr-search-legacy-results-list>
    }
  </ng-template>
</ftr-side-panel-page-layout>
