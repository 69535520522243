import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import {
  IndexedLogNote,
  IndexedLogSheet,
  IndexedRecording,
  IndexedRemark,
  SearchRequestType,
  SearchResponse,
  SearchResult,
  SearchResultTypeDiscriminator,
} from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { DestroySubscribers } from '@ftr/foundation'
import { TAB_QUERY_PARAM } from '@ftr/routing-paths'
import { SttContext } from '@ftr/stt-search'
import {
  SearchFocusState,
  SearchState,
  SetBackToSearchResultsFullUrlAction,
  SetSearchFocusStateAction,
  SetSearchResultsScrollPositionAction,
  SetSelectedSearchItemAction,
  getPlaybackStart,
  getPlaybackStartForLogNote,
  getPlaybackStartForRemark,
  isIndexedLogNote,
  isIndexedLogSheet,
  isIndexedOrder,
  isIndexedRecording,
  isIndexedRemark,
  viewOrderLink,
  viewOrderPlaybackLink,
  viewRecordingLink,
  viewSharedRecordingLink,
} from '@ftr/ui-search'
import { ZoneId } from '@js-joda/core'
import { Store } from '@ngxs/store'
import { Observable, filter, first, map, takeUntil } from 'rxjs'
import { LOG_SHEET_TAB_VALUE, STT_TAB_VALUE } from '~app/features/recording-playback/recording-playback-tab.service'
import { SEARCH_OVERLAY_SELECTOR } from '~app/features/search-legacy/search-results/search-legacy-results.component'
import { SearchParams } from '~app/pages/search.params'
import { WindowRefService } from '~app/services/window/window-ref.service'

export enum SearchNavigationDirection {
  Up = 'up',
  Down = 'down',
}

export interface SearchResultViewModel {
  content: SearchResult
  link: string[] | undefined
  hasLink: boolean
  queryParams: Params
  id: string
}

@Component({
  selector: 'ftr-search-legacy-results-list',
  templateUrl: './search-legacy-results-list.component.html',
  styleUrls: ['./search-legacy-results-list.component.css'],
})
export class SearchResultsListComponent extends DestroySubscribers implements OnInit, AfterViewInit {
  @Input() searchResponse: SearchResponse
  @Input() searchRequestType: SearchRequestType
  @Input() sttContext$: Observable<SttContext | undefined>
  @Input() configuration: PrivateConfiguration
  @ViewChild('resultsList') resultsList: ElementRef

  searchItems$: Observable<SearchResultViewModel[]>
  activeSearchIdLoading: Uuid
  readonly pageParam = SearchParams.SearchPageNumber

  constructor(
    private readonly store: Store,
    readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly windowRefService: WindowRefService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.searchItems$ = this.sttContext$.pipe(
      map(sttContext =>
        this.searchResponse.items.map((content, index) =>
          this.buildViewSearchResult(
            content,
            index.toString(),
            this.configuration.courtSystemId,
            this.configuration.timeZoneId,
            sttContext,
          ),
        ),
      ),
    )
  }

  ngAfterViewInit(): void {
    this.store
      .select(SearchState.searchFocusState)
      .pipe(
        takeUntil(this.finalize),
        filter(s => s === SearchFocusState.RESULTS_LIST),
      )
      .subscribe(() => {
        const firstItem: HTMLElement = this.resultsList?.nativeElement.children[0]
        if (firstItem) {
          firstItem.focus()
        } else {
          this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.NONE))
        }
      })
  }

  onItemClick(data: SearchResultViewModel): void {
    if (data.link) {
      this.activeSearchIdLoading = data.id
      this.store.dispatch(
        new SetSearchResultsScrollPositionAction(
          this.windowRefService.getElementScrollPosition(SEARCH_OVERLAY_SELECTOR),
        ),
      )
      this.store.dispatch(new SetSelectedSearchItemAction(data.content.body))
      const currentUrl = this.router.url
      // Dispatch later to prevent the back to search appearing just before it navigates to the item.
      this.router.events
        .pipe(first(e => e instanceof NavigationEnd))
        .subscribe(() => this.store.dispatch(new SetBackToSearchResultsFullUrlAction(currentUrl)))
    }
  }

  onResultUp(event: Event): void {
    this.navigate(event, SearchNavigationDirection.Up)
  }

  onResultDown(event: Event): void {
    this.navigate(event, SearchNavigationDirection.Down)
  }

  private buildViewSearchResult(
    data: SearchResult,
    id: string,
    courtSystemId: Uuid,
    courtSystemTimeZoneId: ZoneId,
    sttContext: SttContext | undefined,
  ): SearchResultViewModel {
    const link = this.generateLink(data, courtSystemId, sttContext)
    const queryParams = this.generateQueryParams(data, courtSystemTimeZoneId)
    const hasLink = this.shouldLinkSearchResult(link)

    return {
      content: data,
      link,
      hasLink,
      queryParams,
      id,
    }
  }

  private generateLink(result: SearchResult, courtSystemId: Uuid, sttContext: SttContext | undefined): string[] {
    if (!courtSystemId) {
      return []
    }

    const link = []
    let generatedLink: string[] | undefined

    if (this.searchRequestType === SearchRequestType.Orders) {
      generatedLink = this.getLinkForOrderSearchResult(result, courtSystemId)
    } else if (this.searchRequestType === SearchRequestType.AllRecordings) {
      generatedLink = this.getLinkForAllRecordingsSearchResult(result as SearchResult<IndexedRecording>, courtSystemId)
    } else if (this.searchRequestType === SearchRequestType.ThisRecording) {
      generatedLink = this.getLinkForRecordingSearchResult(
        result.body as IndexedRemark | IndexedLogSheet | IndexedLogNote,
        result.type,
        courtSystemId,
      )
    } else if (this.searchRequestType === SearchRequestType.ThisAudioSegment) {
      generatedLink = this.getLinkForAudioSegmentSearchResult(
        result.body as IndexedRemark | IndexedLogSheet | IndexedLogNote,
        result.type,
        sttContext,
      )
    }

    if (generatedLink) {
      link.push(...generatedLink)
    }

    if (link && link.length) {
      return ['/', ...link]
    }

    return []
  }

  private getLinkForOrderSearchResult({ body, type }: SearchResult, courtSystemId: Uuid): string[] | undefined {
    return isIndexedOrder(body, type) ? viewOrderLink(body.lineItemType, courtSystemId, body.id, body.jobId) : undefined
  }

  private getLinkForAllRecordingsSearchResult(
    { body, type }: SearchResult<IndexedRecording>,
    courtSystemId: Uuid,
  ): string[] | undefined {
    if (body.logNotes?.length) {
      return this.getLinkForRecordingSearchResult(body.logNotes[0], type, courtSystemId, body.id, body.location)
    } else if (body.logSheets?.length) {
      return this.getLinkForRecordingSearchResult(body.logSheets[0], type, courtSystemId, body.id, body.location)
    } else if (body.transcript?.length) {
      return this.getLinkForRecordingSearchResult(body.transcript[0], type, courtSystemId, body.id, body.location)
    }
    return this.getLinkForRecordingSearchResult(body, type, courtSystemId, body.id, body.location)
  }

  private getLinkForRecordingSearchResult(
    body: IndexedRecording | IndexedRemark | IndexedLogSheet | IndexedLogNote,
    type: SearchResultTypeDiscriminator,
    courtSystemId: Uuid,
    recordingId?: Uuid,
    location?: IndexedRecording['location'],
  ): string[] | undefined {
    if (isIndexedLogSheet(body, type) || isIndexedLogNote(body, type)) {
      return viewRecordingLink(
        courtSystemId,
        body.courthouseId || location?.courthouseId,
        body.courtroomId || location?.courtroomId,
        recordingId || body.recordingId!,
      )
    } else if (isIndexedRemark(body, type)) {
      return viewRecordingLink(
        courtSystemId,
        body.courthouseId || location?.courthouseId,
        body.courtroomId || location?.courtroomId,
        recordingId || body.recordingId!,
        body.recordingType,
      )
    } else if (isIndexedRecording(body, type)) {
      return viewRecordingLink(
        courtSystemId,
        body.location?.courthouseId,
        body.location?.courtroomId,
        body.id,
        body.recordingType,
      )
    }
    return undefined
  }

  private getLinkForAudioSegmentSearchResult(
    body: IndexedRemark | IndexedLogSheet | IndexedLogNote,
    type: SearchResultTypeDiscriminator,
    sttContext: SttContext | undefined,
  ): string[] | undefined {
    if (sttContext?.type !== 'shared-recording' || !sttContext.sharedRecordingId) {
      return viewOrderPlaybackLink()
    }

    const { sharedRecordingId } = sttContext

    if (isIndexedLogSheet(body, type) || isIndexedLogNote(body, type)) {
      return viewSharedRecordingLink(sharedRecordingId)
    } else if (isIndexedRemark(body, type)) {
      return viewSharedRecordingLink(sharedRecordingId)
    }

    return undefined
  }

  private generateQueryParams(result: SearchResult, courtSystemTimeZoneId: ZoneId): Params {
    let queryParams: Params = {}
    if (this.searchRequestType === SearchRequestType.AllRecordings) {
      queryParams = this.getQueryParamForAllRecordingsSearchResult(
        result as SearchResult<IndexedRecording>,
        courtSystemTimeZoneId,
      )
    } else if (
      this.searchRequestType === SearchRequestType.ThisRecording ||
      this.searchRequestType === SearchRequestType.ThisAudioSegment
    ) {
      queryParams = this.getQueryParamForRecordingSearchResult(
        result.body as IndexedRemark | IndexedLogSheet | IndexedLogNote,
        result.type,
        courtSystemTimeZoneId,
      )
    }

    return queryParams
  }

  private getQueryParamForAllRecordingsSearchResult(
    { body, type }: SearchResult<IndexedRecording>,
    courtSystemTimeZoneId: ZoneId,
  ): Params {
    if (body.logNotes?.length) {
      return this.getQueryParamForRecordingSearchResult(body.logNotes[0], type, courtSystemTimeZoneId)
    } else if (body.logSheets?.length) {
      return this.getQueryParamForRecordingSearchResult(body.logSheets[0], type, courtSystemTimeZoneId)
    } else if (body.transcript?.length) {
      return this.getQueryParamForRecordingSearchResult(body.transcript[0], type, courtSystemTimeZoneId)
    }
    return this.getQueryParamForRecordingSearchResult(body, type, courtSystemTimeZoneId)
  }

  private getQueryParamForRecordingSearchResult(
    body: IndexedRecording | IndexedRemark | IndexedLogNote,
    type: SearchResultTypeDiscriminator,
    courtSystemTimeZoneId: ZoneId,
  ): Params {
    if (isIndexedLogNote(body, type)) {
      return { start: getPlaybackStartForLogNote(body, courtSystemTimeZoneId), [TAB_QUERY_PARAM]: LOG_SHEET_TAB_VALUE }
    } else if (isIndexedRemark(body, type)) {
      return { start: getPlaybackStartForRemark(body, courtSystemTimeZoneId), [TAB_QUERY_PARAM]: STT_TAB_VALUE }
    } else if (isIndexedRecording(body, type)) {
      return { start: getPlaybackStart(body, courtSystemTimeZoneId) }
    }
    return {}
  }

  private navigate(event: Event, direction: SearchNavigationDirection): void {
    const target = event.target as HTMLElement
    const scrollIntoViewOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
    }
    event.preventDefault()
    if (direction === SearchNavigationDirection.Up) {
      const previousElementSibling = target.previousElementSibling as HTMLElement
      if (previousElementSibling) {
        previousElementSibling.focus()
        previousElementSibling.scrollIntoView(scrollIntoViewOptions)
      } else {
        this.store.dispatch(new SetSearchFocusStateAction(SearchFocusState.SEARCH_INPUT))
      }
    } else if (direction === SearchNavigationDirection.Down) {
      const nextElementSibling = target.nextElementSibling as HTMLElement
      if (nextElementSibling) {
        nextElementSibling.focus()
        nextElementSibling.scrollIntoView(scrollIntoViewOptions)
      }
    }
  }

  private shouldLinkSearchResult(link: string[]): boolean {
    /**
     * It's possible for recordings to not return a link but just a query param
     * for a recording's start time. In this case we want the query param to be
     * translated to a same-page link.
     */
    if (link.length <= 0 && this.searchRequestType === SearchRequestType.ThisAudioSegment) {
      return true
    }

    return link.length > 0
  }
}
