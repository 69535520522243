import { Inject } from '@angular/core'
import { API_CONFIGURATION, FullApiClientConfiguration } from '@ftr/api-shared'
import { LoggingService } from '@ftr/ui-observability'
import { MIN_DURATION, MediaPlayerType } from '../../types'
import { MultiChannelPlaylistService } from '../multi-channel-playlist'
import { MediaFactoryService } from './media-factory.service'
import { MediaPlayerAbstract } from './media-player.abstract'

/**
 * This is used for browsers that do not support media source extensions
 *
 * - Desktop Safari < 13
 * - iOS Safari
 * - iOS Chrome
 *
 * See https://developer.apple.com/documentation/webkitjs/htmlmediaelement for events
 */
export class HlsNativeMediaPlayer extends MediaPlayerAbstract {
  constructor(
    mediaFactoryService: MediaFactoryService,
    loggingService: LoggingService,
    @Inject(API_CONFIGURATION) override readonly configurationService: FullApiClientConfiguration,
    mediaPlayerType: MediaPlayerType,
    multiChannelPlaylistService: MultiChannelPlaylistService,
  ) {
    super(mediaFactoryService, loggingService, configurationService, mediaPlayerType, multiChannelPlaylistService)

    // The timeupdate event is the only event to give new seekable ranges
    this.mediaElement.addEventListener('timeupdate', this.updatePlaylistDuration.bind(this))
    this.mediaElement.addEventListener('durationchange', () => {
      const duration = isFinite(this.mediaElement.duration) ? this.mediaElement.duration : 0
      this.duration.next(duration)
    })
  }

  updatePlaylistDuration(): void {
    const seekableAudio = this.mediaElement.seekable
    const duration =
      seekableAudio && seekableAudio.length > 0
        ? seekableAudio.end(seekableAudio.length - 1) - seekableAudio.start(0)
        : MIN_DURATION

    this.duration.next(duration)
  }

  reloadSource(): void {
    this.setSource(this.mediaElement.src)
  }
}
