import { CommonModule } from '@angular/common'
import { Component, ElementRef, Input, TemplateRef, output, viewChild } from '@angular/core'
import { Params, RouterModule } from '@angular/router'

@Component({
  selector: 'ftr-complex-list-item',
  templateUrl: './complex-list-item.component.html',
  styleUrls: ['./complex-list-item.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class ComplexListItemComponent {
  @Input() ariaLabel: string
  @Input() key: string
  @Input() link: string[]
  @Input() queryParams: Params | null | undefined

  @Input() additionalTemplate: TemplateRef<any> | null = null
  // Assumes the additional template is in absolute position, so no space will be reserved for it in the flex container
  @Input() isAbsolutePositionAdditionalTemplate: boolean = false
  @Input() displayMode: 'multi-child' | 'single-child' = 'multi-child'
  @Input() childTemplate?: TemplateRef<any>
  @Input() leftTemplate?: TemplateRef<any>
  @Input() rightTemplate?: TemplateRef<any>

  anchorFocus = output<FocusEvent>()
  anchorKeyDown = output<KeyboardEvent>()

  linkElement = viewChild.required<ElementRef<HTMLAnchorElement>>('linkElement')
}
