import { Component } from '@angular/core'

/**
 * A component for creating an underlay spacing under the header.
 * This is going to be essential while we have a fixed header on the real-time playback page,
 * however when we restructure the page so that scrolling happens inside STT component, this can be removed
 */

@Component({
  standalone: true,
  imports: [],
  selector: 'ftr-real-time-nav-underlay',
  styleUrls: ['./real-time-nav-underlay.component.css'],
  templateUrl: './real-time-nav-underlay.component.html',
})
export class RealTimeNavUnderlayComponent {}
