<ftr-filter-list-card
  className="court-system-selector-item"
  [attr.aria-label]="courtSystem.name"
  [displayMode]="'COMPACT'"
  (select)="handleOnSelect()"
>
  <div class="court-system-selector-item__content">
    @if (courtSystem.logoUrl) {
      <img class="court-system-selector-item__court-logo" [src]="this.courtSystem.logoUrl" />
    } @else {
      <ftr-icon
        class="court-system-selector-item__court-logo"
        icon="Gavel"
        color="Grey30"
        size="ExtraLarge"
        align="Bottom"
      ></ftr-icon>
    }
    <div class="court-system-selector-item__detail">
      <div #courtSystemName class="court-system-selector-item__detail__court-system">
        <div
          class="court-system-selector-item__detail__court-system-name"
          ftrTooltip
          [message]="courtSystem.name"
          [showDash]="false"
          [hideIfNotOverflowing]="true"
          [alignment]="tooltipAlignment.AUTO"
        >
          {{ courtSystem.name }}
        </div>
        @if (courtSystem.externalUrl) {
          <ftr-icon
            icon="OpenInNew"
            align="Sub"
            ftrTooltip
            [message]="externalPortalTooltip"
            [showDash]="false"
            [alignment]="tooltipAlignment.AUTO"
            size="Small"
          ></ftr-icon>
        }
      </div>
      <div class="court-system-selector-item__detail-state">
        {{ this.stateName }}
      </div>
    </div>
    <div class="court-system-selector-item__status">
      @for (badge of badgeConfigs; track badge) {
        <ftr-badge [text]="badge.text" [color]="badge.color" [icon]="badge.icon"> </ftr-badge>
      }
    </div>
  </div>
</ftr-filter-list-card>
