<ftr-search-bar-legacy
  [isMobile]="isMobile"
  [isTablet]="isTablet"
  [isDesktop]="isDesktop"
  [isMobile]="isMobile"
  [isTablet]="isTablet"
  [isDesktop]="isDesktop"
  [isSearching$]="isSearching$"
  [searchTerm$]="searchTerm$"
></ftr-search-bar-legacy>
@if (isSearching()) {
  <ftr-search-results-overlay-legacy
    [isSearching$]="isSearching$"
    [searchType$]="searchType$"
    [searchTerm$]="searchTerm$"
    [searchResultsPageNumber$]="searchPage$"
  ></ftr-search-results-overlay-legacy>
}
