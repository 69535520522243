@for (item of availableFilterItems(); track item.label) {
  <ftr-search-type-filter-item
    [icon]="item.icon"
    [label]="item.label"
    [numberOfResults]="item.numberOfResults"
    [selected]="item.selected"
    (onFilterItemSelected)="onFilterItemSelected.emit(item.type)"
    tabindex="0"
    [attr.aria-label]="
      'Search Filter: ' +
      item.label +
      ' ' +
      (item.selected ? 'Enabled' : 'Disabled') +
      ', Select to ' +
      (!item.selected ? 'Enable' : 'Disable')
    "
  ></ftr-search-type-filter-item>
}
