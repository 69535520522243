/* eslint-disable max-classes-per-file */
import { SearchEntityType } from '@ftr/contracts/api/search'

export class FetchSearchManyResultsAction {
  static readonly type = '[SearchMany] Fetch Search Results'

  constructor(
    readonly searchTerms: string,
    /**
     * This is the list of entities that we are searching for. This is different from the selected entities when no filters
     * are selected.
     */
    readonly searchedEntities: SearchEntityType[],
    /**
     * This corresponds to the selected filters on the UI
     */
    readonly selectedEntities: SearchEntityType[],
    readonly courtSystemId: string,
    readonly pageToken: string | null,
  ) {}
}

export class FetchSearchEntityTypeToNumberOfMatchesAction {
  static readonly type = '[Search] Fetch Search Many Results Total Matches'

  constructor(
    public searchTerm: string,
    readonly entities: SearchEntityType[],
    readonly courtSystemId: string,
  ) {}
}

export class ClearSearchManyResultsAction {
  static readonly type = '[SearchMany] Clear Search Results'
}
