<div
  class="select input card-select"
  data-track-group="Saved Cards"
  [ngClass]="{
    'select--has-value': control.value || usingNewCard,
    'select--menu-open': isOpen,
    'select--display': !isOpen,
    'select--disabled': !enabled,
    'select--invalid': !control.valid,
    'input--invalid': !control.valid,
    'select--touched': control.touched,
    'input--touched': control.touched,
    'card-select--using-new-card': usingNewCard,
  }"
  (clickOutside)="close()"
  [openState]="isOpen"
>
  <div class="select__value-container" [formGroup]="formGroup">
    @if (!usingNewCard) {
      <button type="button" class="select__button" (click)="open()" (focusin)="open()">Select a card</button>
    }
    <div class="select__value">
      @if (usingNewCard) {
        <ftr-payment
          [autoFocus]="usingNewCard"
          formControlName="newCard"
          [control]="formGroup.controls['newCard']"
          [paymentApiKey]="paymentApiKey"
          [highlight]="highlight"
        ></ftr-payment>
      }
      @if (control.value && !usingNewCard) {
        <div class="card-select__value">
          <img
            class="card-select__brand-icon"
            src="/assets/images/svgs/cards/{{ control.value.card.brand | ftrCreditCardBrandImage }}.svg"
            alt="{{ control.value.card.brand }}}"
          />
          <span class="card-select__number">{{ control.value.card | ftrCreditCardMask }}</span>
          <span class="card-select__expiry"
            >{{ control.value.card.expiryMonth }} / {{ control.value.card.expiryYear }}</span
          >
        </div>
      }
    </div>
    @if (!usingNewCard) {
      <label class="select__label">Select a card</label>
    }
    @if (!usingNewCard) {
      <span class="select__bar input__bar"></span>
    }
    <ftr-icon
      (click)="isOpen ? close() : open()"
      class="select__arrow select__arrow--right"
      icon="KeyboardArrowRight"
      title="Choose a saved card"
    ></ftr-icon>
    <ftr-icon
      (click)="isOpen ? close() : open()"
      class="select__arrow select__arrow--down"
      icon="ArrowDropDown"
      title="Choose a saved card"
    ></ftr-icon>
    <span
      class="input__validation select__validation"
      ftrValidationErrorHint
      [label]="'Select a card'"
      [ngFormControl]="control"
      [controlTouched]="control.touched"
    ></span>
  </div>
  <div class="select__open-menu-container">
    <div class="select__input-container">
      <button ftrButton [displayType]="buttonDisplayType.Icon" type="button" class="select__close" (click)="close()">
        <ftr-icon icon="KeyboardArrowLeft"></ftr-icon>
      </button>
      <span class="select__opened-label">Select a card</span>
    </div>
    <div class="select__items">
      @for (savedCard of items; track savedCard) {
        <div
          class="card-select__card"
          (click)="selectSavedCard(savedCard)"
          [ngClass]="{ 'card-select__card--selected': control.value && savedCard.card.id === control.value.id }"
        >
          <img
            class="card-select__brand-icon"
            src="/assets/images/svgs/cards/{{ savedCard.card.brand | ftrCreditCardBrandImage }}.svg"
            alt="{{ savedCard.card.brand }}"
          />
          <span class="card-select__number">{{ savedCard.card | ftrCreditCardMask }}</span>
          <span class="card-select__expiry">{{ savedCard.card.expiryMonth }} / {{ savedCard.card.expiryYear }}</span>
        </div>
      }
      <div class="card-select__card" (click)="selectNewCard()">
        <span class="card-select__new-card" data-track>Use a different card</span>
      </div>
    </div>
  </div>
</div>
