@if (isLoggedIn$ | async) {
  <div [class.header-container--playback-page]="isPlaybackPage$ | async" id="header" class="header-container" #header>
    <ftr-main-side-nav #sideNav [hidden]="!isMobile"></ftr-main-side-nav>
    <div
      class="header"
      [class.search-active]="
        (searchBarInputState$ | async) !== inputStates.Collapsed || (searchBarFocusState$ | async) !== focusStates.NONE
      "
      data-track-group="Header"
    >
      <div class="header__left">
        @if ((searchBarInputState$ | async) === inputStates.Collapsed && isMobile) {
          <div class="side-nav-toggle">
            <ftr-side-nav-menu-toggle (onToggle)="sideNav.toggle()"></ftr-side-nav-menu-toggle>
          </div>
        }
        @if ((searchBarInputState$ | async) === inputStates.Collapsed || !isMobile) {
          <div class="logo">
            @if ((isInternalUser$ | async) === false || hasNoPersonalizedLogo) {
              <ftr-logo [theme]="logoThemes.ThemedNoText"></ftr-logo>
            } @else {
              <ftr-court-system-logo
                [courtSystemId]="(currentCourtSystem$ | async)!.id"
                [courtSystemName]="(currentCourtSystem$ | async)!.name"
                [imageUrl]="logoUrl!"
              ></ftr-court-system-logo>
            }
          </div>
        }
        @if (isMobile || (displayCourtDropdownOrLabel$ | async)) {
          <div class="court-system-dropdown">
            @if (displayCourtSystemDropdown$ | async) {
              <ftr-court-system-dropdown></ftr-court-system-dropdown>
            } @else {
              <div>{{ (currentCourtSystem$ | async)?.name }}</div>
            }
          </div>
        }
        <div
          class="main-menu"
          [class.with-court-system]="displayCourtDropdownOrLabel$ | async"
          [class.with-search]="displaySearchBar$ | async"
        >
          <ftr-legacy-overflow-menu></ftr-legacy-overflow-menu>
        </div>
      </div>
      <div class="header__right">
        @if (displaySearchBar$ | async) {
          <div class="search-bar">
            <div
              [class.expanded]="(searchBarInputState$ | async) === inputStates.Expanded"
              class="justify-content-center"
            >
              @if (searchEverythingByDefaultFlag$ | async) {
                @if (currentCourtSystem$ | async; as courtSystem) {
                  <ftr-search-container
                    [isMobile]="isMobile"
                    [isTablet]="isTablet"
                    [isDesktop]="isDesktop"
                    [courtSystemId]="courtSystem.id"
                    [isSearching]="isSearching()"
                  ></ftr-search-container>
                }
              } @else {
                <ftr-search-container-legacy
                  [isMobile]="isMobile"
                  [isTablet]="isTablet"
                  [isDesktop]="isDesktop"
                  [isSearching]="isSearching()"
                ></ftr-search-container-legacy>
              }
            </div>
          </div>
        }
        <!-- If on mobile and the search overlay is visible dont show this -->
        @if (!isMobile || (searchBarInputState$ | async) !== inputStates.Expanded) {
          <div class="elevio__help" ftrTooltip message="Help Center" [showDash]="false">
            <ftr-icon icon="HelpCircle" color="Primary" [interactive]="true" align="Top" size="ExtraLarge"> </ftr-icon>
          </div>
          <div class="user-dropdown">
            <ftr-account-menu class="no-gutters justify-content-end"></ftr-account-menu>
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <ftr-header-logged-out></ftr-header-logged-out>
}
