import { Component } from '@angular/core'
import { randomInt } from '../../util'
import { CardComponent } from '../card'
import { Illustration, IllustrationComponent } from '../illustration'
import { TooltipAlignment, TooltipDisplay, TooltipModule } from '../tooltip'
import { Spacing } from '../util'

const QUOTES = [
  'My name is Tippy!',
  'This is a tooltip that can be triggered by the Konami code.',
  'TRM stands for "The Record Media".',
  'The Konami Code was invented by Kazuhisa Hashimoto in 1985.',
  'I first existed in the FTR Gold Suite of products',
  'FTR has a special bus! But no one seems to ride it.',
  'What did the TRM say to the RTX file? Give me 5.',
  'RealTime Recorder is fast, but not as fast as the Flash.',
  'FTR Justice Cloud has a feature FTR RealTime which uses a recorder called the Real-Time Recorder which records in' +
    ' real-time.',
  'That Dihydrogen Monoxide (DHMO) is vital to your health! Please stay hydrated.',
  'FTR has been in the industry for over 30 years.',
]

@Component({
  standalone: true,
  selector: 'ftr-tippy',
  templateUrl: './tippy.component.html',
  styleUrls: ['./tippy.component.css'],
  imports: [IllustrationComponent, TooltipModule, CardComponent],
})
export class TippyComponent {
  protected readonly illustration = Illustration
  protected readonly tooltipAlignment = TooltipAlignment
  protected readonly tooltipDisplay = TooltipDisplay
  protected readonly spacing = Spacing

  quote = this.getRandomQuote()

  nextQuote(): void {
    this.quote = this.getRandomQuote()
  }

  getRandomQuote(): string {
    return QUOTES[randomInt(0, QUOTES.length - 1)]
  }
}
