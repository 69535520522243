import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { Params, RouterModule } from '@angular/router'
import { PageMetadata } from '@ftr/contracts/type/shared'
import { IconAlignment, IconComponent } from '@ftr/foundation'

const DEFAULT_PAGE = 1

/*
  Top - paginator above content, sets bottom margin
  Bottom - paginator below content, sets top margin
  Default - no margins set
*/
type PaginationType = 'top' | 'bottom' | 'default'

export const paginationComponentTestIds = {
  pageInfoText: 'pagination-page-info-text',
  previousPageButton: 'pagination-previous-page',
  nextPageButton: 'pagination-next-page',
}

@Component({
  selector: 'ftr-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  standalone: true,
  imports: [CommonModule, IconComponent, RouterModule],
})
export class PaginationComponent implements OnChanges {
  protected readonly testIds = paginationComponentTestIds
  /**
   * Pagination details
   */
  @Input() meta: PageMetadata
  /**
   * Emit pages instead of navigating
   */
  @Input() emitPages = false

  @Input() disabled = false

  @Input() iconAlignment: IconAlignment = 'Middle'

  @Input() pageParamName = 'page'

  @Input() paginationType: PaginationType = 'default'
  /**
   * Emits selected page when `emitPages` is `true`
   */
  @Output() pageSelected = new EventEmitter<number>()

  /**
   * The current page number
   */
  page: number
  /**
   * Whether there are further pages after this one
   */
  hasNextPage: boolean
  /**
   * Whether there are pages before the current page
   */
  hasPrevPage: boolean
  /**
   * The number in the full list of entities of the first entity on the current page
   */
  pageStart: number
  /**
   * The number in the full list of entities of the last entity on the current page
   */
  pageEnd: number
  /**
   * The total number of list items across all pages
   */
  totalItems: number
  /**
   * The number of entities per page
   */
  pageSize: number
  /**
   * Whether the total number has been capped in the backend
   */
  exceededMaxResultsLimit?: boolean

  ngOnChanges(changes: SimpleChanges): void {
    this.meta = changes.meta?.currentValue
    if (changes.meta && changes.meta.currentValue) {
      ;({
        totalItems: this.totalItems,
        size: this.pageSize,
        number: this.page,
        exceededMaxResultsLimit: this.exceededMaxResultsLimit,
      } = this.meta)
      this.setPageNavigation(this.page, this.pageSize, this.totalItems)
      this.setCurrentPageNumbers(this.page, this.pageSize, this.totalItems)
    }
  }

  get prevPageParams(): Params {
    return { [this.pageParamName]: this.page - 1 }
  }

  get nextPageParams(): Params {
    return { [this.pageParamName]: this.page + 1 }
  }

  goToPage(page: number): void {
    // update UI immediately
    this.page = page
    this.setPageNavigation(this.page, this.pageSize, this.totalItems)
    this.setCurrentPageNumbers(this.page, this.pageSize, this.totalItems)

    this.pageSelected.emit(page)
  }

  private setPageNavigation(page: number, size: number, total: number): void {
    this.hasNextPage = page * size < total
    this.hasPrevPage = page > DEFAULT_PAGE
  }

  private setCurrentPageNumbers(page: number, pageSize: number, total: number): void {
    const size = pageSize > total ? total : pageSize
    this.pageStart = (page - 1) * size + 1
    this.pageEnd = page * size < total ? page * size : total
  }
}
