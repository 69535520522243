import { Injectable, Type } from '@angular/core'
import { Observable, Subject, from } from 'rxjs'
import { AngularComponentInputs } from '../../util'
import { ButtonColor } from '../button'
import { ModalType } from '../modal'

interface AngularComponent<T> {
  type: Type<T>
  inputs: AngularComponentInputs<T>
}

interface ConfirmationModalDefinition<T> {
  modalType: ModalType
  title: string
  content: string | AngularComponent<T>
  cancelText: string
  confirmText: string
  confirmButtonColor: ButtonColor
}

export interface ConfirmationModalDefinitionWithCallbacks<T> extends ConfirmationModalDefinition<T> {
  onConfirm: () => void
  onCancel: () => void
}

export const DEFAULT_CONFIRMATION_MODAL_DEFINITION = {
  modalType: ModalType.Default,
  title: 'Confirm',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  confirmButtonColor: ButtonColor.Primary,
  content: 'Are you sure?',
  onConfirm: () => {
    // No operation
  },
  onCancel: () => {
    // No operation
  },
} satisfies ConfirmationModalDefinitionWithCallbacks<unknown>

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  private modalDefinition$ = new Subject<ConfirmationModalDefinitionWithCallbacks<unknown>>()

  async confirm<T>(modalDef: Partial<ConfirmationModalDefinition<T>>): Promise<boolean> {
    return new Promise(resolve => {
      this.modalDefinition$.next({
        ...DEFAULT_CONFIRMATION_MODAL_DEFINITION,
        ...modalDef,
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      })
    })
  }

  observeModalDefinition(): Observable<ConfirmationModalDefinitionWithCallbacks<unknown>> {
    return from(this.modalDefinition$)
  }
}
