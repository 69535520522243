import { AsyncPipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import {
  ButtonColor,
  ButtonComponent,
  ButtonDirection,
  ButtonSize,
  Icon,
  IconComponent,
  LineLoaderComponent,
} from '@ftr/foundation'
import { Observable } from 'rxjs'
import { FormStates } from '../untyped-form/form.states'

@Component({
  standalone: true,
  selector: 'ftr-submit-button',
  imports: [ButtonComponent, AsyncPipe, LineLoaderComponent, LineLoaderComponent, IconComponent],
  templateUrl: './submit-button.component.html',
})
export class SubmitButtonComponent implements OnInit {
  /**
   * Whether the button should be 100% width or not
   */
  @Input() fullWidth = false
  /**
   * Whether the button should be 100% width or not for mobile
   */
  @Input() fullWidthMobile = false
  /**
   * The button color
   */
  @Input() color: ButtonColor = ButtonColor.Primary
  /**
   * The direction for the button
   */
  @Input() direction?: ButtonDirection
  /**
   * The size for the submit button
   */
  @Input() size?: ButtonSize
  /**
   * The default label for the submit form when the form is in the ready state.
   */
  @Input() submitLabel: string
  /**
   * The label for the submit button while the form is submitting
   */
  @Input() submittingLabel: string
  /**
   * Whether there are errors in the form
   */
  @Input() highlightErrors?: Observable<boolean>
  /**
   * The current form state
   */
  @Input() formState: FormStates
  /**
   * An option that enables GA tracking on the submit button
   */
  @Input() dataTrack = false
  /**
   * A custom event action for GA tracking that will be automatically preceded by 'Click',
   * e.g. 'Submit' will make 'Click Submit'. Defaults to this button's submit label
   */
  @Input() dataTrackGroup: string
  /**
   * An optional icon to be displayed on the button
   */
  @Input() icon?: Icon

  readonly formStates = FormStates

  ngOnInit(): void {
    if (this.dataTrackGroup === undefined) {
      this.dataTrackGroup = this.submitLabel
    }
  }
}
