<div class="container" ftrTooltip [showDash]="false" [template]="tooltipTemplate">
  @if (firstGroup().length) {
    <span class="group first">
      <ng-container *ngTemplateOutlet="searchPartsTemplate; context: { searchParts: firstGroup() }"></ng-container>
    </span>
  }

  @if (middleGroup().length) {
    <span class="group middle" [class.last]="lastGroup().length === 0">
      <ng-container *ngTemplateOutlet="searchPartsTemplate; context: { searchParts: middleGroup() }"></ng-container>
    </span>
  }

  @if (lastGroup().length) {
    <span class="group last">
      <ng-container *ngTemplateOutlet="searchPartsTemplate; context: { searchParts: lastGroup() }"></ng-container>
    </span>
  }
</div>

<ng-template #searchPartsTemplate let-searchParts="searchParts">
  @for (searchPart of searchParts; track searchPart) {
    @if (searchPart.isEmBlock) {
      <em [class.subtle]="highlightType() === 'Subtle'">{{ searchPart.innerText }}</em>
    } @else {
      <span>{{ searchPart.innerText }}</span>
    }
  }
</ng-template>

<ng-template #tooltipTemplate>
  <ng-container *ngTemplateOutlet="searchPartsTemplate; context: { searchParts: searchParts() }"></ng-container>
</ng-template>
