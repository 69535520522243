import { Inject } from '@angular/core'
import { API_CONFIGURATION, FullApiClientConfiguration } from '@ftr/api-shared'
import { RecordingType } from '@ftr/contracts/type/recording'
import { LoggingService } from '@ftr/ui-observability'
import Hls, { ErrorData, Fragment, LevelUpdatedData } from 'hls.js'
import { filter, first } from 'rxjs'
import { MediaPlayerType, MediaStatus } from '../../types'
import { AuthCookiePollService } from '../auth-cookie-poll'
import { MultiChannelPlaylistService } from '../multi-channel-playlist'
import { MediaFactoryService } from './media-factory.service'
import { MediaPlayerAbstract } from './media-player.abstract'

// The court system ID is needed by regional API for transcriber permission checks. Alternatively it may be a URL param.
const COURT_SYSTEM_ID_HEADER = 'courtsystemid'

/**
 * An implementation of a playlist-based media player using hls.js.
 *
 * ## Missing fragments workaround (RC-765).
 *
 * We have a known issue where some TRMs do not contain all the expected 1 second fragments in our data store (S3).
 * For native HLS and HLS.js this results in playback halting once these missing fragments are reached.
 *
 * This class includes a workaround for HLS.js which effectively skips over missing fragments when they are detected and
 * resumes playback from the next good fragment.
 *
 * At a high level this works by:
 * - Catching `FRAG_LOAD_ERROR` errors and storing a reference to the corresponding failed fragment.
 * - Catching BUFFER_STALLED errors and if we detect that the buffer has stalled nearby to where a known failed
 *   fragment is, trying to resume playback just after that fragment's end time.
 *
 * It's worth noting that not all `FRAG_LOAD_ERROR` errors are due to missing fragments. I have also seen them being
 * raised due to `regional-api` being at capacity, during `regional-api` deployments, and during bad network conditions.
 *
 * ---
 *
 * @see https://github.com/video-dev/hls.js/blob/master/docs/API.md
 */
export class HlsJsMediaPlayer extends MediaPlayerAbstract {
  /**
   * The HlsJs instance
   */
  private hlsJs: Hls
  /**
   * When Hls polls to update the playlist from our API, we need to know if it is our server or S3 objects, to determine
   * whether to send credentials to authenticate against our API.
   */
  private readonly serverUrl: string
  private readonly regionalApiUrls: string[]

  /**
   * Contains the fragments that we have detected as missing due to a network error in loading them. We use
   * this in order to skip the fragments during playback.
   */
  private missingFragments = new Map<number, Fragment>()

  /**
   * Indicates that the hls.js playback buffer has stalled. This can happen when trying to playback fragments
   * that have not been loaded in via the network yet, either because of slow network, or because the fragments
   * are missing from our data store.
   */
  private bufferHasStalled = false

  constructor(
    mediaFactoryService: MediaFactoryService,
    loggingService: LoggingService,
    @Inject(API_CONFIGURATION) override readonly configurationService: FullApiClientConfiguration,
    mediaPlayerType: MediaPlayerType,
    multiChannelPlaylistService: MultiChannelPlaylistService,
  ) {
    super(mediaFactoryService, loggingService, configurationService, mediaPlayerType, multiChannelPlaylistService)

    this.serverUrl = configurationService.server.rawUrl
    this.regionalApiUrls = Object.values(configurationService.regionalApi.endpointMap)
  }

  override setSource(url: string): void {
    this.hlsJs = new Hls({
      defaultAudioCodec: this.defaultAudioCodec,
      startPosition: this.initialPlaybackSeconds,
      debug: false,
      levelLoadingMaxRetry: 10,
      xhrSetup: this.hlsXhrSetup.bind(this),
      enableWorker: true,
      // Increasing the number of retries to reduce potential friction with increased maxRetryTimeout
      fragLoadingMaxRetry: 12,
      levelLoadingMaxRetryTimeout: AuthCookiePollService.UPDATE_AUTH_COOKIE_INTERVAL_MILLIS * 3,
      // minimum X seconds to buffer ahead, which for 1s segments means X requests on every seek
      maxBufferLength: 10,
      // On first load of long recordings, the endpoint may take longer than the default of 10s
      manifestLoadingTimeOut: 15000,
      // Increasing tolerance factor for overlapping or holes between fragments
      maxBufferHole: 0.5,
    })
    this.hlsJs.loadSource(url)
    this.hlsJs.attachMedia(this.mediaElement)
  }

  reloadSource(): void {
    this.hlsJs.startLoad()
  }

  override startPlayback(): Promise<void> {
    this.addEventListeners()
    return Promise.resolve()
  }

  override destroy(): void {
    if (this.hlsJs) {
      this.hlsJs.destroy()
    }
    super.destroy()
  }

  private addEventListeners(): void {
    this.hlsJs.on(Hls.Events.LEVEL_UPDATED, this.onPlaylistUpdated.bind(this))
    this.hlsJs.on(Hls.Events.ERROR, this.onHlsError.bind(this))
    this.hlsJs.on(Hls.Events.FRAG_CHANGED, () => {
      // The FRAG_CHANGED event represents the current buffer switching to a fragment, which
      // can only happen if the fragment is loaded in and good to play back. When this happens
      // we mark the buffer as no longer being stalled.
      this.bufferHasStalled = false
    })
    this.hlsJs.on(Hls.Events.BUFFER_APPENDED, async () => {
      if (!this.played && this.autoplay) {
        /*
          Calling play() prior to a BUFFER_APPENDED event causes the player to initial skip back to the start,
          once the first buffered chunk is fully received. Implementing play() here avoids this situation.
          We need to set the currentTime on the media element, rather than rely on native events.
        */
        this.mediaElement.currentTime = this.initialPlaybackSeconds
        await this.play()
      }
    })
  }

  /**
   * For Hls.js clients, the duration is only available during a LEVEL_UPDATED event.
   */
  private async onPlaylistUpdated(_: string, data: LevelUpdatedData): Promise<void> {
    this.duration.next(data.details.totalduration)
  }

  private skipFragmentAndRestartPlayback(fragment: Fragment): void {
    const newStartTime = fragment.start + fragment.duration

    // Force HLS.js to start loading fragments from the newly given start time (skipping the current
    // fragment).
    this.hlsJs.startLoad(newStartTime)
    this.hlsJs.recoverMediaError()

    // Update our player to skip ahead to the new desired start time and start playing back.
    this.onSeekUpdated(newStartTime)
    this.updateTime(newStartTime)

    this.mediaElement.play()
  }

  private onHlsError(_: string, errorData: ErrorData): void {
    const data = { ...errorData, networkDetails: undefined }
    // Buffer stalled error means 'we are currently buffering' or we've hit a fragment
    // that was not able to be loaded.
    if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
      this.bufferHasStalled = true

      // Workaround for RC-765 where we know some 1 second fragments are missing from our stores.
      // If we're near a known missing fragment and playback has stalled then skip the player ahead
      // by that fragment's duration.
      const nearbyMissingFragment = this.nearbyMissingFragment()

      if (nearbyMissingFragment) {
        this.skipFragmentAndRestartPlayback(nearbyMissingFragment)
        return
      }

      this.onWaiting()
      return
    }

    // FRAG_PARSING_ERROR are fatal and a bit tricky to deal with since they are fired when the fragment is loaded,
    // before the playtime reaches the corrupt fragment's start, and no event is emitted when it runs out of buffer.
    // Fortunately however, a Waiting event is emitted very close to the start of the fragment, so we can listen for
    // these and skip the corrupt fragment before it is played, with a small, noticeable jump in the audio.
    // Corrupt fragments are very rare and instances should be addressed in the converter.
    if (errorData.details === Hls.ErrorDetails.FRAG_PARSING_ERROR && errorData.frag) {
      this.loggingService.warn({
        message: 'identified a fragment with parsing error',
        eventType: errorData.type,
        audioId: this.recordingId,
        data,
      })

      const { frag } = errorData
      const thresholdSeconds = 0.1
      this.missingFragments.set(frag.start, frag)
      this.mediaEvents
        .pipe(
          filter(
            ({ status, progress }) =>
              status === MediaStatus.Waiting &&
              progress >= frag.start - thresholdSeconds &&
              progress <= frag.start + thresholdSeconds,
          ),
          first(),
        )
        .subscribe(() => {
          this.loggingService.warn({
            message: 'attempting to skip a fragment with parsing error',
            eventType: errorData.type,
            audioId: this.recordingId,
            data,
          })
          this.skipFragmentAndRestartPlayback(frag)
        })

      return
    }

    // For certain errors like 401s, they cause an infinite loop that eventually crash the player.
    // However with 404s we want to explicitly log the error and skip that segment
    if (
      errorData.details === Hls.ErrorDetails.FRAG_LOAD_ERROR &&
      errorData.frag &&
      this.recordingType === RecordingType.TRM &&
      /**
       * @see https://github.com/video-dev/hls.js/issues/3712
       */
      (data.response as any)?.code === 404
    ) {
      this.loggingService.warn({
        message: 'attempting to skip a missing fragment',
        eventType: errorData.type,
        audioId: this.recordingId,
        data,
      })

      this.missingFragments.set(errorData.frag.start, errorData.frag)

      // When the buffer stalls near a known missing fragment it will skip to the next fragment and attempt
      // to resume playback from there, which will cause the next fragment to be loaded in. If we also have
      // an error with trying to load _that_ fragment, the buffer stalled event won't be raised again, so we
      // have to manually try to skip to the next fragment here. This will effectively brute force its way
      // through missing fragments in a playlist until it finds one that works.
      if (this.bufferHasStalled) {
        this.skipFragmentAndRestartPlayback(errorData.frag)
        return
      }
    }

    if (data.fatal) {
      /**
       * @see https://github.com/video-dev/hls.js/issues/3712
       */
      const is401 = (data.response as any)?.code === 401
      /**
       * Demote to a warning when the error is a result of an authentication failure
       * because this is expected when user's attempt to playback media after their
       * session has expired
       */
      const message = is401 ? 'hls.js playback authentication failure' : 'hls.js fatal error occurred'
      this.loggingService[is401 ? 'warn' : 'error']({
        message,
        eventType: errorData.type,
        audioId: this.recordingId,
        data,
      })
      this.dispatchError()
    } else {
      this.loggingService.warn({
        message: 'hls.js non-fatal error occurred',
        eventType: errorData.type,
        audioId: this.recordingId,
        data,
      })
    }
  }

  private hlsXhrSetup(xhr: XMLHttpRequest, url: string): void {
    if (url.includes(this.serverUrl) || this.regionalApiUrls.some(u => url.startsWith(u))) {
      // NOTE: Safari 13 does NOT send Auth to playlist when the request is cross-domain.
      xhr.withCredentials = true
      xhr.setRequestHeader(COURT_SYSTEM_ID_HEADER, this.courtSystemId)
    }
  }

  /**
   * If the current playback position is close (within `deltaSeconds` range) of a known missing fragment,
   * return that missing fragment. Otherwise returns `null`.
   */
  private nearbyMissingFragment(deltaSeconds = 0.5): Fragment | null {
    const currentTime = this.mediaElement.currentTime
    const fragments: Fragment[] = []

    for (const [missingFragmentTime, fragment] of this.missingFragments.entries()) {
      const rangeStart = missingFragmentTime - deltaSeconds
      const rangeEnd = missingFragmentTime + deltaSeconds

      if (currentTime > rangeStart && currentTime < rangeEnd) {
        fragments.push(fragment)
        return fragment
      }
    }

    return null
  }
}
