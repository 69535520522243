<div [ngClass]="{ search__container__focus: isSearching$ | async }" class="search__container">
  @if ((isSearching$ | async) === false && (searchBarInputState$ | async) === inputStates.Collapsed) {
    <button
      class="search__button"
      ftrButton
      (click)="showSearchBar()"
      aria-label="Search"
      data-test-id="open-stt-search-button"
    >
      <ftr-icon class="search__icon" icon="Search" color="Primary" [interactive]="false" align="Top"> </ftr-icon>
    </button>
  }

  @if (
    (isSearching$ | async) ||
    (searchBarInputState$ | async) === inputStates.Expanded ||
    (!isMobile && !isTablet && !isDesktop)
  ) {
    @if ((isSearching$ | async) || (searchBarInputState$ | async) === inputStates.Expanded) {
      <a
        ftrButton
        [displayType]="buttonDisplayType.Text"
        class="search__back"
        [direction]="buttonDirection.Backward"
        (click)="onBack()"
        (keydown.enter)="onBack()"
        tabindex="0"
      >
        Back
      </a>
    }
    <form name="searchForm" class="search__form" [formGroup]="formGroup" (ngSubmit)="onSearchButtonClick()">
      @if (isMobile && ((isSearching$ | async) === false || (searchBarInputState$ | async) === inputStates.Expanded)) {
        <button
          class="search__close"
          type="button"
          ftrButton
          [displayType]="buttonDisplayType.Icon"
          (click)="onClose()"
        >
          <ftr-icon icon="KeyboardArrowLeft" color="Primary" [interactive]="true" align="Middle"></ftr-icon>
        </button>
      }
      <div class="search__input input" [@expandSearch]>
        @if ((searchBarDisplayService.searchTypeOptions$ | async)?.length) {
          <ftr-select
            class="input__dropdown"
            singular="a type"
            [control]="formGroup.controls['searchType']"
            [searchType]="true"
            [items$]="searchBarDisplayService.searchTypeOptions$"
            [required]="true"
            (focus)="onSearchTypeClick()"
          >
          </ftr-select>
        }
        <span class="input__search">
          <input
            #searchIpt
            type="text"
            class="input__control"
            name="searchIpt"
            [placeholder]="getPlaceholderForSearchInput()"
            autocomplete="off"
            [formControl]="asFormControl(formGroup.controls['searchTerm'])"
            (click)="onSearchInputClick()"
            (blur)="onSearchInputBlur()"
            (keydown.arrowDown)="onDown($event)"
            [attr.aria-label]="getPlaceholderForSearchInput()"
            [attr.aria-invalid]="formGroup.controls['searchTerm'].touched && !formGroup.controls['searchTerm'].valid"
            data-test-id="stt-search-input"
          />
          <span>
            @if (formGroup.value.searchTerm?.length > 0) {
              <a
                class="search__clear"
                type="button"
                ftrButton
                [displayType]="buttonDisplayType.Text"
                (click)="onClear()"
              >
                CLEAR
              </a>
            }
          </span>
        </span>
        @if (
          (showSearchHint | async) &&
          (formGroup.value.searchTerm?.length === 0 || formGroup.value.searchTerm?.length === null) &&
          hints[asHintKey(formGroup.controls['searchType'].value)]
        ) {
          <div [@expandSearchHint] class="search__hint">
            <ftr-icon icon="Lightbulb" color="GreyLight" [interactive]="false" align="Top"> </ftr-icon>
            <span tabindex="0" class="search__hint-icon">{{
              hints[asHintKey(formGroup.controls['searchType'].value)]
            }}</span>
          </div>
        }
        @if (
          (showSearchHint | async) === false &&
          formGroup.dirty &&
          (formGroup.value.searchTerm?.length < 3 || formGroup.value.searchTerm?.length > 250)
        ) {
          <div
            ftrValidationErrorHint
            [controlTouched]="formGroup.controls['searchTerm'].touched"
            [submitAttempted]="true"
            [ngFormControl]="formGroup.controls['searchTerm']"
            class="validation__text"
            role="alert"
          ></div>
        }
      </div>
      <button ftrButton (keydown.arrowDown)="onDown($event)" class="search__submit" type="submit" aria-label="submit">
        <ftr-icon icon="Search" color="White" [interactive]="true" align="Top"> </ftr-icon>
      </button>
    </form>
  }
</div>
