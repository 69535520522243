import { CommonModule } from '@angular/common'
import { Component, computed, input } from '@angular/core'
import { HighlightedCase } from '@ftr/annotations-contracts'
import { SearchedCase } from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField, VocabularyTerms } from '@ftr/contracts/type/core'
import {
  BadgeColors,
  BadgeComponent,
  DateComponent,
  DateFormat,
  Join,
  PathsToPrimitiveProps,
  titleCase,
} from '@ftr/foundation'
import { SearchTextWithHighlightingAndTruncationComponent, SearchTextWithHighlightingComponent } from '@ftr/ui-rtstt'
import { get } from 'lodash-es'
import { CardTypeLabelComponent } from '~app/features/search/search-results/card-type-label/card-type-label.component'

type DotNotatedHighlightedCaseLookup = Join<PathsToPrimitiveProps<HighlightedCase>, '.'>

@Component({
  selector: 'ftr-search-results-cases-item',
  templateUrl: './search-results-cases-item.component.html',
  styleUrls: ['./search-results-cases-item.component.css'],
  standalone: true,
  imports: [
    DateComponent,
    SearchTextWithHighlightingComponent,
    CardTypeLabelComponent,
    CommonModule,
    BadgeComponent,
    SearchTextWithHighlightingAndTruncationComponent,
  ],
})
export class SearchResultsCasesItemComponent {
  searchResult = input.required<SearchedCase>()
  vocabularyTerms = input.required<VocabularyTerms>()
  highlight = input.required<ElasticsearchHighlightField>()

  readonly dateFormat = DateFormat
  readonly badgeColor = BadgeColors.Aqua
  readonly caseTitle = computed(
    () => this.getValue('title') || `Untitled ${titleCase(this.vocabularyTerms().case.singular)}`,
  )

  protected getValue(key: DotNotatedHighlightedCaseLookup): string {
    return this.highlight()[key]?.[0] ?? String(get(this.searchResult(), key)) ?? ''
  }

  protected hasHighlight(key: DotNotatedHighlightedCaseLookup): boolean {
    return !!this.highlight()[key]
  }
}
