import { VocabularyTerms } from '@ftr/contracts/type/core'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { assertUnreachable, titleCase } from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import {
  DifferentSessionsValidationReason,
  TimeValidation,
  TimeValidationReason,
  capitalizeFirstLetter,
  formatSuggestedTime,
  getEnclosingTimeRangeForEndTime,
  getEnclosingTimeRangeForStartTime,
  isFutureEndTime,
  runValidators,
  validateBoundaries,
  validateSameSession,
  validateSectionTimes,
} from '../time-validation-utils'

export const HEARING_SECTION_CREATION_OUT_OF_BOUNDS_MESSAGE = (terms: VocabularyTerms): string =>
  `Cannot add a ${titleCase(terms.hearing.singular)} annotation at the specified time.`
export const HEARING_SECTION_FUTURE_END_TIME_MESSAGE = (terms: VocabularyTerms): string =>
  `A ${titleCase(terms.hearing.singular)} annotation's end time cannot be in the future.`
export const HEARING_SECTION_START_TIME_OUT_BOUNDS_MESSAGE = (
  suggestedTime: LocalTime | undefined = undefined,
): string => outOfBoundsMessage('start', suggestedTime)
export const HEARING_SECTION_END_TIME_OUT_BOUNDS_MESSAGE = (suggestedTime: LocalTime | undefined = undefined): string =>
  outOfBoundsMessage('end', suggestedTime)

function outOfBoundsMessage(type: 'start' | 'end', suggestedTime: LocalTime | undefined): string {
  return suggestedTime
    ? `${capitalizeFirstLetter(type)} time is not within a session. This session ${type}s at ${formatSuggestedTime(suggestedTime)}.`
    : `${capitalizeFirstLetter(type)} time is not within a session.`
}

export const HEARING_SECTION_START_AND_END_TIME_OUT_BOUNDS_MESSAGE = 'Start and end times are not within a session.'

export const HEARING_SECTION_START_AND_END_TIME_IN_DIFFERENT_SESSIONS = 'Start and end times are in different sessions.'
export const HEARING_SECTION_START_MUST_BE_BEFORE_END_MESSAGE = 'Start time must be before end time.'

export type HearingAnnotationTimeValidationReason = TimeValidationReason | DifferentSessionsValidationReason

export type HearingAnnotationTimeValidation = TimeValidation<HearingAnnotationTimeValidationReason>

/**
 * behaviour
 *
 * if startTime: start inclusive/end exclusive
 * if endTime: start exclusive/end inclusive
 */
export function validateHearingSectionTimes(
  startTime: LocalTime,
  endTime: LocalTime,
  onRecordTimeFrames: LocalTimeRange[],
  liveLocaltime: LocalTime | undefined,
): HearingAnnotationTimeValidation | undefined {
  const startBoundary = getEnclosingTimeRangeForStartTime(startTime, onRecordTimeFrames)
  const endBoundary = getEnclosingTimeRangeForEndTime(endTime, onRecordTimeFrames)

  const validators: (() => HearingAnnotationTimeValidation | undefined)[] = [
    () => validateSectionTimes(startTime, endTime),
    () => validateBoundaries(startBoundary, endBoundary),
    () => validateSameSession(startBoundary!, endBoundary!),
    () => isFutureEndTime(endTime, endBoundary!, liveLocaltime),
  ]

  return runValidators(validators)
}

export function mapHearingTimeValidationReasonMessage(
  errorValidation: HearingAnnotationTimeValidation,
  terms: VocabularyTerms,
): string {
  switch (errorValidation.reason) {
    case 'start-is-out-of-bounds':
      return HEARING_SECTION_START_TIME_OUT_BOUNDS_MESSAGE(errorValidation.matchingEndBoundary?.start)
    case 'end-is-out-of-bounds':
      return HEARING_SECTION_END_TIME_OUT_BOUNDS_MESSAGE(errorValidation.matchingStartBoundary?.end)
    case 'both-out-of-bounds':
      return HEARING_SECTION_START_AND_END_TIME_OUT_BOUNDS_MESSAGE
    case 'different-sessions':
      return HEARING_SECTION_START_AND_END_TIME_IN_DIFFERENT_SESSIONS
    case 'start-must-be-before-end':
      return HEARING_SECTION_START_MUST_BE_BEFORE_END_MESSAGE
    case 'future-end-time':
      return HEARING_SECTION_FUTURE_END_TIME_MESSAGE(terms)
    case 'start-is-not-a-time':
      return 'Please enter a valid start time.'
    case 'end-is-not-a-time':
      return 'Please enter a valid end time.'
    case 'both-are-not-times':
      return 'Please enter valid times.'
    default:
      return assertUnreachable(errorValidation.reason)
  }
}
