import { CommonModule } from '@angular/common'
import { Component, computed, input } from '@angular/core'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import {
  IndexedLogNote,
  IndexedLogSheet,
  IndexedRecording,
  IndexedRemark,
  SearchResult,
} from '@ftr/contracts/api/search'
import { ElasticsearchHighlightField, ElasticsearchIndex, VocabularyTerms } from '@ftr/contracts/type/core'
import { DateComponent, DateFormat, Join, LayoutService, PathsToPrimitiveProps, toMoment } from '@ftr/foundation'
import { CourtSystemTimeZonePipe, CourtSystemTimezoneModule } from '@ftr/ui-court-system'
import { LiveIndicatorComponent } from '@ftr/ui-realtime-playback'
import { SearchTextWithHighlightingComponent } from '@ftr/ui-rtstt'
import { get } from 'lodash-es'
import moment from 'moment-timezone'
import { CardTypeLabelComponent } from '~app/features/search/search-results/card-type-label/card-type-label.component'
import { SearchResultsThisRecordingItemComponent } from '~app/features/search/search-results/search-results-this-recording-item/search-results-this-recording-item.component'

type DotNotatedIndexedRecordingHighlightLookup = Join<PathsToPrimitiveProps<IndexedRecording>, '.'>

@Component({
  selector: 'ftr-search-results-all-recordings-item',
  templateUrl: './search-results-all-recordings-item.component.html',
  styleUrl: './search-results-all-recordings-item.component.css',
  standalone: true,
  imports: [
    CommonModule,
    CourtSystemTimezoneModule,
    DateComponent,
    SearchTextWithHighlightingComponent,
    CardTypeLabelComponent,
    LiveIndicatorComponent,
    SearchResultsThisRecordingItemComponent,
  ],
})
export class SearchResultsAllRecordingsItemComponent {
  searchResult = input.required<IndexedRecording>()
  highlight = input.required<ElasticsearchHighlightField>()
  courtSystemConfig = input.required<PublicConfiguration>()
  vocabularyTerms = input.required<VocabularyTerms>()

  locationHtml = computed(() =>
    [this.getValue('location.courthouse'), this.getValue('location.courtroom')].filter(Boolean).join(' - '),
  )

  isLiveStreaming = computed(() => {
    const recordingStart = this.courtSystemTimeZonePipe.toCourtSystemTimeZone(
      this.searchResult().recordingStart,
      this.courtSystemConfig(),
      this.searchResult().timeZoneId,
    )

    return !!this.searchResult().isLive && toMoment(recordingStart).moment.isSame(moment(), 'day')
  })

  departmentDetails = computed(() => {
    // If there was no highlighting, we don't show it
    const departmentKey: DotNotatedIndexedRecordingHighlightLookup = 'department.name'
    return this.highlight()[departmentKey]?.[0]
  })

  excerpts = computed(() => {
    return (
      ((this.searchResult().logNotes ?? []) as (IndexedLogNote | IndexedLogSheet | IndexedRemark)[])
        .concat(this.searchResult().logSheets ?? [])
        .concat(this.searchResult().transcript ?? [])
        .map(
          excerpt =>
            ({
              type: ElasticsearchIndex.Recording,
              highlight: this.highlight(),
              relevance: 0,
              body: excerpt,
            }) as SearchResult<IndexedLogNote> | SearchResult<IndexedLogSheet> | SearchResult<IndexedRemark>,
        )
        // Currently only displaying the first excerpt as pagination and linking to individual ones needs to be done
        .slice(0, 1)
    )
  })

  constructor(
    private readonly courtSystemTimeZonePipe: CourtSystemTimeZonePipe,
    private readonly layoutService: LayoutService,
  ) {}

  readonly dateFormat = DateFormat

  // TODO: Remove with CO-2889
  protected isLegacyLayout = this.layoutService.isLegacyLayoutSignal()

  protected getValue(key: DotNotatedIndexedRecordingHighlightLookup): string {
    // If there is a highlighted value, use that (at index 0 since we only care about the first value)
    return this.highlight()[key]?.[0] ?? get(this.searchResult(), key) ?? ''
  }
}
