import { MultiRegionApiConfiguration } from '@ftr/api-shared'
import { AuthenticationService } from '@ftr/ui-user'
import { ConfigurationService } from '../configuration/configuration.service'

/**
 * The options that we pass to auth0/angular-jwt. See: https://github.com/auth0/angular2-jwt#configuration-options
 */
export function jwtOptionsFactory(
  authenticationService: AuthenticationService,
  configurationService: ConfigurationService,
): any {
  const domain = sanitiseUrl(configurationService.server.rawUrl)
  const regionalDomains = extractEndpointMapDomains(configurationService.regionalApi)
  const courtUploaderServerDomains = extractEndpointMapDomains(configurationService.courtUploaderServer)
  const digitalJusticeDomains = extractEndpointMapDomains(configurationService.digitalJusticeApi)
  const auditDomains = extractEndpointMapDomains(configurationService.auditApi)

  return {
    tokenGetter: async () => {
      try {
        return await authenticationService.currentJwtToken
      } catch {
        return undefined
      }
    },
    allowedDomains: [domain].concat(regionalDomains, courtUploaderServerDomains, digitalJusticeDomains, auditDomains),
    headerName: AuthenticationService.authHeader,
    authScheme: '',
  }
}

function extractEndpointMapDomains(configuration: MultiRegionApiConfiguration): string[] {
  return Object.values(configuration.endpointMap).map(endpoint => {
    return sanitiseUrl(endpoint)
  })
}

function sanitiseUrl(url: string): string {
  return url.replace(/^https?:\/\//, '').replace('/', '')
}
