import { NgClass } from '@angular/common'
import { Component, computed, input } from '@angular/core'
import { VocabularyTerms } from '@ftr/contracts/type/core/VocabularyTerms'
import {
  ButtonColor,
  CapitalizeFirstLetterPipe,
  DateFormat,
  DatePipe,
  ModalType,
  NotificationComponent,
  NotificationType,
} from '@ftr/foundation'
import { HearingSectionModel } from '../annotations.model'

@Component({
  standalone: true,
  selector: 'ftr-hearing-annotation-conflicts-modal',
  templateUrl: './hearing-annotation-conflicts-modal.component.html',
  styleUrls: ['./hearing-annotation-conflicts-modal.component.css'],
  imports: [DatePipe, NgClass, NotificationComponent, CapitalizeFirstLetterPipe],
})
export class HearingAnnotationConflictsModalComponent {
  readonly hearingSections = input.required<HearingSectionModel[]>()
  readonly hasPreviousConfirmationExpired = input.required<boolean>()
  readonly terms = input.required<VocabularyTerms>()

  protected readonly sortedHearingSections = computed(() =>
    [...this.hearingSections()].sort((a, b) => a.startTime.compareTo(b.startTime)),
  )

  protected readonly modalType = ModalType
  protected readonly buttonColor = ButtonColor
  protected readonly dateFormat = DateFormat
  protected readonly notificationType = NotificationType
}
