/* eslint-disable max-classes-per-file */
import { Money } from '@ftr/contracts/type/order'
import { Uuid } from '@ftr/contracts/type/shared'

export class FetchCustomerOrderAction {
  static readonly type = '[Order] Fetch Customer Order'
  constructor(
    public orderId: Uuid,
    public orderIsShared = false,
  ) {}
}

export class ResetOrderStateAction {
  static readonly type = '[Order] Reset Order State'
}

export class SetRealTimeOrderUsageFeeAction {
  static readonly type = '[Order] Set Real-Time Order Usage Fee'
  constructor(public usageFee: Money | undefined) {}
}

export class SetRealTimeOrderEligibleToRejoinAction {
  static readonly type = '[Order] Set Real-Time Order eligibleToRejoin state'
  constructor(public eligibleToRejoin: boolean) {}
}

export class SetRealTimeOrderIsInProgressAction {
  static readonly type = '[Order] Set Real-Time Order isInProgress state'
  constructor(public isInProgress: boolean) {}
}

export class StartRealTimeOrderAction {
  static readonly type = '[Order] Start Real-Time Order'
  constructor(public userPlacedOrder: boolean) {}
}

export class ResetRealTimeOrderAction {
  static readonly type = '[Order] Reset Real-Time Order'
}

export class RequestToStopRealTimeOrderAction {
  static readonly type = '[Order] Request To Stop Real-Time Order'
  constructor(public requestedToStop: boolean) {}
}

export class RequestToRejoinRealTimeOrderAction {
  static readonly type = '[Order] Request To Rejoin Real-Time Order'
  constructor(public requestedToRejoin: boolean) {}
}
