import { NgClass } from '@angular/common'
import { Component, Input } from '@angular/core'

const NUMBER_OF_LOADING_ITEMS = 7

export type LoadingSize =
  | 'orders'
  | 'shared-recordings'
  | 'audit-log'
  | 'search-results'
  | 'this-recording-search-results'

@Component({
  standalone: true,
  selector: 'ftr-complex-list-loading',
  templateUrl: './complex-list-loading.component.html',
  styleUrls: ['./complex-list-loading.component.css'],
  imports: [NgClass],
})
export class ComplexListLoadingComponent {
  @Input() loadingItemsCount: number = NUMBER_OF_LOADING_ITEMS
  @Input() loadingSize: LoadingSize

  readonly loadingItems = new Array(this.loadingItemsCount).fill(0)
}
