import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconComponent, PageComponent } from '@ftr/foundation'
import { BackToSearchResultsComponent } from './back-to-search-results-legacy.component'

@NgModule({
  imports: [CommonModule, IconComponent, PageComponent],
  declarations: [BackToSearchResultsComponent],
  exports: [BackToSearchResultsComponent],
})
export class BackToSearchResultsLegacyModule {}
