import { Injectable } from '@angular/core'
import { SearchApiService } from '@ftr/api-core'
import {
  SEARCH_MAX_LENGTH,
  SEARCH_MIN_LENGTH,
  SearchEntityType,
  SearchManyResponse,
  SearchRequestType,
  SearchResponse,
} from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, unwrapData } from '@ftr/foundation'
import { Observable, map } from 'rxjs'

export const SEARCH_PAGE_SIZE = 10

export const SEARCH_MANY_PAGE_SIZE = 25

/**
 * Search service is just for API interaction
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private readonly searchApiService: SearchApiService) {}

  doSearchMany(
    searchTerms: string,
    courtSystemId: Uuid,
    entities: SearchEntityType[],
    pageToken: string | null,
  ): ApiResult<SearchManyResponse> {
    return this.searchApiService.searchMany({
      searchTerms,
      entities,
      pageToken,
      limit: SEARCH_MANY_PAGE_SIZE,
      courtSystemId,
    })
  }

  doSearch(
    query: string | undefined,
    searchType: SearchRequestType,
    courtSystemId: Uuid,
    pageNumber = 1,
    resourceId?: Uuid,
    pageSize = SEARCH_PAGE_SIZE,
  ): ApiResult<SearchResponse> {
    return this.searchApiService.searchRequest({
      query: query as string,
      pageNumber: pageNumber ?? 1,
      resourceId,
      searchType,
      courtSystemId,
      pageSize,
    })
  }

  fetchSearchResultsTotalMatches(
    searchTerms: string,
    courtSystemId: Uuid,
    entities: SearchEntityType[],
  ): Observable<Map<SearchEntityType, number>> {
    return this.doSearchMany(searchTerms, courtSystemId, entities, null).pipe(
      unwrapData(),
      map(data => {
        const result = new Map<SearchEntityType, number>()
        entities.forEach(entity => {
          result.set(entity, data.pagination.sources[entity].totalItems)
        })
        return result
      }),
    )
  }
}

export function isValidSearchTerm(searchTerm: string | undefined | null): searchTerm is string {
  const term = searchTerm?.trim()
  return !!term && term.length >= SEARCH_MIN_LENGTH && term.length <= SEARCH_MAX_LENGTH
}

export function isValidSearchType(searchType: SearchRequestType): boolean {
  return Object.values(SearchRequestType).includes(searchType)
}

export function isValidSearchPage(searchPage: string): boolean {
  return !isNaN(parseInt(searchPage, 10)) && parseInt(searchPage, 10) > 0
}

export function isValidSearchEntities(entities: string[]): entities is SearchEntityType[] {
  return entities.every(isValidSearchEntityType)
}

export function isValidSearchEntityType(entity: string): entity is SearchEntityType {
  return Object.values(SearchEntityType).includes(entity as SearchEntityType)
}
