import { Component, Input } from '@angular/core'
import { SEARCH_PAGE_SIZE } from '@ftr/ui-search'

@Component({
  selector: 'ftr-search-legacy-results-navigation',
  templateUrl: './search-legacy-results-navigation.component.html',
  styleUrls: ['./search-legacy-results-navigation.component.css'],
})
export class SearchResultsNavigationComponent {
  @Input() searchTerm?: string
  @Input() numResults: number
  @Input() pageNumber = 1

  get pageStart(): number {
    return this.pageNumber === 1
      ? 1
      : Math.min(this.numResults, 1 + this.pageNumber * SEARCH_PAGE_SIZE - SEARCH_PAGE_SIZE)
  }

  get pageEnd(): number {
    return Math.min(this.pageNumber * SEARCH_PAGE_SIZE, this.numResults)
  }
}
