import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'
import { SavedCard } from '@ftr/contracts/read/order-account'
import { ButtonDisplayType } from '@ftr/foundation'
import { Observable } from 'rxjs'

@Component({
  selector: 'ftr-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrls: ['./saved-cards.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SavedCardsComponent {
  @Input() control: AbstractControl
  @Input() formGroup: UntypedFormGroup
  @Input() items: SavedCard[]
  @Input() enabled = true
  @Input() highlight: Observable<boolean>
  @Input() paymentApiKey: string
  @Output() selectedSavedCard = new EventEmitter<SavedCard | undefined>()

  usingNewCard = false
  isOpen = false

  readonly buttonDisplayType = ButtonDisplayType

  /**
   * Close the drop down and mark the element touched
   */
  close(): void {
    this.isOpen = false
  }

  /**
   * Open the drop down
   */
  open(): void {
    this.isOpen = true
  }

  /**
   * Close the drop-down and emit a value change up to the parent component.
   */
  selectSavedCard(value: SavedCard): void {
    this.close()
    this.usingNewCard = false
    this.selectedSavedCard.emit(value)
  }

  /**
   * Close the drop down and change it to the new card view, and emit a value change up to the parent component.
   */
  selectNewCard(): void {
    this.close()
    this.usingNewCard = true
    this.selectedSavedCard.emit(undefined)
  }
}
