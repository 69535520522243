import { Component, Input } from '@angular/core'

@Component({
  selector: 'ftr-help-link',
  template: `<a
    data-track
    data-track-group="Support"
    [className]="cls"
    [routerLink]="['./']"
    queryParamsHandling="preserve"
    >{{ label }}</a
  >`,
})
export class HelpLinkComponent {
  @Input() label: string
  /**
   * You have to set up a CSS Selector in Elevio and associate it with the article you wish to link.
   * @see https://app.elev.io/helpers
   */
  @Input() cls: string
}
