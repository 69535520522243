import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, PRIMARY_OUTLET } from '@angular/router'
import { Breadcrumb, BreadcrumbLink } from './breadcrumb'

@Injectable()
export class BreadcrumbService {
  getBreadcrumbs(route: ActivatedRoute): Breadcrumb[] {
    const leafNode = getLeafNode(route)
    /**
     * When loading the court recording playback page as a user with no permissions,
     * multiple api calls return 403 and therefore there are multiple router navigation
     * calls to redirect to our forbidden page. The first of these redirections has a
     * _futureSnapshot property rather than snapshot, which causes a console error.
     * The error could potentially be caused by multiple redirects in quick succession,
     * but checking for snapshot below is an easy solution.
     */

    if (leafNode.snapshot?.params?.courtSystemId) {
      return getBreadcrumbsRecursive(route).filter(breadcrumb =>
        breadcrumb.link.url.includes('court-system/' + leafNode.snapshot?.params?.courtSystemId + '/'),
      )
    }
    return leafNode.snapshot ? getBreadcrumbsRecursive(route) : []
  }

  isShowingBreadcrumbs(route: ActivatedRoute): boolean {
    const breadcrumbs = this.getBreadcrumbs(route)
    return breadcrumbs.length > 1 || (breadcrumbs.length > 0 && breadcrumbs[0].label !== 'Home')
  }

  // TODO: Remove with CO-2889
  getBreadcrumbsLegacy(route: ActivatedRoute, overrideHideBreadcrumbs = false): Breadcrumb[] {
    const leafNode = getLeafNode(route)
    /**
     * When loading the court recording playback page as a user with no permissions,
     * multiple api calls return 403 and therefore there are multiple router navigation
     * calls to redirect to our forbidden page. The first of these redirections has a
     * _futureSnapshot property rather than snapshot, which causes a console error.
     * The error could potentially be caused by multiple redirects in quick succession,
     * but checking for snapshot below is an easy solution.
     */
    return leafNode.snapshot && (!leafNode.snapshot.data.hideBreadcrumbs || overrideHideBreadcrumbs)
      ? getBreadcrumbsRecursive(route)
      : []
  }
}

function getLeafNode(route: ActivatedRoute): ActivatedRoute {
  return !route.children.length ? route : getLeafNode(route.children[0])
}

function getBreadcrumbsRecursive(route: ActivatedRoute, baseUrl = ''): Breadcrumb[] {
  const breadcrumbs: Breadcrumb[] = []

  const link = getRouteLink(route, baseUrl)
  const routeBreadcrumb = getBreadcrumb(route, link)
  if (routeBreadcrumb) {
    if (routeBreadcrumb.customParent) {
      breadcrumbs.push(routeBreadcrumb.customParent)
    }
    breadcrumbs.push(routeBreadcrumb)
  }

  const childBreadcrumbs = route.children.flatMap(child => getBreadcrumbsRecursive(child, link.url))
  breadcrumbs.push(...childBreadcrumbs)

  return breadcrumbs
}

function getBreadcrumb(route: ActivatedRoute, link: BreadcrumbLink): Breadcrumb | undefined {
  if (route.outlet !== PRIMARY_OUTLET) {
    return undefined
  }
  if (!route.snapshot.routeConfig) {
    return undefined
  }

  let breadcrumb = route.snapshot.routeConfig.data && route.snapshot.routeConfig.data.breadcrumb

  if (!breadcrumb && hasBreadcrumbResolver(route)) {
    breadcrumb = route.snapshot.data?.breadcrumb
  }

  if (!breadcrumb) {
    return undefined
  }

  if (isBreadcrumb(breadcrumb)) {
    return breadcrumb
  } else {
    return { label: breadcrumb, link }
  }
}

function hasBreadcrumbResolver(route: ActivatedRoute): boolean {
  return !!route.snapshot.routeConfig?.resolve?.breadcrumb
}

function getRouteLink(route: ActivatedRoute, baseUrl: string): BreadcrumbLink {
  const queryParamsToPreserve: string[] = route.snapshot.data.breadcrumbQueryParamsToPreserve || []

  const queryParams = queryParamsToPreserve.reduce<Params>((params, paramName) => {
    params[paramName] = route.snapshot.queryParams[paramName]
    return params
  }, {})

  const routeUrl = '/' + route.snapshot.url.map(s => s.path).join('/')

  return {
    url: baseUrl.replace(/\/$/, '') + routeUrl,
    queryParams,
  }
}

function isBreadcrumb(breadcrumb: Breadcrumb | string): breadcrumb is Breadcrumb {
  return typeof breadcrumb === 'string' ? false : 'label' in breadcrumb
}
