/**
 * Chrome has a bug in their handling of multichannel audio - to get the correct/same order as the input
 * file we need to remap the channels
 */
export const CHROME_LIKE_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT = new Map<number, Map<number, number>>()
  .set(1, new Map().set(1, 1))
  .set(2, new Map().set(1, 1).set(2, 2))
  .set(3, new Map().set(1, 1).set(2, 3).set(3, 2))
  .set(4, new Map().set(1, 1).set(2, 2).set(3, 3).set(4, 4))
  .set(5, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 4).set(5, 5))
  .set(6, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 5).set(5, 6).set(6, 4))
  .set(7, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 6).set(5, 7).set(6, 5).set(7, 4))
  .set(8, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 7).set(5, 8).set(6, 5).set(7, 6).set(8, 4))

/**
 * From version 118.0 of Firefox they've adopted the same bug as Chrome with channel ordering so we need to
 * remap the audio here too
 */
export const FIREFOX_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT = new Map<number, Map<number, number>>()
  .set(1, new Map().set(1, 1))
  .set(2, new Map().set(1, 1).set(2, 2))
  .set(3, new Map().set(1, 1).set(2, 3).set(3, 2))
  .set(4, new Map().set(1, 1).set(2, 2).set(3, 3).set(4, 4))
  .set(5, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 4).set(5, 5))
  .set(6, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 5).set(5, 6).set(6, 4))
  .set(7, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 6).set(5, 7).set(6, 5).set(7, 4))
  .set(8, new Map().set(1, 1).set(2, 3).set(3, 2).set(4, 7).set(5, 8).set(6, 5).set(7, 6).set(8, 4))

/**
 * ffmpeg in converter stuffs up when converting TRMs so we need to remap the first three channels so that
 * they match the expected order (same as the input to converter)
 */
export const DEFAULT_CHANNEL_REMAPPINGS_BY_CHANNEL_COUNT = new Map<number, Map<number, number>>()
  .set(1, new Map().set(1, 1))
  .set(2, new Map().set(1, 1).set(2, 2))
  .set(3, new Map().set(1, 3).set(2, 1).set(3, 2))
  .set(4, new Map().set(1, 3).set(2, 1).set(3, 2).set(4, 4))
  .set(5, new Map().set(1, 3).set(2, 1).set(3, 2).set(4, 4).set(5, 5))
  .set(6, new Map().set(1, 3).set(2, 1).set(3, 2).set(4, 4).set(5, 5).set(6, 6))
  .set(7, new Map().set(1, 3).set(2, 1).set(3, 2).set(4, 4).set(5, 5).set(6, 6).set(7, 7))
  .set(8, new Map().set(1, 3).set(2, 1).set(3, 2).set(4, 4).set(5, 5).set(6, 6).set(7, 7).set(8, 8))
