import { Component, OnDestroy, OnInit, input, output } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { SearchEntityType, SearchManyResponse } from '@ftr/contracts/api/search'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { RemoteData } from '@ftr/foundation'
import { SearchFocusState } from '@ftr/ui-search'

import { VocabularyTermsService } from '@ftr/ui-vocab'
import { SearchOverlayService } from '~app/services/search-overlay/search-overlay.service'

// NOTES for new layout.  This component can probably be fully removed, and just use global-search-results.component directly
@Component({
  selector: 'ftr-search-results-overlay',
  templateUrl: './search-results-overlay.component.html',
  styleUrls: ['./search-results-overlay.component.css'],
})
export class SearchResultsOverlay implements OnInit, OnDestroy {
  isSearching = input.required<boolean>()
  selectedEntities = input.required<SearchEntityType[]>()
  searchTerm = input.required<string>()
  searchResponse = input.required<RemoteData<SearchManyResponse>>()
  searchFocusState = input.required<SearchFocusState>()
  configuration = input.required<PublicConfiguration>()
  availableEntities = input.required<SearchEntityType[]>()
  searchEntityTypeToNumberOfMatches = input.required<ReadonlyMap<SearchEntityType, number>>()
  vocabularyTerms = input.required<VocabularyTerms>()

  onFilterItemSelected = output<SearchEntityType>()
  onNewSearchFocusState = output<SearchFocusState>()
  onRetry = output()

  protected isSearching$ = toObservable(this.isSearching)

  protected readonly terms = this.vocabService.terms(() => this.configuration().courtSystemId)

  constructor(
    readonly overlay: SearchOverlayService,
    private readonly vocabService: VocabularyTermsService,
  ) {}

  ngOnInit(): void {
    this.overlay.visible.next(true)
  }

  ngOnDestroy(): void {
    // Set timeout to avoid ExpressionChanged. We want to wait for
    // change detection to finish before updating anything
    setTimeout(() => {
      this.overlay.visible.next(false)
    })
  }
}
