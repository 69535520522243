import { Injectable, Signal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { LayoutService } from '@ftr/foundation'
import { FeatureFlagState } from '@ftr/ui-feature-flags'
import { Store } from '@ngxs/store'
import { Observable, filter, map } from 'rxjs'

@Injectable()
export class AppLayoutService implements LayoutService {
  constructor(private store: Store) {}

  isLegacyLayoutSignal(): Signal<boolean> {
    return toSignal(this.isLegacyLayout$(), { initialValue: false })
  }

  isLegacyLayout$(): Observable<boolean> {
    return this.store.select(FeatureFlagState.resolvedFeatureFlagValue('navigation-redesign')).pipe(
      filter(x => x.isCompleted()),
      map(x => (x.isSuccess() ? x._data : false)),
      map(x => !x), // Flip it since the feature flag is for the new layout, and not having it means you are on the old layout
    )
  }
}
