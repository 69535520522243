import { MediaStatus } from './media-status'

export type MediaProgressCategory = 'Live' | 'Jump' | 'SeekToEnd' | 'Other'

export class MediaProgress {
  constructor(
    public status: MediaStatus,
    public progress: number,
  ) {}

  /**
   * Simplified statuses into 4 categories
   *
   * Live: Real-time updates as the player is running
   * Jump: Any interaction that cause time to jump
   * SeekToEnd: When the audio is skipped to the end of the recording by a shortcut
   * Ignored: Any other status relating to metadata etc.
   */
  getStatusCategory(): MediaProgressCategory {
    switch (this.status) {
      case MediaStatus.Playing:
      case MediaStatus.Paused:
        return 'Live'
      case MediaStatus.SeekStarted:
      case MediaStatus.SeekUpdated:
      case MediaStatus.SeekFinished:
      case MediaStatus.JumpedToTime:
      case MediaStatus.JumpedOnClick:
        return 'Jump'
      case MediaStatus.SeekToEnd:
        return 'SeekToEnd'
      case MediaStatus.InitialMetadata:
      case MediaStatus.LoadedMetadata:
      case MediaStatus.Waiting:
      case MediaStatus.Ended:
      case MediaStatus.Errored:
      default:
        return 'Other'
    }
  }
}
