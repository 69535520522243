/* eslint-disable max-classes-per-file */
import { LiveSttResultStatus } from '@ftr/contracts/regional-api/live-stt'
import { Uuid } from '@ftr/contracts/type/shared'
import { LocalDateTime } from '@js-joda/core'

export class RealTimeAudioSegmentWithSttRemarks {
  id: Uuid
  remarks: RealTimeSttRemark[]
  showSpeakers: boolean
}

export class RealTimeHearingWithSttRemarks {
  id: Uuid
  remarks: RealTimeSttRemark[]
}

export class RealTimeSttWithRemarks {
  recordingId: Uuid
  remarks: RealTimeSttRemark[]
}

/**
 * An SttRemark is a word or group of words spoken at a time
 */
export class RealTimeSttRemark {
  id: Uuid
  /**
   * Note: this is currently an utterance worth of words.
   */
  content: string
  /**
   * The time it was spoken from
   */
  startTime: LocalDateTime
  /**
   * The time it was spoken until
   */
  endTime: LocalDateTime
  /**
   * The id of the speaker
   * speakerId is undefined for chunk states
   */
  speakerId?: Uuid
  /**
   * The name of the speaker
   * speakerName is undefined for chunk states
   */
  speakerName?: string

  /**
   * This is populated in the UI, which is used to determine if a finalized remark is sealed or not.
   * It will only apply to users with the UserGroupPermissionId. AccessSealedContent permission as
   * the API filters out sealed remarks for users without the aforementioned permission. Placeholder
   * remarks used to populate instances of SealedContentComponent will not have an isSealed property.
   *
   */
  isSealed?: boolean
  /**
   * TODO OR-2212 remove when removing `release-sealed-segments` flag
   * @deprecated will be removed with the removal of 'release-sealed-segments' flag
   */
  isSealedContentMarker?: boolean

  /**
   * Populated by the UI: sometimes we want to conceptually 'group' a subset of the SttRemarks,
   * for example we 'group' sealed segments. We don't actually contain them in DOM elements, since
   * the group's content might only be partially downloaded at request time. So the simplest way to
   * mark the boundary of any grouping is to identify the first and last remark in a grouping.
   */
  grouping?: {
    firstStartsAt?: LocalDateTime
    isFirst?: boolean
    lastEndsAt?: LocalDateTime
    isLast?: boolean
  }

  liveResult?: {
    /**
     * The live id is not guaranteed to be unique across partial/final remarks. It is commonly observed that several
     * partial & finalised results close together will have the same result id (and different content - usually one per sentence).
     *
     * The resultId &
     */
    resultId: string
    status: LiveSttResultStatus
  }

  /**
   * This property is used to apply styling to remarks that are inside a hearing.
   */
  isHearing?: boolean
}
