import { Injectable } from '@angular/core'
import { Observable, Subject, map, take } from 'rxjs'

/**
 * A request to show the dialog with a given message.
 */
export interface DialogRequest {
  name: string
  message: string
  confirmButtonLabel: string
}

/**
 * A response to the currently shown dialog.
 */
export interface DialogResponse {
  didConfirm: boolean
}

/**
 * Backing service for DialogComponent. Manages requests to
 * show the dialog as well as dialog responses.
 *
 * ### Example usage:
 *
 * ```
 * class QuestionComponent {
 *   constructor (private dialogService: DialogService) {}
 *
 *   askQuestion (): void {
 *     const response = dialogService.showDialog('Do you like cats?')).subscribe(res => console.log('Response was: ', response))
 *   }
 * }
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogRequest: Observable<DialogRequest>
  dialogResponse: Observable<DialogResponse>

  private dialogRequestsSource: Subject<DialogRequest>
  private dialogResponseSource: Subject<DialogResponse>

  constructor() {
    this.dialogRequestsSource = new Subject<DialogRequest>()
    this.dialogRequest = this.dialogRequestsSource.asObservable()

    this.dialogResponseSource = new Subject<DialogResponse>()
    this.dialogResponse = this.dialogResponseSource.asObservable()
  }

  /**
   * Called to request the dialog to be shown with a given message.
   *
   * Returns an Observable which will emit a true/false value once the
   * dialog has been confirmed/denied.
   *
   * @param message the message to prompt the user with
   * @param name a unique name for the modal (for tracking purposes)
   * @param confirmButtonLabel An option label to use for the confirmation button
   */
  showDialog(message: string, name: string, confirmButtonLabel = 'OK'): Observable<boolean> {
    this.dialogRequestsSource.next({ message, name, confirmButtonLabel })
    return this.dialogResponse.pipe(
      map(confirmation => confirmation.didConfirm),
      take(1),
    )
  }

  respondToDialog(didConfirm: boolean): void {
    this.dialogResponseSource.next({ didConfirm })
  }
}
