import { Injectable } from '@angular/core'
import { ApiClient } from '@ftr/api-shared'
import { Uuid } from '@ftr/contracts/type/shared'
import { Observable } from 'rxjs'
import { DigitalJusticeApiClientFactory } from './digital-justice-api-client.factory'

@Injectable()
export class DigitalJusticeApiClientSetupService {
  digitalJusticeApiPath: string
  readonly digitalJusticeApiClientMap = new Map<string, Observable<ApiClient>>()

  constructor(protected readonly digitalJusticeApiClientFactory: DigitalJusticeApiClientFactory) {}

  digitalJusticeApiClient(path: string, courtSystemId: Uuid): Observable<ApiClient> {
    const uniqueKey = `${path}-${courtSystemId}`
    if (!this.digitalJusticeApiClientMap.has(uniqueKey)) {
      // Note on testing: digitalJusticeApiClientFactory.build needs to return from([apiClient.object]),
      // not of(apiClient.object)
      this.digitalJusticeApiClientMap.set(uniqueKey, this.digitalJusticeApiClientFactory.build(path, courtSystemId))
    }
    return this.digitalJusticeApiClientMap.get(uniqueKey)!
  }
}
