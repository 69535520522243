<div #resultsList class="card-list">
  @for (item of searchItems$ | async; track item) {
    <a
      class="search-results-item"
      [tabIndex]="0"
      [routerLink]="item.link"
      [queryParams]="item.hasLink ? item.queryParams : (route.queryParams | async)"
      (keydown.arrowUp)="onResultUp($event)"
      (keydown.arrowDown)="onResultDown($event)"
      (keydown.enter)="onItemClick(item)"
      (click)="onItemClick(item)"
    >
      @if (configuration) {
        <ftr-search-legacy-results-item
          class="card card--borderless card--link search-results-item__item"
          [hasLink]="!!item.link"
          [searchRequestType]="searchRequestType"
          [searchResult]="item.content"
          [courtSystemConfig]="configuration"
        >
          @if (activeSearchIdLoading === item.id) {
            <ftr-line-loader class="search-results-item__loader" message="" [fitToBottom]="true"> </ftr-line-loader>
          }
        </ftr-search-legacy-results-item>
      }
    </a>
  }
</div>
<ftr-pagination [meta]="searchResponse.meta" [pageParamName]="pageParam" paginationType="top"></ftr-pagination>
