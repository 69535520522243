import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SelectComponent, ValidationErrorHintDirective } from '@ftr/forms'
import { AnchorComponent, ButtonComponent, IconComponent, TooltipModule } from '@ftr/foundation'
import { DropdownMenuComponent } from '@ftr/menus'
import { CourtSystemLogoComponent, FtrLogoComponent, HeaderLoggedOutComponent, SideNavModule } from '@ftr/ui-core'
import { AccountMenuComponent } from '~app/core/header/account-menu/account-menu.component'
import { CourtSystemDropdownComponent } from '~app/core/header/court-system-dropdown/court-system-dropdown.component'
import { MainSideNavComponent } from '~app/core/header/main-side-nav/main-side-nav.component'
import { SearchResultsOverlayLegacy } from '~app/core/header/search-container-legacy/overlay-legacy/search-results-overlay.component'
import { SearchBarLegacyComponent } from '~app/core/header/search-container-legacy/search-bar-legacy/search-bar-legacy.component'
import { SearchContainerLegacyComponent } from '~app/core/header/search-container-legacy/search-container-legacy.component'
import { SearchResultsOverlay } from '~app/core/header/search-container/overlay/search-results-overlay.component'
import { SearchBarComponent } from '~app/core/header/search-container/search-bar/search-bar.component'
import { SearchContainerComponent } from '~app/core/header/search-container/search-container.component'
import { DesktopModule } from '~app/features/desktop/desktop.module'
import { SearchLegacyModule } from '~app/features/search-legacy/search.module'
import { SearchModule } from '~app/features/search/search.module'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { LegacyOverflowMenuComponent } from '~app/shared/overflow-menu/legacy-overflow-menu.component'
import { HeaderComponent } from './header.component'

@NgModule({
  imports: [
    AnchorComponent,
    ButtonComponent,
    CommonModule,
    CourtSystemLogoComponent,
    DesktopModule,
    DropdownMenuComponent,
    FtrFormsModule,
    FtrLogoComponent,
    IconComponent,
    LegacyOverflowMenuComponent,
    RouterModule,
    SearchModule,
    SearchLegacyModule,
    SelectComponent,
    SideNavModule,
    TooltipModule,
    ValidationErrorHintDirective,
    AccountMenuComponent,
    HeaderLoggedOutComponent,
  ],
  declarations: [
    SearchContainerComponent,
    SearchResultsOverlay,
    SearchBarLegacyComponent,
    SearchBarComponent,
    CourtSystemDropdownComponent,
    MainSideNavComponent,
    HeaderComponent,
    SearchContainerLegacyComponent,
    SearchResultsOverlayLegacy,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
